export {};

declare global {
  interface Array<T> {
    findLastIndex(
      predicate: (value: T, index: number, obj: T[]) => unknown,
      thisArg?: any
    ): number;
  }
}

if (!Array.prototype.findLastIndex) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.findLastIndex = function (
    callback: (value: any, index: number, array: any[]) => unknown,
    thisArg: any
  ) {
    for (let i = this.length - 1; i >= 0; i--) {
      if (callback.call(thisArg, this[i], i, this)) return i;
    }
    return -1;
  };
}
