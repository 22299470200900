/**
 * Component that accepts <head> tags as children
 */

import React from 'react';

export function Head(props: React.ComponentProps<any>): null {
  React.Children.map(props.children, (child) => {
    if (child.type === 'title') {
      document.title = `${child.props.children} - TileDB`;
    }
  });
  return null;
}

export default React.memo(Head);
