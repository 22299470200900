import {
  UserApi_getHasSeenV2Status$Path,
  UserApi_patchHasSeenV2Status,
  UserApi_patchHasSeenV2Status$Params,
  UserApi_patchHasSeenV2Status$Response,
} from 'api-client';
import { MutateResponse, useMutateRequest } from 'hooks/useRequest';
import { useMutation } from '@tanstack/react-query';
import queryClient from 'utils/helpers/queryClient';

const useUpdateHasSeenV2 = (): MutateResponse<
  UserApi_patchHasSeenV2Status$Params,
  UserApi_patchHasSeenV2Status$Response
> => {
  const { mutateAsync, isLoading, error, data } = useMutation(
    UserApi_patchHasSeenV2Status,
    {
      onSuccess: () => {
        return queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === UserApi_getHasSeenV2Status$Path,
        });
      },
    }
  );

  return [
    useMutateRequest(mutateAsync),
    data,
    error as null | Error,
    isLoading,
  ];
};

export default useUpdateHasSeenV2;
