import { match, matchPath } from 'react-router-dom';
import {
  activityRouterPaths,
  arrayRouterPaths,
  assetsRouterPaths,
  monitorRouterPaths,
  fileRouterPaths,
  marketplaceRouterPaths,
  mlModelRouterPaths,
  notebookRouterPaths,
  overviewRouterPaths,
  settingsRouterPaths,
  taskgraphLogsRouterPaths,
  udfRouterPaths,
} from 'utils/routerPaths';
import getArrayInfoFromCache from 'utils/helpers/cache/getArrayInfoFromCache';
import getGroupInfoFromCache from 'utils/helpers/cache/getGroupInfoFromCache';
import groupRouterPaths from 'utils/routerPaths/groupRouterPaths';
import vcfRouterPaths from 'utils/routerPaths/vcfRouterPaths';
import somaRouterPaths from 'utils/routerPaths/somaRouterPaths';
import biomedicalImagingRouterPaths from 'utils/routerPaths/biomedicalImagingRouterPaths';
import geometryRouterPaths from 'utils/routerPaths/geometryRouterPaths';
import rasterRouterPaths from 'utils/routerPaths/rasterRouterPaths';
import vectorSearchRouterPaths from 'utils/routerPaths/vectorSearchRouterPaths';
import tasksRouterPaths from 'utils/routerPaths/tasksRouterPaths';
import getTaskGraphLogFromCache from 'utils/helpers/cache/getTaskGraphLogFromCache';
import getArrayTaskFromCache from 'utils/helpers/cache/getArrayTaskFromCache';
import polling from 'utils/helpers/polling';
import academyRouterPaths from 'utils/routerPaths/academyRouterPaths';
import stripSearchParamsFromPathName from '../stripSearchParamsFromPathName';
import { AssetType } from 'api-client';
import getAssetTitleForBrowsers from '../getAssetTitleForBrowsers';
import { rootPath } from 'utils/links';
import appsRouterPaths from 'utils/routerPaths/appsRouterPaths';

interface ArrayAssetParams {
  namespace: string;
  arrayId: string;
}

interface LaunchNotebookParams {
  id?: string;
  name?: string;
  namespace?: string;
}

interface GroupAssetParams {
  namespace: string;
  groupId: string;
}

interface TaskGraphLogParams {
  taskGraphId: string;
  namespace: string;
}

interface ArrayTaskParams {
  taskId: string;
}

interface ActivityDetailsParams {
  activityId: string;
}

const wrapValueInPromise = <T>(value: T): Promise<T> => Promise.resolve(value);
const getAppsTitle = (
  matches: match<LaunchNotebookParams>,
  searchParams: URLSearchParams
) => wrapValueInPromise(`Apps`);
const getOverviewTitle = (matches: match<any>, searchParams: URLSearchParams) =>
  wrapValueInPromise('Overview');
const getComputeTitle = (matches: match<any>, searchParams: URLSearchParams) =>
  wrapValueInPromise('Monitor');
const getTasksTitle = (matches: match<any>, searchParams: URLSearchParams) =>
  wrapValueInPromise('Logs • Tasks');
const getTaskgraphLogsTitle = (
  matches: match<any>,
  searchParams: URLSearchParams
) => wrapValueInPromise('Logs • Task graphs');
const getAssetsTitle =
  (asset?: AssetType, defaultTile = 'Assets') =>
  (matches: match<any>) => {
    if (!asset) {
      return wrapValueInPromise(defaultTile);
    }
    const assetTitle = getAssetTitleForBrowsers(asset);

    if (assetTitle) {
      return wrapValueInPromise(assetTitle);
    }

    return wrapValueInPromise(defaultTile);
  };
const getAcademyTitle = (matches: match<any>, searchParams: URLSearchParams) =>
  wrapValueInPromise('Academy');

const getMarketplaceTitle = (
  matches: match<any>,
  searchParams: URLSearchParams
) => wrapValueInPromise(`Marketplace`);

const getSettingsTitle = (matches: match<any>, searchParams: URLSearchParams) =>
  wrapValueInPromise('Settings');
const getArrayAssetTitle = async (
  matches: match<ArrayAssetParams>,
  searchParams: URLSearchParams
) => {
  const { params } = matches;
  const { arrayId, namespace } = params;

  const arrayInfo = await polling(
    () =>
      getArrayInfoFromCache({
        id: arrayId,
        namespace,
      }),
    { interval: 300, timeout: 10_000 }
  );

  return arrayInfo?.name || '';
};

const getGroupAssetTitle = async (
  matches: match<GroupAssetParams>,
  searchParams: URLSearchParams
) => {
  const { params } = matches;
  const { groupId, namespace } = params;

  const groupInfo = await polling(
    () =>
      getGroupInfoFromCache({
        id: groupId,
        namespace,
      }),
    { interval: 300, timeout: 10_000 }
  );

  return groupInfo?.name || '';
};

const getTaskGraphLogTitle = async (
  matches: match<TaskGraphLogParams>,
  searchParams: URLSearchParams
) => {
  const { params } = matches;
  const { taskGraphId, namespace } = params;

  const taskgraphLog = await polling(
    () =>
      getTaskGraphLogFromCache({
        id: taskGraphId,
        namespace,
      }),
    {
      interval: 300,
      timeout: 10_000,
    }
  );

  return taskgraphLog?.name || taskGraphId;
};

const getArrayTaskTitle = async (
  matches: match<ArrayTaskParams>,
  searchParams: URLSearchParams
) => {
  const { params } = matches;
  const { taskId } = params;

  const taskgraphLog = await polling(
    () =>
      getArrayTaskFromCache({
        id: taskId,
      }),
    {
      interval: 300,
      timeout: 10_000,
    }
  );

  return taskgraphLog?.name || '';
};

const getActivityDetailsTitle = async (
  matches: match<ActivityDetailsParams>,
  searchParams: URLSearchParams
) => {
  const { params } = matches;
  const { activityId } = params;

  return activityId;
};

type Handler = (
  matches: match<any>,
  searchParams: URLSearchParams
) => Promise<string>;

const rules: Record<string, Handler> = {
  [overviewRouterPaths.root]: getOverviewTitle,
  [assetsRouterPaths.arrays]: getAssetsTitle(AssetType.Array),
  [assetsRouterPaths.files]: getAssetsTitle(AssetType.File),
  [assetsRouterPaths.biomedicalImaging]: getAssetsTitle(AssetType.Bioimg),
  [assetsRouterPaths.dashboards]: getAssetsTitle(AssetType.Dashboard),
  [assetsRouterPaths.geometry]: getAssetsTitle(AssetType.Geometry),
  [assetsRouterPaths.groups]: getAssetsTitle(AssetType.Group),
  [assetsRouterPaths.mlModels]: getAssetsTitle(AssetType.MlModel),
  [assetsRouterPaths.notebooks]: getAssetsTitle(AssetType.Notebook),
  [assetsRouterPaths.pointCloud]: getAssetsTitle(AssetType.Pointcloud),
  [assetsRouterPaths.raster]: getAssetsTitle(AssetType.Raster),
  [assetsRouterPaths.soma]: getAssetsTitle(AssetType.Soma),
  [assetsRouterPaths.data]: getAssetsTitle(undefined, 'Data'),
  [assetsRouterPaths.code]: getAssetsTitle(undefined, 'Code'),
  [assetsRouterPaths.taskGraphs]: getAssetsTitle(AssetType.RegisteredTaskGraph),
  [assetsRouterPaths.udfs]: getAssetsTitle(AssetType.UserDefinedFunction),
  [assetsRouterPaths.vcf]: getAssetsTitle(AssetType.Vcf),
  [assetsRouterPaths.vector]: getAssetsTitle(AssetType.VectorSearch),
  [assetsRouterPaths.root]: getAssetsTitle(undefined, 'Data'),
  [monitorRouterPaths.taskGraphLogs]: getTaskgraphLogsTitle,
  [monitorRouterPaths.logs]: getTasksTitle,
  [monitorRouterPaths.tasks]: getTasksTitle,
  [monitorRouterPaths.root]: getComputeTitle,
  [marketplaceRouterPaths.root]: getMarketplaceTitle,
  [settingsRouterPaths.root]: getSettingsTitle,
  [arrayRouterPaths.details]: getArrayAssetTitle,
  [fileRouterPaths.details]: getArrayAssetTitle,
  [notebookRouterPaths.details]: getArrayAssetTitle,
  [udfRouterPaths.details]: getArrayAssetTitle,
  [groupRouterPaths.details]: getGroupAssetTitle,
  [vcfRouterPaths.details]: getGroupAssetTitle,
  [somaRouterPaths.details]: getGroupAssetTitle,
  [biomedicalImagingRouterPaths.details]: getGroupAssetTitle,
  [geometryRouterPaths.details]: getGroupAssetTitle,
  [rasterRouterPaths.details]: getGroupAssetTitle,
  [vectorSearchRouterPaths.details]: getGroupAssetTitle,
  [mlModelRouterPaths.details]: getArrayAssetTitle,
  [taskgraphLogsRouterPaths.details]: getTaskGraphLogTitle,
  [tasksRouterPaths.taskDetails]: getArrayTaskTitle,
  [academyRouterPaths.root]: getAcademyTitle,
  [activityRouterPaths.activityDetails]: getActivityDetailsTitle,
  [appsRouterPaths.root]: getAppsTitle,
  [rootPath]: getOverviewTitle,
};

const shouldCheckExactResult = (pathname: string): boolean => {
  if (pathname.startsWith(monitorRouterPaths.root)) {
    return false;
  }

  if (pathname.startsWith(settingsRouterPaths.root)) {
    return false;
  }

  if (pathname.startsWith(appsRouterPaths.root)) {
    return false;
  }

  return true;
};

type Options = {
  exact?: boolean;
};

const getTitleBasedOnPathname = (pathname: string, options?: Options) => {
  const { pathname: pathWithoutSearchParams, searchParams } =
    stripSearchParamsFromPathName(pathname);

  const exact = options?.exact ?? shouldCheckExactResult(pathname);

  const rulesArray = Object.entries(rules);
  for (const [path, handler] of rulesArray) {
    const matches = matchPath(pathWithoutSearchParams, {
      path,
      exact,
    });
    if (matches) {
      return handler(matches, searchParams);
    }
  }
};

export default getTitleBasedOnPathname;
