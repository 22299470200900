import { stringify } from 'query-string';

const dashboardLinks = {
  details: ({
    namespace,
    arrayName,
    arrayId,
  }: {
    namespace: string;
    arrayName: string;
    arrayId: string;
  }) => {
    const qString = stringify({
      id: arrayId,
    });
    return `/dashboards/details/${namespace}/${arrayName}?${qString}`;
  },
};

export default dashboardLinks;
