import React from 'react';

type EventTypeNames = 'mousedown' | 'touchstart';
const eventTypes: EventTypeNames[] = ['mousedown', 'touchstart'];

export const DEFAULT_IGNORE_CLASS = 'ignore-onclickoutside';

interface Options {
  ignoreClass?: string;
}

const hasIgnoreClass = (e: any, ignoreClass: string): boolean => {
  let el = e.target;
  while (el) {
    if (el.classList.contains(ignoreClass)) return true;
    el = el.parentElement;
  }

  return false;
};

const defaultProps = {
  ignoreClass: DEFAULT_IGNORE_CLASS,
};

const useOutsideClick = (
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void,
  { ignoreClass = DEFAULT_IGNORE_CLASS }: Options = defaultProps
) => {
  const handleClick = (e: MouseEvent | TouchEvent): void => {
    if (hasIgnoreClass(e, ignoreClass)) {
      return;
    }
    if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
      callback();
    }
  };

  const removeEventListeners = (): void => {
    eventTypes.forEach((type) => {
      document.removeEventListener(type, handleClick);
    });
  };

  React.useEffect(() => {
    eventTypes.forEach((type) => {
      document.addEventListener(type, handleClick);
    });

    return (): void => {
      removeEventListeners();
    };
  });
};

export default useOutsideClick;
