/* eslint-disable */
// tslint:disable
/**
 * TileDB UI REST API
 * TileDB UI
 *
 * The version of the OpenAPI document: 1.1.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import axios, { AxiosPromise, AxiosInstance, AxiosResponse } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, RequiredError } from './base';

/**
 * Credential information to access Amazon Web Services
 * @export
 * @interface AWSCredential
 */
export interface AWSCredential {
    /**
     * The ID of the access key
     * @type {string}
     * @memberof AWSCredential
     */
    access_key_id?: string;
    /**
     * The access key\'s secret. Never returned in responses.
     * @type {string}
     * @memberof AWSCredential
     */
    secret_access_key?: string;
    /**
     * The endpoint used for this credential
     * @type {string}
     * @memberof AWSCredential
     */
    endpoint?: string | null;
}
/**
 * Role information to access Amazon Web Services
 * @export
 * @interface AWSRole
 */
export interface AWSRole {
    /**
     * The role arn used to access
     * @type {string}
     * @memberof AWSRole
     */
    role_arn?: string;
    /**
     * The role external id used to access
     * @type {string}
     * @memberof AWSRole
     */
    external_id?: string;
    /**
     * The endpoint used for this role
     * @type {string}
     * @memberof AWSRole
     */
    endpoint?: string | null;
}
/**
 * user\'s academy lessons
 * @export
 * @interface AcademyLesson
 */
export interface AcademyLesson {
    /**
     * Unique ID of the lesson
     * @type {string}
     * @memberof AcademyLesson
     */
    lesson_id?: string;
    /**
     * Version of the lesson
     * @type {string}
     * @memberof AcademyLesson
     */
    lesson_version?: string;
    /**
     * Indicates if lesson is completed
     * @type {boolean}
     * @memberof AcademyLesson
     */
    completed?: boolean | null;
    /**
     * Indicates if lesson is pinned
     * @type {boolean}
     * @memberof AcademyLesson
     */
    pinned?: boolean | null;
}
/**
 * user\'s academy questionnaire
 * @export
 * @interface AcademyQuestionnaireListResponse
 */
export interface AcademyQuestionnaireListResponse {
    /**
     * Unique ID of the questionnaire
     * @type {string}
     * @memberof AcademyQuestionnaireListResponse
     */
    questionnaire_id?: string;
    /**
     * Version of the questionnaire
     * @type {string}
     * @memberof AcademyQuestionnaireListResponse
     */
    questionnaire_version?: string;
    /**
     * Indicates if questionnaire is completed
     * @type {boolean}
     * @memberof AcademyQuestionnaireListResponse
     */
    completed?: boolean;
    /**
     * Array of user answers
     * @type {Array<UserAnswer>}
     * @memberof AcademyQuestionnaireListResponse
     */
    user_answers?: Array<UserAnswer>;
}
/**
 * user\'s academy questionnaire
 * @export
 * @interface AcademyQuestionnairePutRequest
 */
export interface AcademyQuestionnairePutRequest {
    /**
     * Unique ID of the questionnaire
     * @type {string}
     * @memberof AcademyQuestionnairePutRequest
     */
    questionnaire_id?: string;
    /**
     * Version of the questionnaire
     * @type {string}
     * @memberof AcademyQuestionnairePutRequest
     */
    questionnaire_version?: string;
    /**
     * Indicates if questionnaire is completed
     * @type {boolean}
     * @memberof AcademyQuestionnairePutRequest
     */
    completed?: boolean;
    /**
     * Array of user answers
     * @type {Array<UserAnswer>}
     * @memberof AcademyQuestionnairePutRequest
     */
    user_answers?: Array<UserAnswer>;
}
/**
 * A union type which may contain a credential to access any one cloud provider.
 * @export
 * @interface AccessCredential
 */
export interface AccessCredential {
    /**
     * A user-specified name for the key
     * @type {string}
     * @memberof AccessCredential
     */
    name: string;
    /**
     * 
     * @type {CloudProvider}
     * @memberof AccessCredential
     */
    provider: CloudProvider;
    /**
     * True if this is the namespace\'s default credential to be used when connecting to the given cloud provider. There can be at most one default for each unique provider.
     * @type {boolean}
     * @memberof AccessCredential
     */
    provider_default?: boolean;
    /**
     * Time when the credential was created (rfc3339)
     * @type {string}
     * @memberof AccessCredential
     */
    created_at: string;
    /**
     * Time when the credential was last updated (rfc3339)
     * @type {string}
     * @memberof AccessCredential
     */
    updated_at: string;
    /**
     * Is this credential allowed to be used in tasks
     * @type {boolean}
     * @memberof AccessCredential
     */
    allowed_in_tasks?: boolean | null;
    /**
     * 
     * @type {AccessCredentialCredential}
     * @memberof AccessCredential
     */
    credential?: AccessCredentialCredential;
    /**
     * 
     * @type {AccessCredentialRole}
     * @memberof AccessCredential
     */
    role?: AccessCredentialRole;
    /**
     * 
     * @type {AccessCredentialToken}
     * @memberof AccessCredential
     */
    token?: AccessCredentialToken;
}
/**
 * The credential information itself. Exactly one sub-field may be set. The names match those in the CloudProvider enum.
 * @export
 * @interface AccessCredentialCredential
 */
export interface AccessCredentialCredential {
    /**
     * 
     * @type {AWSCredential}
     * @memberof AccessCredentialCredential
     */
    aws?: AWSCredential | null;
    /**
     * 
     * @type {AzureCredential}
     * @memberof AccessCredentialCredential
     */
    azure?: AzureCredential | null;
    /**
     * 
     * @type {GCPInteroperabilityCredential}
     * @memberof AccessCredentialCredential
     */
    gcp?: GCPInteroperabilityCredential | null;
}
/**
 * The role information itself. Exactly one sub-field may be set. The names match those in the CloudProvider enum.
 * @export
 * @interface AccessCredentialRole
 */
export interface AccessCredentialRole {
    /**
     * 
     * @type {AWSRole}
     * @memberof AccessCredentialRole
     */
    aws?: AWSRole | null;
}
/**
 * The token information itself. Exactly one sub-field may be set. The names match those in the CloudProvider enum.
 * @export
 * @interface AccessCredentialToken
 */
export interface AccessCredentialToken {
    /**
     * 
     * @type {AzureToken}
     * @memberof AccessCredentialToken
     */
    azure?: AzureToken | null;
    /**
     * 
     * @type {GCPServiceAccountKey}
     * @memberof AccessCredentialToken
     */
    gcp?: GCPServiceAccountKey | null;
}
/**
 * The types of an access credential
 * @export
 * @enum {string}
 */
export enum AccessCredentialType {
    Key = 'key',
    Arn = 'arn',
    AzureToken = 'azure_token',
    GcpToken = 'gcp_token'
}

/**
 * A union type which may contain a credential to access any one cloud provider.
 * @export
 * @interface AccessCredentialUpdate
 */
export interface AccessCredentialUpdate {
    /**
     * A user-specified name for the key
     * @type {string}
     * @memberof AccessCredentialUpdate
     */
    name: string;
    /**
     * True if this is the namespace\'s default credential to be used when connecting to the given cloud provider. There can be at most one default for each unique provider.
     * @type {boolean}
     * @memberof AccessCredentialUpdate
     */
    provider_default?: boolean;
    /**
     * The credential provider
     * @type {string}
     * @memberof AccessCredentialUpdate
     */
    provider?: string;
    /**
     * Is this credential allowed to be used in tasks
     * @type {boolean}
     * @memberof AccessCredentialUpdate
     */
    allowed_in_tasks?: boolean | null;
    /**
     * 
     * @type {AccessCredentialUpdateCredential}
     * @memberof AccessCredentialUpdate
     */
    credential?: AccessCredentialUpdateCredential;
    /**
     * 
     * @type {AccessCredentialRole}
     * @memberof AccessCredentialUpdate
     */
    role?: AccessCredentialRole;
    /**
     * 
     * @type {AccessCredentialToken}
     * @memberof AccessCredentialUpdate
     */
    token?: AccessCredentialToken;
}
/**
 * The credential information itself. Exactly one sub-field may be set. The names match those in the CloudProvider enum.
 * @export
 * @interface AccessCredentialUpdateCredential
 */
export interface AccessCredentialUpdateCredential {
    /**
     * 
     * @type {AccessCredentialUpdateCredentialGcp}
     * @memberof AccessCredentialUpdateCredential
     */
    gcp?: AccessCredentialUpdateCredentialGcp;
    /**
     * 
     * @type {AccessCredentialUpdateCredentialAws}
     * @memberof AccessCredentialUpdateCredential
     */
    aws?: AccessCredentialUpdateCredentialAws;
    /**
     * 
     * @type {AccessCredentialUpdateCredentialAzure}
     * @memberof AccessCredentialUpdateCredential
     */
    azure?: AccessCredentialUpdateCredentialAzure;
}
/**
 * Credential information to access Amazon Web Services
 * @export
 * @interface AccessCredentialUpdateCredentialAws
 */
export interface AccessCredentialUpdateCredentialAws {
    /**
     * The ID of the access key
     * @type {string}
     * @memberof AccessCredentialUpdateCredentialAws
     */
    access_key_id?: string;
    /**
     * The access key\'s secret. Never returned in responses.
     * @type {string}
     * @memberof AccessCredentialUpdateCredentialAws
     */
    secret_access_key?: string;
}
/**
 * Credential information to access Microsoft Azure. Each supported property is the snake_case version of its name in an Azure Storage connection string.
 * @export
 * @interface AccessCredentialUpdateCredentialAzure
 */
export interface AccessCredentialUpdateCredentialAzure {
    /**
     * The name of the Azure account to access
     * @type {string}
     * @memberof AccessCredentialUpdateCredentialAzure
     */
    account_name?: string;
    /**
     * The secret key. Never returned in responses.
     * @type {string}
     * @memberof AccessCredentialUpdateCredentialAzure
     */
    account_key?: string;
}
/**
 * Credential information to access Google cloud
 * @export
 * @interface AccessCredentialUpdateCredentialGcp
 */
export interface AccessCredentialUpdateCredentialGcp {
    /**
     * The ID of the access key
     * @type {string}
     * @memberof AccessCredentialUpdateCredentialGcp
     */
    access_key_id?: string;
    /**
     * The access key\'s secret. Never returned in responses.
     * @type {string}
     * @memberof AccessCredentialUpdateCredentialGcp
     */
    secret_access_key?: string;
}
/**
 * Object including credentials and pagination metadata
 * @export
 * @interface AccessCredentialsData
 */
export interface AccessCredentialsData {
    /**
     * List of credentials
     * @type {Array<AccessCredential>}
     * @memberof AccessCredentialsData
     */
    credentials?: Array<AccessCredential>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof AccessCredentialsData
     */
    pagination_metadata?: PaginationMetadata;
}
/**
 * account overiew to be used in overview
 * @export
 * @interface AccountOverview
 */
export interface AccountOverview {
    /**
     * number of arrays owned or shared by current user
     * @type {number}
     * @memberof AccountOverview
     */
    num_of_arrays?: number;
    /**
     * number of organizations current user is member of
     * @type {number}
     * @memberof AccountOverview
     */
    num_of_organizations?: number;
    /**
     * total of all invoices that are not paid
     * @type {number}
     * @memberof AccountOverview
     */
    total_balance: number;
}
/**
 * Type of activity logged
 * @export
 * @enum {string}
 */
export enum ActivityEventType {
    ReadSchema = 'read_schema',
    MaxBufferSizes = 'max_buffer_sizes',
    NonEmptyDomain = 'non_empty_domain',
    QueryRead = 'query_read',
    QueryWrite = 'query_write',
    Create = 'create',
    Delete = 'delete',
    Register = 'register',
    Deregister = 'deregister',
    Udf = 'udf',
    ArrayMetadataGet = 'array_metadata_get',
    ArrayMetadataUpdate = 'array_metadata_update',
    EstimatedResultSizes = 'estimated_result_sizes',
    Update = 'update',
    Info = 'info',
    Run = 'run'
}

/**
 * actions a user can take on an array
 * @export
 * @enum {string}
 */
export enum ArrayActions {
    Read = 'read',
    Write = 'write',
    Edit = 'edit',
    ReadArrayLogs = 'read_array_logs',
    ReadArrayInfo = 'read_array_info',
    ReadArraySchema = 'read_array_schema'
}

/**
 * Actvity of an Array. Valid to display only if there is actual I/O.
 * @export
 * @interface ArrayActivityLog
 */
export interface ArrayActivityLog {
    /**
     * time event took place (RFC3339)
     * @type {string}
     * @memberof ArrayActivityLog
     */
    event_at: string;
    /**
     * 
     * @type {ActivityEventType}
     * @memberof ArrayActivityLog
     */
    action: ActivityEventType;
    /**
     * Optional work carried out in the context of this activity
     * @type {string}
     * @memberof ArrayActivityLog
     */
    action_description: string;
    /**
     * User who performed action
     * @type {string}
     * @memberof ArrayActivityLog
     */
    username: string;
    /**
     * Bytes sent to client
     * @type {number}
     * @memberof ArrayActivityLog
     */
    bytes_sent: number;
    /**
     * Bytes received from client
     * @type {number}
     * @memberof ArrayActivityLog
     */
    bytes_received: number;
    /**
     * uuid of associated array task
     * @type {string}
     * @memberof ArrayActivityLog
     */
    array_task_id?: string;
    /**
     * id of the activity
     * @type {string}
     * @memberof ArrayActivityLog
     */
    id: string;
    /**
     * ranges for query
     * @type {string}
     * @memberof ArrayActivityLog
     */
    query_ranges?: string;
    /**
     * stats for query
     * @type {string}
     * @memberof ArrayActivityLog
     */
    query_stats?: string;
}
/**
 * Object including array tasks and metadata
 * @export
 * @interface ArrayActivityLogData
 */
export interface ArrayActivityLogData {
    /**
     * Array ArrayActivityLog
     * @type {Array<ArrayActivityLog>}
     * @memberof ArrayActivityLogData
     */
    activitylogs?: Array<ArrayActivityLog>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof ArrayActivityLogData
     */
    pagination_metadata?: PaginationMetadata;
}
/**
 * Object including array info and pagination metadata
 * @export
 * @interface ArrayBrowserData
 */
export interface ArrayBrowserData {
    /**
     * Array Info
     * @type {Array<ArrayInfo>}
     * @memberof ArrayBrowserData
     */
    arrays?: Array<ArrayInfo>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof ArrayBrowserData
     */
    pagination_metadata?: PaginationMetadata;
}
/**
 * Object for ui array tasks browser page
 * @export
 * @interface ArrayBrowserSidebar
 */
export interface ArrayBrowserSidebar {
    /**
     * list of all unique namespaces to display
     * @type {Array<string>}
     * @memberof ArrayBrowserSidebar
     */
    namespaces?: Array<string>;
    /**
     * A count of \"all\" of category
     * @type {number}
     * @memberof ArrayBrowserSidebar
     */
    result_count_for_all?: number;
    /**
     * A map that includes the result count by namespace
     * @type {object}
     * @memberof ArrayBrowserSidebar
     */
    result_count_by_namespace?: object;
}
/**
 * Object including array versions (list of Unix epoch timestamps in milliseconds) and pagination metadata
 * @export
 * @interface ArrayEndTimetsampsData
 */
export interface ArrayEndTimetsampsData {
    /**
     * List of timestamps expressed in milliseconds since Unix epoch
     * @type {Array<number>}
     * @memberof ArrayEndTimetsampsData
     */
    end_timestamps?: Array<number>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof ArrayEndTimetsampsData
     */
    pagination_metadata?: PaginationMetadata;
}
/**
 * A user-favorite array item
 * @export
 * @interface ArrayFavorite
 */
export interface ArrayFavorite {
    /**
     * unique UUID of the array
     * @type {string}
     * @memberof ArrayFavorite
     */
    array_uuid?: string;
    /**
     * the namespace of the array
     * @type {string}
     * @memberof ArrayFavorite
     */
    namespace?: string;
    /**
     * the name of the array
     * @type {string}
     * @memberof ArrayFavorite
     */
    name?: string;
}
/**
 * Object including array favorites and pagination metadata
 * @export
 * @interface ArrayFavoritesData
 */
export interface ArrayFavoritesData {
    /**
     * List of array infos
     * @type {Array<ArrayInfo>}
     * @memberof ArrayFavoritesData
     */
    arrays?: Array<ArrayInfo>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof ArrayFavoritesData
     */
    pagination_metadata?: PaginationMetadata;
}
/**
 * info of an array
 * @export
 * @interface ArrayInfo
 */
export interface ArrayInfo {
    /**
     * uuid
     * @type {string}
     * @memberof ArrayInfo
     */
    id: string;
    /**
     * uri of array (url encoded)
     * @type {string}
     * @memberof ArrayInfo
     */
    uri: string;
    /**
     * array of tags associated with the array
     * @type {Array<string>}
     * @memberof ArrayInfo
     */
    tags: Array<string>;
    /**
     * namespace array is in
     * @type {string}
     * @memberof ArrayInfo
     */
    namespace: string;
    /**
     * size in bytes of array
     * @type {number}
     * @memberof ArrayInfo
     */
    size?: number | null;
    /**
     * Datetime array was last accessed in UTC
     * @type {string}
     * @memberof ArrayInfo
     */
    last_accessed: string;
    /**
     * description of array
     * @type {string}
     * @memberof ArrayInfo
     */
    description: string;
    /**
     * description of array
     * @type {string}
     * @memberof ArrayInfo
     */
    name: string;
    /**
     * list of actions user is allowed to do on this array
     * @type {Array<ArrayActions>}
     * @memberof ArrayInfo
     */
    allowed_actions: Array<ArrayActions>;
    /**
     * License identifier from SPDX License List or Custom
     * @type {string}
     * @memberof ArrayInfo
     */
    license_id?: string;
    /**
     * License text
     * @type {string}
     * @memberof ArrayInfo
     */
    license_text?: string;
    /**
     * logo (base64 encoded) for the array. Optional
     * @type {string}
     * @memberof ArrayInfo
     */
    logo?: string;
    /**
     * 
     * @type {ArrayType}
     * @memberof ArrayInfo
     */
    type?: ArrayType;
    /**
     * number of unique namespaces this array is shared with
     * @type {number}
     * @memberof ArrayInfo
     */
    share_count: number;
    /**
     * Suggests if the array was shared to public by owner
     * @type {boolean}
     * @memberof ArrayInfo
     */
    public_share?: boolean;
    /**
     * the name of the access credentials to use. if unset, the default credentials will be used
     * @type {string}
     * @memberof ArrayInfo
     */
    access_credentials_name: string;
    /**
     * Depends on the namespace asking, denotes the existence of subscription of namespace to this array
     * @type {boolean}
     * @memberof ArrayInfo
     */
    namespace_subscribed?: boolean;
    /**
     * URI for user with TileDB cloud service
     * @type {string}
     * @memberof ArrayInfo
     */
    tiledb_uri: string;
    /**
     * list of pricing created for this array
     * @type {Array<Pricing>}
     * @memberof ArrayInfo
     */
    pricing?: Array<Pricing>;
    /**
     * 
     * @type {FileType}
     * @memberof ArrayInfo
     */
    file_type?: FileType;
    /**
     * map of file properties created for this array
     * @type {{ [key: string]: string; }}
     * @memberof ArrayInfo
     */
    file_properties?: { [key: string]: string; };
    /**
     * Suggests if the array is in read_only mode
     * @type {boolean}
     * @memberof ArrayInfo
     */
    read_only?: boolean | null;
    /**
     * Indicates whether the array is in user favorites
     * @type {boolean}
     * @memberof ArrayInfo
     */
    is_favorite?: boolean;
    /**
     * Datetime array was registered with tiledb
     * @type {string}
     * @memberof ArrayInfo
     */
    created_at: string;
    /**
     * The region where the array is saved to
     * @type {string}
     * @memberof ArrayInfo
     */
    region?: string | null;
}
/**
 * Metadata from array in Json format
 * @export
 * @interface ArrayMetadata
 */
export interface ArrayMetadata {
    /**
     * Metadata key
     * @type {string}
     * @memberof ArrayMetadata
     */
    key?: string;
    /**
     * 
     * @type {object}
     * @memberof ArrayMetadata
     */
    value?: object;
}
/**
 * Params to register a new array
 * @export
 * @interface ArrayRegisterParams
 */
export interface ArrayRegisterParams {
    /**
     * uri of array (url encoded)
     * @type {string}
     * @memberof ArrayRegisterParams
     */
    uri: string;
    /**
     * array of tags associated with the array
     * @type {Array<string>}
     * @memberof ArrayRegisterParams
     */
    tags?: Array<string>;
    /**
     * namespace array is in
     * @type {string}
     * @memberof ArrayRegisterParams
     */
    namespace: string;
    /**
     * description of array
     * @type {string}
     * @memberof ArrayRegisterParams
     */
    description?: string;
    /**
     * description of array
     * @type {string}
     * @memberof ArrayRegisterParams
     */
    name: string;
    /**
     * License identifier from SPDX License List or Custom
     * @type {string}
     * @memberof ArrayRegisterParams
     */
    license_id?: string;
    /**
     * License text
     * @type {string}
     * @memberof ArrayRegisterParams
     */
    license_text?: string;
    /**
     * logo (base64 encoded) for the array. Optional
     * @type {string}
     * @memberof ArrayRegisterParams
     */
    logo?: string;
    /**
     * the name of the access credentials to use. if unset, the default credentials will be used
     * @type {string}
     * @memberof ArrayRegisterParams
     */
    access_credentials_name: string;
    /**
     * URI for user with TileDB cloud service
     * @type {string}
     * @memberof ArrayRegisterParams
     */
    tiledb_uri?: string;
}
/**
 * Sample data from array
 * @export
 * @interface ArraySample
 */
export interface ArraySample {
    /**
     * List of dimensions
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ArraySample
     */
    dimensions?: Array<{ [key: string]: object; }>;
    /**
     * List of attributes
     * @type {Array<{ [key: string]: object; }>}
     * @memberof ArraySample
     */
    attributes?: Array<{ [key: string]: object; }>;
}
/**
 * ArraySchema
 * @export
 * @interface ArraySchema
 */
export interface ArraySchema {
    /**
     * URI of schema
     * @type {string}
     * @memberof ArraySchema
     */
    uri?: string;
    /**
     * file format version
     * @type {Array<number>}
     * @memberof ArraySchema
     */
    version?: Array<number>;
    /**
     * 
     * @type {ArrayType}
     * @memberof ArraySchema
     */
    arrayType: ArrayType;
    /**
     * 
     * @type {Layout}
     * @memberof ArraySchema
     */
    tileOrder: Layout;
    /**
     * 
     * @type {Layout}
     * @memberof ArraySchema
     */
    cellOrder: Layout;
    /**
     * Capacity of array
     * @type {number}
     * @memberof ArraySchema
     */
    capacity: number;
    /**
     * 
     * @type {FilterPipeline}
     * @memberof ArraySchema
     */
    coordsFilterPipeline: FilterPipeline;
    /**
     * 
     * @type {FilterPipeline}
     * @memberof ArraySchema
     */
    offsetFilterPipeline: FilterPipeline;
    /**
     * 
     * @type {Domain}
     * @memberof ArraySchema
     */
    domain: Domain;
    /**
     * Attributes of array
     * @type {Array<Attribute>}
     * @memberof ArraySchema
     */
    attributes: Array<Attribute>;
    /**
     * True if the array allows coordinate duplicates. Applicable only to sparse arrays.
     * @type {boolean}
     * @memberof ArraySchema
     */
    allowsDuplicates?: boolean;
}
/**
 * details for sharing a given array
 * @export
 * @interface ArraySharing
 */
export interface ArraySharing {
    /**
     * List of permitted actions
     * @type {Array<ArrayActions>}
     * @memberof ArraySharing
     */
    actions: Array<ArrayActions>;
    /**
     * namespace being granted array access can be a user or organization
     * @type {string}
     * @memberof ArraySharing
     */
    namespace: string;
    /**
     * 
     * @type {NamespaceType}
     * @memberof ArraySharing
     */
    namespace_type: NamespaceType;
}
/**
 * details for sharing a given array
 * @export
 * @interface ArraySharingRequestParams
 */
export interface ArraySharingRequestParams {
    /**
     * List of permitted actions
     * @type {Array<ArrayActions>}
     * @memberof ArraySharingRequestParams
     */
    actions: Array<ArrayActions>;
    /**
     * namespace being granted array access can be a user or organization
     * @type {string}
     * @memberof ArraySharingRequestParams
     */
    namespace: string;
}
/**
 * Synchronous Task to Run
 * @export
 * @interface ArrayTask
 */
export interface ArrayTask {
    /**
     * task id
     * @type {string}
     * @memberof ArrayTask
     */
    id: string;
    /**
     * Optional task name
     * @type {string}
     * @memberof ArrayTask
     */
    name: string;
    /**
     * Optional task description (Task \'s purpose)
     * @type {string}
     * @memberof ArrayTask
     */
    description: string;
    /**
     * 
     * @type {ArrayInfo}
     * @memberof ArrayTask
     */
    array_metadata?: ArrayInfo;
    /**
     * 
     * @type {DomainArray}
     * @memberof ArrayTask
     */
    subarray?: DomainArray;
    /**
     * memory allocated to task in bytes
     * @type {number}
     * @memberof ArrayTask
     */
    memory: number;
    /**
     * millicpu allocated to task
     * @type {number}
     * @memberof ArrayTask
     */
    cpu: number;
    /**
     * namespace task is tied to
     * @type {string}
     * @memberof ArrayTask
     */
    namespace: string;
    /**
     * 
     * @type {ArrayTaskStatus}
     * @memberof ArrayTask
     */
    status: ArrayTaskStatus;
    /**
     * The reason the array task status is in the state
     * @type {string}
     * @memberof ArrayTask
     */
    status_message?: string | null;
    /**
     * Start time RFC3339 for job
     * @type {string}
     * @memberof ArrayTask
     */
    start_time?: string;
    /**
     * Finish time RFC3339 for job
     * @type {string}
     * @memberof ArrayTask
     */
    finish_time?: string;
    /**
     * Total accumulated for task in USD, example is $0.12
     * @type {number}
     * @memberof ArrayTask
     */
    cost: number;
    /**
     * Total accumulated for egress task in USD, example is $0.12
     * @type {number}
     * @memberof ArrayTask
     */
    egress_cost?: number;
    /**
     * Cost accumulated for access task in USD, example is $0.12
     * @type {number}
     * @memberof ArrayTask
     */
    access_cost?: number;
    /**
     * 
     * @type {QueryType}
     * @memberof ArrayTask
     */
    query_type?: QueryType;
    /**
     * Optional actual code that is going to be executed
     * @type {string}
     * @memberof ArrayTask
     */
    udf_code?: string;
    /**
     * Optional actual language used to express udf_code
     * @type {string}
     * @memberof ArrayTask
     */
    udf_language?: string;
    /**
     * Optional actual sql query that is going to be executed
     * @type {string}
     * @memberof ArrayTask
     */
    sql_query?: string;
    /**
     * username that executed this task
     * @type {string}
     * @memberof ArrayTask
     */
    username?: string;
    /**
     * 
     * @type {ArrayTaskType}
     * @memberof ArrayTask
     */
    type: ArrayTaskType;
    /**
     * Array activity logs for task
     * @type {Array<ArrayActivityLog>}
     * @memberof ArrayTask
     */
    activity?: Array<ArrayActivityLog>;
    /**
     * logs from array task
     * @type {string}
     * @memberof ArrayTask
     */
    logs: string;
    /**
     * duration in nanoseconds of an array task
     * @type {number}
     * @memberof ArrayTask
     */
    duration?: number;
    /**
     * SQL queries or commands to run before main sql query
     * @type {Array<string>}
     * @memberof ArrayTask
     */
    sql_init_commands?: Array<string>;
    /**
     * SQL query parameters
     * @type {Array<object>}
     * @memberof ArrayTask
     */
    sql_parameters?: Array<object>;
    /**
     * 
     * @type {ResultFormat}
     * @memberof ArrayTask
     */
    result_format?: ResultFormat;
    /**
     * If set, the ID of the log for the task graph that this was part of. 
     * @type {string}
     * @memberof ArrayTask
     */
    task_graph_uuid?: string;
    /**
     * If set, the client-defined ID of the node within this task\'s graph. 
     * @type {string}
     * @memberof ArrayTask
     */
    client_node_uuid?: string;
    /**
     * The name of the cloud provider where this task executed.
     * @type {string}
     * @memberof ArrayTask
     */
    cloud_provider?: string;
    /**
     * The region of the cloud provider where this task executed.
     * @type {string}
     * @memberof ArrayTask
     */
    cloud_region?: string;
}
/**
 * Object for ui array tasks browser page
 * @export
 * @interface ArrayTaskBrowserData
 */
export interface ArrayTaskBrowserData {
    /**
     * Array Tasks
     * @type {Array<ArrayTask>}
     * @memberof ArrayTaskBrowserData
     */
    array_tasks: Array<ArrayTask>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof ArrayTaskBrowserData
     */
    pagination_metadata: PaginationMetadata;
}
/**
 * Object for ui array tasks browser page
 * @export
 * @interface ArrayTaskBrowserSidebarData
 */
export interface ArrayTaskBrowserSidebarData {
    /**
     * list of all unique organizations the user is part of that have array tasks
     * @type {Array<string>}
     * @memberof ArrayTaskBrowserSidebarData
     */
    organizations?: Array<string>;
    /**
     * A count of \"all\"
     * @type {number}
     * @memberof ArrayTaskBrowserSidebarData
     */
    result_count_for_all?: number;
    /**
     * A map that includes the result count by namespace
     * @type {object}
     * @memberof ArrayTaskBrowserSidebarData
     */
    result_count_by_namespace?: object;
}
/**
 * Status of array task
 * @export
 * @enum {string}
 */
export enum ArrayTaskStatus {
    QUEUED = 'QUEUED',
    FAILED = 'FAILED',
    COMPLETED = 'COMPLETED',
    RUNNING = 'RUNNING',
    DENIED = 'DENIED',
    UNKNOWN = 'UNKNOWN',
    CANCELLED = 'CANCELLED',
    RESOURCESUNAVAILABLE = 'RESOURCES_UNAVAILABLE'
}

/**
 * Synchronous Task Type
 * @export
 * @enum {string}
 */
export enum ArrayTaskType {
    SQL = 'SQL',
    UDF = 'UDF',
    QUERY = 'QUERY',
    GENERICUDF = 'GENERIC_UDF',
    BATCHUDF = 'BATCH_UDF',
    CLIENTCOMPUTATION = 'CLIENT_COMPUTATION'
}

/**
 * TileDB array type
 * @export
 * @enum {string}
 */
export enum ArrayType {
    Dense = 'dense',
    Sparse = 'sparse'
}

/**
 * 
 * @export
 * @interface AssetActivityLog
 */
export interface AssetActivityLog {
    /**
     * The ID of the activity
     * @type {string}
     * @memberof AssetActivityLog
     */
    id: string;
    /**
     * time event took place (RFC3339)
     * @type {string}
     * @memberof AssetActivityLog
     */
    event_at: string;
    /**
     * type of the event
     * @type {string}
     * @memberof AssetActivityLog
     */
    action: string;
    /**
     * User who performed action
     * @type {string}
     * @memberof AssetActivityLog
     */
    username: string;
    /**
     * uuid of associated array task
     * @type {string}
     * @memberof AssetActivityLog
     */
    array_task_id?: string;
    /**
     * 
     * @type {AssetActivityLogAsset}
     * @memberof AssetActivityLog
     */
    asset: AssetActivityLogAsset;
}
/**
 * The asset details
 * @export
 * @interface AssetActivityLogAsset
 */
export interface AssetActivityLogAsset {
    /**
     * The asset ID
     * @type {string}
     * @memberof AssetActivityLogAsset
     */
    id?: string;
    /**
     * The asset name
     * @type {string}
     * @memberof AssetActivityLogAsset
     */
    name?: string;
    /**
     * The namespace that the asset belongs to
     * @type {string}
     * @memberof AssetActivityLogAsset
     */
    namespace?: string;
    /**
     * 
     * @type {AssetType}
     * @memberof AssetActivityLogAsset
     */
    asset_type?: AssetType;
}
/**
 * The technology that an asset is backed by
 * @export
 * @enum {string}
 */
export enum AssetBackingType {
    Array = 'array',
    Group = 'group'
}

/**
 * metadata of an asset
 * @export
 * @interface AssetInfo
 */
export interface AssetInfo {
    /**
     * unique ID of a registered asset
     * @type {string}
     * @memberof AssetInfo
     */
    uuid: string;
    /**
     * 
     * @type {AssetType}
     * @memberof AssetInfo
     */
    asset_type: AssetType;
    /**
     * 
     * @type {AssetBackingType}
     * @memberof AssetInfo
     */
    asset_backing_type: AssetBackingType;
    /**
     * 
     * @type {AssetOwnershipLevel}
     * @memberof AssetInfo
     */
    asset_ownership_level: AssetOwnershipLevel;
    /**
     * namespace_name that the asset is registered to
     * @type {string}
     * @memberof AssetInfo
     */
    namespace_name: string;
    /**
     * namespace_uuid that the asset is registered to
     * @type {string}
     * @memberof AssetInfo
     */
    namespace_uuid: string;
    /**
     * name of asset
     * @type {string}
     * @memberof AssetInfo
     */
    name: string;
    /**
     * mime type of the asset
     * @type {string}
     * @memberof AssetInfo
     */
    mime_type: string;
    /**
     * Time when the asset was created (rfc3339)
     * @type {string}
     * @memberof AssetInfo
     */
    created_at: string;
}
/**
 * Asset associated with a TileDB Cloud account
 * @export
 * @interface AssetListResponse
 */
export interface AssetListResponse {
    /**
     * asset information for each asset
     * @type {Array<AssetInfo>}
     * @memberof AssetListResponse
     */
    data?: Array<AssetInfo>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof AssetListResponse
     */
    pagination_metadata?: PaginationMetadata;
}
/**
 * List of assets grouped by the asset and ownership_level
 * @export
 * @interface AssetListSummariesResponse
 */
export interface AssetListSummariesResponse {
    /**
     * 
     * @type {AssetListSummariesResponseData}
     * @memberof AssetListSummariesResponse
     */
    data?: AssetListSummariesResponseData;
}
/**
 * asset information for each asset
 * @export
 * @interface AssetListSummariesResponseData
 */
export interface AssetListSummariesResponseData {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AssetListSummariesResponseData
     */
    owned?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AssetListSummariesResponseData
     */
    shared?: { [key: string]: number; };
}
/**
 * Custom storage locations on a per-asset type basis. If any is unset, a suffix of the owning (user/organization) `default_s3_path` is used. 
 * @export
 * @interface AssetLocations
 */
export interface AssetLocations {
    /**
     * 
     * @type {StorageLocation}
     * @memberof AssetLocations
     */
    arrays?: StorageLocation;
    /**
     * 
     * @type {StorageLocation}
     * @memberof AssetLocations
     */
    files?: StorageLocation;
    /**
     * 
     * @type {StorageLocation}
     * @memberof AssetLocations
     */
    ml_models?: StorageLocation;
    /**
     * 
     * @type {StorageLocation}
     * @memberof AssetLocations
     */
    notebooks?: StorageLocation;
    /**
     * 
     * @type {StorageLocation}
     * @memberof AssetLocations
     */
    task_graphs?: StorageLocation;
    /**
     * 
     * @type {StorageLocation}
     * @memberof AssetLocations
     */
    udfs?: StorageLocation;
    /**
     * 
     * @type {StorageLocation}
     * @memberof AssetLocations
     */
    groups?: StorageLocation;
}
/**
 * The ownership level of the asset
 * @export
 * @enum {string}
 */
export enum AssetOwnershipLevel {
    Owned = 'owned',
    Shared = 'shared'
}

/**
 * Asset types represented as TileDB arrays
 * @export
 * @enum {string}
 */
export enum AssetType {
    Array = 'array',
    Notebook = 'notebook',
    Dashboard = 'dashboard',
    UserDefinedFunction = 'user_defined_function',
    MlModel = 'ml_model',
    File = 'file',
    RegisteredTaskGraph = 'registered_task_graph',
    Group = 'group',
    Vcf = 'vcf',
    Soma = 'soma',
    Pointcloud = 'pointcloud',
    Bioimg = 'bioimg',
    Geometry = 'geometry',
    Raster = 'raster',
    VectorSearch = 'vector_search'
}

/**
 * Attribute of array
 * @export
 * @interface Attribute
 */
export interface Attribute {
    /**
     * Attribute name
     * @type {string}
     * @memberof Attribute
     */
    name: string;
    /**
     * 
     * @type {Datatype}
     * @memberof Attribute
     */
    type: Datatype;
    /**
     * 
     * @type {FilterPipeline}
     * @memberof Attribute
     */
    filterPipeline: FilterPipeline;
    /**
     * Attribute number of values per cell
     * @type {number}
     * @memberof Attribute
     */
    cellValNum: number;
    /**
     * Is attribute nullable
     * @type {boolean}
     * @memberof Attribute
     */
    nullable?: boolean;
    /**
     * The default fill value
     * @type {Array<number>}
     * @memberof Attribute
     */
    fillValue?: Array<number>;
}
/**
 * Credential information to access Microsoft Azure. Each supported property is the snake_case version of its name in an Azure Storage connection string.
 * @export
 * @interface AzureCredential
 */
export interface AzureCredential {
    /**
     * The name of the Azure account to access
     * @type {string}
     * @memberof AzureCredential
     */
    account_name?: string;
    /**
     * The secret key. Never returned in responses.
     * @type {string}
     * @memberof AzureCredential
     */
    account_key?: string;
}
/**
 * Token information to access Azure services
 * @export
 * @interface AzureToken
 */
export interface AzureToken {
    /**
     * The account name of the configuration
     * @type {string}
     * @memberof AzureToken
     */
    account_name?: string;
    /**
     * The token to use for this account
     * @type {string}
     * @memberof AzureToken
     */
    sas_token?: string;
}
/**
 * Backoff is a backoff strategy to use within retryStrategy
 * @export
 * @interface Backoff
 */
export interface Backoff {
    /**
     * Duration is the amount to back off. Default unit is seconds, but could also be a duration (e.g. \"2m\", \"1h\")
     * @type {string}
     * @memberof Backoff
     */
    duration?: string;
    /**
     * Factor is a factor to multiply the base duration after each failed retry
     * @type {number}
     * @memberof Backoff
     */
    factor?: number;
    /**
     * MaxDuration is the maximum amount of time allowed for the backoff strategy
     * @type {string}
     * @memberof Backoff
     */
    maxDuration?: string;
}
/**
 * overview visible data
 * @export
 * @interface BillingOverview
 */
export interface BillingOverview {
    /**
     * 
     * @type {Invoice}
     * @memberof BillingOverview
     */
    current_invoice: Invoice;
    /**
     * 
     * @type {AccountOverview}
     * @memberof BillingOverview
     */
    account_overview: AccountOverview;
}
/**
 * Parameters for ingesting bioimaging data. See tiledb.cloud.bioimg.ingestion.ingest for details. 
 * @export
 * @interface BioImagingIngestionRequest
 */
export interface BioImagingIngestionRequest {
    /**
     * The source file or directory to use.
     * @type {string}
     * @memberof BioImagingIngestionRequest
     */
    source?: string;
    /**
     * The name of the ARN credentials to use to read source data and write to destination.
     * @type {string}
     * @memberof BioImagingIngestionRequest
     */
    access_credentials_name?: string;
    /**
     * The TileDB path to store the output group(s).
     * @type {string}
     * @memberof BioImagingIngestionRequest
     */
    destination?: string;
    /**
     * The name of the created taskgraph.
     * @type {string}
     * @memberof BioImagingIngestionRequest
     */
    taskgraph_name?: string;
    /**
     * The memory that will be needed in GiB.
     * @type {string}
     * @memberof BioImagingIngestionRequest
     */
    memory?: string;
    /**
     * The number of threads.
     * @type {number}
     * @memberof BioImagingIngestionRequest
     */
    threads?: number;
    /**
     * The number of cpu cores.
     * @type {number}
     * @memberof BioImagingIngestionRequest
     */
    cpu?: number;
    /**
     * Should ingest be in chunks.
     * @type {boolean}
     * @memberof BioImagingIngestionRequest
     */
    chunked?: boolean;
    /**
     * Verbose ingest or not.
     * @type {boolean}
     * @memberof BioImagingIngestionRequest
     */
    verbose?: boolean;
}
/**
 * A service where data is stored or computations take place.
 * @export
 * @enum {string}
 */
export enum CloudProvider {
    AWS = 'AWS',
    AZURE = 'AZURE',
    GCP = 'GCP'
}

/**
 * Token to use regarding the email confirmation
 * @export
 * @interface ConfirmEmail
 */
export interface ConfirmEmail {
    /**
     * confirm email api token for user
     * @type {string}
     * @memberof ConfirmEmail
     */
    token?: string;
}
/**
 * data to create user in organization
 * @export
 * @interface CreateOrganizationUserParams
 */
export interface CreateOrganizationUserParams {
    /**
     * username for user
     * @type {string}
     * @memberof CreateOrganizationUserParams
     */
    username: string;
    /**
     * 
     * @type {OrganizationRoles}
     * @memberof CreateOrganizationUserParams
     */
    role: OrganizationRoles;
}
/**
 * TileDB data type
 * @export
 * @enum {string}
 */
export enum Datatype {
    INT32 = 'INT32',
    INT64 = 'INT64',
    FLOAT32 = 'FLOAT32',
    FLOAT64 = 'FLOAT64',
    CHAR = 'CHAR',
    INT8 = 'INT8',
    UINT8 = 'UINT8',
    INT16 = 'INT16',
    UINT16 = 'UINT16',
    UINT32 = 'UINT32',
    UINT64 = 'UINT64',
    STRINGASCII = 'STRING_ASCII',
    STRINGUTF8 = 'STRING_UTF8',
    STRINGUTF16 = 'STRING_UTF16',
    STRINGUTF32 = 'STRING_UTF32',
    STRINGUCS2 = 'STRING_UCS2',
    STRINGUCS4 = 'STRING_UCS4',
    ANY = 'ANY'
}

/**
 * The depth param for asset listing
 * @export
 * @enum {string}
 */
export enum DepthParam {
    Root = 'root',
    All = 'all'
}

/**
 * Dimension of array
 * @export
 * @interface Dimension
 */
export interface Dimension {
    /**
     * Dimension name
     * @type {string}
     * @memberof Dimension
     */
    name?: string;
    /**
     * 
     * @type {Datatype}
     * @memberof Dimension
     */
    type: Datatype;
    /**
     * 
     * @type {DomainArray}
     * @memberof Dimension
     */
    domain: DomainArray;
    /**
     * Is tile extent null
     * @type {boolean}
     * @memberof Dimension
     */
    nullTileExtent: boolean;
    /**
     * 
     * @type {DimensionTileExtent}
     * @memberof Dimension
     */
    tileExtent?: DimensionTileExtent;
    /**
     * 
     * @type {FilterPipeline}
     * @memberof Dimension
     */
    filterPipeline?: FilterPipeline;
}
/**
 * Extent of tile
 * @export
 * @interface DimensionTileExtent
 */
export interface DimensionTileExtent {
    /**
     * 
     * @type {number}
     * @memberof DimensionTileExtent
     */
    int8?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionTileExtent
     */
    uint8?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionTileExtent
     */
    int16?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionTileExtent
     */
    uint16?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionTileExtent
     */
    int32?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionTileExtent
     */
    uint32?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionTileExtent
     */
    int64?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionTileExtent
     */
    uint64?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionTileExtent
     */
    float32?: number;
    /**
     * 
     * @type {number}
     * @memberof DimensionTileExtent
     */
    float64?: number;
}
/**
 * Domain of array
 * @export
 * @interface Domain
 */
export interface Domain {
    /**
     * 
     * @type {Datatype}
     * @memberof Domain
     */
    type: Datatype;
    /**
     * 
     * @type {Layout}
     * @memberof Domain
     */
    tileOrder: Layout;
    /**
     * 
     * @type {Layout}
     * @memberof Domain
     */
    cellOrder: Layout;
    /**
     * Array of dimensions
     * @type {Array<Dimension>}
     * @memberof Domain
     */
    dimensions: Array<Dimension>;
}
/**
 * Domain object for an array of each type
 * @export
 * @interface DomainArray
 */
export interface DomainArray {
    /**
     * 
     * @type {Array<number>}
     * @memberof DomainArray
     */
    int8?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DomainArray
     */
    uint8?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DomainArray
     */
    int16?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DomainArray
     */
    uint16?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DomainArray
     */
    int32?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DomainArray
     */
    uint32?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DomainArray
     */
    int64?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DomainArray
     */
    uint64?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DomainArray
     */
    float32?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DomainArray
     */
    float64?: Array<number>;
}
/**
 * The record of a check of a single domain\'s status.
 * @export
 * @interface DomainCheckResult
 */
export interface DomainCheckResult {
    /**
     * The timestamp when the check was performed.
     * @type {string}
     * @memberof DomainCheckResult
     */
    time: string;
    /**
     * 
     * @type {DomainCheckStatus}
     * @memberof DomainCheckResult
     */
    status: DomainCheckStatus;
}
/**
 * The status of a single check on a domain\'s status.
 * @export
 * @enum {string}
 */
export enum DomainCheckStatus {
    Verified = 'verified',
    Failed = 'failed',
    Error = 'error'
}

/**
 * The current status of the claim on the domain.
 * @export
 * @enum {string}
 */
export enum DomainVerificationStatus {
    Unverified = 'unverified',
    Verified = 'verified',
    GracePeriod = 'grace_period',
    Failed = 'failed'
}

/**
 * Beta features that the user/organization has access to
 * @export
 * @enum {string}
 */
export enum EnabledFeature {
    Monetization = 'monetization',
    Notebooks = 'notebooks',
    NotebookSharing = 'notebook_sharing',
    Groups = 'groups',
    DomainSso = 'domain_sso',
    Ingestion = 'ingestion',
    Academy = 'academy'
}

/**
 * TileDB Encryption type
 * @export
 * @enum {string}
 */
export enum EncryptionType {
    NOENCRYPTION = 'NO_ENCRYPTION',
    AES256GCM = 'AES_256_GCM'
}

/**
 * Parameters for ingesting files. See tiledb.cloud.file.ingestion.ingest for details. 
 * @export
 * @interface FileIngestionRequest
 */
export interface FileIngestionRequest {
    /**
     * The source URI.
     * @type {string}
     * @memberof FileIngestionRequest
     */
    search_uri?: string;
    /**
     * The destination URI.
     * @type {string}
     * @memberof FileIngestionRequest
     */
    dataset_uri?: string;
    /**
     * The URI of the group to ingest all files into (optional).
     * @type {string}
     * @memberof FileIngestionRequest
     */
    group_uri?: string;
    /**
     * The name of the ARN credentials to use to read source data and write to destination.
     * @type {string}
     * @memberof FileIngestionRequest
     */
    access_credentials_name?: string;
    /**
     * Verbose ingest or not.
     * @type {boolean}
     * @memberof FileIngestionRequest
     */
    verbose?: boolean;
    /**
     * UNIX shell style pattern to filter files in the search
     * @type {string}
     * @memberof FileIngestionRequest
     */
    pattern?: string;
    /**
     * UNIX shell style pattern to filter files in the search
     * @type {string}
     * @memberof FileIngestionRequest
     */
    ignore?: string;
    /**
     * Optional name for taskgraph, defaults to \"file-ingestion\"
     * @type {string}
     * @memberof FileIngestionRequest
     */
    taskgraph_name?: string;
    /**
     * Batch size for file ingestion, defaults to 100
     * @type {number}
     * @memberof FileIngestionRequest
     */
    batch_size?: number;
    /**
     * maximum number of File URIs to read/find
     * @type {number}
     * @memberof FileIngestionRequest
     */
    max_files?: number;
}
/**
 * File property assigned to a specific file (array)
 * @export
 * @enum {string}
 */
export enum FilePropertyName {
    Image = 'image',
    Size = 'size',
    CodeBlock = 'code_block',
    UdfLanguage = 'udf_language',
    IsDashboard = 'is_dashboard'
}

/**
 * File types represented as TileDB arrays
 * @export
 * @enum {string}
 */
export enum FileType {
    Notebook = 'notebook',
    UserDefinedFunction = 'user_defined_function',
    MlModel = 'ml_model',
    File = 'file',
    RegisteredTaskGraph = 'registered_task_graph'
}

/**
 * Uploaded file name and information
 * @export
 * @interface FileUploaded
 */
export interface FileUploaded {
    /**
     * output location of the TileDB File
     * @type {string}
     * @memberof FileUploaded
     */
    output_uri: string;
    /**
     * name of the file uploaded
     * @type {string}
     * @memberof FileUploaded
     */
    file_name: string;
    /**
     * unique ID of the uploaded file
     * @type {string}
     * @memberof FileUploaded
     */
    id: string;
}
/**
 * Filter
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {FilterType}
     * @memberof Filter
     */
    type: FilterType;
    /**
     * 
     * @type {FilterData}
     * @memberof Filter
     */
    data: FilterData;
}
/**
 * Filter data
 * @export
 * @interface FilterData
 */
export interface FilterData {
    /**
     * 
     * @type {number}
     * @memberof FilterData
     */
    int8?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterData
     */
    uint8?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterData
     */
    int16?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterData
     */
    uint16?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterData
     */
    int32?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterData
     */
    uint32?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterData
     */
    int64?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterData
     */
    uint64?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterData
     */
    float32?: number;
    /**
     * 
     * @type {number}
     * @memberof FilterData
     */
    float64?: number;
}
/**
 * TileDB filter option
 * @export
 * @enum {string}
 */
export enum FilterOption {
    COMPRESSIONLEVEL = 'COMPRESSION_LEVEL',
    BITWIDTHMAXWINDOW = 'BIT_WIDTH_MAX_WINDOW',
    POSITIVEDELTAMAXWINDOW = 'POSITIVE_DELTA_MAX_WINDOW'
}

/**
 * One or more filters to apply
 * @export
 * @interface FilterPipeline
 */
export interface FilterPipeline {
    /**
     * 
     * @type {Array<Filter>}
     * @memberof FilterPipeline
     */
    filters?: Array<Filter>;
}
/**
 * TileDB filter types
 * @export
 * @enum {string}
 */
export enum FilterType {
    NONE = 'FILTER_NONE',
    GZIP = 'FILTER_GZIP',
    ZSTD = 'FILTER_ZSTD',
    LZ4 = 'FILTER_LZ4',
    RLE = 'FILTER_RLE',
    BZIP2 = 'FILTER_BZIP2',
    DOUBLEDELTA = 'FILTER_DOUBLE_DELTA',
    BITWIDTHREDUCTION = 'FILTER_BIT_WIDTH_REDUCTION',
    BITSHUFFLE = 'FILTER_BITSHUFFLE',
    BYTESHUFFLE = 'FILTER_BYTESHUFFLE',
    POSITIVEDELTA = 'FILTER_POSITIVE_DELTA'
}

/**
 * Credential information to access Google Cloud. using well knows key/secret pair to access storage.
 * @export
 * @interface GCPInteroperabilityCredential
 */
export interface GCPInteroperabilityCredential {
    /**
     * The ID of the access key
     * @type {string}
     * @memberof GCPInteroperabilityCredential
     */
    access_key_id?: string;
    /**
     * The access key\'s secret. Never returned in responses.
     * @type {string}
     * @memberof GCPInteroperabilityCredential
     */
    secret_access_key?: string;
}
/**
 * The key to a Google Cloud Platform service account.
 * @export
 * @interface GCPServiceAccountKey
 */
export interface GCPServiceAccountKey {
    /**
     * The ID of the service account (i.e., its email address). This is ignored when uploading key information, and is only provided by the server when downloading metadata about an existing key. 
     * @type {string}
     * @memberof GCPServiceAccountKey
     */
    account_id?: string;
    /**
     * The ID of the particular key. This identifies it among other keys issued for this service account. This is ignored when uploading key information, and is only provided by the server when downloading metadata about an existing key. 
     * @type {string}
     * @memberof GCPServiceAccountKey
     */
    key_id?: string;
    /**
     * The full file provided by Google Cloud. This is usually in the form of a JSON document, but TileDB Cloud treats it as opaque (except to attempt to extract the service account ID and the key ID). 
     * @type {string}
     * @memberof GCPServiceAccountKey
     */
    key_text?: string;
}
/**
 * User sign in with github
 * @export
 * @interface GithubSignIn
 */
export interface GithubSignIn {
    /**
     * github\'s redirect code param
     * @type {string}
     * @memberof GithubSignIn
     */
    code: string;
    /**
     * flag to create a token with expiration of 30 days, default is false
     * @type {string}
     * @memberof GithubSignIn
     */
    remember_me?: string;
}
/**
 * User sign in with google
 * @export
 * @interface GoogleSignIn
 */
export interface GoogleSignIn {
    /**
     * google\'s user access_token
     * @type {string}
     * @memberof GoogleSignIn
     */
    access_token: string;
    /**
     * flag to create a token with expiration of 30 days, default is false
     * @type {string}
     * @memberof GoogleSignIn
     */
    remember_me?: string;
}
/**
 * Response data from a successfully-started task graph.
 * @export
 * @interface GraphStartedResponse
 */
export interface GraphStartedResponse {
    /**
     * The UUID of the newly created
     * @type {string}
     * @memberof GraphStartedResponse
     */
    task_graph_uuid?: string;
}
/**
 * actions a user can take on a group
 * @export
 * @enum {string}
 */
export enum GroupActions {
    Read = 'read',
    Write = 'write',
    Edit = 'edit'
}

/**
 * Object containing activity logs of a group and its content (arrays and subgroups) along with pagination metadata
 * @export
 * @interface GroupActivityResponse
 */
export interface GroupActivityResponse {
    /**
     * Array of activity logs, including both group and array activities
     * @type {Array<AssetActivityLog>}
     * @memberof GroupActivityResponse
     */
    activity_logs?: Array<AssetActivityLog>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof GroupActivityResponse
     */
    pagination_metadata?: PaginationMetadata;
}
/**
 * Object including group info and pagination metadata
 * @export
 * @interface GroupBrowserData
 */
export interface GroupBrowserData {
    /**
     * Groups Info
     * @type {Array<GroupInfo>}
     * @memberof GroupBrowserData
     */
    groups: Array<GroupInfo>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof GroupBrowserData
     */
    pagination_metadata: PaginationMetadata;
}
/**
 * Object with data to fill browser filter
 * @export
 * @interface GroupBrowserFilterData
 */
export interface GroupBrowserFilterData {
    /**
     * list of all unique namespaces to display
     * @type {Array<string>}
     * @memberof GroupBrowserFilterData
     */
    namespaces?: Array<string>;
    /**
     * list of all available group types to display
     * @type {Array<GroupType>}
     * @memberof GroupBrowserFilterData
     */
    group_types?: Array<GroupType>;
}
/**
 * Object containing activity of an asset of a group
 * @export
 * @interface GroupContentActivity
 */
export interface GroupContentActivity {
    /**
     * 
     * @type {GroupContentActivityAsset}
     * @memberof GroupContentActivity
     */
    asset: GroupContentActivityAsset;
    /**
     * 
     * @type {ArrayActivityLog}
     * @memberof GroupContentActivity
     */
    activity_log: ArrayActivityLog;
}
/**
 * The asset details
 * @export
 * @interface GroupContentActivityAsset
 */
export interface GroupContentActivityAsset {
    /**
     * The asset ID
     * @type {string}
     * @memberof GroupContentActivityAsset
     */
    id: string;
    /**
     * The asset name
     * @type {string}
     * @memberof GroupContentActivityAsset
     */
    name: string;
    /**
     * The namespace that the asset belongs to
     * @type {string}
     * @memberof GroupContentActivityAsset
     */
    namespace: string;
    /**
     * 
     * @type {AssetType}
     * @memberof GroupContentActivityAsset
     */
    asset_type: AssetType;
}
/**
 * Object containing activity logs of group content along with the pagination metadata
 * @export
 * @interface GroupContentActivityResponse
 */
export interface GroupContentActivityResponse {
    /**
     * Activity of a group\'s content
     * @type {Array<GroupContentActivity>}
     * @memberof GroupContentActivityResponse
     */
    activity: Array<GroupContentActivity>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof GroupContentActivityResponse
     */
    pagination_metadata: PaginationMetadata;
}
/**
 * Object including a page of members of a group and pagination metadata
 * @export
 * @interface GroupContents
 */
export interface GroupContents {
    /**
     * Groups members
     * @type {Array<GroupEntry>}
     * @memberof GroupContents
     */
    entries: Array<GroupEntry>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof GroupContents
     */
    pagination_metadata: PaginationMetadata;
}
/**
 * Updates the contents group
 * @export
 * @interface GroupContentsChangesRequest
 */
export interface GroupContentsChangesRequest {
    /**
     * 
     * @type {TileDBConfig}
     * @memberof GroupContentsChangesRequest
     */
    config: TileDBConfig;
    /**
     * 
     * @type {GroupContentsChangesRequestGroupChanges}
     * @memberof GroupContentsChangesRequest
     */
    group_changes: GroupContentsChangesRequestGroupChanges;
}
/**
 * 
 * @export
 * @interface GroupContentsChangesRequestGroupChanges
 */
export interface GroupContentsChangesRequestGroupChanges {
    /**
     * optional series of members to remove
     * @type {Array<string>}
     * @memberof GroupContentsChangesRequestGroupChanges
     */
    members_to_remove?: Array<string>;
    /**
     * optional series of members to add
     * @type {Array<GroupMember>}
     * @memberof GroupContentsChangesRequestGroupChanges
     */
    members_to_add?: Array<GroupMember>;
}
/**
 * Object with data to fill contents filter
 * @export
 * @interface GroupContentsFilterData
 */
export interface GroupContentsFilterData {
    /**
     * list of all unique namespaces to display
     * @type {Array<string>}
     * @memberof GroupContentsFilterData
     */
    namespaces?: Array<string>;
}
/**
 * Updates the contents group
 * @export
 * @interface GroupContentsMoveRequest
 */
export interface GroupContentsMoveRequest {
    /**
     * 
     * @type {TileDBConfig}
     * @memberof GroupContentsMoveRequest
     */
    config: TileDBConfig;
    /**
     * 
     * @type {GroupContentsMoveRequestGroupChanges}
     * @memberof GroupContentsMoveRequest
     */
    group_changes: GroupContentsMoveRequestGroupChanges;
}
/**
 * 
 * @export
 * @interface GroupContentsMoveRequestGroupChanges
 */
export interface GroupContentsMoveRequestGroupChanges {
    /**
     * optional series of members to add
     * @type {Array<GroupMember>}
     * @memberof GroupContentsMoveRequestGroupChanges
     */
    members_to_move?: Array<GroupMember>;
    /**
     * 
     * @type {GroupContentsMoveRequestGroupChangesTargetGroup}
     * @memberof GroupContentsMoveRequestGroupChanges
     */
    target_group?: GroupContentsMoveRequestGroupChangesTargetGroup;
}
/**
 * The target group to move contents to
 * @export
 * @interface GroupContentsMoveRequestGroupChangesTargetGroup
 */
export interface GroupContentsMoveRequestGroupChangesTargetGroup {
    /**
     * The namespace of the target group
     * @type {string}
     * @memberof GroupContentsMoveRequestGroupChangesTargetGroup
     */
    namespace: string;
    /**
     * The name of the target group
     * @type {string}
     * @memberof GroupContentsMoveRequestGroupChangesTargetGroup
     */
    name: string;
}
/**
 * information for creating a new group with the passed configuration
 * @export
 * @interface GroupCreate
 */
export interface GroupCreate {
    /**
     * 
     * @type {TileDBConfig}
     * @memberof GroupCreate
     */
    config: TileDBConfig;
    /**
     * 
     * @type {GroupCreateGroupDetails}
     * @memberof GroupCreate
     */
    group_details: GroupCreateGroupDetails;
}
/**
 * Initial attributes for the creation of a group.
 * @export
 * @interface GroupCreateGroupDetails
 */
export interface GroupCreateGroupDetails {
    /**
     * A human readable description of the contents of the group.
     * @type {string}
     * @memberof GroupCreateGroupDetails
     */
    description?: string;
    /**
     * The name of the group. If must be unique within the group.
     * @type {string}
     * @memberof GroupCreateGroupDetails
     */
    name?: string;
    /**
     * The unique name or id of the parent of the group. If empty, then the new group will be a top level group.
     * @type {string}
     * @memberof GroupCreateGroupDetails
     */
    parent?: string;
    /**
     * uri of group.
     * @type {string}
     * @memberof GroupCreateGroupDetails
     */
    uri?: string;
    /**
     * logo (base64 encoded) for the group. Optional
     * @type {string}
     * @memberof GroupCreateGroupDetails
     */
    logo?: string;
    /**
     * optional tags for groups.
     * @type {Array<string>}
     * @memberof GroupCreateGroupDetails
     */
    tags?: Array<string>;
    /**
     * License identifier from SPDX License List or Custom.
     * @type {string}
     * @memberof GroupCreateGroupDetails
     */
    license_id?: string;
    /**
     * License text
     * @type {string}
     * @memberof GroupCreateGroupDetails
     */
    license_text?: string;
}
/**
 * Object describing a single member of a group, which can be an array or a group
 * @export
 * @interface GroupEntry
 */
export interface GroupEntry {
    /**
     * 
     * @type {GroupInfo}
     * @memberof GroupEntry
     */
    group?: GroupInfo;
    /**
     * 
     * @type {ArrayInfo}
     * @memberof GroupEntry
     */
    array?: ArrayInfo;
}
/**
 * metadata of a group
 * @export
 * @interface GroupInfo
 */
export interface GroupInfo {
    /**
     * unique ID of registered group
     * @type {string}
     * @memberof GroupInfo
     */
    id: string;
    /**
     * namespace group is in
     * @type {string}
     * @memberof GroupInfo
     */
    namespace: string;
    /**
     * name of group
     * @type {string}
     * @memberof GroupInfo
     */
    name: string;
    /**
     * description of group
     * @type {string}
     * @memberof GroupInfo
     */
    description: string | null;
    /**
     * uri of group
     * @type {string}
     * @memberof GroupInfo
     */
    uri: string;
    /**
     * uri for access through TileDB cloud
     * @type {string}
     * @memberof GroupInfo
     */
    tiledb_uri: string;
    /**
     * A count of direct array members
     * @type {number}
     * @memberof GroupInfo
     */
    asset_count?: number;
    /**
     * A count of direct group members
     * @type {number}
     * @memberof GroupInfo
     */
    group_count: number;
    /**
     * A count of direct members. This is the sum of asset_count and group_count
     * @type {number}
     * @memberof GroupInfo
     */
    size: number;
    /**
     * 
     * @type {GroupType}
     * @memberof GroupInfo
     */
    group_type?: GroupType;
    /**
     * Datetime groups was last accessed in UTC
     * @type {string}
     * @memberof GroupInfo
     */
    last_accessed: string;
    /**
     * list of actions user is allowed to do on this group
     * @type {Array<GroupActions>}
     * @memberof GroupInfo
     */
    allowed_actions: Array<GroupActions>;
    /**
     * logo (base64 encoded) for the gruop. Optional
     * @type {string}
     * @memberof GroupInfo
     */
    logo?: string;
    /**
     * the name of the access credentials to use. if unset, the default credentials will be used
     * @type {string}
     * @memberof GroupInfo
     */
    access_credentials_name: string;
    /**
     * number of unique namespaces this group is shared with
     * @type {number}
     * @memberof GroupInfo
     */
    share_count: number;
    /**
     * Suggests if the group was shared to public by owner
     * @type {boolean}
     * @memberof GroupInfo
     */
    public_share: boolean;
    /**
     * optional tags for group
     * @type {Array<string>}
     * @memberof GroupInfo
     */
    tags?: Array<string>;
    /**
     * License identifier from SPDX License List or Custom
     * @type {string}
     * @memberof GroupInfo
     */
    license_id?: string;
    /**
     * License text
     * @type {string}
     * @memberof GroupInfo
     */
    license_text?: string;
    /**
     * Datetime array was registered with tiledb
     * @type {string}
     * @memberof GroupInfo
     */
    created_at: string;
    /**
     * The region where the array is saved to
     * @type {string}
     * @memberof GroupInfo
     */
    region?: string | null;
}
/**
 * A groups member, array or another groups, to add or remove from an existing group.
 * @export
 * @interface GroupMember
 */
export interface GroupMember {
    /**
     * The name of the member
     * @type {string}
     * @memberof GroupMember
     */
    name: string;
    /**
     * The uri of the member
     * @type {string}
     * @memberof GroupMember
     */
    uri: string;
    /**
     * 
     * @type {GroupMemberType}
     * @memberof GroupMember
     */
    type: GroupMemberType;
}
/**
 * Specific file types of group members
 * @export
 * @enum {string}
 */
export enum GroupMemberAssetType {
    Group = 'group',
    Array = 'array',
    Notebook = 'notebook',
    Dashboard = 'dashboard',
    UserDefinedFunction = 'user_defined_function',
    MlModel = 'ml_model',
    File = 'file',
    Bioimg = 'bioimg',
    Soma = 'soma',
    Vcf = 'vcf',
    Pointcloud = 'pointcloud',
    Raster = 'raster',
    Geometry = 'geometry',
    VectorSearch = 'vector_search'
}

/**
 * File types that can be included in groups
 * @export
 * @enum {string}
 */
export enum GroupMemberType {
    GROUP = 'GROUP',
    ARRAY = 'ARRAY'
}

/**
 * user\'s TileDB metadata
 * @export
 * @interface GroupMetadata
 */
export interface GroupMetadata {
    /**
     * List of metadata entries
     * @type {Array<GroupMetadataEntry>}
     * @memberof GroupMetadata
     */
    entries?: Array<GroupMetadataEntry>;
}
/**
 * key/value pair representing a group metadata map entry
 * @export
 * @interface GroupMetadataEntry
 */
export interface GroupMetadataEntry {
    /**
     * 
     * @type {string}
     * @memberof GroupMetadataEntry
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupMetadataEntry
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupMetadataEntry
     */
    valueNum?: number;
    /**
     * 
     * @type {object}
     * @memberof GroupMetadataEntry
     */
    value?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GroupMetadataEntry
     */
    del?: boolean;
}
/**
 * Retrieves the metadata of a group
 * @export
 * @interface GroupMetadataRetrievalRequest
 */
export interface GroupMetadataRetrievalRequest {
    /**
     * 
     * @type {TileDBConfig}
     * @memberof GroupMetadataRetrievalRequest
     */
    config?: TileDBConfig;
}
/**
 * information for creating a new group with the passed configuration
 * @export
 * @interface GroupRegister
 */
export interface GroupRegister {
    /**
     * 
     * @type {TileDBConfig}
     * @memberof GroupRegister
     */
    config?: TileDBConfig;
    /**
     * 
     * @type {GroupRegisterGroupDetails}
     * @memberof GroupRegister
     */
    group_details: GroupRegisterGroupDetails;
}
/**
 * Initial attributes for the creation of a group.
 * @export
 * @interface GroupRegisterGroupDetails
 */
export interface GroupRegisterGroupDetails {
    /**
     * A human readable description of the contents of the group.
     * @type {string}
     * @memberof GroupRegisterGroupDetails
     */
    description?: string;
    /**
     * The name of the group. If must be unique within the group.
     * @type {string}
     * @memberof GroupRegisterGroupDetails
     */
    name?: string;
    /**
     * The unique name or id of the parent of the group. If empty, then the new group will be a top level group.
     * @type {string}
     * @memberof GroupRegisterGroupDetails
     */
    parent?: string;
    /**
     * uri of group.
     * @type {string}
     * @memberof GroupRegisterGroupDetails
     */
    uri?: string;
    /**
     * logo (base64 encoded) for the group. Optional
     * @type {string}
     * @memberof GroupRegisterGroupDetails
     */
    logo?: string;
    /**
     * optional tags for groups.
     * @type {Array<string>}
     * @memberof GroupRegisterGroupDetails
     */
    tags?: Array<string>;
    /**
     * License identifier from SPDX License List or Custom.
     * @type {string}
     * @memberof GroupRegisterGroupDetails
     */
    license_id?: string;
    /**
     * License text
     * @type {string}
     * @memberof GroupRegisterGroupDetails
     */
    license_text?: string;
    /**
     * region of the group
     * @type {string}
     * @memberof GroupRegisterGroupDetails
     */
    region?: string;
}
/**
 * sharing state of a group with a namespace
 * @export
 * @interface GroupSharing
 */
export interface GroupSharing {
    /**
     * List of permitted actions for the group and all subgroups
     * @type {Array<GroupActions>}
     * @memberof GroupSharing
     */
    group_actions?: Array<GroupActions>;
    /**
     * List of permitted actions for all the subarrays of the group
     * @type {Array<ArrayActions>}
     * @memberof GroupSharing
     */
    array_actions?: Array<ArrayActions>;
    /**
     * namespace being granted group access can be a user or organization
     * @type {string}
     * @memberof GroupSharing
     */
    namespace: string;
    /**
     * details on if the namespace is a organization or user
     * @type {string}
     * @memberof GroupSharing
     */
    namespace_type: string;
}
/**
 * a request to share a group and all the contents with a namespace
 * @export
 * @interface GroupSharingRequest
 */
export interface GroupSharingRequest {
    /**
     * List of permitted actions for the group and all subgroups
     * @type {Array<GroupActions>}
     * @memberof GroupSharingRequest
     */
    group_actions?: Array<GroupActions>;
    /**
     * List of permitted actions for all the subarrays of the group
     * @type {Array<ArrayActions>}
     * @memberof GroupSharingRequest
     */
    array_actions?: Array<ArrayActions>;
    /**
     * namespace being granted group access can be a user or organization
     * @type {string}
     * @memberof GroupSharingRequest
     */
    namespace?: string;
}
/**
 * Group type is a hint for the groups contents and TileDB Cloud may use a specialized viewer for each type
 * @export
 * @enum {string}
 */
export enum GroupType {
    Generic = 'generic',
    Vcf = 'vcf',
    Soma = 'soma',
    Bioimg = 'bioimg',
    Pointcloud = 'pointcloud',
    Raster = 'raster',
    Geometry = 'geometry',
    VectorSearch = 'vector_search'
}

/**
 * Updates for a group. New values for the attributes.
 * @export
 * @interface GroupUpdate
 */
export interface GroupUpdate {
    /**
     * A human readable description of the content of the group
     * @type {string}
     * @memberof GroupUpdate
     */
    description?: string;
    /**
     * The name of the group. If must be unique within the group.
     * @type {string}
     * @memberof GroupUpdate
     */
    name?: string;
    /**
     * logo (base64 encoded) for the group. Optional
     * @type {string}
     * @memberof GroupUpdate
     */
    logo?: string;
    /**
     * the name of the access credentials to use. if unset, the default credentials will be used
     * @type {string}
     * @memberof GroupUpdate
     */
    access_credentials_name?: string;
    /**
     * optional tags for groups
     * @type {Array<string>}
     * @memberof GroupUpdate
     */
    tags?: Array<string>;
    /**
     * License identifier from SPDX License List or Custom
     * @type {string}
     * @memberof GroupUpdate
     */
    license_id?: string;
    /**
     * License text
     * @type {string}
     * @memberof GroupUpdate
     */
    license_text?: string;
}
/**
 * Flag to indicate if user has seen the UI V2.
 * @export
 * @interface HasSeenV2StatusGetResponse
 */
export interface HasSeenV2StatusGetResponse {
    /**
     * 
     * @type {boolean}
     * @memberof HasSeenV2StatusGetResponse
     */
    has_seen_v2: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * username to reset
     * @type {string}
     * @memberof InlineObject
     */
    username?: string;
    /**
     * email for account to reset
     * @type {string}
     * @memberof InlineObject
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * username for email confirmation
     * @type {string}
     * @memberof InlineObject1
     */
    username?: string;
    /**
     * email for confirmation email
     * @type {string}
     * @memberof InlineObject1
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * The login cookie existence
     * @type {boolean}
     * @memberof InlineResponse200
     */
    status?: boolean;
}
/**
 * Invitations to share or collaborate
 * @export
 * @interface Invitation
 */
export interface Invitation {
    /**
     * Unique id of invitation added to magic link
     * @type {string}
     * @memberof Invitation
     */
    id: string;
    /**
     * 
     * @type {InvitationType}
     * @memberof Invitation
     */
    invitation_type: InvitationType;
    /**
     * Namespace of the owner of the invitation (user or organization)
     * @type {string}
     * @memberof Invitation
     */
    owner_namespace_uuid?: string;
    /**
     * Unique ID of the user that sent the invitation
     * @type {string}
     * @memberof Invitation
     */
    inviter_uuid?: string;
    /**
     * Unique id of the user accepted the invitation
     * @type {string}
     * @memberof Invitation
     */
    user_namespace_uuid?: string;
    /**
     * Unique ID of the organization whose user(s) accepted the invitation
     * @type {string}
     * @memberof Invitation
     */
    organization_uuid?: string;
    /**
     * Unique id of the organization user accepted the invitation
     * @type {string}
     * @memberof Invitation
     */
    organization_user_uuid?: string;
    /**
     * Name of the organization, does not persist in database
     * @type {string}
     * @memberof Invitation
     */
    organization_name?: string;
    /**
     * 
     * @type {OrganizationRoles}
     * @memberof Invitation
     */
    organization_role?: OrganizationRoles;
    /**
     * Unique id of the array
     * @type {string}
     * @memberof Invitation
     */
    array_uuid?: string;
    /**
     * Unique ID of the group
     * @type {string}
     * @memberof Invitation
     */
    group_uuid?: string;
    /**
     * Name of the array, does not persist in database
     * @type {string}
     * @memberof Invitation
     */
    array_name?: string;
    /**
     * Email of the individual we send the invitation to
     * @type {string}
     * @memberof Invitation
     */
    email: string;
    /**
     * A comma separated list of ArrayActions or NamespaceActions
     * @type {string}
     * @memberof Invitation
     */
    permissions: string;
    /**
     * A comma separated list of GroupActions
     * @type {string}
     * @memberof Invitation
     */
    group_actions?: string;
    /**
     * 
     * @type {InvitationStatus}
     * @memberof Invitation
     */
    status: InvitationStatus;
    /**
     * Datetime the invitation was created in UTC
     * @type {string}
     * @memberof Invitation
     */
    created_at: string;
    /**
     * The namespace invited (user or organization, if it exists in the DB)
     * @type {string}
     * @memberof Invitation
     */
    namespace_invited?: string;
}
/**
 * Encapsulates information regarding inviting people using email to share array, same permissions for all invitees
 * @export
 * @interface InvitationArrayShareEmail
 */
export interface InvitationArrayShareEmail {
    /**
     * List of permitted actions
     * @type {Array<ArrayActions>}
     * @memberof InvitationArrayShareEmail
     */
    actions: Array<ArrayActions>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvitationArrayShareEmail
     */
    invitee_email: Array<string>;
}
/**
 * Object including invitations and pagination metadata
 * @export
 * @interface InvitationData
 */
export interface InvitationData {
    /**
     * Invitations
     * @type {Array<Invitation>}
     * @memberof InvitationData
     */
    invitations?: Array<Invitation>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof InvitationData
     */
    pagination_metadata?: PaginationMetadata;
}
/**
 * Object including invitation details such as the array shared or organization
 * @export
 * @interface InvitationDetails
 */
export interface InvitationDetails {
    /**
     * 
     * @type {InvitationType}
     * @memberof InvitationDetails
     */
    invitation_type: InvitationType;
    /**
     * 
     * @type {InvitationStatus}
     * @memberof InvitationDetails
     */
    invitation_status: InvitationStatus;
    /**
     * 
     * @type {InvitationDetailsArray}
     * @memberof InvitationDetails
     */
    array?: InvitationDetailsArray;
    /**
     * 
     * @type {InvitationDetailsOrganization}
     * @memberof InvitationDetails
     */
    organization?: InvitationDetailsOrganization;
    /**
     * 
     * @type {InvitationDetailsGroup}
     * @memberof InvitationDetails
     */
    group?: InvitationDetailsGroup;
    /**
     * 
     * @type {InvitationDetailsAsset}
     * @memberof InvitationDetails
     */
    asset?: InvitationDetailsAsset;
    /**
     * 
     * @type {InvitationDetailsInvitedBy}
     * @memberof InvitationDetails
     */
    invited_by: InvitationDetailsInvitedBy;
    /**
     * 
     * @type {InvitationDetailsNamespaceInvited}
     * @memberof InvitationDetails
     */
    namespace_invited?: InvitationDetailsNamespaceInvited;
}
/**
 * the array shared
 * @export
 * @interface InvitationDetailsArray
 */
export interface InvitationDetailsArray {
    /**
     * array id
     * @type {string}
     * @memberof InvitationDetailsArray
     */
    id?: string;
    /**
     * the namespace that the array belongs to
     * @type {string}
     * @memberof InvitationDetailsArray
     */
    namespace?: string;
    /**
     * name of the array
     * @type {string}
     * @memberof InvitationDetailsArray
     */
    name?: string;
    /**
     * 
     * @type {FileType}
     * @memberof InvitationDetailsArray
     */
    file_type?: FileType;
    /**
     * 
     * @type {InvitationDetailsArrayFileProperties}
     * @memberof InvitationDetailsArray
     */
    file_properties?: InvitationDetailsArrayFileProperties;
}
/**
 * map of file properties created for this array
 * @export
 * @interface InvitationDetailsArrayFileProperties
 */
export interface InvitationDetailsArrayFileProperties {
    /**
     * 
     * @type {string}
     * @memberof InvitationDetailsArrayFileProperties
     */
    is_dashboard?: string;
}
/**
 * the asset shared
 * @export
 * @interface InvitationDetailsAsset
 */
export interface InvitationDetailsAsset {
    /**
     * asset id
     * @type {string}
     * @memberof InvitationDetailsAsset
     */
    id: string;
    /**
     * the namespace that the asset belongs to
     * @type {string}
     * @memberof InvitationDetailsAsset
     */
    namespace: string;
    /**
     * the asset name
     * @type {string}
     * @memberof InvitationDetailsAsset
     */
    name: string;
    /**
     * asset\'s logo
     * @type {string}
     * @memberof InvitationDetailsAsset
     */
    logo?: string | null;
    /**
     * 
     * @type {AssetType}
     * @memberof InvitationDetailsAsset
     */
    asset_type: AssetType;
    /**
     * A comma separated list of ArrayActions
     * @type {string}
     * @memberof InvitationDetailsAsset
     */
    actions?: string;
}
/**
 * the group shared
 * @export
 * @interface InvitationDetailsGroup
 */
export interface InvitationDetailsGroup {
    /**
     * group id
     * @type {string}
     * @memberof InvitationDetailsGroup
     */
    id?: string;
    /**
     * the namespace that the group belongs to
     * @type {string}
     * @memberof InvitationDetailsGroup
     */
    namespace?: string;
    /**
     * the group name
     * @type {string}
     * @memberof InvitationDetailsGroup
     */
    name?: string;
}
/**
 * the user that created the invitation
 * @export
 * @interface InvitationDetailsInvitedBy
 */
export interface InvitationDetailsInvitedBy {
    /**
     * user id
     * @type {string}
     * @memberof InvitationDetailsInvitedBy
     */
    id: string;
    /**
     * user\'s username
     * @type {string}
     * @memberof InvitationDetailsInvitedBy
     */
    username: string;
    /**
     * user\'s display name
     * @type {string}
     * @memberof InvitationDetailsInvitedBy
     */
    name: string;
    /**
     * user\'s logo
     * @type {string}
     * @memberof InvitationDetailsInvitedBy
     */
    logo?: string | null;
}
/**
 * the namespace that the invitation was sent to (if it is an organization)
 * @export
 * @interface InvitationDetailsNamespaceInvited
 */
export interface InvitationDetailsNamespaceInvited {
    /**
     * The id of the organization
     * @type {string}
     * @memberof InvitationDetailsNamespaceInvited
     */
    id?: string;
    /**
     * The namespace of the organization
     * @type {string}
     * @memberof InvitationDetailsNamespaceInvited
     */
    namespace?: string;
}
/**
 * the organization that the user was invited to
 * @export
 * @interface InvitationDetailsOrganization
 */
export interface InvitationDetailsOrganization {
    /**
     * organization id
     * @type {string}
     * @memberof InvitationDetailsOrganization
     */
    id: string;
    /**
     * organization name
     * @type {string}
     * @memberof InvitationDetailsOrganization
     */
    name: string;
    /**
     * organization logo
     * @type {string}
     * @memberof InvitationDetailsOrganization
     */
    logo?: string | null;
    /**
     * 
     * @type {OrganizationRoles}
     * @memberof InvitationDetailsOrganization
     */
    role: OrganizationRoles;
}
/**
 * Contains the invitation details to be included in a notification when the notification type is \"invitation\"
 * @export
 * @interface InvitationEvent
 */
export interface InvitationEvent {
    /**
     * The invitation uuid
     * @type {string}
     * @memberof InvitationEvent
     */
    invitation_id?: string;
    /**
     * 
     * @type {InvitationType}
     * @memberof InvitationEvent
     */
    invitation_type?: InvitationType;
    /**
     * 
     * @type {InvitationEventInvitedBy}
     * @memberof InvitationEvent
     */
    invited_by?: InvitationEventInvitedBy;
    /**
     * 
     * @type {InvitationEventNamespaceInvited}
     * @memberof InvitationEvent
     */
    namespace_invited?: InvitationEventNamespaceInvited;
    /**
     * 
     * @type {InvitationEventAsset}
     * @memberof InvitationEvent
     */
    asset?: InvitationEventAsset;
    /**
     * 
     * @type {InvitationEventOrganization}
     * @memberof InvitationEvent
     */
    organization?: InvitationEventOrganization;
}
/**
 * the array shared
 * @export
 * @interface InvitationEventAsset
 */
export interface InvitationEventAsset {
    /**
     * array id
     * @type {string}
     * @memberof InvitationEventAsset
     */
    id?: string;
    /**
     * the namespace that the array belongs to
     * @type {string}
     * @memberof InvitationEventAsset
     */
    namespace?: string;
    /**
     * name of the array
     * @type {string}
     * @memberof InvitationEventAsset
     */
    name?: string;
    /**
     * 
     * @type {AssetType}
     * @memberof InvitationEventAsset
     */
    asset_type?: AssetType;
}
/**
 * namespace that created the invitation
 * @export
 * @interface InvitationEventInvitedBy
 */
export interface InvitationEventInvitedBy {
    /**
     * Namespace\'s uuid
     * @type {string}
     * @memberof InvitationEventInvitedBy
     */
    id?: string;
    /**
     * Namespace that created the invitation
     * @type {string}
     * @memberof InvitationEventInvitedBy
     */
    namespace?: string;
    /**
     * User\'s name
     * @type {string}
     * @memberof InvitationEventInvitedBy
     */
    name?: string;
    /**
     * Namespace\'s logo (base64 encoded)
     * @type {string}
     * @memberof InvitationEventInvitedBy
     */
    logo?: string;
}
/**
 * the namespace that the invitation was sent to (if it is an organization)
 * @export
 * @interface InvitationEventNamespaceInvited
 */
export interface InvitationEventNamespaceInvited {
    /**
     * The id of the organization
     * @type {string}
     * @memberof InvitationEventNamespaceInvited
     */
    id?: string;
    /**
     * The namespace of the organization
     * @type {string}
     * @memberof InvitationEventNamespaceInvited
     */
    namespace?: string;
    /**
     * The name of the organization
     * @type {string}
     * @memberof InvitationEventNamespaceInvited
     */
    name?: string;
    /**
     * The logo of the organization (base64 encoded)
     * @type {string}
     * @memberof InvitationEventNamespaceInvited
     */
    logo?: string;
}
/**
 * the organization that the user was invited to
 * @export
 * @interface InvitationEventOrganization
 */
export interface InvitationEventOrganization {
    /**
     * organization id
     * @type {string}
     * @memberof InvitationEventOrganization
     */
    id?: string;
    /**
     * organization name
     * @type {string}
     * @memberof InvitationEventOrganization
     */
    name?: string;
    /**
     * organization logo
     * @type {string}
     * @memberof InvitationEventOrganization
     */
    logo?: string;
}
/**
 * Encapsulates information regarding the failed invitation recipients
 * @export
 * @interface InvitationFailedRecipients
 */
export interface InvitationFailedRecipients {
    /**
     * 
     * @type {Array<string>}
     * @memberof InvitationFailedRecipients
     */
    failed_recipients: Array<string>;
}
/**
 * Encapsulates information regarding inviting people using email to share group, same permissions for all invitees
 * @export
 * @interface InvitationGroupShareEmail
 */
export interface InvitationGroupShareEmail {
    /**
     * List of permitted array actions
     * @type {Array<ArrayActions>}
     * @memberof InvitationGroupShareEmail
     */
    array_actions: Array<ArrayActions>;
    /**
     * List of permitted group actions
     * @type {Array<GroupActions>}
     * @memberof InvitationGroupShareEmail
     */
    group_actions: Array<GroupActions>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvitationGroupShareEmail
     */
    invitee_email_or_name: Array<string>;
}
/**
 * Encapsulates information regarding inviting people using email to join organization, same permissions for all invitees
 * @export
 * @interface InvitationOrganizationJoinEmail
 */
export interface InvitationOrganizationJoinEmail {
    /**
     * List of permitted actions
     * @type {Array<NamespaceActions>}
     * @memberof InvitationOrganizationJoinEmail
     */
    actions?: Array<NamespaceActions>;
    /**
     * 
     * @type {OrganizationRoles}
     * @memberof InvitationOrganizationJoinEmail
     */
    organization_role: OrganizationRoles;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvitationOrganizationJoinEmail
     */
    invitee_email: Array<string>;
}
/**
 * List of values that InvitationStatus can take
 * @export
 * @enum {string}
 */
export enum InvitationStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED'
}

/**
 * List of values that InvitationType can take
 * @export
 * @enum {string}
 */
export enum InvitationType {
    ARRAYSHARE = 'ARRAY_SHARE',
    GROUPSHARE = 'GROUP_SHARE',
    JOINORGANIZATION = 'JOIN_ORGANIZATION'
}

/**
 * An invoice, comprised of one or more InvoiceLine(s)
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * Description of invoice
     * @type {string}
     * @memberof Invoice
     */
    invoice_description: string;
    /**
     * Start date of the period the invoice is referred to in UTC
     * @type {string}
     * @memberof Invoice
     */
    start: string;
    /**
     * End date of the period the invoice is referred to in UTC
     * @type {string}
     * @memberof Invoice
     */
    end: string;
    /**
     * Date the invoice is issued, referred to in UTC
     * @type {string}
     * @memberof Invoice
     */
    date_of_issue: string;
    /**
     * Date the invoice is due, referred to in UTC
     * @type {string}
     * @memberof Invoice
     */
    date_due: string;
    /**
     * The difference between starting and ending balance, added to Total to form AmountDue
     * @type {number}
     * @memberof Invoice
     */
    applied_balance?: number;
    /**
     * Amount due out of total
     * @type {number}
     * @memberof Invoice
     */
    amount_due: number;
    /**
     * Amount paid out of total
     * @type {number}
     * @memberof Invoice
     */
    amount_paid: number;
    /**
     * Amount remaining out of total
     * @type {number}
     * @memberof Invoice
     */
    amount_remaining: number;
    /**
     * Charging attempts
     * @type {number}
     * @memberof Invoice
     */
    attempt_count: number;
    /**
     * Attempted flag
     * @type {boolean}
     * @memberof Invoice
     */
    attempted: boolean;
    /**
     * Paid flag
     * @type {boolean}
     * @memberof Invoice
     */
    paid: boolean;
    /**
     * Tax of invoice
     * @type {number}
     * @memberof Invoice
     */
    tax: number;
    /**
     * Total minus Tax
     * @type {number}
     * @memberof Invoice
     */
    subtotal: number;
    /**
     * Total cost of invoice, the sum of all line items
     * @type {number}
     * @memberof Invoice
     */
    total: number;
    /**
     * Currency of total, three-letter ISO currency code, in lowercase
     * @type {string}
     * @memberof Invoice
     */
    currency: string;
    /**
     * Datetime the invoice was created in UTC
     * @type {string}
     * @memberof Invoice
     */
    created: string;
    /**
     * Array of invoice lines
     * @type {Array<InvoiceLine>}
     * @memberof Invoice
     */
    invoice_lines: Array<InvoiceLine>;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof Invoice
     */
    status: InvoiceStatus;
    /**
     * 
     * @type {InvoiceBillingReason}
     * @memberof Invoice
     */
    billing_reason: InvoiceBillingReason;
    /**
     * invoice id number
     * @type {string}
     * @memberof Invoice
     */
    number: string;
    /**
     * stripe customer email
     * @type {string}
     * @memberof Invoice
     */
    customer_email: string;
    /**
     * stripe customer name
     * @type {string}
     * @memberof Invoice
     */
    customer_name?: string;
    /**
     * 
     * @type {InvoiceCustomerAddress}
     * @memberof Invoice
     */
    customer_address: InvoiceCustomerAddress;
    /**
     * url for downloading pdf of invoice
     * @type {string}
     * @memberof Invoice
     */
    invoice_pdf?: string;
    /**
     * current account balance, if its negatives there is credits left
     * @type {number}
     * @memberof Invoice
     */
    account_balance: number;
}
/**
 * List of values that InvoiceBillingReason can take.
 * @export
 * @enum {string}
 */
export enum InvoiceBillingReason {
    Manual = 'manual',
    Subscription = 'subscription',
    SubscriptionCreate = 'subscription_create',
    SubscriptionCycle = 'subscription_cycle',
    SubscriptionThreshold = 'subscription_threshold',
    SubscriptionUpdate = 'subscription_update',
    SubscriptionUpcoming = 'subscription_upcoming'
}

/**
 * stripe customer address
 * @export
 * @interface InvoiceCustomerAddress
 */
export interface InvoiceCustomerAddress {
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerAddress
     */
    line1?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerAddress
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerAddress
     */
    postal_code?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerAddress
     */
    state?: string;
}
/**
 * A single line of an invoice
 * @export
 * @interface InvoiceLine
 */
export interface InvoiceLine {
    /**
     * Total amount for line item, in dollars
     * @type {number}
     * @memberof InvoiceLine
     */
    amount: number;
    /**
     * Currency of current balance, three-letter ISO currency code, in lowercase
     * @type {string}
     * @memberof InvoiceLine
     */
    currency: string;
    /**
     * Description of line item
     * @type {string}
     * @memberof InvoiceLine
     */
    line_description: string;
    /**
     * Quantity of line item
     * @type {number}
     * @memberof InvoiceLine
     */
    quantity: number;
    /**
     * Start date of the period the line item is referred to in UTC
     * @type {string}
     * @memberof InvoiceLine
     */
    start: string;
    /**
     * End date of the period the line item is referred to in UTC
     * @type {string}
     * @memberof InvoiceLine
     */
    end: string;
    /**
     * 
     * @type {InvoiceLineType}
     * @memberof InvoiceLine
     */
    type: InvoiceLineType;
    /**
     * Rate of billing plan, in dollars
     * @type {number}
     * @memberof InvoiceLine
     */
    rate: number;
    /**
     * Label for rate
     * @type {string}
     * @memberof InvoiceLine
     */
    rate_unit_label: string;
    /**
     * Unit price column of line item
     * @type {string}
     * @memberof InvoiceLine
     */
    unit_price: string;
}
/**
 * List of values that InvoiceLineType can take.
 * @export
 * @enum {string}
 */
export enum InvoiceLineType {
    Invoiceitem = 'invoiceitem',
    Subscription = 'subscription'
}

/**
 * List of values that InvoiceBillingStatus can take.
 * @export
 * @enum {string}
 */
export enum InvoiceStatus {
    Draft = 'draft',
    Open = 'open',
    Paid = 'paid',
    Uncollectible = 'uncollectible',
    Void = 'void'
}

/**
 * Layout of array
 * @export
 * @enum {string}
 */
export enum Layout {
    RowMajor = 'row-major',
    ColMajor = 'col-major',
    GlobalOrder = 'global-order',
    Unordered = 'unordered'
}

/**
 * Object including array favorites and pagination metadata
 * @export
 * @interface ListFavoritesResponse
 */
export interface ListFavoritesResponse {
    /**
     * List of array infos
     * @type {Array<ArrayInfo>}
     * @memberof ListFavoritesResponse
     */
    arrays?: Array<ArrayInfo>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof ListFavoritesResponse
     */
    pagination_metadata?: PaginationMetadata;
}
/**
 * User login
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * username or email to login with
     * @type {string}
     * @memberof Login
     */
    username_or_email: string;
    /**
     * password
     * @type {string}
     * @memberof Login
     */
    password: string;
    /**
     * flag to create a token with expiration of 30 days, default is false
     * @type {string}
     * @memberof Login
     */
    remember_me?: string;
}
/**
 * A user-favorite MLModel item
 * @export
 * @interface MLModelFavorite
 */
export interface MLModelFavorite {
    /**
     * unique UUID of the MLModel
     * @type {string}
     * @memberof MLModelFavorite
     */
    mlmodel_uuid?: string;
    /**
     * the namespace of the MLModel
     * @type {string}
     * @memberof MLModelFavorite
     */
    namespace?: string;
    /**
     * the name of the MLModel
     * @type {string}
     * @memberof MLModelFavorite
     */
    name?: string;
}
/**
 * Object including MLModel favorites and pagination metadata
 * @export
 * @interface MLModelFavoritesData
 */
export interface MLModelFavoritesData {
    /**
     * List of MLModel infos
     * @type {Array<ArrayInfo>}
     * @memberof MLModelFavoritesData
     */
    mlmodels?: Array<ArrayInfo>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof MLModelFavoritesData
     */
    pagination_metadata?: PaginationMetadata;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    message: string;
}
/**
 * actions a user can take on an organization
 * @export
 * @enum {string}
 */
export enum NamespaceActions {
    Read = 'read',
    Write = 'write',
    Create = 'create',
    Delete = 'delete',
    Edit = 'edit',
    ReadArrayLogs = 'read_array_logs',
    ReadJobLogs = 'read_job_logs',
    ReadObjectLogs = 'read_object_logs',
    RunJob = 'run_job',
    DeleteOrganization = 'delete_organization',
    EditOrganization = 'edit_organization'
}

/**
 * Type of namespace
 * @export
 * @enum {string}
 */
export enum NamespaceType {
    User = 'user',
    Organization = 'organization'
}

/**
 * Copied notebook uri and information
 * @export
 * @interface NotebookCopied
 */
export interface NotebookCopied {
    /**
     * output location of the TileDB Notebook
     * @type {string}
     * @memberof NotebookCopied
     */
    output_uri?: string;
    /**
     * name of the notebook created
     * @type {string}
     * @memberof NotebookCopied
     */
    name?: string;
    /**
     * namespace copied to
     * @type {string}
     * @memberof NotebookCopied
     */
    namespace?: string;
    /**
     * unique ID of the copied notebook
     * @type {string}
     * @memberof NotebookCopied
     */
    id: string;
}
/**
 * Output information required to copy a notebook
 * @export
 * @interface NotebookCopy
 */
export interface NotebookCopy {
    /**
     * output location of the TileDB File
     * @type {string}
     * @memberof NotebookCopy
     */
    output_uri?: string;
    /**
     * name to set for registered notebook
     * @type {string}
     * @memberof NotebookCopy
     */
    name?: string;
    /**
     * namespace to copy to
     * @type {string}
     * @memberof NotebookCopy
     */
    namespace?: string;
}
/**
 * A user-favorite notebook item
 * @export
 * @interface NotebookFavorite
 */
export interface NotebookFavorite {
    /**
     * unique UUID of the notebook
     * @type {string}
     * @memberof NotebookFavorite
     */
    notebook_uuid?: string;
    /**
     * the namespace of the notebook
     * @type {string}
     * @memberof NotebookFavorite
     */
    namespace?: string;
    /**
     * the name of the notebook
     * @type {string}
     * @memberof NotebookFavorite
     */
    name?: string;
}
/**
 * Object including notebook favorites and pagination metadata
 * @export
 * @interface NotebookFavoritesData
 */
export interface NotebookFavoritesData {
    /**
     * List of notebook infos
     * @type {Array<ArrayInfo>}
     * @memberof NotebookFavoritesData
     */
    notebooks?: Array<ArrayInfo>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof NotebookFavoritesData
     */
    pagination_metadata?: PaginationMetadata;
}
/**
 * Size of notebook image
 * @export
 * @enum {string}
 */
export enum NotebookSize {
    Small = 'small',
    Large = 'large',
    GpuP2 = 'gpu_p2'
}

/**
 * Status details of a notebook server
 * @export
 * @interface NotebookStatus
 */
export interface NotebookStatus {
    /**
     * namespace of notebook
     * @type {string}
     * @memberof NotebookStatus
     */
    namespace: string;
    /**
     * the region the notebook is currently running
     * @type {string}
     * @memberof NotebookStatus
     */
    region?: string;
    /**
     * duration notebook has been running in seconds
     * @type {number}
     * @memberof NotebookStatus
     */
    uptime: number;
    /**
     * current cpu usage in millicpu
     * @type {number}
     * @memberof NotebookStatus
     */
    cpu_usage: number;
    /**
     * gpu usage in milligpu
     * @type {number}
     * @memberof NotebookStatus
     */
    gpu_usage?: number;
    /**
     * memory usage in bytes
     * @type {number}
     * @memberof NotebookStatus
     */
    memory_usage: number;
    /**
     * gpu limit in milligpu
     * @type {number}
     * @memberof NotebookStatus
     */
    gpu_limit?: number;
    /**
     * memory allocated to notebook server in bytes
     * @type {number}
     * @memberof NotebookStatus
     */
    memory_limit: number;
    /**
     * storage usage in bytes
     * @type {number}
     * @memberof NotebookStatus
     */
    storage_usage?: number;
    /**
     * storage allocated to notebook server in bytes
     * @type {number}
     * @memberof NotebookStatus
     */
    storage_limit?: number;
    /**
     * millicpu allocated to notebook server
     * @type {number}
     * @memberof NotebookStatus
     */
    cpu_count: number;
    /**
     * cost in USD for the current notebook session
     * @type {number}
     * @memberof NotebookStatus
     */
    cost?: number | null;
    /**
     * 
     * @type {PodStatus}
     * @memberof NotebookStatus
     */
    pod_status?: PodStatus;
}
/**
 * Type of notebook image
 * @export
 * @enum {string}
 */
export enum NotebookType {
    BasicDataScience = 'basic_data_science',
    Geospatial = 'geospatial',
    Genomics = 'genomics'
}

/**
 * Uploaded notebook name and information
 * @export
 * @interface NotebookUploaded
 */
export interface NotebookUploaded {
    /**
     * output location of the TileDB Notebook
     * @type {string}
     * @memberof NotebookUploaded
     */
    output_uri: string;
    /**
     * name of the notebook uploaded
     * @type {string}
     * @memberof NotebookUploaded
     */
    name: string;
    /**
     * unique ID of the uploaded notebook
     * @type {string}
     * @memberof NotebookUploaded
     */
    id: string;
}
/**
 * Notification for a given namespace
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * Unique ID of the notification
     * @type {string}
     * @memberof Notification
     */
    id: string;
    /**
     * Time when notification was created (rfc3339)
     * @type {string}
     * @memberof Notification
     */
    created_at: string;
    /**
     * 
     * @type {NotificationStatus}
     * @memberof Notification
     */
    status: NotificationStatus;
    /**
     * 
     * @type {NotificationType}
     * @memberof Notification
     */
    type: NotificationType;
    /**
     * 
     * @type {NotificationEvent}
     * @memberof Notification
     */
    notification_event: NotificationEvent;
}
/**
 * Object including a notification event
 * @export
 * @interface NotificationEvent
 */
export interface NotificationEvent {
    /**
     * 
     * @type {InvitationEvent}
     * @memberof NotificationEvent
     */
    invitation?: InvitationEvent;
    /**
     * 
     * @type {TaskGraphEvent}
     * @memberof NotificationEvent
     */
    taskgraph?: TaskGraphEvent;
}
/**
 * Object including list of notifications and pagination metadata
 * @export
 * @interface NotificationListResponse
 */
export interface NotificationListResponse {
    /**
     * List of notifications
     * @type {Array<Notification>}
     * @memberof NotificationListResponse
     */
    notifications?: Array<Notification>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof NotificationListResponse
     */
    pagination_metadata?: PaginationMetadata;
}
/**
 * Status of the notification (read/unread)
 * @export
 * @enum {string}
 */
export enum NotificationStatus {
    Read = 'read',
    Unread = 'unread'
}

/**
 * Request body for changing the status of invitations
 * @export
 * @interface NotificationStatusChange
 */
export interface NotificationStatusChange {
    /**
     * 
     * @type {NotificationStatus}
     * @memberof NotificationStatusChange
     */
    status?: NotificationStatus;
    /**
     * The notification ids to be changed
     * @type {Array<string>}
     * @memberof NotificationStatusChange
     */
    notifications?: Array<string>;
}
/**
 * type of the notification
 * @export
 * @enum {string}
 */
export enum NotificationType {
    Invitation = 'invitation',
    Taskgraph = 'taskgraph'
}

/**
 * redirect object for auth
 * @export
 * @interface OAuth2ConsentDetails
 */
export interface OAuth2ConsentDetails {
    /**
     * User accepts scopes included
     * @type {boolean}
     * @memberof OAuth2ConsentDetails
     */
    accept?: boolean;
    /**
     * Website requesting scope access
     * @type {string}
     * @memberof OAuth2ConsentDetails
     */
    client_id?: string;
    /**
     * scopes to consent user for
     * @type {Array<string>}
     * @memberof OAuth2ConsentDetails
     */
    scopes?: Array<string>;
    /**
     * scopes audience
     * @type {Array<string>}
     * @memberof OAuth2ConsentDetails
     */
    access_token_audience?: Array<string>;
    /**
     * url to redirect user to
     * @type {string}
     * @memberof OAuth2ConsentDetails
     */
    redirect_to?: string;
}
/**
 * redirect object for auth
 * @export
 * @interface OAuth2Redirect
 */
export interface OAuth2Redirect {
    /**
     * url to redirect user to
     * @type {string}
     * @memberof OAuth2Redirect
     */
    redirect_to: string;
}
/**
 * User sign in with okta
 * @export
 * @interface OktaSignIn
 */
export interface OktaSignIn {
    /**
     * okta\'s redirect code param
     * @type {string}
     * @memberof OktaSignIn
     */
    code: string;
    /**
     * okta\'s redirect url param
     * @type {string}
     * @memberof OktaSignIn
     */
    redirect_url: string;
    /**
     * okta\'s state param
     * @type {string}
     * @memberof OktaSignIn
     */
    state: string;
    /**
     * flag to create a token with expiration of 30 days, default is false
     * @type {string}
     * @memberof OktaSignIn
     */
    remember_me?: string;
}
/**
 * List of values that OnboardingItem can take.
 * @export
 * @enum {string}
 */
export enum OnboardingItem {
    ThemeSelection = 'theme_selection',
    ProfileInfo = 'profile_info',
    ClaimCredits = 'claim_credits',
    Intro = 'intro',
    BasicTour = 'basic_tour',
    SkipUserOnboarding = 'skip_user_onboarding',
    LaunchNotebook = 'launch_notebook',
    IndicateFinishUserOnboarding = 'indicate_finish_user_onboarding',
    OrganizationBasicTour = 'organization_basic_tour',
    IndicateFinishOrgOnboarding = 'indicate_finish_org_onboarding',
    SkipOrganizationOnboarding = 'skip_organization_onboarding'
}

/**
 * user\'s Onboarding items
 * @export
 * @interface OnboardingItems
 */
export interface OnboardingItems {
    /**
     * List of Onboarding items
     * @type {Array<OnboardingItem>}
     * @memberof OnboardingItems
     */
    entries?: Array<OnboardingItem>;
}
/**
 * namespace\'s Onboarding state
 * @export
 * @interface OnboardingState
 */
export interface OnboardingState {
    /**
     * The existence of cloud storage settings
     * @type {boolean}
     * @memberof OnboardingState
     */
    cloud_storage_settings?: boolean;
    /**
     * The existence of at least one registered asset
     * @type {boolean}
     * @memberof OnboardingState
     */
    registered_asset?: boolean;
    /**
     * The existence of at least one extra member in the organization (besides the owner)
     * @type {boolean}
     * @memberof OnboardingState
     */
    organization_members?: boolean;
}
/**
 * Organization
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * unique id of organization
     * @type {string}
     * @memberof Organization
     */
    id: string;
    /**
     * 
     * @type {OrganizationRoles}
     * @memberof Organization
     */
    role: OrganizationRoles;
    /**
     * organization name must be unique
     * @type {string}
     * @memberof Organization
     */
    name: string;
    /**
     * Datetime organization was created in UTC
     * @type {string}
     * @memberof Organization
     */
    created_at: string;
    /**
     * Datetime organization was updated in UTC
     * @type {string}
     * @memberof Organization
     */
    updated_at: string;
    /**
     * Organization logo
     * @type {string}
     * @memberof Organization
     */
    logo?: string;
    /**
     * Organization description
     * @type {string}
     * @memberof Organization
     */
    description: string | null;
    /**
     * 
     * @type {Array<OrganizationUser>}
     * @memberof Organization
     */
    users: Array<OrganizationUser>;
    /**
     * list of actions user is allowed to do on this organization
     * @type {Array<NamespaceActions>}
     * @memberof Organization
     */
    allowed_actions?: Array<NamespaceActions>;
    /**
     * A notice that the user has an unpaid subscription
     * @type {boolean}
     * @memberof Organization
     */
    unpaid_subscription: boolean;
    /**
     * number of registered arrays for this organization
     * @type {number}
     * @memberof Organization
     */
    num_of_arrays: number;
    /**
     * Denotes that the organization is able to apply pricing to arrays by means of Stripe Connect
     * @type {boolean}
     * @memberof Organization
     */
    stripe_connect: boolean;
    /**
     * default s3 path to store newly created notebooks
     * @type {string}
     * @memberof Organization
     */
    default_s3_path?: string;
    /**
     * Default s3 path credentials name is the credentials name to use along with default_s3_path
     * @type {string}
     * @memberof Organization
     */
    default_s3_path_credentials_name?: string;
    /**
     * Beta features that the organization has access to
     * @type {Array<EnabledFeature>}
     * @memberof Organization
     */
    enabled_features?: Array<EnabledFeature>;
    /**
     * 
     * @type {AssetLocations}
     * @memberof Organization
     */
    asset_locations?: AssetLocations;
    /**
     * A AWS Role that will be first assumed before assuming the organization\'s AWS Role. Visible to owners and admins. 
     * @type {string}
     * @memberof Organization
     */
    assume_role_arn?: string;
}
/**
 * Data to create a new organization
 * @export
 * @interface OrganizationCreateParams
 */
export interface OrganizationCreateParams {
    /**
     * organization name must be unique
     * @type {string}
     * @memberof OrganizationCreateParams
     */
    name: string;
    /**
     * Organization logo
     * @type {string}
     * @memberof OrganizationCreateParams
     */
    logo?: string;
}
/**
 * role user has in organization
 * @export
 * @enum {string}
 */
export enum OrganizationRoles {
    Owner = 'owner',
    Admin = 'admin',
    ReadWrite = 'read_write',
    ReadOnly = 'read_only'
}

/**
 * Organization values that can be updated by the user
 * @export
 * @interface OrganizationUpdateParams
 */
export interface OrganizationUpdateParams {
    /**
     * organization name must be unique
     * @type {string}
     * @memberof OrganizationUpdateParams
     */
    name?: string;
    /**
     * Organization logo
     * @type {string}
     * @memberof OrganizationUpdateParams
     */
    logo?: string;
    /**
     * Organization description
     * @type {string}
     * @memberof OrganizationUpdateParams
     */
    description?: string;
    /**
     * default s3 path to store newly created notebooks
     * @type {string}
     * @memberof OrganizationUpdateParams
     */
    default_s3_path?: string;
    /**
     * Default s3 path credentials name is the credentials name to use along with default_s3_path
     * @type {string}
     * @memberof OrganizationUpdateParams
     */
    default_s3_path_credentials_name?: string;
    /**
     * 
     * @type {AssetLocations}
     * @memberof OrganizationUpdateParams
     */
    asset_locations?: AssetLocations;
}
/**
 * user in an organization
 * @export
 * @interface OrganizationUser
 */
export interface OrganizationUser {
    /**
     * username for user
     * @type {string}
     * @memberof OrganizationUser
     */
    username: string;
    /**
     * name of organization
     * @type {string}
     * @memberof OrganizationUser
     */
    organization_name: string;
    /**
     * 
     * @type {OrganizationRoles}
     * @memberof OrganizationUser
     */
    role: OrganizationRoles;
    /**
     * organization\'s UUID
     * @type {string}
     * @memberof OrganizationUser
     */
    organization_id: string;
    /**
     * user\'s UUID
     * @type {string}
     * @memberof OrganizationUser
     */
    user_id: string;
}
/**
 * overview visible data
 * @export
 * @interface OverviewData
 */
export interface OverviewData {
    /**
     * current account balance, if its negatives there is credits left
     * @type {number}
     * @memberof OverviewData
     */
    account_balance: number;
    /**
     * last accessed arrays by current user
     * @type {Array<ArrayInfo>}
     * @memberof OverviewData
     */
    last_accessed_arrays: Array<ArrayInfo>;
    /**
     * last accessed notebooks by current user
     * @type {Array<ArrayInfo>}
     * @memberof OverviewData
     */
    last_accessed_notebooks?: Array<ArrayInfo>;
    /**
     * last accessed dashboards by current user
     * @type {Array<ArrayInfo>}
     * @memberof OverviewData
     */
    last_accessed_dashboards?: Array<ArrayInfo>;
    /**
     * last accessed udfs by current user
     * @type {Array<ArrayInfo>}
     * @memberof OverviewData
     */
    last_accessed_udfs?: Array<ArrayInfo>;
    /**
     * last accessed ml models by current user
     * @type {Array<ArrayInfo>}
     * @memberof OverviewData
     */
    last_accessed_ml_models?: Array<ArrayInfo>;
    /**
     * last accessed files by current user
     * @type {Array<ArrayInfo>}
     * @memberof OverviewData
     */
    last_accessed_files?: Array<ArrayInfo>;
    /**
     * last accessed task graphs by current user
     * @type {Array<ArrayInfo>}
     * @memberof OverviewData
     */
    last_accessed_taskgraphs?: Array<ArrayInfo>;
    /**
     * 
     * @type {AccountOverview}
     * @memberof OverviewData
     */
    account_overview?: AccountOverview;
}
/**
 * Ownership level of assets
 * @export
 * @enum {string}
 */
export enum OwnershipLevel {
    Owned = 'owned',
    Shared = 'shared',
    Public = 'public'
}

/**
 * 
 * @export
 * @interface PaginationMetadata
 */
export interface PaginationMetadata {
    /**
     * pagination offset
     * @type {number}
     * @memberof PaginationMetadata
     */
    page: number;
    /**
     * pagination limit
     * @type {number}
     * @memberof PaginationMetadata
     */
    per_page: number;
    /**
     * number of total pages with current limit
     * @type {number}
     * @memberof PaginationMetadata
     */
    total_pages: number;
    /**
     * number of total available items
     * @type {number}
     * @memberof PaginationMetadata
     */
    total_items: number;
}
/**
 * Password to update
 * @export
 * @interface PasswordReset
 */
export interface PasswordReset {
    /**
     * reset password api token for user
     * @type {string}
     * @memberof PasswordReset
     */
    token?: string;
    /**
     * password
     * @type {string}
     * @memberof PasswordReset
     */
    password?: string;
}
/**
 * User setting up credit card details
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * Billing Email
     * @type {string}
     * @memberof PaymentMethod
     */
    email: string;
    /**
     * Stripe one use token from element card
     * @type {string}
     * @memberof PaymentMethod
     */
    token?: string;
    /**
     * Billing Address Line1
     * @type {string}
     * @memberof PaymentMethod
     */
    address1: string;
    /**
     * Billing Address Line2
     * @type {string}
     * @memberof PaymentMethod
     */
    address2?: string;
    /**
     * Billing City
     * @type {string}
     * @memberof PaymentMethod
     */
    city: string;
    /**
     * Billing State
     * @type {string}
     * @memberof PaymentMethod
     */
    state: string;
    /**
     * Billing Country
     * @type {string}
     * @memberof PaymentMethod
     */
    country: string;
    /**
     * Billing ZipCode
     * @type {string}
     * @memberof PaymentMethod
     */
    zip_code: string;
    /**
     * Last 4 of CC on file
     * @type {string}
     * @memberof PaymentMethod
     */
    cc_last_4?: string;
    /**
     * Billing name
     * @type {string}
     * @memberof PaymentMethod
     */
    name: string;
    /**
     * type of cc
     * @type {string}
     * @memberof PaymentMethod
     */
    cc_brand?: string;
    /**
     * Free credits given to the user
     * @type {number}
     * @memberof PaymentMethod
     */
    sign_up_credits?: number;
    /**
     * Indicates if customer is a trusted customer
     * @type {boolean}
     * @memberof PaymentMethod
     */
    is_trusted_customer?: boolean;
}
/**
 * The code sent back to the backend allowing it to verify an OpenID Connect login and create a user etc. 
 * @export
 * @interface PerDomainLoginCode
 */
export interface PerDomainLoginCode {
    /**
     * The `code` parameter returned by the OpenID Connect server.
     * @type {string}
     * @memberof PerDomainLoginCode
     */
    code: string;
    /**
     * The claimed domain that the user is logging into.
     * @type {string}
     * @memberof PerDomainLoginCode
     */
    domain: string;
    /**
     * The `redirect_url` parameter that was sent to the OpenID Connect server to redirect the user back to TileDB Cloud to complete login.
     * @type {string}
     * @memberof PerDomainLoginCode
     */
    redirect_url: string;
}
/**
 * The data needed to send a user to an OpenID Connect login page.
 * @export
 * @interface PerDomainOIDCEndpoint
 */
export interface PerDomainOIDCEndpoint {
    /**
     * The claimed domain that the user is logging into.
     * @type {string}
     * @memberof PerDomainOIDCEndpoint
     */
    domain: string;
    /**
     * The OpenID Connect authorization endpoint URL. Used to construct the URL we send the user to for SSO. 
     * @type {string}
     * @memberof PerDomainOIDCEndpoint
     */
    authorization_endpoint: string;
    /**
     * The OpenID Connect Client ID.
     * @type {string}
     * @memberof PerDomainOIDCEndpoint
     */
    client_id: string;
    /**
     * The OpenID Connect scopes we request.
     * @type {Array<string>}
     * @memberof PerDomainOIDCEndpoint
     */
    scopes: Array<string>;
}
/**
 * The code sent back to the backend allowing it to verify an OpenID Connect login and create a user etc. 
 * @export
 * @interface PerDomainSignIn
 */
export interface PerDomainSignIn {
    /**
     * The `code` parameter returned by the OpenID Connect server.
     * @type {string}
     * @memberof PerDomainSignIn
     */
    code?: string;
    /**
     * The claimed domain that the user is logging into.
     * @type {string}
     * @memberof PerDomainSignIn
     */
    domain?: string;
    /**
     * The `redirect_url` parameter that was sent to the OpenID Connect server to redirect the user back to TileDB Cloud to complete login.
     * @type {string}
     * @memberof PerDomainSignIn
     */
    redirect_url?: string;
}
/**
 * List of all available pod statuses
 * @export
 * @enum {string}
 */
export enum PodStatus {
    TERMINATING = 'TERMINATING',
    RUNNING = 'RUNNING',
    LAUNCHING = 'LAUNCHING'
}

/**
 * Parameters for ingesting point cloud data. See tiledb.cloud.geospatial.ingest for details. 
 * @export
 * @interface PointCloudIngestionRequest
 */
export interface PointCloudIngestionRequest {
    /**
     * dataset URI
     * @type {string}
     * @memberof PointCloudIngestionRequest
     */
    dataset_uri?: string;
    /**
     * The name of the ARN credentials to use to read source data and write to destination.
     * @type {string}
     * @memberof PointCloudIngestionRequest
     */
    access_credentials_name?: string;
    /**
     * The name of the ingested data.
     * @type {string}
     * @memberof PointCloudIngestionRequest
     */
    register_name?: string;
    /**
     * The URI to search.
     * @type {string}
     * @memberof PointCloudIngestionRequest
     */
    search_uri?: string;
    /**
     * What pattern to search in search_uri.
     * @type {string}
     * @memberof PointCloudIngestionRequest
     */
    pattern?: string;
    /**
     * What pattern to ignore in search_uri.
     * @type {string}
     * @memberof PointCloudIngestionRequest
     */
    ignore?: string;
    /**
     * Verbose ingest or not.
     * @type {boolean}
     * @memberof PointCloudIngestionRequest
     */
    verbose?: boolean;
    /**
     * Enables trace logging
     * @type {boolean}
     * @memberof PointCloudIngestionRequest
     */
    trace?: boolean;
}
/**
 * preview for different array backed files
 * @export
 * @interface Preview
 */
export interface Preview {
    /**
     * previewable contents
     * @type {string}
     * @memberof Preview
     */
    value?: string;
    /**
     * language type used for the preview
     * @type {string}
     * @memberof Preview
     */
    language?: string;
    /**
     * the mime type of the file
     * @type {string}
     * @memberof Preview
     */
    mimeType?: string | null;
    /**
     * timestamp (epoch milliseconds) the preview corresponds to
     * @type {number}
     * @memberof Preview
     */
    end_timestamp?: number | null;
    /**
     * timestamp the preview corresponds to in RFC3339
     * @type {string}
     * @memberof Preview
     */
    EndTimestampRFC3339?: string;
}
/**
 * Pricing created by converting an array to product
 * @export
 * @interface Pricing
 */
export interface Pricing {
    /**
     * Unique id of plan as defined by Stripe
     * @type {string}
     * @memberof Pricing
     */
    id: string;
    /**
     * Unique id of registered array
     * @type {string}
     * @memberof Pricing
     */
    array_uuid: string;
    /**
     * Name of pricing
     * @type {string}
     * @memberof Pricing
     */
    pricing_name: string;
    /**
     * 
     * @type {PricingType}
     * @memberof Pricing
     */
    pricing_type: PricingType;
    /**
     * Name of product
     * @type {string}
     * @memberof Pricing
     */
    product_name: string;
    /**
     * Extra information about a product which will appear on your customer’s credit card statement
     * @type {string}
     * @memberof Pricing
     */
    product_statement_descriptor: string;
    /**
     * 
     * @type {PricingUnitLabel}
     * @memberof Pricing
     */
    product_unit_label: PricingUnitLabel;
    /**
     * 
     * @type {PricingCurrency}
     * @memberof Pricing
     */
    currency: PricingCurrency;
    /**
     * 
     * @type {PricingAggregateUsage}
     * @memberof Pricing
     */
    aggregate_usage: PricingAggregateUsage;
    /**
     * 
     * @type {PricingInterval}
     * @memberof Pricing
     */
    interval: PricingInterval;
    /**
     * Group of n product unit labels
     * @type {number}
     * @memberof Pricing
     */
    divided_by: number;
    /**
     * Price in cents (decimal) per unitlabel
     * @type {number}
     * @memberof Pricing
     */
    charge: number;
    /**
     * If pricing is activated
     * @type {boolean}
     * @memberof Pricing
     */
    activated: boolean;
}
/**
 * Specifies a usage aggregation strategy for pricings of usage_type=metered
 * @export
 * @enum {string}
 */
export enum PricingAggregateUsage {
    Sum = 'sum'
}

/**
 * Currency of pricing
 * @export
 * @enum {string}
 */
export enum PricingCurrency {
    USD = 'USD'
}

/**
 * Pricing info created
 * @export
 * @interface PricingInfo
 */
export interface PricingInfo {
    /**
     * Price in cents (decimal) per unitlabel
     * @type {number}
     * @memberof PricingInfo
     */
    egress_charge?: number;
    /**
     * Price in cents (decimal) per unitlabel
     * @type {number}
     * @memberof PricingInfo
     */
    access_charge?: number;
}
/**
 * Interval for pricing
 * @export
 * @enum {string}
 */
export enum PricingInterval {
    Month = 'month'
}

/**
 * Pricing types
 * @export
 * @enum {string}
 */
export enum PricingType {
    Egress = 'egress',
    Access = 'access'
}

/**
 * Unit label
 * @export
 * @enum {string}
 */
export enum PricingUnitLabel {
    Byte = 'byte',
    Second = 'second'
}

/**
 * Query parameter to get array metadatas
 * @export
 * @enum {string}
 */
export enum PublicShareFilter {
    Exclude = 'exclude',
    Only = 'only'
}

/**
 * Query returning results as json
 * @export
 * @interface QueryJson
 */
export interface QueryJson {
    /**
     * 
     * @type {QueryRanges}
     * @memberof QueryJson
     */
    query_ranges?: QueryRanges;
    /**
     * List of fields to return data from, empty means return data for all fields
     * @type {Array<string>}
     * @memberof QueryJson
     */
    fields?: Array<string>;
}
/**
 * Subarray bounds to query
 * @export
 * @interface QueryRanges
 */
export interface QueryRanges {
    /**
     * 
     * @type {Layout}
     * @memberof QueryRanges
     */
    layout?: Layout;
    /**
     * List of ranges,
     * @type {Array<Array<number>>}
     * @memberof QueryRanges
     */
    ranges?: Array<Array<number>>;
}
/**
 * Type of query
 * @export
 * @enum {string}
 */
export enum QueryType {
    READ = 'READ',
    WRITE = 'WRITE'
}

/**
 * The structure and metadata of a task graph that can be stored on TileDB Cloud and executed by users who have access to it. 
 * @export
 * @interface RegisteredTaskGraph
 */
export interface RegisteredTaskGraph {
    /**
     * A server-assigned unique ID for the UDF, in UUID format.
     * @type {string}
     * @memberof RegisteredTaskGraph
     */
    uuid?: string;
    /**
     * The namespace that owns this task graph log.
     * @type {string}
     * @memberof RegisteredTaskGraph
     */
    namespace?: string;
    /**
     * The name of this graph, to appear in URLs. Must be unique per-namespace. 
     * @type {string}
     * @memberof RegisteredTaskGraph
     */
    name?: string;
    /**
     * Documentation for the task graph, in Markdown format.
     * @type {string}
     * @memberof RegisteredTaskGraph
     */
    readme?: string;
    /**
     * SPDX license identifier.
     * @type {string}
     * @memberof RegisteredTaskGraph
     */
    license_id?: string | null;
    /**
     * Full text of the license.
     * @type {string}
     * @memberof RegisteredTaskGraph
     */
    license_text?: string | null;
    /**
     * Optional tags to classify the graph.
     * @type {Array<string>}
     * @memberof RegisteredTaskGraph
     */
    tags?: Array<string>;
    /**
     * The structure of the graph, in the form of the nodes that make it up. As with `TaskGraphLog`, nodes must topologically sorted, so that any node appears after all the nodes it depends on. 
     * @type {Array<TaskGraphNode>}
     * @memberof RegisteredTaskGraph
     */
    nodes?: Array<TaskGraphNode>;
}
/**
 * Results type
 * @export
 * @enum {string}
 */
export enum ResultFormat {
    Native = 'native',
    Json = 'json',
    Arrow = 'arrow'
}

/**
 * RetryPolicy is a policy of node statuses that will be retried
 * @export
 * @enum {string}
 */
export enum RetryPolicy {
    Always = 'Always',
    OnFailure = 'OnFailure',
    OnError = 'OnError',
    OnTransientError = 'OnTransientError'
}

/**
 * RetryStrategy provides controls on how to retry a taskgraph node
 * @export
 * @interface RetryStrategy
 */
export interface RetryStrategy {
    /**
     * 
     * @type {Backoff}
     * @memberof RetryStrategy
     */
    backoff?: Backoff;
    /**
     * Expression is a condition expression for when a node will be retried. If it evaluates to false, the node will not be retried and the retry strategy will be ignored
     * @type {string}
     * @memberof RetryStrategy
     */
    expression?: string;
    /**
     * Limit is the maximum number of retry attempts when retrying a container. It does not include the original container; the maximum number of total attempts will be `limit + 1`.
     * @type {number}
     * @memberof RetryStrategy
     */
    limit?: number;
    /**
     * 
     * @type {RetryPolicy}
     * @memberof RetryStrategy
     */
    retryPolicy?: RetryPolicy;
}
/**
 * The information used to set up a single-sign on connection to a customer domain. 
 * @export
 * @interface SSODomainConfig
 */
export interface SSODomainConfig {
    /**
     * A server-generated ID for the configuration.
     * @type {string}
     * @memberof SSODomainConfig
     */
    uuid?: string;
    /**
     * The fully-qualified domain (but with no trailing dot) to connect for single sign-on. This may not be changed after creation. 
     * @type {string}
     * @memberof SSODomainConfig
     */
    domain?: string | null;
    /**
     * The URL of the OpenID Connect issuer that can be used to authenticate this domain\'s users. The prefix where the `/.well-known/openid-configuration` file can be found; usually without a trailing slash. 
     * @type {string}
     * @memberof SSODomainConfig
     */
    oidc_issuer?: string;
    /**
     * The OpenID Connect client ID for this SSO instance.
     * @type {string}
     * @memberof SSODomainConfig
     */
    oidc_client_id?: string;
    /**
     * The OpenID Connect client secret for this SSO instance.
     * @type {string}
     * @memberof SSODomainConfig
     */
    oidc_client_secret?: string;
    /**
     * 
     * @type {SSODomainSetup}
     * @memberof SSODomainConfig
     */
    domain_setup?: SSODomainSetup;
    /**
     * 
     * @type {DomainVerificationStatus}
     * @memberof SSODomainConfig
     */
    verification_status?: DomainVerificationStatus;
    /**
     * A list of the results of recent attempts to verify this domain. 
     * @type {Array<DomainCheckResult>}
     * @memberof SSODomainConfig
     */
    check_results?: Array<DomainCheckResult>;
}
/**
 * The response to a request for the list of domain claims associated with a particular organization. 
 * @export
 * @interface SSODomainConfigResponse
 */
export interface SSODomainConfigResponse {
    /**
     * 
     * @type {Array<SSODomainConfig>}
     * @memberof SSODomainConfigResponse
     */
    domain_configs?: Array<SSODomainConfig>;
}
/**
 * Configuration settings to verify ownership of a given domain. At least one of these must be completed enable user login from the domain. 
 * @export
 * @interface SSODomainSetup
 */
export interface SSODomainSetup {
    /**
     * a DNS TXT record to set on the domain to claim.
     * @type {string}
     * @memberof SSODomainSetup
     */
    txt?: string;
    /**
     * a DNS name to set a CNAME record on
     * @type {string}
     * @memberof SSODomainSetup
     */
    cname_src?: string;
    /**
     * the CNAME target of `cname_src`.
     * @type {string}
     * @memberof SSODomainSetup
     */
    cname_dst?: string;
}
/**
 * The path at which a given asset will be stored, and the credentials used to access that asset. 
 * @export
 * @interface StorageLocation
 */
export interface StorageLocation {
    /**
     * The path to store this asset type. If unset, a suffix of the user\'s `default_s3_path` is used. When updating, explicitly set to `\"\"`, the empty string, to clear this path; leaving it `null` (or absent) will leave the path unchanged. 
     * @type {string}
     * @memberof StorageLocation
     */
    path?: string | null;
    /**
     * The name of the credentials used to acess this storage path. If unset, the `default_s3_path_credentials_name` is used. When updating, explicitly set to `\"\"`, the empty string, to clear this name; leaving it `null` (or absent) will leave the name unchanged. 
     * @type {string}
     * @memberof StorageLocation
     */
    credentials_name?: string | null;
}
/**
 * User setting up credit card details
 * @export
 * @interface StripeDetails
 */
export interface StripeDetails {
    /**
     * Billing Email
     * @type {string}
     * @memberof StripeDetails
     */
    email: string;
    /**
     * Stripe one use token from element card
     * @type {string}
     * @memberof StripeDetails
     */
    token?: string;
    /**
     * Billing Address Line1
     * @type {string}
     * @memberof StripeDetails
     */
    address1: string;
    /**
     * Billing Address Line2
     * @type {string}
     * @memberof StripeDetails
     */
    address2?: string;
    /**
     * Billing City
     * @type {string}
     * @memberof StripeDetails
     */
    city: string;
    /**
     * Billing State
     * @type {string}
     * @memberof StripeDetails
     */
    state: string;
    /**
     * Billing Country
     * @type {string}
     * @memberof StripeDetails
     */
    country: string;
    /**
     * Billing ZipCode
     * @type {string}
     * @memberof StripeDetails
     */
    zip_code: string;
    /**
     * Last 4 of CC on file
     * @type {string}
     * @memberof StripeDetails
     */
    cc_last_4?: string;
    /**
     * Billing name
     * @type {string}
     * @memberof StripeDetails
     */
    name: string;
    /**
     * type of cc
     * @type {string}
     * @memberof StripeDetails
     */
    cc_brand?: string;
}
/**
 * User sign in with stripe
 * @export
 * @interface StripeSignIn
 */
export interface StripeSignIn {
    /**
     * stripe\'s user code
     * @type {string}
     * @memberof StripeSignIn
     */
    code: string;
    /**
     * a namespace ID, user or organization
     * @type {string}
     * @memberof StripeSignIn
     */
    state?: string;
    /**
     * flag to create a token with expiration of 30 days, default is false
     * @type {string}
     * @memberof StripeSignIn
     */
    remember_me?: string;
}
/**
 * Specifies that a node is an “input value”, allowing for parameterized task graphs. An input node may not depend upon any other nodes. 
 * @export
 * @interface TGInputNodeData
 */
export interface TGInputNodeData {
    /**
     * An argument provided to a node. This is one of a direct value (i.e., a raw JSON value) or a `TGSentinel`. For example this Python value:      {\"a\": [1, \"pipe\", range(30), None], \"b\": b\"bytes\"}  is encoded thusly (with included comments):      {  // A dictionary with string keys is JSON-encodable.       \"a\": [  // As is a list.         1,         \"pipe\",         {  // A `range` is replaced with its pickle.           \"__tdbudf__\": \"immediate\",           \"format\": \"python_pickle\",           \"base64_data\": \"gASVIAAAAAAAAACMCGJ1aWx0aW5zlIwFcmFuZ2WUk5RLAEseSwGHlFKULg==\"         },         null       ],       \"b\": {  // Raw binary data is encoded into base64.         \"__tdbudf__\": \"immediate\"         \"format\": \"bytes\",         \"base64_data\": \"Ynl0ZXM=\"       }     } 
     * @type {object}
     * @memberof TGInputNodeData
     */
    default_value?: object;
    /**
     * An annotation of what datatype this node is supposed to be. Conventionally, this is a Python-format type annotation, but it’s purely for documentation purposes and not validated. 
     * @type {string}
     * @memberof TGInputNodeData
     */
    datatype?: string | null;
}
/**
 * A node specifying an SQL query to execute in TileDB Cloud. 
 * @export
 * @interface TGSQLNodeData
 */
export interface TGSQLNodeData {
    /**
     * The commands to execute before running the query itself.
     * @type {Array<string>}
     * @memberof TGSQLNodeData
     */
    init_commands?: Array<string>;
    /**
     * The text of the SQL query to execute. Parameters are substituted in for `?`s, just as in a regular MariaDB query. 
     * @type {string}
     * @memberof TGSQLNodeData
     */
    query?: string;
    /**
     * The parameters to substitute in for arguments in the `query`. Fixed-length. Arguments must be in JSON format. 
     * @type {Array<object>}
     * @memberof TGSQLNodeData
     */
    parameters?: Array<object>;
    /**
     * 
     * @type {ResultFormat}
     * @memberof TGSQLNodeData
     */
    result_format?: ResultFormat;
}
/**
 * A single argument to a UDF. This may represent a positional argument or a named argument, depending upon whether `name` is set. 
 * @export
 * @interface TGUDFArgument
 */
export interface TGUDFArgument {
    /**
     * The name of the argument, if present.
     * @type {string}
     * @memberof TGUDFArgument
     */
    name?: string | null;
    /**
     * An argument provided to a node. This is one of a direct value (i.e., a raw JSON value) or a `TGSentinel`. For example this Python value:      {\"a\": [1, \"pipe\", range(30), None], \"b\": b\"bytes\"}  is encoded thusly (with included comments):      {  // A dictionary with string keys is JSON-encodable.       \"a\": [  // As is a list.         1,         \"pipe\",         {  // A `range` is replaced with its pickle.           \"__tdbudf__\": \"immediate\",           \"format\": \"python_pickle\",           \"base64_data\": \"gASVIAAAAAAAAACMCGJ1aWx0aW5zlIwFcmFuZ2WUk5RLAEseSwGHlFKULg==\"         },         null       ],       \"b\": {  // Raw binary data is encoded into base64.         \"__tdbudf__\": \"immediate\"         \"format\": \"bytes\",         \"base64_data\": \"Ynl0ZXM=\"       }     } 
     * @type {object}
     * @memberof TGUDFArgument
     */
    value?: object;
}
/**
 * Metadata about the environment where we want to execute a UDF.
 * @export
 * @interface TGUDFEnvironment
 */
export interface TGUDFEnvironment {
    /**
     * 
     * @type {UDFLanguage}
     * @memberof TGUDFEnvironment
     */
    language?: UDFLanguage;
    /**
     * The language version used to execute this UDF. Neither this nor `language` needs to be set for registered UDFs, since the language and version are stored server-side with the UDF itself. 
     * @type {string}
     * @memberof TGUDFEnvironment
     */
    language_version?: string;
    /**
     * The name of the image to use for the execution environment. 
     * @type {string}
     * @memberof TGUDFEnvironment
     */
    image_name?: string;
    /**
     * The resource class to use for the UDF execution. Resource classes define resource limits for memory and CPUs. If this is empty, then the UDF will execute in the standard resource class of the TileDB Cloud provider. 
     * @type {string}
     * @memberof TGUDFEnvironment
     */
    resource_class?: string;
    /**
     * 
     * @type {TGUDFEnvironmentResources}
     * @memberof TGUDFEnvironment
     */
    resources?: TGUDFEnvironmentResources;
    /**
     * A hint that, if possible, this function should be executed on the client side rather than on the server. Registered UDFs and functions which take arrays as inputs can never be executed client-side. If the client’s environment is incompatible, or the client does not support client-side execution, the function will be executed on the server. 
     * @type {boolean}
     * @memberof TGUDFEnvironment
     */
    run_client_side?: boolean;
}
/**
 * The resources requested for this particular node. If resources are not  specified resource_class is used, if it is not set the standard resource  // defaults are used 
 * @export
 * @interface TGUDFEnvironmentResources
 */
export interface TGUDFEnvironmentResources {
    /**
     * 
     * @type {string}
     * @memberof TGUDFEnvironmentResources
     */
    cpu?: string;
    /**
     * 
     * @type {string}
     * @memberof TGUDFEnvironmentResources
     */
    memory?: string;
    /**
     * 
     * @type {number}
     * @memberof TGUDFEnvironmentResources
     */
    gpu?: number;
}
/**
 * A node specifying the execution of a user-defined function.
 * @export
 * @interface TGUDFNodeData
 */
export interface TGUDFNodeData {
    /**
     * If set, the name of the registered UDF to execute, in the format `namespace/name`. Either this or `executable_code` should be set, but not both. 
     * @type {string}
     * @memberof TGUDFNodeData
     */
    registered_udf_name?: string | null;
    /**
     * If set, the base64 serialization of the code for this step, encoded in a language-specific format (e.g. Pickle for Python, serialization for R). 
     * @type {string}
     * @memberof TGUDFNodeData
     */
    executable_code?: string | null;
    /**
     * Optionally, the source text for the code passed in `executable_code`. *For reference only; only the code in `executable_code` is actually executed.* This will be included in activity logs and may be useful for debugging. 
     * @type {string}
     * @memberof TGUDFNodeData
     */
    source_text?: string;
    /**
     * 
     * @type {TGUDFEnvironment}
     * @memberof TGUDFNodeData
     */
    environment?: TGUDFEnvironment;
    /**
     * The arguments to a UDF function. This encompasses both named and positional arguments. The format is designed to provide compatibility across languages like Python which have a fairly traditional split between positional arguments and named arguments, and languages like R which has a rather unique way of specifying arguments. For Python (and most other languages), all positional arguments will come before all named arguments (if any are present):      // fn(arg1, arg2, arg3)     [       {value: arg1},       {value: arg2},       {value: arg3},     ]     // fn(arg1, arg2, n=kw1, a=kw2)     [       {value: arg1},       {value: arg2},       {name: \"n\", value: kw1},       {name: \"a\", value: kw2},     ]     // fn(kw=k1, only=k2)     [       {name: \"kw\", value: k1},       {name: \"only\", value: k2},     ]  However, in R, named and positional arguments may be intermixed freely:      // fn(arg, n=kw1, arg2)     [       {value: arg},       {name: \"n\", value: kw1},       {value: arg2},     ] 
     * @type {Array<TGUDFArgument>}
     * @memberof TGUDFNodeData
     */
    arguments?: Array<TGUDFArgument>;
}
/**
 * Information about a task graph.
 * @export
 * @interface TaskGraph
 */
export interface TaskGraph {
    /**
     * The server-generated UUID of the task graph.
     * @type {string}
     * @memberof TaskGraph
     */
    uuid?: string;
    /**
     * The namespace that owns this task graph. When creating a task graph log, this is used as the namespace to create the log in; thereafter it is read-only. 
     * @type {string}
     * @memberof TaskGraph
     */
    namespace?: string;
    /**
     * The name of the user who created this task graph log.
     * @type {string}
     * @memberof TaskGraph
     */
    created_by?: string;
    /**
     * A name for this task graph, displayed in the UI. Does not need to be unique. 
     * @type {string}
     * @memberof TaskGraph
     */
    name?: string;
    /**
     * The date/time when this task graph was originally created. 
     * @type {string}
     * @memberof TaskGraph
     */
    created_at?: string;
    /**
     * The structure of the graph. This is provided by the client when first setting up the task graph.  This must be topographically sorted; that is, each node must appear after all nodes that it depends upon. 
     * @type {Array<TaskGraphNode>}
     * @memberof TaskGraph
     */
    nodes?: Array<TaskGraphNode>;
    /**
     * Parallelism limits the max total parallel pods that can execute at the same time in a workflow. 
     * @type {number}
     * @memberof TaskGraph
     */
    parallelism?: number | null;
    /**
     * 
     * @type {RetryStrategy}
     * @memberof TaskGraph
     */
    retry_strategy?: RetryStrategy;
    /**
     * Duration in seconds relative to the workflow start time which the workflow is allowed to run before it gets terminated. 
     * @type {number}
     * @memberof TaskGraph
     */
    deadline?: number | null;
    /**
     * 
     * @type {TaskGraphType}
     * @memberof TaskGraph
     */
    task_graph_type?: TaskGraphType;
}
/**
 * actions a user can take on a UDF
 * @export
 * @enum {string}
 */
export enum TaskGraphActions {
    FetchTaskGraph = 'fetch_task_graph',
    ShareTaskGraph = 'share_task_graph'
}

/**
 * Event capturing a status change in a task graph
 * @export
 * @interface TaskGraphEvent
 */
export interface TaskGraphEvent {
    /**
     * The server-generated UUID of the task graph. This field holds the value of TaskGraphLog.UUID. 
     * @type {string}
     * @memberof TaskGraphEvent
     */
    uuid: string;
    /**
     * The non-unique name of this task graph log, displayed in the UI. This field holds the value of TaskGraphLog.Name. 
     * @type {string}
     * @memberof TaskGraphEvent
     */
    name: string;
    /**
     * The namespace name that owns this task graph log. This field holds the value of TaskGraphLog.Namespace. 
     * @type {string}
     * @memberof TaskGraphEvent
     */
    namespace: string;
    /**
     * 
     * @type {TaskGraphEventType}
     * @memberof TaskGraphEvent
     */
    task_graph_event_type: TaskGraphEventType;
}
/**
 * List of values that TaskGraphEvent can take These statuses are a subset of TaskGraphLogStatus, defined in openapi-v1.yaml. 
 * @export
 * @enum {string}
 */
export enum TaskGraphEventType {
    Succeeded = 'succeeded',
    Failed = 'failed',
    Cancelled = 'cancelled'
}

/**
 * Logging information about the execution of a task graph.
 * @export
 * @interface TaskGraphLog
 */
export interface TaskGraphLog {
    /**
     * The server-generated UUID of the task graph.
     * @type {string}
     * @memberof TaskGraphLog
     */
    uuid?: string;
    /**
     * The namespace that owns this task graph log. When creating a task graph log, this is used as the namespace to create the log in; thereafter it is read-only. 
     * @type {string}
     * @memberof TaskGraphLog
     */
    namespace?: string;
    /**
     * The name of the user who created this task graph log.
     * @type {string}
     * @memberof TaskGraphLog
     */
    created_by?: string;
    /**
     * A name for this task graph log, displayed in the UI. Does not need to be unique. 
     * @type {string}
     * @memberof TaskGraphLog
     */
    name?: string;
    /**
     * The date/time when this task graph log was originally created. This is distinct from the execution start_time. 
     * @type {string}
     * @memberof TaskGraphLog
     */
    created_at?: string;
    /**
     * The start time of the task graph, recorded when the server starts executing the first node. 
     * @type {string}
     * @memberof TaskGraphLog
     */
    start_time?: string | null;
    /**
     * The end time of the task graph, recorded when the client reports completion. 
     * @type {string}
     * @memberof TaskGraphLog
     */
    end_time?: string | null;
    /**
     * 
     * @type {TaskGraphLogStatus}
     * @memberof TaskGraphLog
     */
    status?: TaskGraphLogStatus;
    /**
     * If present, the total cost of executing all nodes in this task graph. 
     * @type {number}
     * @memberof TaskGraphLog
     */
    total_cost?: number | null;
    /**
     * If present, the total cost of access from execution of the nodes in this task graph. 
     * @type {number}
     * @memberof TaskGraphLog
     */
    access_cost?: number | null;
    /**
     * If present, the total cost of access from execution of the nodes in this task graph. 
     * @type {number}
     * @memberof TaskGraphLog
     */
    egress_cost?: number | null;
    /**
     * The total execution time of all the nodes in this graph, in ISO 8601 format with hours, minutes, and seconds. 
     * @type {string}
     * @memberof TaskGraphLog
     */
    execution_time?: string;
    /**
     * A mapping from `ArrayTaskStatus` string value to the number of nodes in this graph that are in that status. 
     * @type {{ [key: string]: number; }}
     * @memberof TaskGraphLog
     */
    status_count?: { [key: string]: number; };
    /**
     * The structure of the graph. This is provided by the client when first setting up the task graph. Thereafter, it is read-only. This must be topographically sorted; that is, each node must appear after all nodes that it depends upon. 
     * @type {Array<TaskGraphNodeMetadata>}
     * @memberof TaskGraphLog
     */
    nodes?: Array<TaskGraphNodeMetadata>;
    /**
     * 
     * @type {TaskGraphType}
     * @memberof TaskGraphLog
     */
    task_graph_type?: TaskGraphType;
    /**
     * The UUID of the task graph.
     * @type {string}
     * @memberof TaskGraphLog
     */
    task_graph_id?: string;
    /**
     * A system message describing a potential system error
     * @type {string}
     * @memberof TaskGraphLog
     */
    status_message?: string | null;
    /**
     * The name of the cloud provider where this task graph executed.
     * @type {string}
     * @memberof TaskGraphLog
     */
    cloud_provider?: string;
    /**
     * The region of the cloud provider where this task graph executed.
     * @type {string}
     * @memberof TaskGraphLog
     */
    cloud_region?: string;
}
/**
 * The location where an individual node of a task graph is executed. 
 * @export
 * @enum {string}
 */
export enum TaskGraphLogRunLocation {
    Server = 'server',
    Client = 'client',
    Virtual = 'virtual'
}

/**
 * The status of a given task graph execution.
 * @export
 * @enum {string}
 */
export enum TaskGraphLogStatus {
    Submitted = 'submitted',
    Running = 'running',
    Idle = 'idle',
    Abandoned = 'abandoned',
    Succeeded = 'succeeded',
    Failed = 'failed',
    Cancelled = 'cancelled'
}

/**
 * Response data for a task graph list, including pagination metadata.
 * @export
 * @interface TaskGraphLogsData
 */
export interface TaskGraphLogsData {
    /**
     * The requested task graph logs.
     * @type {Array<TaskGraphLog>}
     * @memberof TaskGraphLogsData
     */
    task_graph_logs?: Array<TaskGraphLog>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof TaskGraphLogsData
     */
    pagination_metadata?: PaginationMetadata;
}
/**
 * Information about a single node within a registered task graph. A single node represents one piece of data or a computational step; either as an input value, a data source, or a computation that acts upon earlier nodes. The structure parallels the existing `TaskGraphNodeMetadata`. 
 * @export
 * @interface TaskGraphNode
 */
export interface TaskGraphNode {
    /**
     * The client-generated UUID of the given graph node.
     * @type {string}
     * @memberof TaskGraphNode
     */
    client_node_id?: string;
    /**
     * A client-specified name for the node. If provided, this must be unique. 
     * @type {string}
     * @memberof TaskGraphNode
     */
    name?: string | null;
    /**
     * The client_node_uuid of each node that this node depends upon. Used to define the structure of the graph. 
     * @type {Array<string>}
     * @memberof TaskGraphNode
     */
    depends_on?: Array<string>;
    /**
     * 
     * @type {UDFArrayDetails}
     * @memberof TaskGraphNode
     */
    array_node?: UDFArrayDetails;
    /**
     * 
     * @type {TGInputNodeData}
     * @memberof TaskGraphNode
     */
    input_node?: TGInputNodeData | null;
    /**
     * 
     * @type {TGSQLNodeData}
     * @memberof TaskGraphNode
     */
    sql_node?: TGSQLNodeData | null;
    /**
     * 
     * @type {TGUDFNodeData}
     * @memberof TaskGraphNode
     */
    udf_node?: TGUDFNodeData | null;
    /**
     * 
     * @type {RetryStrategy}
     * @memberof TaskGraphNode
     */
    retry_strategy?: RetryStrategy;
    /**
     * Used to create dynamic tasks based on the output of another node. The other node\'s output must be a JSON list of values. The expansion process creates one task per item in the output list. The item is also passed as an argument to each task. The value is the client_node_uuid of the node that we want to expand. 
     * @type {string}
     * @memberof TaskGraphNode
     */
    expand_node_output?: string | null;
    /**
     * Duration in seconds relative to the node start time which the node is allowed to run before it gets terminated. 
     * @type {number}
     * @memberof TaskGraphNode
     */
    deadline?: number | null;
}
/**
 * Metadata about an individual node in a task graph.
 * @export
 * @interface TaskGraphNodeMetadata
 */
export interface TaskGraphNodeMetadata {
    /**
     * The client-generated UUID of the given graph node.
     * @type {string}
     * @memberof TaskGraphNodeMetadata
     */
    client_node_uuid?: string;
    /**
     * The client-generated name of the node. This is not guaranteed to be unique. 
     * @type {string}
     * @memberof TaskGraphNodeMetadata
     */
    name?: string;
    /**
     * The client_node_uuid of each node that this node depends upon. Used to define the structure of the graph. 
     * @type {Array<string>}
     * @memberof TaskGraphNodeMetadata
     */
    depends_on?: Array<string>;
    /**
     * 
     * @type {TaskGraphLogRunLocation}
     * @memberof TaskGraphNodeMetadata
     */
    run_location?: TaskGraphLogRunLocation;
    /**
     * 
     * @type {ArrayTaskStatus}
     * @memberof TaskGraphNodeMetadata
     */
    status?: ArrayTaskStatus;
    /**
     * The reason the array task status is in the state
     * @type {string}
     * @memberof TaskGraphNodeMetadata
     */
    status_message?: string | null;
    /**
     * ArrayTasks representing each execution attempt for this node. For nodes that have never been submitted, this will be empty. For nodes that have been retried, this may have multiple entries. The last one in the list represents the most recent execution. This is read-only and generated by the server based on the tasks it has actually executed. 
     * @type {Array<ArrayTask>}
     * @memberof TaskGraphNodeMetadata
     */
    executions?: Array<ArrayTask>;
}
/**
 * details for sharing a given registered task graph
 * @export
 * @interface TaskGraphSharing
 */
export interface TaskGraphSharing {
    /**
     * List of permitted actions
     * @type {Array<TaskGraphActions>}
     * @memberof TaskGraphSharing
     */
    actions?: Array<TaskGraphActions>;
    /**
     * namespace being granted array access can be a user or organization
     * @type {string}
     * @memberof TaskGraphSharing
     */
    namespace?: string;
    /**
     * details on if the namespace is a organization or user
     * @type {string}
     * @memberof TaskGraphSharing
     */
    namespace_type?: string;
}
/**
 * The type of a task graph. 
 * @export
 * @enum {string}
 */
export enum TaskGraphType {
    Batch = 'batch',
    Realtime = 'realtime'
}

/**
 * Information about a series of task graphs.
 * @export
 * @interface TaskGraphs
 */
export interface TaskGraphs {
    /**
     * The series of graphs. 
     * @type {Array<TaskGraph>}
     * @memberof TaskGraphs
     */
    graphs?: Array<TaskGraph>;
}
/**
 * Object containing data related to taskgraph logs to be used in the tasgraph log listing route 
 * @export
 * @interface TaskgraphLogsFiltersResponse
 */
export interface TaskgraphLogsFiltersResponse {
    /**
     * List of users that have ran any taskgraph that belongs to the request namespace
     * @type {Array<TaskgraphLogsFiltersResponseCreators>}
     * @memberof TaskgraphLogsFiltersResponse
     */
    creators?: Array<TaskgraphLogsFiltersResponseCreators>;
}
/**
 * 
 * @export
 * @interface TaskgraphLogsFiltersResponseCreators
 */
export interface TaskgraphLogsFiltersResponseCreators {
    /**
     * user\'s uuid
     * @type {string}
     * @memberof TaskgraphLogsFiltersResponseCreators
     */
    id: string;
    /**
     * user\'s username
     * @type {string}
     * @memberof TaskgraphLogsFiltersResponseCreators
     */
    username: string;
}
/**
 * TileDB config used for interaction with the embedded library
 * @export
 * @interface TileDBConfig
 */
export interface TileDBConfig {
    /**
     * 
     * @type {Array<TileDBConfigEntries>}
     * @memberof TileDBConfig
     */
    entries?: Array<TileDBConfigEntries>;
}
/**
 * 
 * @export
 * @interface TileDBConfigEntries
 */
export interface TileDBConfigEntries {
    /**
     * 
     * @type {string}
     * @memberof TileDBConfigEntries
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof TileDBConfigEntries
     */
    value?: string;
}
/**
 * A api token and its metadata
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * unique id of organization
     * @type {string}
     * @memberof Token
     */
    user_id: string;
    /**
     * A api token
     * @type {string}
     * @memberof Token
     */
    token: string;
    /**
     * Name of token
     * @type {string}
     * @memberof Token
     */
    name: string;
    /**
     * datetime the token was created at
     * @type {string}
     * @memberof Token
     */
    issued_at: string;
    /**
     * datetime the token when token will expire
     * @type {string}
     * @memberof Token
     */
    expires_at: string;
    /**
     * 
     * @type {TokenScope}
     * @memberof Token
     */
    scope: TokenScope;
}
/**
 * A request from a user for an api token
 * @export
 * @interface TokenRequest
 */
export interface TokenRequest {
    /**
     * Expiration date for token, if empty token defaults to 30 minutes
     * @type {string}
     * @memberof TokenRequest
     */
    expires?: string;
    /**
     * Optional name for token, if the name already exists for the user it will be auto incremented (i.e. myToken-1)
     * @type {string}
     * @memberof TokenRequest
     */
    name?: string;
    /**
     * 
     * @type {TokenScope}
     * @memberof TokenRequest
     */
    scope?: TokenScope;
}
/**
 * An api token scope available for creation
 * @export
 * @enum {string}
 */
export enum TokenScope {
    PasswordReset = 'password_reset',
    ConfirmEmail = 'confirm_email',
    Star = '*',
    Userread = 'user:read',
    UserreadWrite = 'user:read-write',
    Useradmin = 'user:admin',
    Arrayread = 'array:read',
    ArrayreadWrite = 'array:read-write',
    Arrayadmin = 'array:admin',
    Organizationread = 'organization:read',
    OrganizationreadWrite = 'organization:read-write',
    Organizationadmin = 'organization:admin',
    Groupread = 'group:read',
    GroupreadWrite = 'group:read-write',
    Groupadmin = 'group:admin'
}

/**
 * Contains array details for multi-array query including uri, ranges buffers
 * @export
 * @interface UDFArrayDetails
 */
export interface UDFArrayDetails {
    /**
     * An optional client-generated identifier to distinguish between multiple range/buffer requests from the same array in the same call. This may be set for MultiArrayUDFs that use the `argument_json` style of passing arrays. 
     * @type {string}
     * @memberof UDFArrayDetails
     */
    parameter_id?: string | null;
    /**
     * array to set ranges and buffers on, must be in tiledb:// format
     * @type {string}
     * @memberof UDFArrayDetails
     */
    uri?: string;
    /**
     * 
     * @type {QueryRanges}
     * @memberof UDFArrayDetails
     */
    ranges?: QueryRanges;
    /**
     * List of buffers to fetch (attributes + dimensions)
     * @type {Array<string>}
     * @memberof UDFArrayDetails
     */
    buffers?: Array<string>;
}
/**
 * Copied udf uri and information
 * @export
 * @interface UDFCopied
 */
export interface UDFCopied {
    /**
     * output location of the TileDB udf
     * @type {string}
     * @memberof UDFCopied
     */
    output_uri?: string;
    /**
     * namespace of the copied udf
     * @type {string}
     * @memberof UDFCopied
     */
    namespace?: string;
    /**
     * name of the copied udf
     * @type {string}
     * @memberof UDFCopied
     */
    name?: string;
    /**
     * unique ID of the copied udf
     * @type {string}
     * @memberof UDFCopied
     */
    id: string;
}
/**
 * information required to copy a udf
 * @export
 * @interface UDFCopy
 */
export interface UDFCopy {
    /**
     * output location of the TileDB File
     * @type {string}
     * @memberof UDFCopy
     */
    output_uri?: string;
    /**
     * namespace to register the copy. If empty use the namespace of the request user
     * @type {string}
     * @memberof UDFCopy
     */
    namespace?: string;
    /**
     * name to set for the copy. If empty use the name as the original udf, if it not already used in the namespace
     * @type {string}
     * @memberof UDFCopy
     */
    name?: string;
}
/**
 * A user-favorite UDF item
 * @export
 * @interface UDFFavorite
 */
export interface UDFFavorite {
    /**
     * unique UUID of the UDF
     * @type {string}
     * @memberof UDFFavorite
     */
    udf_uuid?: string;
    /**
     * the namespace of the UDF
     * @type {string}
     * @memberof UDFFavorite
     */
    namespace?: string;
    /**
     * the name of the UDF
     * @type {string}
     * @memberof UDFFavorite
     */
    name?: string;
}
/**
 * Object including UDF favorites and pagination metadata
 * @export
 * @interface UDFFavoritesData
 */
export interface UDFFavoritesData {
    /**
     * List of UDF infos
     * @type {Array<ArrayInfo>}
     * @memberof UDFFavoritesData
     */
    udfs?: Array<ArrayInfo>;
    /**
     * 
     * @type {PaginationMetadata}
     * @memberof UDFFavoritesData
     */
    pagination_metadata?: PaginationMetadata;
}
/**
 * Language of a user defined function
 * @export
 * @enum {string}
 */
export enum UDFLanguage {
    Python = 'python'
}

/**
 * data to update user in organization
 * @export
 * @interface UpdateOrganizationUserParams
 */
export interface UpdateOrganizationUserParams {
    /**
     * username for user
     * @type {string}
     * @memberof UpdateOrganizationUserParams
     */
    username?: string;
    /**
     * 
     * @type {OrganizationRoles}
     * @memberof UpdateOrganizationUserParams
     */
    role?: OrganizationRoles;
}
/**
 * User
 * @export
 * @interface User
 */
export interface User {
    /**
     * user\'s uuid
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * username must be unique
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * User logo
     * @type {string}
     * @memberof User
     */
    logo?: string;
    /**
     * password
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * the user\'s full, real name
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * the user\'s email
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * when the user last logged in (set by the server)
     * @type {string}
     * @memberof User
     */
    last_activity_date?: string;
    /**
     * the user\'s timezone
     * @type {string}
     * @memberof User
     */
    timezone?: string;
    /**
     * A notice that the user has an unpaid subscription
     * @type {boolean}
     * @memberof User
     */
    unpaid_subscription: boolean;
    /**
     * Array of organizations a user is part of and their roles
     * @type {Array<UserOrganization>}
     * @memberof User
     */
    organizations: Array<UserOrganization>;
    /**
     * Denotes that the user is able to apply pricing to arrays by means of Stripe Connect
     * @type {boolean}
     * @memberof User
     */
    stripe_connect: boolean;
    /**
     * default s3 path to store newly created notebooks
     * @type {string}
     * @memberof User
     */
    default_s3_path?: string;
    /**
     * Default s3 path credentials name is the credentials name to use along with default_s3_path
     * @type {string}
     * @memberof User
     */
    default_s3_path_credentials_name?: string;
    /**
     * Override the default namespace charged for actions when no namespace is specified
     * @type {string}
     * @memberof User
     */
    default_namespace_charged?: string;
    /**
     * The default region to use for notebooks and other operations. It must be a region supported by TileDB, see https://docs.tiledb.com/cloud/concepts/tiledb-cloud-internals/architecture#orchestration 
     * @type {string}
     * @memberof User
     */
    default_region?: string;
    /**
     * User\'s company
     * @type {string}
     * @memberof User
     */
    company?: string;
    /**
     * Beta features that the user has access to
     * @type {Array<EnabledFeature>}
     * @memberof User
     */
    enabled_features?: Array<EnabledFeature>;
    /**
     * Free credits given to the user
     * @type {number}
     * @memberof User
     */
    sign_up_credits?: number;
    /**
     * 
     * @type {AssetLocations}
     * @memberof User
     */
    asset_locations?: AssetLocations;
}
/**
 * user\'s answer
 * @export
 * @interface UserAnswer
 */
export interface UserAnswer {
    /**
     * ID of the question in the questionnaire
     * @type {string}
     * @memberof UserAnswer
     */
    question_id?: string;
    /**
     * ID of the answer in the questionnaire
     * @type {string}
     * @memberof UserAnswer
     */
    answer_id?: string;
}
/**
 * user\'s new password
 * @export
 * @interface UserChangePassword
 */
export interface UserChangePassword {
    /**
     * User\'s current password
     * @type {string}
     * @memberof UserChangePassword
     */
    current_password: string;
    /**
     * User\'s new password
     * @type {string}
     * @memberof UserChangePassword
     */
    password: string;
}
/**
 * User\'s organization (in user object)
 * @export
 * @interface UserOrganization
 */
export interface UserOrganization {
    /**
     * unique id of organization
     * @type {string}
     * @memberof UserOrganization
     */
    organization_id: string;
    /**
     * 
     * @type {OrganizationRoles}
     * @memberof UserOrganization
     */
    role: OrganizationRoles;
    /**
     * organization name must be unique
     * @type {string}
     * @memberof UserOrganization
     */
    organization_name: string;
    /**
     * list of actions user is allowed to do on this organization
     * @type {Array<NamespaceActions>}
     * @memberof UserOrganization
     */
    allowed_actions: Array<NamespaceActions>;
    /**
     * unique id of user
     * @type {string}
     * @memberof UserOrganization
     */
    user_id: string;
    /**
     * username
     * @type {string}
     * @memberof UserOrganization
     */
    username: string;
}
/**
 * The entropy of a user\'s password.
 * @export
 * @interface UserPasswordEntropy
 */
export interface UserPasswordEntropy {
    /**
     * 
     * @type {number}
     * @memberof UserPasswordEntropy
     */
    entropy: number;
}
/**
 * User\'s request for free credits
 * @export
 * @interface UserRequestCredits
 */
export interface UserRequestCredits {
    /**
     * User\'s data type
     * @type {string}
     * @memberof UserRequestCredits
     */
    data_type: string;
    /**
     * User\'s use case
     * @type {string}
     * @memberof UserRequestCredits
     */
    use_case: string;
    /**
     * User\'s role
     * @type {string}
     * @memberof UserRequestCredits
     */
    role: string;
    /**
     * User\'s intention to use TileDB Cloud as
     * @type {string}
     * @memberof UserRequestCredits
     */
    intention: string;
}
/**
 * User
 * @export
 * @interface UserSignupParams
 */
export interface UserSignupParams {
    /**
     * username must be unique
     * @type {string}
     * @memberof UserSignupParams
     */
    username: string;
    /**
     * password
     * @type {string}
     * @memberof UserSignupParams
     */
    password: string;
    /**
     * the user\'s email
     * @type {string}
     * @memberof UserSignupParams
     */
    email: string;
    /**
     * Captcha token retrieved from google (optional, used in SaaS only)
     * @type {string}
     * @memberof UserSignupParams
     */
    captcha_token?: string;
    /**
     * Referrer value from document (if any)
     * @type {string}
     * @memberof UserSignupParams
     */
    referrer?: string;
}
/**
 * User properties that can be patch-updated
 * @export
 * @interface UserUpdateParams
 */
export interface UserUpdateParams {
    /**
     * username must be unique
     * @type {string}
     * @memberof UserUpdateParams
     */
    username?: string;
    /**
     * User logo
     * @type {string}
     * @memberof UserUpdateParams
     */
    logo?: string;
    /**
     * the user\'s full, real name
     * @type {string}
     * @memberof UserUpdateParams
     */
    name?: string;
    /**
     * the user\'s email
     * @type {string}
     * @memberof UserUpdateParams
     */
    email?: string;
    /**
     * the user\'s timezone
     * @type {string}
     * @memberof UserUpdateParams
     */
    timezone?: string;
    /**
     * default s3 path to store newly created notebooks
     * @type {string}
     * @memberof UserUpdateParams
     */
    default_s3_path?: string;
    /**
     * Default s3 path credentials name is the credentials name to use along with default_s3_path
     * @type {string}
     * @memberof UserUpdateParams
     */
    default_s3_path_credentials_name?: string;
    /**
     * Override the default namespace charged for actions when no namespace is specified
     * @type {string}
     * @memberof UserUpdateParams
     */
    default_namespace_charged?: string;
    /**
     * The default region to use for notebooks and other operations. It must be a region supported by TileDB, see https://docs.tiledb.com/cloud/concepts/tiledb-cloud-internals/architecture#orchestration 
     * @type {string}
     * @memberof UserUpdateParams
     */
    default_region?: string;
    /**
     * User\'s company
     * @type {string}
     * @memberof UserUpdateParams
     */
    company?: string;
    /**
     * 
     * @type {AssetLocations}
     * @memberof UserUpdateParams
     */
    asset_locations?: AssetLocations;
}
/**
 * Parameters for ingesting VCF data. See tiledb.cloud.bioimg.ingestion.ingest for details. 
 * @export
 * @interface VCFIngestionRequest
 */
export interface VCFIngestionRequest {
    /**
     * The URI to search.
     * @type {string}
     * @memberof VCFIngestionRequest
     */
    search_uri?: string;
    /**
     * The destination folder.
     * @type {string}
     * @memberof VCFIngestionRequest
     */
    dataset_uri?: string;
    /**
     * The name of the ARN credentials to use to read source data and write to destination.
     * @type {string}
     * @memberof VCFIngestionRequest
     */
    access_credentials_name?: string;
    /**
     * What pattern to search in search_uri.
     * @type {string}
     * @memberof VCFIngestionRequest
     */
    pattern?: string;
    /**
     * The name of the ingested data.
     * @type {string}
     * @memberof VCFIngestionRequest
     */
    register_name?: string;
    /**
     * The memory that will be needed in GiB.
     * @type {string}
     * @memberof VCFIngestionRequest
     */
    memory?: string;
    /**
     * The number of threads.
     * @type {number}
     * @memberof VCFIngestionRequest
     */
    threads?: number;
    /**
     * The number of cpu cores.
     * @type {number}
     * @memberof VCFIngestionRequest
     */
    cpu?: number;
    /**
     * Verbose ingest or not.
     * @type {boolean}
     * @memberof VCFIngestionRequest
     */
    verbose?: boolean;
    /**
     * List of contigs (sequences or scaffolds that make up a reference genome.).
     * @type {Array<string>}
     * @memberof VCFIngestionRequest
     */
    contigs?: Array<string>;
}



export type AcademyApi_getAcademyLessons$Params = void;

export const AcademyApi_getAcademyLessons$Path = '/academy/lessons';

/**
 * Get information about user lessons
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const AcademyApi_getAcademyLessonsParamCreator = async (data: AcademyApi_getAcademyLessons$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/academy/lessons`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface AcademyApi_getAcademyQuestionnaire$Params {
    questionnaireId: string,
}

export const AcademyApi_getAcademyQuestionnaire$Path = '/academy/questionnaires/{questionnaire_id}';

/**
 * Get academy questionnaire information for user
 * @param {string} questionnaireId Required questionnaire UUID. This is used for tracking answers on a questionnaire per user. 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const AcademyApi_getAcademyQuestionnaireParamCreator = async (data: AcademyApi_getAcademyQuestionnaire$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'questionnaireId' is not null or undefined
if (data['questionnaireId'] === null || data['questionnaireId'] === undefined) {
    throw new RequiredError('questionnaireId','Required parameter questionnaireId was null or undefined when calling getAcademyQuestionnaire.');
}
const localVarPath = `/academy/questionnaires/{questionnaire_id}`
    .replace(`{${"questionnaire_id"}}`, encodeURIComponent(String(data['questionnaireId'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface AcademyApi_putAcademyLessons$Params {
    academyLessons?: Array<AcademyLesson>,
}

export const AcademyApi_putAcademyLessons$Path = '/academy/lessons';

/**
 * Store information about user lessons
 * @param {Array<AcademyLesson>} [academyLessons] 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const AcademyApi_putAcademyLessonsParamCreator = async (data: AcademyApi_putAcademyLessons$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/academy/lessons`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['academyLessons'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['academyLessons'] !== undefined ? data['academyLessons'] : {}) : (data['academyLessons'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface AcademyApi_putAcademyQuestionnaire$Params {
    questionnaireId: string,
    academyQuestionnaire?: AcademyQuestionnairePutRequest,
}

export const AcademyApi_putAcademyQuestionnaire$Path = '/academy/questionnaires/{questionnaire_id}';

/**
 * Create or update academy questionnaire
 * @param {string} questionnaireId Required questionnaire UUID. This is used for tracking answers on a questionnaire per user. 
 * @param {AcademyQuestionnairePutRequest} [academyQuestionnaire] 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const AcademyApi_putAcademyQuestionnaireParamCreator = async (data: AcademyApi_putAcademyQuestionnaire$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'questionnaireId' is not null or undefined
if (data['questionnaireId'] === null || data['questionnaireId'] === undefined) {
    throw new RequiredError('questionnaireId','Required parameter questionnaireId was null or undefined when calling putAcademyQuestionnaire.');
}
const localVarPath = `/academy/questionnaires/{questionnaire_id}`
    .replace(`{${"questionnaire_id"}}`, encodeURIComponent(String(data['questionnaireId'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['academyQuestionnaire'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['academyQuestionnaire'] !== undefined ? data['academyQuestionnaire'] : {}) : (data['academyQuestionnaire'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type AcademyApi_getAcademyLessons$Response = Array<AcademyLesson>;

/**
  * Get information about user lessons
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function AcademyApi_getAcademyLessons(data: AcademyApi_getAcademyLessons$Params, options?: any): Promise<AxiosResponse<AcademyApi_getAcademyLessons$Response>> {
    const localVarAxiosArgs = await AcademyApi_getAcademyLessonsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type AcademyApi_getAcademyQuestionnaire$Response = AcademyQuestionnaireListResponse;

/**
  * Get academy questionnaire information for user
  * @param {string} questionnaireId Required questionnaire UUID. This is used for tracking answers on a questionnaire per user. 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function AcademyApi_getAcademyQuestionnaire(data: AcademyApi_getAcademyQuestionnaire$Params, options?: any): Promise<AxiosResponse<AcademyApi_getAcademyQuestionnaire$Response>> {
    const localVarAxiosArgs = await AcademyApi_getAcademyQuestionnaireParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type AcademyApi_putAcademyLessons$Response = void;

/**
  * Store information about user lessons
  * @param {Array<AcademyLesson>} [academyLessons] 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function AcademyApi_putAcademyLessons(data: AcademyApi_putAcademyLessons$Params, options?: any): Promise<AxiosResponse<AcademyApi_putAcademyLessons$Response>> {
    const localVarAxiosArgs = await AcademyApi_putAcademyLessonsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type AcademyApi_putAcademyQuestionnaire$Response = void;

/**
  * Create or update academy questionnaire
  * @param {string} questionnaireId Required questionnaire UUID. This is used for tracking answers on a questionnaire per user. 
  * @param {AcademyQuestionnairePutRequest} [academyQuestionnaire] 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function AcademyApi_putAcademyQuestionnaire(data: AcademyApi_putAcademyQuestionnaire$Params, options?: any): Promise<AxiosResponse<AcademyApi_putAcademyQuestionnaire$Response>> {
    const localVarAxiosArgs = await AcademyApi_putAcademyQuestionnaireParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface ArrayApi_arrayActivityLog$Params {
    namespace: string,
    array: string,
    start?: number,
    end?: number,
    eventTypes?: Array<string>,
    taskId?: string,
    hasTaskId?: boolean,
    page?: number,
    perPage?: number,
}

export const ArrayApi_arrayActivityLog$Path = '/arrays/{namespace}/{array}/activity';

/**
 * get array activity logs
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {number} [start] Start time of window of fetch logs, unix epoch in seconds (default: seven days ago)
 * @param {number} [end] End time of window of fetch logs, unix epoch in seconds (default: current utc timestamp)
 * @param {Array<string>} [eventTypes] Event values can be one or more of the following read, write, create, delete, register, deregister, comma separated
 * @param {string} [taskId] Array task id To filter activity to
 * @param {boolean} [hasTaskId] Excludes activity log results that does not contain an array task uuid
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_arrayActivityLogParamCreator = async (data: ArrayApi_arrayActivityLog$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling arrayActivityLog.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling arrayActivityLog.');
}
const localVarPath = `/arrays/{namespace}/{array}/activity`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['start'] !== undefined) {
    localVarQueryParameter['start'] = data['start'];
}
if (data['end'] !== undefined) {
    localVarQueryParameter['end'] = data['end'];
}
if (data['eventTypes']) {
    localVarQueryParameter['event_types'] = data['eventTypes'];
}
if (data['taskId'] !== undefined) {
    localVarQueryParameter['task_id'] = data['taskId'];
}
if (data['hasTaskId'] !== undefined) {
    localVarQueryParameter['has_task_id'] = data['hasTaskId'];
}
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_arraysBrowserOwnedGet$Params {
    page?: number,
    perPage?: number,
    search?: string,
    namespace?: string,
    orderby?: string,
    permissions?: string,
    tag?: Array<string>,
    excludeTag?: Array<string>,
    fileType?: Array<string>,
    excludeFileType?: Array<string>,
    fileProperty?: Array<string>,
}

export const ArrayApi_arraysBrowserOwnedGet$Path = '/arrays/browser/owned';

/**
 * Fetch a list of all arrays that are owned directly by user or user\'s organizations
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {string} [search] search string that will look at name, namespace or description fields
 * @param {string} [namespace] namespace
 * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
 * @param {string} [permissions] permissions valid values include read, read_write, write, admin
 * @param {Array<string>} [tag] tag to search for, more than one can be included
 * @param {Array<string>} [excludeTag] tags to exclude matching array in results, more than one can be included
 * @param {Array<string>} [fileType] file_type to search for, more than one can be included
 * @param {Array<string>} [excludeFileType] file_type to exclude matching array in results, more than one can be included
 * @param {Array<string>} [fileProperty] file_property key-value pair (comma separated, i.e. key,value) to search for, more than one can be included
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_arraysBrowserOwnedGetParamCreator = async (data: ArrayApi_arraysBrowserOwnedGet$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/arrays/browser/owned`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['search'] !== undefined) {
    localVarQueryParameter['search'] = data['search'];
}
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}
if (data['orderby'] !== undefined) {
    localVarQueryParameter['orderby'] = data['orderby'];
}
if (data['permissions'] !== undefined) {
    localVarQueryParameter['permissions'] = data['permissions'];
}
if (data['tag']) {
    localVarQueryParameter['tag'] = data['tag'];
}
if (data['excludeTag']) {
    localVarQueryParameter['exclude_tag'] = data['excludeTag'];
}
if (data['fileType']) {
    localVarQueryParameter['file_type'] = data['fileType'];
}
if (data['excludeFileType']) {
    localVarQueryParameter['exclude_file_type'] = data['excludeFileType'];
}
if (data['fileProperty']) {
    localVarQueryParameter['file_property'] = data['fileProperty'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_arraysBrowserOwnedSidebarGet$Params {
    fileType?: Array<string>,
    excludeFileType?: Array<string>,
    fileProperty?: Array<string>,
    sharedTo?: Array<string>,
}

export const ArrayApi_arraysBrowserOwnedSidebarGet$Path = '/arrays/browser/owned/sidebar';

/**
 * Fetch a sidebar for arrays that are owned directly by user or user\'s organizations
 * @param {Array<string>} [fileType] file_type to search for, more than one can be included
 * @param {Array<string>} [excludeFileType] file_type to exclude matching array in results, more than one can be included
 * @param {Array<string>} [fileProperty] file_property key-value pair (comma separated, i.e. key,value) to search for, more than one can be included
 * @param {Array<string>} [sharedTo] namespaces to filter results of where there groups were shared to
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_arraysBrowserOwnedSidebarGetParamCreator = async (data: ArrayApi_arraysBrowserOwnedSidebarGet$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/arrays/browser/owned/sidebar`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['fileType']) {
    localVarQueryParameter['file_type'] = data['fileType'];
}
if (data['excludeFileType']) {
    localVarQueryParameter['exclude_file_type'] = data['excludeFileType'];
}
if (data['fileProperty']) {
    localVarQueryParameter['file_property'] = data['fileProperty'];
}
if (data['sharedTo']) {
    localVarQueryParameter['shared_to'] = data['sharedTo'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_arraysBrowserPublicGet$Params {
    page?: number,
    perPage?: number,
    search?: string,
    namespace?: string,
    orderby?: string,
    permissions?: string,
    tag?: Array<string>,
    excludeTag?: Array<string>,
    fileType?: Array<string>,
    excludeFileType?: Array<string>,
    fileProperty?: Array<string>,
}

export const ArrayApi_arraysBrowserPublicGet$Path = '/arrays/browser/public';

/**
 * Fetch a list of all arrays that have been shared publically
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {string} [search] search string that will look at name, namespace or description fields
 * @param {string} [namespace] namespace
 * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
 * @param {string} [permissions] permissions valid values include read, read_write, write, admin
 * @param {Array<string>} [tag] tag to search for, more than one can be included
 * @param {Array<string>} [excludeTag] tags to exclude matching array in results, more than one can be included
 * @param {Array<string>} [fileType] file_type to search for, more than one can be included
 * @param {Array<string>} [excludeFileType] file_type to exclude matching array in results, more than one can be included
 * @param {Array<string>} [fileProperty] file_property key-value pair (comma separated, i.e. key,value) to search for, more than one can be included
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_arraysBrowserPublicGetParamCreator = async (data: ArrayApi_arraysBrowserPublicGet$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/arrays/browser/public`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['search'] !== undefined) {
    localVarQueryParameter['search'] = data['search'];
}
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}
if (data['orderby'] !== undefined) {
    localVarQueryParameter['orderby'] = data['orderby'];
}
if (data['permissions'] !== undefined) {
    localVarQueryParameter['permissions'] = data['permissions'];
}
if (data['tag']) {
    localVarQueryParameter['tag'] = data['tag'];
}
if (data['excludeTag']) {
    localVarQueryParameter['exclude_tag'] = data['excludeTag'];
}
if (data['fileType']) {
    localVarQueryParameter['file_type'] = data['fileType'];
}
if (data['excludeFileType']) {
    localVarQueryParameter['exclude_file_type'] = data['excludeFileType'];
}
if (data['fileProperty']) {
    localVarQueryParameter['file_property'] = data['fileProperty'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_arraysBrowserPublicSidebarGet$Params {
    fileType?: Array<string>,
    excludeFileType?: Array<string>,
    fileProperty?: Array<string>,
    sharedTo?: Array<string>,
}

export const ArrayApi_arraysBrowserPublicSidebarGet$Path = '/arrays/browser/public/sidebar';

/**
 * Fetch a sidebar of all arrays that have been shared publically
 * @param {Array<string>} [fileType] file_type to search for, more than one can be included
 * @param {Array<string>} [excludeFileType] file_type to exclude matching array in results, more than one can be included
 * @param {Array<string>} [fileProperty] file_property key-value pair (comma separated, i.e. key,value) to search for, more than one can be included
 * @param {Array<string>} [sharedTo] namespaces to filter results of where there groups were shared to
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_arraysBrowserPublicSidebarGetParamCreator = async (data: ArrayApi_arraysBrowserPublicSidebarGet$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/arrays/browser/public/sidebar`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['fileType']) {
    localVarQueryParameter['file_type'] = data['fileType'];
}
if (data['excludeFileType']) {
    localVarQueryParameter['exclude_file_type'] = data['excludeFileType'];
}
if (data['fileProperty']) {
    localVarQueryParameter['file_property'] = data['fileProperty'];
}
if (data['sharedTo']) {
    localVarQueryParameter['shared_to'] = data['sharedTo'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_arraysBrowserSharedGet$Params {
    page?: number,
    perPage?: number,
    search?: string,
    namespace?: string,
    orderby?: string,
    permissions?: string,
    tag?: Array<string>,
    excludeTag?: Array<string>,
    fileType?: Array<string>,
    excludeFileType?: Array<string>,
    fileProperty?: Array<string>,
    sharedTo?: Array<string>,
}

export const ArrayApi_arraysBrowserSharedGet$Path = '/arrays/browser/shared';

/**
 * Fetch a list of all arrays that have been shared with the user
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {string} [search] search string that will look at name, namespace or description fields
 * @param {string} [namespace] namespace
 * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
 * @param {string} [permissions] permissions valid values include read, read_write, write, admin
 * @param {Array<string>} [tag] tag to search for, more than one can be included
 * @param {Array<string>} [excludeTag] tags to exclude matching array in results, more than one can be included
 * @param {Array<string>} [fileType] file_type to search for, more than one can be included
 * @param {Array<string>} [excludeFileType] file_type to exclude matching array in results, more than one can be included
 * @param {Array<string>} [fileProperty] file_property key-value pair (comma separated, i.e. key,value) to search for, more than one can be included
 * @param {Array<string>} [sharedTo] namespaces to filter results of where there arrays were shared to
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_arraysBrowserSharedGetParamCreator = async (data: ArrayApi_arraysBrowserSharedGet$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/arrays/browser/shared`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['search'] !== undefined) {
    localVarQueryParameter['search'] = data['search'];
}
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}
if (data['orderby'] !== undefined) {
    localVarQueryParameter['orderby'] = data['orderby'];
}
if (data['permissions'] !== undefined) {
    localVarQueryParameter['permissions'] = data['permissions'];
}
if (data['tag']) {
    localVarQueryParameter['tag'] = data['tag'];
}
if (data['excludeTag']) {
    localVarQueryParameter['exclude_tag'] = data['excludeTag'];
}
if (data['fileType']) {
    localVarQueryParameter['file_type'] = data['fileType'];
}
if (data['excludeFileType']) {
    localVarQueryParameter['exclude_file_type'] = data['excludeFileType'];
}
if (data['fileProperty']) {
    localVarQueryParameter['file_property'] = data['fileProperty'];
}
if (data['sharedTo']) {
    localVarQueryParameter['shared_to'] = data['sharedTo'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_arraysBrowserSharedSidebarGet$Params {
    fileType?: Array<string>,
    excludeFileType?: Array<string>,
    fileProperty?: Array<string>,
    sharedTo?: Array<string>,
}

export const ArrayApi_arraysBrowserSharedSidebarGet$Path = '/arrays/browser/shared/sidebar';

/**
 * Fetch a list of all arrays that have been shared with the user
 * @param {Array<string>} [fileType] file_type to search for, more than one can be included
 * @param {Array<string>} [excludeFileType] file_type to exclude matching array in results, more than one can be included
 * @param {Array<string>} [fileProperty] file_property key-value pair (comma separated, i.e. key,value) to search for, more than one can be included
 * @param {Array<string>} [sharedTo] namespaces to filter results of where there groups were shared to
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_arraysBrowserSharedSidebarGetParamCreator = async (data: ArrayApi_arraysBrowserSharedSidebarGet$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/arrays/browser/shared/sidebar`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['fileType']) {
    localVarQueryParameter['file_type'] = data['fileType'];
}
if (data['excludeFileType']) {
    localVarQueryParameter['exclude_file_type'] = data['excludeFileType'];
}
if (data['fileProperty']) {
    localVarQueryParameter['file_property'] = data['fileProperty'];
}
if (data['sharedTo']) {
    localVarQueryParameter['shared_to'] = data['sharedTo'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_createPricing$Params {
    namespace: string,
    array: string,
    pricingInfo?: PricingInfo,
}

export const ArrayApi_createPricing$Path = '/payments/pricing/{namespace}/{array}';

/**
 * Create pricing info for a specified URI in a namespace
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {PricingInfo} [pricingInfo] Array pricing details
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_createPricingParamCreator = async (data: ArrayApi_createPricing$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling createPricing.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling createPricing.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['pricingInfo'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['pricingInfo'] !== undefined ? data['pricingInfo'] : {}) : (data['pricingInfo'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_deleteArray$Params {
    namespace: string,
    array: string,
}

export const ArrayApi_deleteArray$Path = '/arrays/{namespace}/{array}/delete';

/**
 * deletes a array
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_deleteArrayParamCreator = async (data: ArrayApi_deleteArray$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling deleteArray.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling deleteArray.');
}
const localVarPath = `/arrays/{namespace}/{array}/delete`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_deletePricing$Params {
    namespace: string,
    array: string,
}

export const ArrayApi_deletePricing$Path = '/payments/pricing/{namespace}/{array}';

/**
 * Delete pricing info for a specified URI in a namespace
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_deletePricingParamCreator = async (data: ArrayApi_deletePricing$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling deletePricing.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling deletePricing.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_deregisterArray$Params {
    namespace: string,
    array: string,
}

export const ArrayApi_deregisterArray$Path = '/arrays/{namespace}/{array}/deregister';

/**
 * deregister a array
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_deregisterArrayParamCreator = async (data: ArrayApi_deregisterArray$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling deregisterArray.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling deregisterArray.');
}
const localVarPath = `/arrays/{namespace}/{array}/deregister`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_getActivityLogById$Params {
    namespace: string,
    array: string,
    id: string,
}

export const ArrayApi_getActivityLogById$Path = '/arrays/{namespace}/{array}/activity/{id}';

/**
 * get activity log by id
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {string} id id of the activity
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_getActivityLogByIdParamCreator = async (data: ArrayApi_getActivityLogById$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getActivityLogById.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling getActivityLogById.');
}
// verify required parameter 'id' is not null or undefined
if (data['id'] === null || data['id'] === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling getActivityLogById.');
}
const localVarPath = `/arrays/{namespace}/{array}/activity/{id}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])))
    .replace(`{${"id"}}`, encodeURIComponent(String(data['id'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_getAllArrayInfo$Params {
    namespace?: string,
    orderby?: string,
    permissions?: string,
    search?: string,
    publicShare?: string,
}

export const ArrayApi_getAllArrayInfo$Path = '/arrays';

/**
 * get all array metadata user has access to
 * @param {string} [namespace] namespace
 * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
 * @param {string} [permissions] permissions valid values include read, read_write, write, admin
 * @param {string} [search] search string that will look at name, namespace or description fields
 * @param {string} [publicShare] Public share values can be one of exclude, only
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_getAllArrayInfoParamCreator = async (data: ArrayApi_getAllArrayInfo$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/arrays`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}
if (data['orderby'] !== undefined) {
    localVarQueryParameter['orderby'] = data['orderby'];
}
if (data['permissions'] !== undefined) {
    localVarQueryParameter['permissions'] = data['permissions'];
}
if (data['search'] !== undefined) {
    localVarQueryParameter['search'] = data['search'];
}
if (data['publicShare'] !== undefined) {
    localVarQueryParameter['public_share'] = data['publicShare'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_getArrayEndTimestamps$Params {
    namespace: string,
    array: string,
    page?: number,
    perPage?: number,
}

export const ArrayApi_getArrayEndTimestamps$Path = '/arrays/{namespace}/{array}/end_timestamps';

/**
 * retrieve a list of timestamps from the array fragment info listing in milliseconds, paginated
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_getArrayEndTimestampsParamCreator = async (data: ArrayApi_getArrayEndTimestamps$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getArrayEndTimestamps.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling getArrayEndTimestamps.');
}
const localVarPath = `/arrays/{namespace}/{array}/end_timestamps`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_getArrayInfo$Params {
    namespace: string,
    array: string,
}

export const ArrayApi_getArrayInfo$Path = '/arrays/{namespace}/{array}/info';

/**
 * get metadata on an array
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_getArrayInfoParamCreator = async (data: ArrayApi_getArrayInfo$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getArrayInfo.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling getArrayInfo.');
}
const localVarPath = `/arrays/{namespace}/{array}/info`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_getArrayMetaData$Params {
    namespace: string,
    array: string,
    length?: number,
    version?: number,
}

export const ArrayApi_getArrayMetaData$Path = '/arrays/{namespace}/{array}/metadata';

/**
 * get metadata from the array in json format
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {number} [length] (optional) limit character length of returned values
 * @param {number} [version] Milliseconds since Unix epoch, metadata will use open_at functionality to open array at the specific timestamp
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_getArrayMetaDataParamCreator = async (data: ArrayApi_getArrayMetaData$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getArrayMetaData.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling getArrayMetaData.');
}
const localVarPath = `/arrays/{namespace}/{array}/metadata`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['length'] !== undefined) {
    localVarQueryParameter['length'] = data['length'];
}
if (data['version'] !== undefined) {
    localVarQueryParameter['version'] = data['version'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_getArraySampleData$Params {
    namespace: string,
    array: string,
    samples?: number,
}

export const ArrayApi_getArraySampleData$Path = '/arrays/{namespace}/{array}/sample';

/**
 * get an sample set of data from the array
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {number} [samples] Number of sample results to return
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_getArraySampleDataParamCreator = async (data: ArrayApi_getArraySampleData$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getArraySampleData.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling getArraySampleData.');
}
const localVarPath = `/arrays/{namespace}/{array}/sample`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['samples'] !== undefined) {
    localVarQueryParameter['samples'] = data['samples'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_getArraySchema$Params {
    namespace: string,
    array: string,
}

export const ArrayApi_getArraySchema$Path = '/arrays/{namespace}/{array}';

/**
 * get an ArraySchema using a url encoded uri
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_getArraySchemaParamCreator = async (data: ArrayApi_getArraySchema$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getArraySchema.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling getArraySchema.');
}
const localVarPath = `/arrays/{namespace}/{array}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_getArraySharingPolicies$Params {
    namespace: string,
    array: string,
}

export const ArrayApi_getArraySharingPolicies$Path = '/arrays/{namespace}/{array}/share';

/**
 * Get all sharing details of the array
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_getArraySharingPoliciesParamCreator = async (data: ArrayApi_getArraySharingPolicies$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getArraySharingPolicies.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling getArraySharingPolicies.');
}
const localVarPath = `/arrays/{namespace}/{array}/share`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_getFragmentEndTimestamp$Params {
    namespace: string,
    array: string,
    endTimestamp?: number,
}

export const ArrayApi_getFragmentEndTimestamp$Path = '/arrays/{namespace}/{array}/fragment_end_timestamp';

/**
 * get fragment timestamp (start) on an array
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {number} [endTimestamp] Milliseconds since Unix epoch
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_getFragmentEndTimestampParamCreator = async (data: ArrayApi_getFragmentEndTimestamp$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getFragmentEndTimestamp.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling getFragmentEndTimestamp.');
}
const localVarPath = `/arrays/{namespace}/{array}/fragment_end_timestamp`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['endTimestamp'] !== undefined) {
    localVarQueryParameter['end_timestamp'] = data['endTimestamp'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_registerArray$Params {
    arrayMetadata: ArrayRegisterParams,
}

export const ArrayApi_registerArray$Path = '/arrays/register';

/**
 * register an array at a specified URI registered to the given namespace
 * @param {ArrayRegisterParams} arrayMetadata metadata associated with array
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_registerArrayParamCreator = async (data: ArrayApi_registerArray$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'arrayMetadata' is not null or undefined
if (data['arrayMetadata'] === null || data['arrayMetadata'] === undefined) {
    throw new RequiredError('arrayMetadata','Required parameter arrayMetadata was null or undefined when calling registerArray.');
}
const localVarPath = `/arrays/register`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['arrayMetadata'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['arrayMetadata'] !== undefined ? data['arrayMetadata'] : {}) : (data['arrayMetadata'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_shareArray$Params {
    namespace: string,
    array: string,
    arraySharing: ArraySharingRequestParams,
}

export const ArrayApi_shareArray$Path = '/arrays/{namespace}/{array}/share';

/**
 * Share an array with a user
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {ArraySharingRequestParams} arraySharing Namespace and list of permissions to share with. An empty list of permissions will remove the namespace, if permissions already exist they will be deleted then new ones added. In the event of a failure, the new polcies will be rolled back to prevent partial policies, and its likely the array will not be shared with the namespace at all
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_shareArrayParamCreator = async (data: ArrayApi_shareArray$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling shareArray.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling shareArray.');
}
// verify required parameter 'arraySharing' is not null or undefined
if (data['arraySharing'] === null || data['arraySharing'] === undefined) {
    throw new RequiredError('arraySharing','Required parameter arraySharing was null or undefined when calling shareArray.');
}
const localVarPath = `/arrays/{namespace}/{array}/share`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['arraySharing'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['arraySharing'] !== undefined ? data['arraySharing'] : {}) : (data['arraySharing'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_submitQueryJson$Params {
    namespace: string,
    array: string,
    queryJson: QueryJson,
}

export const ArrayApi_submitQueryJson$Path = '/arrays/{namespace}/{array}/query/submit_query_json';

/**
 * send a query to run against a specified array/URI registered to a group/project, returns json results
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {QueryJson} queryJson query to run
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_submitQueryJsonParamCreator = async (data: ArrayApi_submitQueryJson$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling submitQueryJson.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling submitQueryJson.');
}
// verify required parameter 'queryJson' is not null or undefined
if (data['queryJson'] === null || data['queryJson'] === undefined) {
    throw new RequiredError('queryJson','Required parameter queryJson was null or undefined when calling submitQueryJson.');
}
const localVarPath = `/arrays/{namespace}/{array}/query/submit_query_json`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['queryJson'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['queryJson'] !== undefined ? data['queryJson'] : {}) : (data['queryJson'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_subscribeToArray$Params {
    namespace: string,
    array: string,
}

export const ArrayApi_subscribeToArray$Path = '/payments/pricing/{namespace}/{array}/subscribe';

/**
 * Subscribe to an array set up for monetization
 * @param {string} namespace Namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_subscribeToArrayParamCreator = async (data: ArrayApi_subscribeToArray$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling subscribeToArray.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling subscribeToArray.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}/subscribe`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_unsubscribeFromArray$Params {
    namespace: string,
    array: string,
}

export const ArrayApi_unsubscribeFromArray$Path = '/payments/pricing/{namespace}/{array}/subscribe';

/**
 * Unsubscribe to an array set up for monetization
 * @param {string} namespace Namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_unsubscribeFromArrayParamCreator = async (data: ArrayApi_unsubscribeFromArray$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling unsubscribeFromArray.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling unsubscribeFromArray.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}/subscribe`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_updateArrayInfo$Params {
    namespace: string,
    array: string,
    arrayInfo: ArrayInfo,
}

export const ArrayApi_updateArrayInfo$Path = '/arrays/{namespace}/{array}/info';

/**
 * update info of an array
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {ArrayInfo} arrayInfo array data to update
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_updateArrayInfoParamCreator = async (data: ArrayApi_updateArrayInfo$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling updateArrayInfo.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling updateArrayInfo.');
}
// verify required parameter 'arrayInfo' is not null or undefined
if (data['arrayInfo'] === null || data['arrayInfo'] === undefined) {
    throw new RequiredError('arrayInfo','Required parameter arrayInfo was null or undefined when calling updateArrayInfo.');
}
const localVarPath = `/arrays/{namespace}/{array}/info`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['arrayInfo'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['arrayInfo'] !== undefined ? data['arrayInfo'] : {}) : (data['arrayInfo'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayApi_updatePricing$Params {
    namespace: string,
    array: string,
    pricingInfo: PricingInfo,
}

export const ArrayApi_updatePricing$Path = '/payments/pricing/{namespace}/{array}';

/**
 * Update pricing info for a specified URI in a namespace
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {PricingInfo} pricingInfo Pricing metadata to update
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayApi_updatePricingParamCreator = async (data: ArrayApi_updatePricing$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling updatePricing.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling updatePricing.');
}
// verify required parameter 'pricingInfo' is not null or undefined
if (data['pricingInfo'] === null || data['pricingInfo'] === undefined) {
    throw new RequiredError('pricingInfo','Required parameter pricingInfo was null or undefined when calling updatePricing.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['pricingInfo'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['pricingInfo'] !== undefined ? data['pricingInfo'] : {}) : (data['pricingInfo'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type ArrayApi_arrayActivityLog$Response = ArrayActivityLogData;

/**
  * get array activity logs
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {number} [start] Start time of window of fetch logs, unix epoch in seconds (default: seven days ago)
  * @param {number} [end] End time of window of fetch logs, unix epoch in seconds (default: current utc timestamp)
  * @param {Array<string>} [eventTypes] Event values can be one or more of the following read, write, create, delete, register, deregister, comma separated
  * @param {string} [taskId] Array task id To filter activity to
  * @param {boolean} [hasTaskId] Excludes activity log results that does not contain an array task uuid
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_arrayActivityLog(data: ArrayApi_arrayActivityLog$Params, options?: any): Promise<AxiosResponse<ArrayApi_arrayActivityLog$Response>> {
    const localVarAxiosArgs = await ArrayApi_arrayActivityLogParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_arraysBrowserOwnedGet$Response = ArrayBrowserData;

/**
  * Fetch a list of all arrays that are owned directly by user or user\'s organizations
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {string} [search] search string that will look at name, namespace or description fields
  * @param {string} [namespace] namespace
  * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
  * @param {string} [permissions] permissions valid values include read, read_write, write, admin
  * @param {Array<string>} [tag] tag to search for, more than one can be included
  * @param {Array<string>} [excludeTag] tags to exclude matching array in results, more than one can be included
  * @param {Array<string>} [fileType] file_type to search for, more than one can be included
  * @param {Array<string>} [excludeFileType] file_type to exclude matching array in results, more than one can be included
  * @param {Array<string>} [fileProperty] file_property key-value pair (comma separated, i.e. key,value) to search for, more than one can be included
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_arraysBrowserOwnedGet(data: ArrayApi_arraysBrowserOwnedGet$Params, options?: any): Promise<AxiosResponse<ArrayApi_arraysBrowserOwnedGet$Response>> {
    const localVarAxiosArgs = await ArrayApi_arraysBrowserOwnedGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_arraysBrowserOwnedSidebarGet$Response = ArrayBrowserSidebar;

/**
  * Fetch a sidebar for arrays that are owned directly by user or user\'s organizations
  * @param {Array<string>} [fileType] file_type to search for, more than one can be included
  * @param {Array<string>} [excludeFileType] file_type to exclude matching array in results, more than one can be included
  * @param {Array<string>} [fileProperty] file_property key-value pair (comma separated, i.e. key,value) to search for, more than one can be included
  * @param {Array<string>} [sharedTo] namespaces to filter results of where there groups were shared to
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_arraysBrowserOwnedSidebarGet(data: ArrayApi_arraysBrowserOwnedSidebarGet$Params, options?: any): Promise<AxiosResponse<ArrayApi_arraysBrowserOwnedSidebarGet$Response>> {
    const localVarAxiosArgs = await ArrayApi_arraysBrowserOwnedSidebarGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_arraysBrowserPublicGet$Response = ArrayBrowserData;

/**
  * Fetch a list of all arrays that have been shared publically
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {string} [search] search string that will look at name, namespace or description fields
  * @param {string} [namespace] namespace
  * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
  * @param {string} [permissions] permissions valid values include read, read_write, write, admin
  * @param {Array<string>} [tag] tag to search for, more than one can be included
  * @param {Array<string>} [excludeTag] tags to exclude matching array in results, more than one can be included
  * @param {Array<string>} [fileType] file_type to search for, more than one can be included
  * @param {Array<string>} [excludeFileType] file_type to exclude matching array in results, more than one can be included
  * @param {Array<string>} [fileProperty] file_property key-value pair (comma separated, i.e. key,value) to search for, more than one can be included
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_arraysBrowserPublicGet(data: ArrayApi_arraysBrowserPublicGet$Params, options?: any): Promise<AxiosResponse<ArrayApi_arraysBrowserPublicGet$Response>> {
    const localVarAxiosArgs = await ArrayApi_arraysBrowserPublicGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_arraysBrowserPublicSidebarGet$Response = ArrayBrowserSidebar;

/**
  * Fetch a sidebar of all arrays that have been shared publically
  * @param {Array<string>} [fileType] file_type to search for, more than one can be included
  * @param {Array<string>} [excludeFileType] file_type to exclude matching array in results, more than one can be included
  * @param {Array<string>} [fileProperty] file_property key-value pair (comma separated, i.e. key,value) to search for, more than one can be included
  * @param {Array<string>} [sharedTo] namespaces to filter results of where there groups were shared to
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_arraysBrowserPublicSidebarGet(data: ArrayApi_arraysBrowserPublicSidebarGet$Params, options?: any): Promise<AxiosResponse<ArrayApi_arraysBrowserPublicSidebarGet$Response>> {
    const localVarAxiosArgs = await ArrayApi_arraysBrowserPublicSidebarGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_arraysBrowserSharedGet$Response = ArrayBrowserData;

/**
  * Fetch a list of all arrays that have been shared with the user
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {string} [search] search string that will look at name, namespace or description fields
  * @param {string} [namespace] namespace
  * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
  * @param {string} [permissions] permissions valid values include read, read_write, write, admin
  * @param {Array<string>} [tag] tag to search for, more than one can be included
  * @param {Array<string>} [excludeTag] tags to exclude matching array in results, more than one can be included
  * @param {Array<string>} [fileType] file_type to search for, more than one can be included
  * @param {Array<string>} [excludeFileType] file_type to exclude matching array in results, more than one can be included
  * @param {Array<string>} [fileProperty] file_property key-value pair (comma separated, i.e. key,value) to search for, more than one can be included
  * @param {Array<string>} [sharedTo] namespaces to filter results of where there arrays were shared to
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_arraysBrowserSharedGet(data: ArrayApi_arraysBrowserSharedGet$Params, options?: any): Promise<AxiosResponse<ArrayApi_arraysBrowserSharedGet$Response>> {
    const localVarAxiosArgs = await ArrayApi_arraysBrowserSharedGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_arraysBrowserSharedSidebarGet$Response = ArrayBrowserSidebar;

/**
  * Fetch a list of all arrays that have been shared with the user
  * @param {Array<string>} [fileType] file_type to search for, more than one can be included
  * @param {Array<string>} [excludeFileType] file_type to exclude matching array in results, more than one can be included
  * @param {Array<string>} [fileProperty] file_property key-value pair (comma separated, i.e. key,value) to search for, more than one can be included
  * @param {Array<string>} [sharedTo] namespaces to filter results of where there groups were shared to
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_arraysBrowserSharedSidebarGet(data: ArrayApi_arraysBrowserSharedSidebarGet$Params, options?: any): Promise<AxiosResponse<ArrayApi_arraysBrowserSharedSidebarGet$Response>> {
    const localVarAxiosArgs = await ArrayApi_arraysBrowserSharedSidebarGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_createPricing$Response = void;

/**
  * Create pricing info for a specified URI in a namespace
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {PricingInfo} [pricingInfo] Array pricing details
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_createPricing(data: ArrayApi_createPricing$Params, options?: any): Promise<AxiosResponse<ArrayApi_createPricing$Response>> {
    const localVarAxiosArgs = await ArrayApi_createPricingParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_deleteArray$Response = void;

/**
  * deletes a array
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_deleteArray(data: ArrayApi_deleteArray$Params, options?: any): Promise<AxiosResponse<ArrayApi_deleteArray$Response>> {
    const localVarAxiosArgs = await ArrayApi_deleteArrayParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_deletePricing$Response = void;

/**
  * Delete pricing info for a specified URI in a namespace
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_deletePricing(data: ArrayApi_deletePricing$Params, options?: any): Promise<AxiosResponse<ArrayApi_deletePricing$Response>> {
    const localVarAxiosArgs = await ArrayApi_deletePricingParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_deregisterArray$Response = void;

/**
  * deregister a array
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_deregisterArray(data: ArrayApi_deregisterArray$Params, options?: any): Promise<AxiosResponse<ArrayApi_deregisterArray$Response>> {
    const localVarAxiosArgs = await ArrayApi_deregisterArrayParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_getActivityLogById$Response = ArrayActivityLog;

/**
  * get activity log by id
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {string} id id of the activity
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_getActivityLogById(data: ArrayApi_getActivityLogById$Params, options?: any): Promise<AxiosResponse<ArrayApi_getActivityLogById$Response>> {
    const localVarAxiosArgs = await ArrayApi_getActivityLogByIdParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_getAllArrayInfo$Response = ArrayBrowserData;

/**
  * get all array metadata user has access to
  * @param {string} [namespace] namespace
  * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
  * @param {string} [permissions] permissions valid values include read, read_write, write, admin
  * @param {string} [search] search string that will look at name, namespace or description fields
  * @param {string} [publicShare] Public share values can be one of exclude, only
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_getAllArrayInfo(data: ArrayApi_getAllArrayInfo$Params, options?: any): Promise<AxiosResponse<ArrayApi_getAllArrayInfo$Response>> {
    const localVarAxiosArgs = await ArrayApi_getAllArrayInfoParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_getArrayEndTimestamps$Response = ArrayEndTimetsampsData;

/**
  * retrieve a list of timestamps from the array fragment info listing in milliseconds, paginated
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_getArrayEndTimestamps(data: ArrayApi_getArrayEndTimestamps$Params, options?: any): Promise<AxiosResponse<ArrayApi_getArrayEndTimestamps$Response>> {
    const localVarAxiosArgs = await ArrayApi_getArrayEndTimestampsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_getArrayInfo$Response = ArrayInfo;

/**
  * get metadata on an array
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_getArrayInfo(data: ArrayApi_getArrayInfo$Params, options?: any): Promise<AxiosResponse<ArrayApi_getArrayInfo$Response>> {
    const localVarAxiosArgs = await ArrayApi_getArrayInfoParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_getArrayMetaData$Response = Array<ArrayMetadata>;

/**
  * get metadata from the array in json format
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {number} [length] (optional) limit character length of returned values
  * @param {number} [version] Milliseconds since Unix epoch, metadata will use open_at functionality to open array at the specific timestamp
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_getArrayMetaData(data: ArrayApi_getArrayMetaData$Params, options?: any): Promise<AxiosResponse<ArrayApi_getArrayMetaData$Response>> {
    const localVarAxiosArgs = await ArrayApi_getArrayMetaDataParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_getArraySampleData$Response = ArraySample;

/**
  * get an sample set of data from the array
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {number} [samples] Number of sample results to return
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_getArraySampleData(data: ArrayApi_getArraySampleData$Params, options?: any): Promise<AxiosResponse<ArrayApi_getArraySampleData$Response>> {
    const localVarAxiosArgs = await ArrayApi_getArraySampleDataParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_getArraySchema$Response = ArraySchema;

/**
  * get an ArraySchema using a url encoded uri
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_getArraySchema(data: ArrayApi_getArraySchema$Params, options?: any): Promise<AxiosResponse<ArrayApi_getArraySchema$Response>> {
    const localVarAxiosArgs = await ArrayApi_getArraySchemaParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_getArraySharingPolicies$Response = Array<ArraySharing>;

/**
  * Get all sharing details of the array
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_getArraySharingPolicies(data: ArrayApi_getArraySharingPolicies$Params, options?: any): Promise<AxiosResponse<ArrayApi_getArraySharingPolicies$Response>> {
    const localVarAxiosArgs = await ArrayApi_getArraySharingPoliciesParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_getFragmentEndTimestamp$Response = number;

/**
  * get fragment timestamp (start) on an array
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {number} [endTimestamp] Milliseconds since Unix epoch
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_getFragmentEndTimestamp(data: ArrayApi_getFragmentEndTimestamp$Params, options?: any): Promise<AxiosResponse<ArrayApi_getFragmentEndTimestamp$Response>> {
    const localVarAxiosArgs = await ArrayApi_getFragmentEndTimestampParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_registerArray$Response = ArrayInfo;

/**
  * register an array at a specified URI registered to the given namespace
  * @param {ArrayRegisterParams} arrayMetadata metadata associated with array
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_registerArray(data: ArrayApi_registerArray$Params, options?: any): Promise<AxiosResponse<ArrayApi_registerArray$Response>> {
    const localVarAxiosArgs = await ArrayApi_registerArrayParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_shareArray$Response = void;

/**
  * Share an array with a user
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {ArraySharingRequestParams} arraySharing Namespace and list of permissions to share with. An empty list of permissions will remove the namespace, if permissions already exist they will be deleted then new ones added. In the event of a failure, the new polcies will be rolled back to prevent partial policies, and its likely the array will not be shared with the namespace at all
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_shareArray(data: ArrayApi_shareArray$Params, options?: any): Promise<AxiosResponse<ArrayApi_shareArray$Response>> {
    const localVarAxiosArgs = await ArrayApi_shareArrayParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_submitQueryJson$Response = object;

/**
  * send a query to run against a specified array/URI registered to a group/project, returns json results
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {QueryJson} queryJson query to run
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_submitQueryJson(data: ArrayApi_submitQueryJson$Params, options?: any): Promise<AxiosResponse<ArrayApi_submitQueryJson$Response>> {
    const localVarAxiosArgs = await ArrayApi_submitQueryJsonParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_subscribeToArray$Response = void;

/**
  * Subscribe to an array set up for monetization
  * @param {string} namespace Namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_subscribeToArray(data: ArrayApi_subscribeToArray$Params, options?: any): Promise<AxiosResponse<ArrayApi_subscribeToArray$Response>> {
    const localVarAxiosArgs = await ArrayApi_subscribeToArrayParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_unsubscribeFromArray$Response = void;

/**
  * Unsubscribe to an array set up for monetization
  * @param {string} namespace Namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_unsubscribeFromArray(data: ArrayApi_unsubscribeFromArray$Params, options?: any): Promise<AxiosResponse<ArrayApi_unsubscribeFromArray$Response>> {
    const localVarAxiosArgs = await ArrayApi_unsubscribeFromArrayParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_updateArrayInfo$Response = void;

/**
  * update info of an array
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {ArrayInfo} arrayInfo array data to update
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_updateArrayInfo(data: ArrayApi_updateArrayInfo$Params, options?: any): Promise<AxiosResponse<ArrayApi_updateArrayInfo$Response>> {
    const localVarAxiosArgs = await ArrayApi_updateArrayInfoParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayApi_updatePricing$Response = void;

/**
  * Update pricing info for a specified URI in a namespace
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {PricingInfo} pricingInfo Pricing metadata to update
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayApi_updatePricing(data: ArrayApi_updatePricing$Params, options?: any): Promise<AxiosResponse<ArrayApi_updatePricing$Response>> {
    const localVarAxiosArgs = await ArrayApi_updatePricingParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface ArrayTasksApi_getArrayTask$Params {
    id: string,
}

export const ArrayTasksApi_getArrayTask$Path = '/array_tasks/{id}';

/**
 * 
 * @param {string} id array task id
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayTasksApi_getArrayTaskParamCreator = async (data: ArrayTasksApi_getArrayTask$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'id' is not null or undefined
if (data['id'] === null || data['id'] === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling getArrayTask.');
}
const localVarPath = `/array_tasks/{id}`
    .replace(`{${"id"}}`, encodeURIComponent(String(data['id'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface ArrayTasksApi_getArrayTasks$Params {
    namespace?: string,
    createdBy?: string,
    array?: string,
    start?: number,
    end?: number,
    page?: number,
    perPage?: number,
    type?: string,
    fileType?: Array<string>,
    excludeFileType?: Array<string>,
    status?: string,
    search?: string,
    orderby?: string,
}

export const ArrayTasksApi_getArrayTasks$Path = '/array_tasks';

/**
 * 
 * @param {string} [namespace] namespace
 * @param {string} [createdBy] username to filter
 * @param {string} [array] name/uri of array that is url-encoded to filter
 * @param {number} [start] Fetch tasks created after this time, unix epoch in seconds, default 7 days ago
 * @param {number} [end] Fetch tasks created before this time, unix epoch in seconds, default now
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {string} [type] task type, \&quot;QUERY\&quot;, \&quot;SQL\&quot;, \&quot;UDF\&quot;
 * @param {Array<string>} [fileType] match file_type of task array, more than one can be included
 * @param {Array<string>} [excludeFileType] exclude file_type of task arrays, more than one can be included
 * @param {string} [status] state enum \&quot;SUCCESS\&quot; \&quot;FAIL\&quot; \&quot;PENDING\&quot;
 * @param {string} [search] search string that will look at name, namespace or description fields
 * @param {string} [orderby] sort by which field valid values include start_time, name
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const ArrayTasksApi_getArrayTasksParamCreator = async (data: ArrayTasksApi_getArrayTasks$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/array_tasks`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}
if (data['createdBy'] !== undefined) {
    localVarQueryParameter['created_by'] = data['createdBy'];
}
if (data['array'] !== undefined) {
    localVarQueryParameter['array'] = data['array'];
}
if (data['start'] !== undefined) {
    localVarQueryParameter['start'] = data['start'];
}
if (data['end'] !== undefined) {
    localVarQueryParameter['end'] = data['end'];
}
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['type'] !== undefined) {
    localVarQueryParameter['type'] = data['type'];
}
if (data['fileType']) {
    localVarQueryParameter['file_type'] = data['fileType'];
}
if (data['excludeFileType']) {
    localVarQueryParameter['exclude_file_type'] = data['excludeFileType'];
}
if (data['status'] !== undefined) {
    localVarQueryParameter['status'] = data['status'];
}
if (data['search'] !== undefined) {
    localVarQueryParameter['search'] = data['search'];
}
if (data['orderby'] !== undefined) {
    localVarQueryParameter['orderby'] = data['orderby'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type ArrayTasksApi_getArrayTask$Response = ArrayTask;

/**
  * 
  * @param {string} id array task id
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayTasksApi_getArrayTask(data: ArrayTasksApi_getArrayTask$Params, options?: any): Promise<AxiosResponse<ArrayTasksApi_getArrayTask$Response>> {
    const localVarAxiosArgs = await ArrayTasksApi_getArrayTaskParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type ArrayTasksApi_getArrayTasks$Response = ArrayTaskBrowserData;

/**
  * 
  * @param {string} [namespace] namespace
  * @param {string} [createdBy] username to filter
  * @param {string} [array] name/uri of array that is url-encoded to filter
  * @param {number} [start] Fetch tasks created after this time, unix epoch in seconds, default 7 days ago
  * @param {number} [end] Fetch tasks created before this time, unix epoch in seconds, default now
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {string} [type] task type, \&quot;QUERY\&quot;, \&quot;SQL\&quot;, \&quot;UDF\&quot;
  * @param {Array<string>} [fileType] match file_type of task array, more than one can be included
  * @param {Array<string>} [excludeFileType] exclude file_type of task arrays, more than one can be included
  * @param {string} [status] state enum \&quot;SUCCESS\&quot; \&quot;FAIL\&quot; \&quot;PENDING\&quot;
  * @param {string} [search] search string that will look at name, namespace or description fields
  * @param {string} [orderby] sort by which field valid values include start_time, name
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function ArrayTasksApi_getArrayTasks(data: ArrayTasksApi_getArrayTasks$Params, options?: any): Promise<AxiosResponse<ArrayTasksApi_getArrayTasks$Response>> {
    const localVarAxiosArgs = await ArrayTasksApi_getArrayTasksParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface AssetsApi_listAssetSummaries$Params {
    namespace: string,
}

export const AssetsApi_listAssetSummaries$Path = '/assets/{namespace}/summaries';

/**
 * List asset types for a namespace
 * @param {string} namespace namespace
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const AssetsApi_listAssetSummariesParamCreator = async (data: AssetsApi_listAssetSummaries$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling listAssetSummaries.');
}
const localVarPath = `/assets/{namespace}/summaries`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface AssetsApi_listAssets$Params {
    namespace: string,
    assetType?: string,
    ownershipLevel?: string,
    search?: string,
    page?: number,
    perPage?: number,
    depth?: string,
    orderBy?: string,
}

export const AssetsApi_listAssets$Path = '/assets/{namespace}';

/**
 * List assets in a namespace
 * @param {string} namespace namespace
 * @param {string} [assetType] asset_type to filter to
 * @param {string} [ownershipLevel] ownership_level to filter to (owned, public, shared)
 * @param {string} [search] search string
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {string} [depth] depth of assets to be returned
 * @param {string} [orderBy] order by a specific property, defaults to &#x60;created_at desc&#x60; supported values are created_at, name, asset_type can also include the order type (asc or desc) separated by space i.e. &#x60;name asc&#x60; &#x60;name desc&#x60; etc. 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const AssetsApi_listAssetsParamCreator = async (data: AssetsApi_listAssets$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling listAssets.');
}
const localVarPath = `/assets/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['assetType'] !== undefined) {
    localVarQueryParameter['asset_type'] = data['assetType'];
}
if (data['ownershipLevel'] !== undefined) {
    localVarQueryParameter['ownership_level'] = data['ownershipLevel'];
}
if (data['search'] !== undefined) {
    localVarQueryParameter['search'] = data['search'];
}
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['depth'] !== undefined) {
    localVarQueryParameter['depth'] = data['depth'];
}
if (data['orderBy'] !== undefined) {
    localVarQueryParameter['order_by'] = data['orderBy'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface AssetsApi_listPublicAssets$Params {
    assetType?: string,
    search?: string,
    page?: number,
    perPage?: number,
    depth?: string,
    orderBy?: string,
}

export const AssetsApi_listPublicAssets$Path = '/assets/_public';

/**
 * List public assets
 * @param {string} [assetType] asset_type to filter to
 * @param {string} [search] search string
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {string} [depth] depth of assets to be returned
 * @param {string} [orderBy] order by a specific property, defaults to &#x60;created_at desc&#x60; supported values are created_at, name, asset_type can also include the order type (asc or desc) separated by space i.e. &#x60;name asc&#x60; &#x60;name desc&#x60; etc. 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const AssetsApi_listPublicAssetsParamCreator = async (data: AssetsApi_listPublicAssets$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/assets/_public`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['assetType'] !== undefined) {
    localVarQueryParameter['asset_type'] = data['assetType'];
}
if (data['search'] !== undefined) {
    localVarQueryParameter['search'] = data['search'];
}
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['depth'] !== undefined) {
    localVarQueryParameter['depth'] = data['depth'];
}
if (data['orderBy'] !== undefined) {
    localVarQueryParameter['order_by'] = data['orderBy'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type AssetsApi_listAssetSummaries$Response = AssetListSummariesResponse;

/**
  * List asset types for a namespace
  * @param {string} namespace namespace
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function AssetsApi_listAssetSummaries(data: AssetsApi_listAssetSummaries$Params, options?: any): Promise<AxiosResponse<AssetsApi_listAssetSummaries$Response>> {
    const localVarAxiosArgs = await AssetsApi_listAssetSummariesParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type AssetsApi_listAssets$Response = AssetListResponse;

/**
  * List assets in a namespace
  * @param {string} namespace namespace
  * @param {string} [assetType] asset_type to filter to
  * @param {string} [ownershipLevel] ownership_level to filter to (owned, public, shared)
  * @param {string} [search] search string
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {string} [depth] depth of assets to be returned
  * @param {string} [orderBy] order by a specific property, defaults to &#x60;created_at desc&#x60; supported values are created_at, name, asset_type can also include the order type (asc or desc) separated by space i.e. &#x60;name asc&#x60; &#x60;name desc&#x60; etc. 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function AssetsApi_listAssets(data: AssetsApi_listAssets$Params, options?: any): Promise<AxiosResponse<AssetsApi_listAssets$Response>> {
    const localVarAxiosArgs = await AssetsApi_listAssetsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type AssetsApi_listPublicAssets$Response = AssetListResponse;

/**
  * List public assets
  * @param {string} [assetType] asset_type to filter to
  * @param {string} [search] search string
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {string} [depth] depth of assets to be returned
  * @param {string} [orderBy] order by a specific property, defaults to &#x60;created_at desc&#x60; supported values are created_at, name, asset_type can also include the order type (asc or desc) separated by space i.e. &#x60;name asc&#x60; &#x60;name desc&#x60; etc. 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function AssetsApi_listPublicAssets(data: AssetsApi_listPublicAssets$Params, options?: any): Promise<AxiosResponse<AssetsApi_listPublicAssets$Response>> {
    const localVarAxiosArgs = await AssetsApi_listPublicAssetsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface CredentialsApi_credentialsNamespaceVerifyPost$Params {
    namespace: string,
    accessCredential: AccessCredential,
}

export const CredentialsApi_credentialsNamespaceVerifyPost$Path = '/credentials/{namespace}/verify';

/**
 * verify the credentials connection for a namespace
 * @param {string} namespace namespace the credential is attempting to be added in
 * @param {AccessCredential} accessCredential 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const CredentialsApi_credentialsNamespaceVerifyPostParamCreator = async (data: CredentialsApi_credentialsNamespaceVerifyPost$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling credentialsNamespaceVerifyPost.');
}
// verify required parameter 'accessCredential' is not null or undefined
if (data['accessCredential'] === null || data['accessCredential'] === undefined) {
    throw new RequiredError('accessCredential','Required parameter accessCredential was null or undefined when calling credentialsNamespaceVerifyPost.');
}
const localVarPath = `/credentials/{namespace}/verify`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['accessCredential'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['accessCredential'] !== undefined ? data['accessCredential'] : {}) : (data['accessCredential'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type CredentialsApi_credentialsNamespaceVerifyPost$Response = void;

/**
  * verify the credentials connection for a namespace
  * @param {string} namespace namespace the credential is attempting to be added in
  * @param {AccessCredential} accessCredential 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function CredentialsApi_credentialsNamespaceVerifyPost(data: CredentialsApi_credentialsNamespaceVerifyPost$Params, options?: any): Promise<AxiosResponse<CredentialsApi_credentialsNamespaceVerifyPost$Response>> {
    const localVarAxiosArgs = await CredentialsApi_credentialsNamespaceVerifyPostParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface FavoritesApi_addFavorite$Params {
    namespace: string,
    name: string,
    fileType?: string,
    isDashboard?: boolean,
}

export const FavoritesApi_addFavorite$Path = '/favorites/{namespace}/{name}';

/**
 * Add a new favorite
 * @param {string} namespace The namespace of the asset
 * @param {string} name The name or uuid of the asset
 * @param {string} [fileType] file type of the array
 * @param {boolean} [isDashboard] dashboard indicator for notebooks
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const FavoritesApi_addFavoriteParamCreator = async (data: FavoritesApi_addFavorite$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling addFavorite.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling addFavorite.');
}
const localVarPath = `/favorites/{namespace}/{name}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['fileType'] !== undefined) {
    localVarQueryParameter['file_type'] = data['fileType'];
}
if (data['isDashboard'] !== undefined) {
    localVarQueryParameter['is_dashboard'] = data['isDashboard'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface FavoritesApi_listFavorites$Params {
    fileType?: Array<string>,
    isDashboard?: boolean,
    page?: number,
    perPage?: number,
}

export const FavoritesApi_listFavorites$Path = '/favorites';

/**
 * Fetch a page of favorites of connected user
 * @param {Array<string>} [fileType] file type of the array
 * @param {boolean} [isDashboard] dashboard indicator for notebooks
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const FavoritesApi_listFavoritesParamCreator = async (data: FavoritesApi_listFavorites$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/favorites`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['fileType']) {
    localVarQueryParameter['file_type'] = data['fileType'];
}
if (data['isDashboard'] !== undefined) {
    localVarQueryParameter['is_dashboard'] = data['isDashboard'];
}
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface FavoritesApi_removeFavorite$Params {
    namespace: string,
    name: string,
    fileType?: string,
    isDashboard?: boolean,
}

export const FavoritesApi_removeFavorite$Path = '/favorites/{namespace}/{name}';

/**
 * Remove specific favorite
 * @param {string} namespace The namespace of the asset
 * @param {string} name The name or uuid of the asset
 * @param {string} [fileType] file type of the array
 * @param {boolean} [isDashboard] dashboard indicator for notebooks
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const FavoritesApi_removeFavoriteParamCreator = async (data: FavoritesApi_removeFavorite$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling removeFavorite.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling removeFavorite.');
}
const localVarPath = `/favorites/{namespace}/{name}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['fileType'] !== undefined) {
    localVarQueryParameter['file_type'] = data['fileType'];
}
if (data['isDashboard'] !== undefined) {
    localVarQueryParameter['is_dashboard'] = data['isDashboard'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type FavoritesApi_addFavorite$Response = void;

/**
  * Add a new favorite
  * @param {string} namespace The namespace of the asset
  * @param {string} name The name or uuid of the asset
  * @param {string} [fileType] file type of the array
  * @param {boolean} [isDashboard] dashboard indicator for notebooks
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function FavoritesApi_addFavorite(data: FavoritesApi_addFavorite$Params, options?: any): Promise<AxiosResponse<FavoritesApi_addFavorite$Response>> {
    const localVarAxiosArgs = await FavoritesApi_addFavoriteParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type FavoritesApi_listFavorites$Response = ListFavoritesResponse;

/**
  * Fetch a page of favorites of connected user
  * @param {Array<string>} [fileType] file type of the array
  * @param {boolean} [isDashboard] dashboard indicator for notebooks
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function FavoritesApi_listFavorites(data: FavoritesApi_listFavorites$Params, options?: any): Promise<AxiosResponse<FavoritesApi_listFavorites$Response>> {
    const localVarAxiosArgs = await FavoritesApi_listFavoritesParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type FavoritesApi_removeFavorite$Response = void;

/**
  * Remove specific favorite
  * @param {string} namespace The namespace of the asset
  * @param {string} name The name or uuid of the asset
  * @param {string} [fileType] file type of the array
  * @param {boolean} [isDashboard] dashboard indicator for notebooks
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function FavoritesApi_removeFavorite(data: FavoritesApi_removeFavorite$Params, options?: any): Promise<AxiosResponse<FavoritesApi_removeFavorite$Response>> {
    const localVarAxiosArgs = await FavoritesApi_removeFavoriteParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface FilesApi_filesNamespaceArrayPreviewGet$Params {
    namespace: string,
    array: string,
    endTimestamp?: number,
}

export const FilesApi_filesNamespaceArrayPreviewGet$Path = '/files/{namespace}/{array}/preview';

/**
 * retrieve the contents of the file base64 encodeded and the mimeType
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {number} [endTimestamp] Milliseconds since Unix epoch, preview will use open_at functionality to open notebook at the specific timestamp
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const FilesApi_filesNamespaceArrayPreviewGetParamCreator = async (data: FilesApi_filesNamespaceArrayPreviewGet$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling filesNamespaceArrayPreviewGet.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling filesNamespaceArrayPreviewGet.');
}
const localVarPath = `/files/{namespace}/{array}/preview`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['endTimestamp'] !== undefined) {
    localVarQueryParameter['end_timestamp'] = data['endTimestamp'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface FilesApi_handleCopyNotebook$Params {
    namespace: string,
    array: string,
    notebookCopy: NotebookCopy,
    credentialsName?: string,
    endTimestamp?: number,
}

export const FilesApi_handleCopyNotebook$Path = '/notebooks/{namespace}/{array}/copy';

/**
 * Copy a tiledb notebook at the specified location
 * @param {string} namespace The namespace of the notebook
 * @param {string} array The name of the notebook
 * @param {NotebookCopy} notebookCopy Input/Output information to create a new TileDB file
 * @param {string} [credentialsName] name of the credentials
 * @param {number} [endTimestamp] Milliseconds since Unix epoch, copy will use open_at functionality to copy notebook created at the specific timestamp
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const FilesApi_handleCopyNotebookParamCreator = async (data: FilesApi_handleCopyNotebook$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling handleCopyNotebook.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling handleCopyNotebook.');
}
// verify required parameter 'notebookCopy' is not null or undefined
if (data['notebookCopy'] === null || data['notebookCopy'] === undefined) {
    throw new RequiredError('notebookCopy','Required parameter notebookCopy was null or undefined when calling handleCopyNotebook.');
}
const localVarPath = `/notebooks/{namespace}/{array}/copy`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['credentialsName'] !== undefined) {
    localVarQueryParameter['credentials_name'] = data['credentialsName'];
}
if (data['endTimestamp'] !== undefined) {
    localVarQueryParameter['end_timestamp'] = data['endTimestamp'];
}


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['notebookCopy'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['notebookCopy'] !== undefined ? data['notebookCopy'] : {}) : (data['notebookCopy'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface FilesApi_handleUploadFileMultipart$Params {
    namespace: string,
    outputUri: string,
    name: string,
    fileSize: number,
    mimeType: string,
    file: any,
    credentialsName?: string,
    filename?: string,
}

export const FilesApi_handleUploadFileMultipart$Path = '/files/{namespace}/multipart-upload';

/**
 * Upload a tiledb file at the specified location
 * @param {string} namespace The namespace of the file
 * @param {string} outputUri output location of the TileDB File
 * @param {string} name name to set for registered file
 * @param {number} fileSize size of the file to be uploaded
 * @param {string} mimeType Mime Type of file
 * @param {any} file file to upload
 * @param {string} [credentialsName] Optional registered access credentials to use for creation
 * @param {string} [filename] original file name
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const FilesApi_handleUploadFileMultipartParamCreator = async (data: FilesApi_handleUploadFileMultipart$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling handleUploadFileMultipart.');
}
// verify required parameter 'outputUri' is not null or undefined
if (data['outputUri'] === null || data['outputUri'] === undefined) {
    throw new RequiredError('outputUri','Required parameter outputUri was null or undefined when calling handleUploadFileMultipart.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling handleUploadFileMultipart.');
}
// verify required parameter 'fileSize' is not null or undefined
if (data['fileSize'] === null || data['fileSize'] === undefined) {
    throw new RequiredError('fileSize','Required parameter fileSize was null or undefined when calling handleUploadFileMultipart.');
}
// verify required parameter 'mimeType' is not null or undefined
if (data['mimeType'] === null || data['mimeType'] === undefined) {
    throw new RequiredError('mimeType','Required parameter mimeType was null or undefined when calling handleUploadFileMultipart.');
}
// verify required parameter 'file' is not null or undefined
if (data['file'] === null || data['file'] === undefined) {
    throw new RequiredError('file','Required parameter file was null or undefined when calling handleUploadFileMultipart.');
}
const localVarPath = `/files/{namespace}/multipart-upload`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
const localVarFormParams = new FormData();
if (data['credentialsName'] !== undefined) {
    localVarQueryParameter['credentials_name'] = data['credentialsName'];
}
if (data['outputUri'] !== undefined) {
    localVarQueryParameter['output_uri'] = data['outputUri'];
}
if (data['name'] !== undefined) {
    localVarQueryParameter['name'] = data['name'];
}
if (data['filename'] !== undefined) {
    localVarQueryParameter['filename'] = data['filename'];
}
if (data['fileSize'] !== undefined) {
    localVarQueryParameter['file_size'] = data['fileSize'];
}
if (data['mimeType'] !== undefined) {
    localVarQueryParameter['mime_type'] = data['mimeType'];
}

    if (data['file'] !== undefined) { 
        localVarFormParams.append('file', data['file'] as any);
    }


    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    localVarRequestOptions.data = localVarFormParams;
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type FilesApi_filesNamespaceArrayPreviewGet$Response = any;

/**
  * retrieve the contents of the file base64 encodeded and the mimeType
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {number} [endTimestamp] Milliseconds since Unix epoch, preview will use open_at functionality to open notebook at the specific timestamp
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function FilesApi_filesNamespaceArrayPreviewGet(data: FilesApi_filesNamespaceArrayPreviewGet$Params, options?: any): Promise<AxiosResponse<FilesApi_filesNamespaceArrayPreviewGet$Response>> {
    const localVarAxiosArgs = await FilesApi_filesNamespaceArrayPreviewGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type FilesApi_handleCopyNotebook$Response = NotebookCopied;

/**
  * Copy a tiledb notebook at the specified location
  * @param {string} namespace The namespace of the notebook
  * @param {string} array The name of the notebook
  * @param {NotebookCopy} notebookCopy Input/Output information to create a new TileDB file
  * @param {string} [credentialsName] name of the credentials
  * @param {number} [endTimestamp] Milliseconds since Unix epoch, copy will use open_at functionality to copy notebook created at the specific timestamp
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function FilesApi_handleCopyNotebook(data: FilesApi_handleCopyNotebook$Params, options?: any): Promise<AxiosResponse<FilesApi_handleCopyNotebook$Response>> {
    const localVarAxiosArgs = await FilesApi_handleCopyNotebookParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type FilesApi_handleUploadFileMultipart$Response = FileUploaded;

/**
  * Upload a tiledb file at the specified location
  * @param {string} namespace The namespace of the file
  * @param {string} outputUri output location of the TileDB File
  * @param {string} name name to set for registered file
  * @param {number} fileSize size of the file to be uploaded
  * @param {string} mimeType Mime Type of file
  * @param {any} file file to upload
  * @param {string} [credentialsName] Optional registered access credentials to use for creation
  * @param {string} [filename] original file name
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function FilesApi_handleUploadFileMultipart(data: FilesApi_handleUploadFileMultipart$Params, options?: any): Promise<AxiosResponse<FilesApi_handleUploadFileMultipart$Response>> {
    const localVarAxiosArgs = await FilesApi_handleUploadFileMultipartParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface GroupsApi_createGroup$Params {
    namespace: string,
    xTILEDBCLOUDACCESSCREDENTIALSNAME?: string,
    groupCreate?: GroupCreate,
}

export const GroupsApi_createGroup$Path = '/groups/{namespace}/create';

/**
 * Creates a new group in the namespace.
 * @param {string} namespace The namespace of the group
 * @param {string} [xTILEDBCLOUDACCESSCREDENTIALSNAME] Optional registered access credentials to use for creation
 * @param {GroupCreate} [groupCreate] 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_createGroupParamCreator = async (data: GroupsApi_createGroup$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling createGroup.');
}
const localVarPath = `/groups/{namespace}/create`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['xTILEDBCLOUDACCESSCREDENTIALSNAME'] !== undefined && data['xTILEDBCLOUDACCESSCREDENTIALSNAME'] !== null) {
    localVarHeaderParameter['X-TILEDB-CLOUD-ACCESS-CREDENTIALS-NAME'] = String(data['xTILEDBCLOUDACCESSCREDENTIALSNAME']);
}


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['groupCreate'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['groupCreate'] !== undefined ? data['groupCreate'] : {}) : (data['groupCreate'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_deleteGroup$Params {
    groupNamespace: string,
    groupName: string,
    recursive?: string,
}

export const GroupsApi_deleteGroup$Path = '/groups/{group_namespace}/{group_name}/delete';

/**
 * Deregisters and physically deletes a group
 * @param {string} groupNamespace The namespace of the group
 * @param {string} groupName The unique name or id of the group
 * @param {string} [recursive] If true, it descends in the group and deletes every subgroup and subarray
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_deleteGroupParamCreator = async (data: GroupsApi_deleteGroup$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'groupNamespace' is not null or undefined
if (data['groupNamespace'] === null || data['groupNamespace'] === undefined) {
    throw new RequiredError('groupNamespace','Required parameter groupNamespace was null or undefined when calling deleteGroup.');
}
// verify required parameter 'groupName' is not null or undefined
if (data['groupName'] === null || data['groupName'] === undefined) {
    throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling deleteGroup.');
}
const localVarPath = `/groups/{group_namespace}/{group_name}/delete`
    .replace(`{${"group_namespace"}}`, encodeURIComponent(String(data['groupNamespace'])))
    .replace(`{${"group_name"}}`, encodeURIComponent(String(data['groupName'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['recursive'] !== undefined) {
    localVarQueryParameter['recursive'] = data['recursive'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_deregisterGroup$Params {
    groupNamespace: string,
    groupName: string,
    recursive?: string,
}

export const GroupsApi_deregisterGroup$Path = '/groups/{group_namespace}/{group_name}';

/**
 * Degegisters a group. The assets are not deleted nor are not relocated to any other group
 * @param {string} groupNamespace The namespace of the group
 * @param {string} groupName The unique name or id of the group
 * @param {string} [recursive] If true, it descends in the group and deregisters every subgroup and subarray
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_deregisterGroupParamCreator = async (data: GroupsApi_deregisterGroup$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'groupNamespace' is not null or undefined
if (data['groupNamespace'] === null || data['groupNamespace'] === undefined) {
    throw new RequiredError('groupNamespace','Required parameter groupNamespace was null or undefined when calling deregisterGroup.');
}
// verify required parameter 'groupName' is not null or undefined
if (data['groupName'] === null || data['groupName'] === undefined) {
    throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling deregisterGroup.');
}
const localVarPath = `/groups/{group_namespace}/{group_name}`
    .replace(`{${"group_namespace"}}`, encodeURIComponent(String(data['groupNamespace'])))
    .replace(`{${"group_name"}}`, encodeURIComponent(String(data['groupName'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['recursive'] !== undefined) {
    localVarQueryParameter['recursive'] = data['recursive'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_getGroup$Params {
    groupNamespace: string,
    groupName: string,
}

export const GroupsApi_getGroup$Path = '/groups/{group_namespace}/{group_name}';

/**
 * Returns the the group
 * @param {string} groupNamespace The namespace of the group
 * @param {string} groupName The unique name or id of the group
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_getGroupParamCreator = async (data: GroupsApi_getGroup$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'groupNamespace' is not null or undefined
if (data['groupNamespace'] === null || data['groupNamespace'] === undefined) {
    throw new RequiredError('groupNamespace','Required parameter groupNamespace was null or undefined when calling getGroup.');
}
// verify required parameter 'groupName' is not null or undefined
if (data['groupName'] === null || data['groupName'] === undefined) {
    throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getGroup.');
}
const localVarPath = `/groups/{group_namespace}/{group_name}`
    .replace(`{${"group_namespace"}}`, encodeURIComponent(String(data['groupNamespace'])))
    .replace(`{${"group_name"}}`, encodeURIComponent(String(data['groupName'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_getGroupActivity$Params {
    groupNamespace: string,
    groupName: string,
    start?: number,
    end?: number,
    page?: number,
    perPage?: number,
}

export const GroupsApi_getGroupActivity$Path = '/groups/{group_namespace}/{group_name}/activity';

/**
 * Retrieves activity logs for all assets contained in a group (arrays and other groups) including the parent group itself.
 * @param {string} groupNamespace namespace group is in (an organization name or user\&#39;s username)
 * @param {string} groupName name/uri of group that is url-encoded
 * @param {number} [start] Start time of window of fetch logs, unix epoch in seconds (default: seven days ago)
 * @param {number} [end] End time of window of fetch logs, unix epoch in seconds (default: current utc timestamp)
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_getGroupActivityParamCreator = async (data: GroupsApi_getGroupActivity$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'groupNamespace' is not null or undefined
if (data['groupNamespace'] === null || data['groupNamespace'] === undefined) {
    throw new RequiredError('groupNamespace','Required parameter groupNamespace was null or undefined when calling getGroupActivity.');
}
// verify required parameter 'groupName' is not null or undefined
if (data['groupName'] === null || data['groupName'] === undefined) {
    throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getGroupActivity.');
}
const localVarPath = `/groups/{group_namespace}/{group_name}/activity`
    .replace(`{${"group_namespace"}}`, encodeURIComponent(String(data['groupNamespace'])))
    .replace(`{${"group_name"}}`, encodeURIComponent(String(data['groupName'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['start'] !== undefined) {
    localVarQueryParameter['start'] = data['start'];
}
if (data['end'] !== undefined) {
    localVarQueryParameter['end'] = data['end'];
}
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_getGroupContents$Params {
    groupNamespace: string,
    groupName: string,
    page?: number,
    perPage?: number,
    namespace?: string,
    search?: string,
    orderby?: string,
    tag?: Array<string>,
    excludeTag?: Array<string>,
    memberType?: Array<string>,
    excludeMemberType?: Array<string>,
    permissions?: string,
}

export const GroupsApi_getGroupContents$Path = '/groups/{group_namespace}/{group_name}/contents';

/**
 * Returns the contents of the group
 * @param {string} groupNamespace The namespace of the group
 * @param {string} groupName The unique name or id of the group
 * @param {number} [page] pagination offset for assets
 * @param {number} [perPage] pagination limit for assets
 * @param {string} [namespace] namespace to search for
 * @param {string} [search] search string that will look at name, namespace or description fields
 * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
 * @param {Array<string>} [tag] tag to search for, more than one can be included
 * @param {Array<string>} [excludeTag] tags to exclude matching array in results, more than one can be included
 * @param {Array<string>} [memberType] member type to search for, more than one can be included
 * @param {Array<string>} [excludeMemberType] member type to exclude matching groups in results, more than one can be included
 * @param {string} [permissions] permissions valid values include read, read_write, write, admin
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_getGroupContentsParamCreator = async (data: GroupsApi_getGroupContents$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'groupNamespace' is not null or undefined
if (data['groupNamespace'] === null || data['groupNamespace'] === undefined) {
    throw new RequiredError('groupNamespace','Required parameter groupNamespace was null or undefined when calling getGroupContents.');
}
// verify required parameter 'groupName' is not null or undefined
if (data['groupName'] === null || data['groupName'] === undefined) {
    throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getGroupContents.');
}
const localVarPath = `/groups/{group_namespace}/{group_name}/contents`
    .replace(`{${"group_namespace"}}`, encodeURIComponent(String(data['groupNamespace'])))
    .replace(`{${"group_name"}}`, encodeURIComponent(String(data['groupName'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}
if (data['search'] !== undefined) {
    localVarQueryParameter['search'] = data['search'];
}
if (data['orderby'] !== undefined) {
    localVarQueryParameter['orderby'] = data['orderby'];
}
if (data['tag']) {
    localVarQueryParameter['tag'] = data['tag'];
}
if (data['excludeTag']) {
    localVarQueryParameter['exclude_tag'] = data['excludeTag'];
}
if (data['memberType']) {
    localVarQueryParameter['member_type'] = data['memberType'];
}
if (data['excludeMemberType']) {
    localVarQueryParameter['exclude_member_type'] = data['excludeMemberType'];
}
if (data['permissions'] !== undefined) {
    localVarQueryParameter['permissions'] = data['permissions'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_getGroupContentsFilters$Params {
    groupNamespace: string,
    groupName: string,
}

export const GroupsApi_getGroupContentsFilters$Path = '/groups/{group_namespace}/{group_name}/contents/filters';

/**
 * Fetch data to initialize filters for the group contents
 * @param {string} groupNamespace The namespace of the group
 * @param {string} groupName The unique name or id of the group
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_getGroupContentsFiltersParamCreator = async (data: GroupsApi_getGroupContentsFilters$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'groupNamespace' is not null or undefined
if (data['groupNamespace'] === null || data['groupNamespace'] === undefined) {
    throw new RequiredError('groupNamespace','Required parameter groupNamespace was null or undefined when calling getGroupContentsFilters.');
}
// verify required parameter 'groupName' is not null or undefined
if (data['groupName'] === null || data['groupName'] === undefined) {
    throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getGroupContentsFilters.');
}
const localVarPath = `/groups/{group_namespace}/{group_name}/contents/filters`
    .replace(`{${"group_namespace"}}`, encodeURIComponent(String(data['groupNamespace'])))
    .replace(`{${"group_name"}}`, encodeURIComponent(String(data['groupName'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_getGroupMetadata$Params {
    groupNamespace: string,
    groupName: string,
    metadataRetrieval: GroupMetadataRetrievalRequest,
}

export const GroupsApi_getGroupMetadata$Path = '/groups/{group_namespace}/{group_name}/metadata';

/**
 * get metadata on an array using the requested config
 * @param {string} groupNamespace The namespace of the group
 * @param {string} groupName The unique name or id of the group
 * @param {GroupMetadataRetrievalRequest} metadataRetrieval 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_getGroupMetadataParamCreator = async (data: GroupsApi_getGroupMetadata$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'groupNamespace' is not null or undefined
if (data['groupNamespace'] === null || data['groupNamespace'] === undefined) {
    throw new RequiredError('groupNamespace','Required parameter groupNamespace was null or undefined when calling getGroupMetadata.');
}
// verify required parameter 'groupName' is not null or undefined
if (data['groupName'] === null || data['groupName'] === undefined) {
    throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getGroupMetadata.');
}
// verify required parameter 'metadataRetrieval' is not null or undefined
if (data['metadataRetrieval'] === null || data['metadataRetrieval'] === undefined) {
    throw new RequiredError('metadataRetrieval','Required parameter metadataRetrieval was null or undefined when calling getGroupMetadata.');
}
const localVarPath = `/groups/{group_namespace}/{group_name}/metadata`
    .replace(`{${"group_namespace"}}`, encodeURIComponent(String(data['groupNamespace'])))
    .replace(`{${"group_name"}}`, encodeURIComponent(String(data['groupName'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['metadataRetrieval'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['metadataRetrieval'] !== undefined ? data['metadataRetrieval'] : {}) : (data['metadataRetrieval'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_getGroupSharingPolicies$Params {
    groupNamespace: string,
    groupName: string,
}

export const GroupsApi_getGroupSharingPolicies$Path = '/groups/{group_namespace}/{group_name}/share';

/**
 * Get all sharing details of the group
 * @param {string} groupNamespace The namespace of the group
 * @param {string} groupName The unique name or id of the group
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_getGroupSharingPoliciesParamCreator = async (data: GroupsApi_getGroupSharingPolicies$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'groupNamespace' is not null or undefined
if (data['groupNamespace'] === null || data['groupNamespace'] === undefined) {
    throw new RequiredError('groupNamespace','Required parameter groupNamespace was null or undefined when calling getGroupSharingPolicies.');
}
// verify required parameter 'groupName' is not null or undefined
if (data['groupName'] === null || data['groupName'] === undefined) {
    throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getGroupSharingPolicies.');
}
const localVarPath = `/groups/{group_namespace}/{group_name}/share`
    .replace(`{${"group_namespace"}}`, encodeURIComponent(String(data['groupNamespace'])))
    .replace(`{${"group_name"}}`, encodeURIComponent(String(data['groupName'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_groupsBrowserOwnedFiltersGet$Params {
    namespace?: string,
}

export const GroupsApi_groupsBrowserOwnedFiltersGet$Path = '/groups/browser/owned/filters';

/**
 * Fetch a list of all groups that have been shared with the user
 * @param {string} [namespace] namespace
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_groupsBrowserOwnedFiltersGetParamCreator = async (data: GroupsApi_groupsBrowserOwnedFiltersGet$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/groups/browser/owned/filters`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export type GroupsApi_groupsBrowserPublicFiltersGet$Params = void;

export const GroupsApi_groupsBrowserPublicFiltersGet$Path = '/groups/browser/public/filters';

/**
 * Fetch a list of all groups that have been shared with the user
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_groupsBrowserPublicFiltersGetParamCreator = async (data: GroupsApi_groupsBrowserPublicFiltersGet$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/groups/browser/public/filters`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_groupsBrowserSharedFiltersGet$Params {
    namespace?: string,
}

export const GroupsApi_groupsBrowserSharedFiltersGet$Path = '/groups/browser/shared/filters';

/**
 * Fetch a list of all groups that have been shared with the user
 * @param {string} [namespace] namespace
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_groupsBrowserSharedFiltersGetParamCreator = async (data: GroupsApi_groupsBrowserSharedFiltersGet$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/groups/browser/shared/filters`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_listOwnedGroups$Params {
    page?: number,
    perPage?: number,
    groupType?: string,
    search?: string,
    namespace?: string,
    orderby?: string,
    permissions?: string,
    tag?: Array<string>,
    excludeTag?: Array<string>,
    flat?: boolean,
    parent?: string,
}

export const GroupsApi_listOwnedGroups$Path = '/groups/browser/owned';

/**
 * Returns one page of owned groups.
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {string} [groupType] filter by a specific group type
 * @param {string} [search] search string that will look at name, namespace or description fields
 * @param {string} [namespace] namespace
 * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
 * @param {string} [permissions] permissions valid values include read, read_write, write, admin
 * @param {Array<string>} [tag] tag to search for, more than one can be included
 * @param {Array<string>} [excludeTag] tags to exclude matching array in results, more than one can be included
 * @param {boolean} [flat] if true, ignores the nesting of groups and searches all of them
 * @param {string} [parent] search only the children of the groups with this uuid
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_listOwnedGroupsParamCreator = async (data: GroupsApi_listOwnedGroups$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/groups/browser/owned`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['groupType'] !== undefined) {
    localVarQueryParameter['group_type'] = data['groupType'];
}
if (data['search'] !== undefined) {
    localVarQueryParameter['search'] = data['search'];
}
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}
if (data['orderby'] !== undefined) {
    localVarQueryParameter['orderby'] = data['orderby'];
}
if (data['permissions'] !== undefined) {
    localVarQueryParameter['permissions'] = data['permissions'];
}
if (data['tag']) {
    localVarQueryParameter['tag'] = data['tag'];
}
if (data['excludeTag']) {
    localVarQueryParameter['exclude_tag'] = data['excludeTag'];
}
if (data['flat'] !== undefined) {
    localVarQueryParameter['flat'] = data['flat'];
}
if (data['parent'] !== undefined) {
    localVarQueryParameter['parent'] = data['parent'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_listPublicGroups$Params {
    page?: number,
    perPage?: number,
    groupType?: string,
    search?: string,
    namespace?: string,
    orderby?: string,
    permissions?: string,
    tag?: Array<string>,
    excludeTag?: Array<string>,
    flat?: boolean,
    parent?: string,
}

export const GroupsApi_listPublicGroups$Path = '/groups/browser/public';

/**
 * Returns one page of public groups.
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {string} [groupType] filter by a specific group type
 * @param {string} [search] search string that will look at name, namespace or description fields
 * @param {string} [namespace] namespace
 * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
 * @param {string} [permissions] permissions valid values include read, read_write, write, admin
 * @param {Array<string>} [tag] tag to search for, more than one can be included
 * @param {Array<string>} [excludeTag] tags to exclude matching array in results, more than one can be included
 * @param {boolean} [flat] if true, ignores the nesting of groups and searches all of them
 * @param {string} [parent] search only the children of the groups with this uuid
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_listPublicGroupsParamCreator = async (data: GroupsApi_listPublicGroups$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/groups/browser/public`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['groupType'] !== undefined) {
    localVarQueryParameter['group_type'] = data['groupType'];
}
if (data['search'] !== undefined) {
    localVarQueryParameter['search'] = data['search'];
}
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}
if (data['orderby'] !== undefined) {
    localVarQueryParameter['orderby'] = data['orderby'];
}
if (data['permissions'] !== undefined) {
    localVarQueryParameter['permissions'] = data['permissions'];
}
if (data['tag']) {
    localVarQueryParameter['tag'] = data['tag'];
}
if (data['excludeTag']) {
    localVarQueryParameter['exclude_tag'] = data['excludeTag'];
}
if (data['flat'] !== undefined) {
    localVarQueryParameter['flat'] = data['flat'];
}
if (data['parent'] !== undefined) {
    localVarQueryParameter['parent'] = data['parent'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_listSharedGroups$Params {
    page?: number,
    perPage?: number,
    groupType?: string,
    search?: string,
    namespace?: string,
    orderby?: string,
    permissions?: string,
    tag?: Array<string>,
    excludeTag?: Array<string>,
    flat?: boolean,
    parent?: string,
    sharedTo?: Array<string>,
}

export const GroupsApi_listSharedGroups$Path = '/groups/browser/shared';

/**
 * Returns one page of shared groups.
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {string} [groupType] filter by a specific group type
 * @param {string} [search] search string that will look at name, namespace or description fields
 * @param {string} [namespace] namespace
 * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
 * @param {string} [permissions] permissions valid values include read, read_write, write, admin
 * @param {Array<string>} [tag] tag to search for, more than one can be included
 * @param {Array<string>} [excludeTag] tags to exclude matching array in results, more than one can be included
 * @param {boolean} [flat] if true, ignores the nesting of groups and searches all of them
 * @param {string} [parent] search only the children of the groups with this uuid
 * @param {Array<string>} [sharedTo] namespaces to filter results of where there groups were shared to
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_listSharedGroupsParamCreator = async (data: GroupsApi_listSharedGroups$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/groups/browser/shared`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['groupType'] !== undefined) {
    localVarQueryParameter['group_type'] = data['groupType'];
}
if (data['search'] !== undefined) {
    localVarQueryParameter['search'] = data['search'];
}
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}
if (data['orderby'] !== undefined) {
    localVarQueryParameter['orderby'] = data['orderby'];
}
if (data['permissions'] !== undefined) {
    localVarQueryParameter['permissions'] = data['permissions'];
}
if (data['tag']) {
    localVarQueryParameter['tag'] = data['tag'];
}
if (data['excludeTag']) {
    localVarQueryParameter['exclude_tag'] = data['excludeTag'];
}
if (data['flat'] !== undefined) {
    localVarQueryParameter['flat'] = data['flat'];
}
if (data['parent'] !== undefined) {
    localVarQueryParameter['parent'] = data['parent'];
}
if (data['sharedTo']) {
    localVarQueryParameter['shared_to'] = data['sharedTo'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_moveGroupContents$Params {
    groupNamespace: string,
    groupName: string,
    groupMoveContents: GroupContentsMoveRequest,
}

export const GroupsApi_moveGroupContents$Path = '/groups/{group_namespace}/{group_name}/contents/move';

/**
 * Move the contents of the group to another group
 * @param {string} groupNamespace The namespace of the parent group
 * @param {string} groupName The unique name or id of the parent group
 * @param {GroupContentsMoveRequest} groupMoveContents 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_moveGroupContentsParamCreator = async (data: GroupsApi_moveGroupContents$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'groupNamespace' is not null or undefined
if (data['groupNamespace'] === null || data['groupNamespace'] === undefined) {
    throw new RequiredError('groupNamespace','Required parameter groupNamespace was null or undefined when calling moveGroupContents.');
}
// verify required parameter 'groupName' is not null or undefined
if (data['groupName'] === null || data['groupName'] === undefined) {
    throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling moveGroupContents.');
}
// verify required parameter 'groupMoveContents' is not null or undefined
if (data['groupMoveContents'] === null || data['groupMoveContents'] === undefined) {
    throw new RequiredError('groupMoveContents','Required parameter groupMoveContents was null or undefined when calling moveGroupContents.');
}
const localVarPath = `/groups/{group_namespace}/{group_name}/contents/move`
    .replace(`{${"group_namespace"}}`, encodeURIComponent(String(data['groupNamespace'])))
    .replace(`{${"group_name"}}`, encodeURIComponent(String(data['groupName'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['groupMoveContents'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['groupMoveContents'] !== undefined ? data['groupMoveContents'] : {}) : (data['groupMoveContents'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_registerGroup$Params {
    namespace: string,
    xTILEDBCLOUDACCESSCREDENTIALSNAME?: string,
    groupRegister?: GroupRegister,
}

export const GroupsApi_registerGroup$Path = '/groups/{namespace}/register';

/**
 * Registers an existing group in the namespace.
 * @param {string} namespace The namespace of the group
 * @param {string} [xTILEDBCLOUDACCESSCREDENTIALSNAME] Optional registered access credentials to use for creation
 * @param {GroupRegister} [groupRegister] 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_registerGroupParamCreator = async (data: GroupsApi_registerGroup$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling registerGroup.');
}
const localVarPath = `/groups/{namespace}/register`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['xTILEDBCLOUDACCESSCREDENTIALSNAME'] !== undefined && data['xTILEDBCLOUDACCESSCREDENTIALSNAME'] !== null) {
    localVarHeaderParameter['X-TILEDB-CLOUD-ACCESS-CREDENTIALS-NAME'] = String(data['xTILEDBCLOUDACCESSCREDENTIALSNAME']);
}


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['groupRegister'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['groupRegister'] !== undefined ? data['groupRegister'] : {}) : (data['groupRegister'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_shareGroup$Params {
    groupNamespace: string,
    groupName: string,
    groupSharingRequest: GroupSharingRequest,
}

export const GroupsApi_shareGroup$Path = '/groups/{group_namespace}/{group_name}/share';

/**
 * Share a group with a namespace
 * @param {string} groupNamespace The namespace of the group
 * @param {string} groupName The unique name or id of the group
 * @param {GroupSharingRequest} groupSharingRequest Namespace and list of permissions to share with. Sharing is recursive, it is applied to all reachable subgroups and arrays of the group. An empty list of permissions will remove the namespace; if permissions already exist they will be deleted then new ones added. In the event of a failure, the new policies will be rolled back to prevent partial policies, and it\&#39;s likely the group will not be shared with the namespace at all.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_shareGroupParamCreator = async (data: GroupsApi_shareGroup$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'groupNamespace' is not null or undefined
if (data['groupNamespace'] === null || data['groupNamespace'] === undefined) {
    throw new RequiredError('groupNamespace','Required parameter groupNamespace was null or undefined when calling shareGroup.');
}
// verify required parameter 'groupName' is not null or undefined
if (data['groupName'] === null || data['groupName'] === undefined) {
    throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling shareGroup.');
}
// verify required parameter 'groupSharingRequest' is not null or undefined
if (data['groupSharingRequest'] === null || data['groupSharingRequest'] === undefined) {
    throw new RequiredError('groupSharingRequest','Required parameter groupSharingRequest was null or undefined when calling shareGroup.');
}
const localVarPath = `/groups/{group_namespace}/{group_name}/share`
    .replace(`{${"group_namespace"}}`, encodeURIComponent(String(data['groupNamespace'])))
    .replace(`{${"group_name"}}`, encodeURIComponent(String(data['groupName'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['groupSharingRequest'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['groupSharingRequest'] !== undefined ? data['groupSharingRequest'] : {}) : (data['groupSharingRequest'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_updateGroup$Params {
    groupNamespace: string,
    groupName: string,
    groupUpdate?: GroupUpdate,
}

export const GroupsApi_updateGroup$Path = '/groups/{group_namespace}/{group_name}';

/**
 * Changes attributes of the group
 * @param {string} groupNamespace The namespace of the group
 * @param {string} groupName The unique name or id of the group
 * @param {GroupUpdate} [groupUpdate] 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_updateGroupParamCreator = async (data: GroupsApi_updateGroup$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'groupNamespace' is not null or undefined
if (data['groupNamespace'] === null || data['groupNamespace'] === undefined) {
    throw new RequiredError('groupNamespace','Required parameter groupNamespace was null or undefined when calling updateGroup.');
}
// verify required parameter 'groupName' is not null or undefined
if (data['groupName'] === null || data['groupName'] === undefined) {
    throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling updateGroup.');
}
const localVarPath = `/groups/{group_namespace}/{group_name}`
    .replace(`{${"group_namespace"}}`, encodeURIComponent(String(data['groupNamespace'])))
    .replace(`{${"group_name"}}`, encodeURIComponent(String(data['groupName'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['groupUpdate'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['groupUpdate'] !== undefined ? data['groupUpdate'] : {}) : (data['groupUpdate'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface GroupsApi_updateGroupContents$Params {
    groupNamespace: string,
    groupName: string,
    groupUpdateContents: GroupContentsChangesRequest,
}

export const GroupsApi_updateGroupContents$Path = '/groups/{group_namespace}/{group_name}/contents';

/**
 * Change the contents of the group
 * @param {string} groupNamespace The namespace of the group
 * @param {string} groupName The unique name or id of the group
 * @param {GroupContentsChangesRequest} groupUpdateContents 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const GroupsApi_updateGroupContentsParamCreator = async (data: GroupsApi_updateGroupContents$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'groupNamespace' is not null or undefined
if (data['groupNamespace'] === null || data['groupNamespace'] === undefined) {
    throw new RequiredError('groupNamespace','Required parameter groupNamespace was null or undefined when calling updateGroupContents.');
}
// verify required parameter 'groupName' is not null or undefined
if (data['groupName'] === null || data['groupName'] === undefined) {
    throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling updateGroupContents.');
}
// verify required parameter 'groupUpdateContents' is not null or undefined
if (data['groupUpdateContents'] === null || data['groupUpdateContents'] === undefined) {
    throw new RequiredError('groupUpdateContents','Required parameter groupUpdateContents was null or undefined when calling updateGroupContents.');
}
const localVarPath = `/groups/{group_namespace}/{group_name}/contents`
    .replace(`{${"group_namespace"}}`, encodeURIComponent(String(data['groupNamespace'])))
    .replace(`{${"group_name"}}`, encodeURIComponent(String(data['groupName'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['groupUpdateContents'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['groupUpdateContents'] !== undefined ? data['groupUpdateContents'] : {}) : (data['groupUpdateContents'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type GroupsApi_createGroup$Response = GroupInfo;

/**
  * Creates a new group in the namespace.
  * @param {string} namespace The namespace of the group
  * @param {string} [xTILEDBCLOUDACCESSCREDENTIALSNAME] Optional registered access credentials to use for creation
  * @param {GroupCreate} [groupCreate] 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_createGroup(data: GroupsApi_createGroup$Params, options?: any): Promise<AxiosResponse<GroupsApi_createGroup$Response>> {
    const localVarAxiosArgs = await GroupsApi_createGroupParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_deleteGroup$Response = void;

/**
  * Deregisters and physically deletes a group
  * @param {string} groupNamespace The namespace of the group
  * @param {string} groupName The unique name or id of the group
  * @param {string} [recursive] If true, it descends in the group and deletes every subgroup and subarray
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_deleteGroup(data: GroupsApi_deleteGroup$Params, options?: any): Promise<AxiosResponse<GroupsApi_deleteGroup$Response>> {
    const localVarAxiosArgs = await GroupsApi_deleteGroupParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_deregisterGroup$Response = void;

/**
  * Degegisters a group. The assets are not deleted nor are not relocated to any other group
  * @param {string} groupNamespace The namespace of the group
  * @param {string} groupName The unique name or id of the group
  * @param {string} [recursive] If true, it descends in the group and deregisters every subgroup and subarray
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_deregisterGroup(data: GroupsApi_deregisterGroup$Params, options?: any): Promise<AxiosResponse<GroupsApi_deregisterGroup$Response>> {
    const localVarAxiosArgs = await GroupsApi_deregisterGroupParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_getGroup$Response = GroupInfo;

/**
  * Returns the the group
  * @param {string} groupNamespace The namespace of the group
  * @param {string} groupName The unique name or id of the group
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_getGroup(data: GroupsApi_getGroup$Params, options?: any): Promise<AxiosResponse<GroupsApi_getGroup$Response>> {
    const localVarAxiosArgs = await GroupsApi_getGroupParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_getGroupActivity$Response = GroupActivityResponse;

/**
  * Retrieves activity logs for all assets contained in a group (arrays and other groups) including the parent group itself.
  * @param {string} groupNamespace namespace group is in (an organization name or user\&#39;s username)
  * @param {string} groupName name/uri of group that is url-encoded
  * @param {number} [start] Start time of window of fetch logs, unix epoch in seconds (default: seven days ago)
  * @param {number} [end] End time of window of fetch logs, unix epoch in seconds (default: current utc timestamp)
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_getGroupActivity(data: GroupsApi_getGroupActivity$Params, options?: any): Promise<AxiosResponse<GroupsApi_getGroupActivity$Response>> {
    const localVarAxiosArgs = await GroupsApi_getGroupActivityParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_getGroupContents$Response = GroupContents;

/**
  * Returns the contents of the group
  * @param {string} groupNamespace The namespace of the group
  * @param {string} groupName The unique name or id of the group
  * @param {number} [page] pagination offset for assets
  * @param {number} [perPage] pagination limit for assets
  * @param {string} [namespace] namespace to search for
  * @param {string} [search] search string that will look at name, namespace or description fields
  * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
  * @param {Array<string>} [tag] tag to search for, more than one can be included
  * @param {Array<string>} [excludeTag] tags to exclude matching array in results, more than one can be included
  * @param {Array<string>} [memberType] member type to search for, more than one can be included
  * @param {Array<string>} [excludeMemberType] member type to exclude matching groups in results, more than one can be included
  * @param {string} [permissions] permissions valid values include read, read_write, write, admin
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_getGroupContents(data: GroupsApi_getGroupContents$Params, options?: any): Promise<AxiosResponse<GroupsApi_getGroupContents$Response>> {
    const localVarAxiosArgs = await GroupsApi_getGroupContentsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_getGroupContentsFilters$Response = GroupContentsFilterData;

/**
  * Fetch data to initialize filters for the group contents
  * @param {string} groupNamespace The namespace of the group
  * @param {string} groupName The unique name or id of the group
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_getGroupContentsFilters(data: GroupsApi_getGroupContentsFilters$Params, options?: any): Promise<AxiosResponse<GroupsApi_getGroupContentsFilters$Response>> {
    const localVarAxiosArgs = await GroupsApi_getGroupContentsFiltersParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_getGroupMetadata$Response = GroupMetadata;

/**
  * get metadata on an array using the requested config
  * @param {string} groupNamespace The namespace of the group
  * @param {string} groupName The unique name or id of the group
  * @param {GroupMetadataRetrievalRequest} metadataRetrieval 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_getGroupMetadata(data: GroupsApi_getGroupMetadata$Params, options?: any): Promise<AxiosResponse<GroupsApi_getGroupMetadata$Response>> {
    const localVarAxiosArgs = await GroupsApi_getGroupMetadataParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_getGroupSharingPolicies$Response = Array<GroupSharing>;

/**
  * Get all sharing details of the group
  * @param {string} groupNamespace The namespace of the group
  * @param {string} groupName The unique name or id of the group
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_getGroupSharingPolicies(data: GroupsApi_getGroupSharingPolicies$Params, options?: any): Promise<AxiosResponse<GroupsApi_getGroupSharingPolicies$Response>> {
    const localVarAxiosArgs = await GroupsApi_getGroupSharingPoliciesParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_groupsBrowserOwnedFiltersGet$Response = GroupBrowserFilterData;

/**
  * Fetch a list of all groups that have been shared with the user
  * @param {string} [namespace] namespace
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_groupsBrowserOwnedFiltersGet(data: GroupsApi_groupsBrowserOwnedFiltersGet$Params, options?: any): Promise<AxiosResponse<GroupsApi_groupsBrowserOwnedFiltersGet$Response>> {
    const localVarAxiosArgs = await GroupsApi_groupsBrowserOwnedFiltersGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_groupsBrowserPublicFiltersGet$Response = GroupBrowserFilterData;

/**
  * Fetch a list of all groups that have been shared with the user
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_groupsBrowserPublicFiltersGet(data: GroupsApi_groupsBrowserPublicFiltersGet$Params, options?: any): Promise<AxiosResponse<GroupsApi_groupsBrowserPublicFiltersGet$Response>> {
    const localVarAxiosArgs = await GroupsApi_groupsBrowserPublicFiltersGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_groupsBrowserSharedFiltersGet$Response = GroupBrowserFilterData;

/**
  * Fetch a list of all groups that have been shared with the user
  * @param {string} [namespace] namespace
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_groupsBrowserSharedFiltersGet(data: GroupsApi_groupsBrowserSharedFiltersGet$Params, options?: any): Promise<AxiosResponse<GroupsApi_groupsBrowserSharedFiltersGet$Response>> {
    const localVarAxiosArgs = await GroupsApi_groupsBrowserSharedFiltersGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_listOwnedGroups$Response = GroupBrowserData;

/**
  * Returns one page of owned groups.
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {string} [groupType] filter by a specific group type
  * @param {string} [search] search string that will look at name, namespace or description fields
  * @param {string} [namespace] namespace
  * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
  * @param {string} [permissions] permissions valid values include read, read_write, write, admin
  * @param {Array<string>} [tag] tag to search for, more than one can be included
  * @param {Array<string>} [excludeTag] tags to exclude matching array in results, more than one can be included
  * @param {boolean} [flat] if true, ignores the nesting of groups and searches all of them
  * @param {string} [parent] search only the children of the groups with this uuid
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_listOwnedGroups(data: GroupsApi_listOwnedGroups$Params, options?: any): Promise<AxiosResponse<GroupsApi_listOwnedGroups$Response>> {
    const localVarAxiosArgs = await GroupsApi_listOwnedGroupsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_listPublicGroups$Response = GroupBrowserData;

/**
  * Returns one page of public groups.
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {string} [groupType] filter by a specific group type
  * @param {string} [search] search string that will look at name, namespace or description fields
  * @param {string} [namespace] namespace
  * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
  * @param {string} [permissions] permissions valid values include read, read_write, write, admin
  * @param {Array<string>} [tag] tag to search for, more than one can be included
  * @param {Array<string>} [excludeTag] tags to exclude matching array in results, more than one can be included
  * @param {boolean} [flat] if true, ignores the nesting of groups and searches all of them
  * @param {string} [parent] search only the children of the groups with this uuid
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_listPublicGroups(data: GroupsApi_listPublicGroups$Params, options?: any): Promise<AxiosResponse<GroupsApi_listPublicGroups$Response>> {
    const localVarAxiosArgs = await GroupsApi_listPublicGroupsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_listSharedGroups$Response = GroupBrowserData;

/**
  * Returns one page of shared groups.
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {string} [groupType] filter by a specific group type
  * @param {string} [search] search string that will look at name, namespace or description fields
  * @param {string} [namespace] namespace
  * @param {string} [orderby] sort by which field valid values include last_accessed, size, name
  * @param {string} [permissions] permissions valid values include read, read_write, write, admin
  * @param {Array<string>} [tag] tag to search for, more than one can be included
  * @param {Array<string>} [excludeTag] tags to exclude matching array in results, more than one can be included
  * @param {boolean} [flat] if true, ignores the nesting of groups and searches all of them
  * @param {string} [parent] search only the children of the groups with this uuid
  * @param {Array<string>} [sharedTo] namespaces to filter results of where there groups were shared to
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_listSharedGroups(data: GroupsApi_listSharedGroups$Params, options?: any): Promise<AxiosResponse<GroupsApi_listSharedGroups$Response>> {
    const localVarAxiosArgs = await GroupsApi_listSharedGroupsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_moveGroupContents$Response = void;

/**
  * Move the contents of the group to another group
  * @param {string} groupNamespace The namespace of the parent group
  * @param {string} groupName The unique name or id of the parent group
  * @param {GroupContentsMoveRequest} groupMoveContents 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_moveGroupContents(data: GroupsApi_moveGroupContents$Params, options?: any): Promise<AxiosResponse<GroupsApi_moveGroupContents$Response>> {
    const localVarAxiosArgs = await GroupsApi_moveGroupContentsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_registerGroup$Response = void;

/**
  * Registers an existing group in the namespace.
  * @param {string} namespace The namespace of the group
  * @param {string} [xTILEDBCLOUDACCESSCREDENTIALSNAME] Optional registered access credentials to use for creation
  * @param {GroupRegister} [groupRegister] 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_registerGroup(data: GroupsApi_registerGroup$Params, options?: any): Promise<AxiosResponse<GroupsApi_registerGroup$Response>> {
    const localVarAxiosArgs = await GroupsApi_registerGroupParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_shareGroup$Response = void;

/**
  * Share a group with a namespace
  * @param {string} groupNamespace The namespace of the group
  * @param {string} groupName The unique name or id of the group
  * @param {GroupSharingRequest} groupSharingRequest Namespace and list of permissions to share with. Sharing is recursive, it is applied to all reachable subgroups and arrays of the group. An empty list of permissions will remove the namespace; if permissions already exist they will be deleted then new ones added. In the event of a failure, the new policies will be rolled back to prevent partial policies, and it\&#39;s likely the group will not be shared with the namespace at all.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_shareGroup(data: GroupsApi_shareGroup$Params, options?: any): Promise<AxiosResponse<GroupsApi_shareGroup$Response>> {
    const localVarAxiosArgs = await GroupsApi_shareGroupParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_updateGroup$Response = void;

/**
  * Changes attributes of the group
  * @param {string} groupNamespace The namespace of the group
  * @param {string} groupName The unique name or id of the group
  * @param {GroupUpdate} [groupUpdate] 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_updateGroup(data: GroupsApi_updateGroup$Params, options?: any): Promise<AxiosResponse<GroupsApi_updateGroup$Response>> {
    const localVarAxiosArgs = await GroupsApi_updateGroupParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type GroupsApi_updateGroupContents$Response = void;

/**
  * Change the contents of the group
  * @param {string} groupNamespace The namespace of the group
  * @param {string} groupName The unique name or id of the group
  * @param {GroupContentsChangesRequest} groupUpdateContents 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function GroupsApi_updateGroupContents(data: GroupsApi_updateGroupContents$Params, options?: any): Promise<AxiosResponse<GroupsApi_updateGroupContents$Response>> {
    const localVarAxiosArgs = await GroupsApi_updateGroupContentsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface IngestionApi_ingestBioImaging$Params {
    namespace: string,
    request: BioImagingIngestionRequest,
}

export const IngestionApi_ingestBioImaging$Path = '/ingest/{namespace}/bioimaging';

/**
 * Start this ingestion job.
 * @param {string} namespace Namespace to execute the wrapper UDF
 * @param {BioImagingIngestionRequest} request the data to ingest
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const IngestionApi_ingestBioImagingParamCreator = async (data: IngestionApi_ingestBioImaging$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling ingestBioImaging.');
}
// verify required parameter 'request' is not null or undefined
if (data['request'] === null || data['request'] === undefined) {
    throw new RequiredError('request','Required parameter request was null or undefined when calling ingestBioImaging.');
}
const localVarPath = `/ingest/{namespace}/bioimaging`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['request'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['request'] !== undefined ? data['request'] : {}) : (data['request'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface IngestionApi_ingestFile$Params {
    namespace: string,
    request: FileIngestionRequest,
}

export const IngestionApi_ingestFile$Path = '/ingest/{namespace}/file';

/**
 * Start this ingestion job.
 * @param {string} namespace Namespace to execute the wrapper UDF
 * @param {FileIngestionRequest} request the data to ingest
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const IngestionApi_ingestFileParamCreator = async (data: IngestionApi_ingestFile$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling ingestFile.');
}
// verify required parameter 'request' is not null or undefined
if (data['request'] === null || data['request'] === undefined) {
    throw new RequiredError('request','Required parameter request was null or undefined when calling ingestFile.');
}
const localVarPath = `/ingest/{namespace}/file`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['request'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['request'] !== undefined ? data['request'] : {}) : (data['request'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface IngestionApi_ingestPointcloud$Params {
    namespace: string,
    request: PointCloudIngestionRequest,
}

export const IngestionApi_ingestPointcloud$Path = '/ingest/{namespace}/pointcloud';

/**
 * Start this ingestion job.
 * @param {string} namespace Namespace to execute the wrapper UDF
 * @param {PointCloudIngestionRequest} request the data to ingest
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const IngestionApi_ingestPointcloudParamCreator = async (data: IngestionApi_ingestPointcloud$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling ingestPointcloud.');
}
// verify required parameter 'request' is not null or undefined
if (data['request'] === null || data['request'] === undefined) {
    throw new RequiredError('request','Required parameter request was null or undefined when calling ingestPointcloud.');
}
const localVarPath = `/ingest/{namespace}/pointcloud`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['request'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['request'] !== undefined ? data['request'] : {}) : (data['request'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface IngestionApi_ingestVCF$Params {
    namespace: string,
    request: VCFIngestionRequest,
}

export const IngestionApi_ingestVCF$Path = '/ingest/{namespace}/vcf';

/**
 * Start this ingestion job.
 * @param {string} namespace Namespace to execute the wrapper UDF
 * @param {VCFIngestionRequest} request the data to ingest
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const IngestionApi_ingestVCFParamCreator = async (data: IngestionApi_ingestVCF$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling ingestVCF.');
}
// verify required parameter 'request' is not null or undefined
if (data['request'] === null || data['request'] === undefined) {
    throw new RequiredError('request','Required parameter request was null or undefined when calling ingestVCF.');
}
const localVarPath = `/ingest/{namespace}/vcf`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['request'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['request'] !== undefined ? data['request'] : {}) : (data['request'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type IngestionApi_ingestBioImaging$Response = GraphStartedResponse;

/**
  * Start this ingestion job.
  * @param {string} namespace Namespace to execute the wrapper UDF
  * @param {BioImagingIngestionRequest} request the data to ingest
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function IngestionApi_ingestBioImaging(data: IngestionApi_ingestBioImaging$Params, options?: any): Promise<AxiosResponse<IngestionApi_ingestBioImaging$Response>> {
    const localVarAxiosArgs = await IngestionApi_ingestBioImagingParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type IngestionApi_ingestFile$Response = GraphStartedResponse;

/**
  * Start this ingestion job.
  * @param {string} namespace Namespace to execute the wrapper UDF
  * @param {FileIngestionRequest} request the data to ingest
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function IngestionApi_ingestFile(data: IngestionApi_ingestFile$Params, options?: any): Promise<AxiosResponse<IngestionApi_ingestFile$Response>> {
    const localVarAxiosArgs = await IngestionApi_ingestFileParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type IngestionApi_ingestPointcloud$Response = GraphStartedResponse;

/**
  * Start this ingestion job.
  * @param {string} namespace Namespace to execute the wrapper UDF
  * @param {PointCloudIngestionRequest} request the data to ingest
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function IngestionApi_ingestPointcloud(data: IngestionApi_ingestPointcloud$Params, options?: any): Promise<AxiosResponse<IngestionApi_ingestPointcloud$Response>> {
    const localVarAxiosArgs = await IngestionApi_ingestPointcloudParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type IngestionApi_ingestVCF$Response = GraphStartedResponse;

/**
  * Start this ingestion job.
  * @param {string} namespace Namespace to execute the wrapper UDF
  * @param {VCFIngestionRequest} request the data to ingest
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function IngestionApi_ingestVCF(data: IngestionApi_ingestVCF$Params, options?: any): Promise<AxiosResponse<IngestionApi_ingestVCF$Response>> {
    const localVarAxiosArgs = await IngestionApi_ingestVCFParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface InvitationApi_acceptInvitation$Params {
    invitation: string,
}

export const InvitationApi_acceptInvitation$Path = '/invitations/{invitation}';

/**
 * Accepts invitation
 * @param {string} invitation the id of invitation about to be accepted
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const InvitationApi_acceptInvitationParamCreator = async (data: InvitationApi_acceptInvitation$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'invitation' is not null or undefined
if (data['invitation'] === null || data['invitation'] === undefined) {
    throw new RequiredError('invitation','Required parameter invitation was null or undefined when calling acceptInvitation.');
}
const localVarPath = `/invitations/{invitation}`
    .replace(`{${"invitation"}}`, encodeURIComponent(String(data['invitation'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface InvitationApi_cancelJoinOrganization$Params {
    invitation: string,
    organization: string,
}

export const InvitationApi_cancelJoinOrganization$Path = '/invitations/{invitation}/{organization}/join';

/**
 * Cancels join organization invitation
 * @param {string} invitation the id of invitation about to be cancelled
 * @param {string} organization name or uuid of organization
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const InvitationApi_cancelJoinOrganizationParamCreator = async (data: InvitationApi_cancelJoinOrganization$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'invitation' is not null or undefined
if (data['invitation'] === null || data['invitation'] === undefined) {
    throw new RequiredError('invitation','Required parameter invitation was null or undefined when calling cancelJoinOrganization.');
}
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling cancelJoinOrganization.');
}
const localVarPath = `/invitations/{invitation}/{organization}/join`
    .replace(`{${"invitation"}}`, encodeURIComponent(String(data['invitation'])))
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface InvitationApi_cancelShareArray$Params {
    namespace: string,
    invitation: string,
    array: string,
}

export const InvitationApi_cancelShareArray$Path = '/invitations/{invitation}/{namespace}/{array}/share';

/**
 * Cancels array sharing invitation
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} invitation the id of invitation about to be cancelled
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const InvitationApi_cancelShareArrayParamCreator = async (data: InvitationApi_cancelShareArray$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling cancelShareArray.');
}
// verify required parameter 'invitation' is not null or undefined
if (data['invitation'] === null || data['invitation'] === undefined) {
    throw new RequiredError('invitation','Required parameter invitation was null or undefined when calling cancelShareArray.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling cancelShareArray.');
}
const localVarPath = `/invitations/{invitation}/{namespace}/{array}/share`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"invitation"}}`, encodeURIComponent(String(data['invitation'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface InvitationApi_cancelShareGroupByInvite$Params {
    namespace: string,
    invitation: string,
    groupName: string,
}

export const InvitationApi_cancelShareGroupByInvite$Path = '/invitations/group/{invitation}/{namespace}/{group_name}/share';

/**
 * Cancels group sharing invitation
 * @param {string} namespace namespace group is in (an organization name or user\&#39;s username)
 * @param {string} invitation the ID of invitation about to be cancelled
 * @param {string} groupName name/uuid of group that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const InvitationApi_cancelShareGroupByInviteParamCreator = async (data: InvitationApi_cancelShareGroupByInvite$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling cancelShareGroupByInvite.');
}
// verify required parameter 'invitation' is not null or undefined
if (data['invitation'] === null || data['invitation'] === undefined) {
    throw new RequiredError('invitation','Required parameter invitation was null or undefined when calling cancelShareGroupByInvite.');
}
// verify required parameter 'groupName' is not null or undefined
if (data['groupName'] === null || data['groupName'] === undefined) {
    throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling cancelShareGroupByInvite.');
}
const localVarPath = `/invitations/group/{invitation}/{namespace}/{group_name}/share`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"invitation"}}`, encodeURIComponent(String(data['invitation'])))
    .replace(`{${"group_name"}}`, encodeURIComponent(String(data['groupName'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface InvitationApi_fetchInvitations$Params {
    type: string,
    organization?: string,
    array?: string,
    group?: string,
    start?: number,
    end?: number,
    page?: number,
    perPage?: number,
    status?: string,
    orderby?: string,
}

export const InvitationApi_fetchInvitations$Path = '/invitations';

/**
 * Fetch a list of invitations
 * @param {string} type invitation type, \&quot;ARRAY_SHARE\&quot;, \&quot;JOIN_ORGANIZATION\&quot;
 * @param {string} [organization] name or id of organization to filter
 * @param {string} [array] name/uri of array that is url-encoded to filter
 * @param {string} [group] name or ID of group to filter
 * @param {number} [start] start time for tasks to filter by
 * @param {number} [end] end time for tasks to filter by
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {string} [status] Filter to only return \&quot;PENDING\&quot;, \&quot;ACCEPTED\&quot;
 * @param {string} [orderby] sort by which field valid values include timestamp, array_name, organization_name
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const InvitationApi_fetchInvitationsParamCreator = async (data: InvitationApi_fetchInvitations$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'type' is not null or undefined
if (data['type'] === null || data['type'] === undefined) {
    throw new RequiredError('type','Required parameter type was null or undefined when calling fetchInvitations.');
}
const localVarPath = `/invitations`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['organization'] !== undefined) {
    localVarQueryParameter['organization'] = data['organization'];
}
if (data['array'] !== undefined) {
    localVarQueryParameter['array'] = data['array'];
}
if (data['group'] !== undefined) {
    localVarQueryParameter['group'] = data['group'];
}
if (data['start'] !== undefined) {
    localVarQueryParameter['start'] = data['start'];
}
if (data['end'] !== undefined) {
    localVarQueryParameter['end'] = data['end'];
}
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['type'] !== undefined) {
    localVarQueryParameter['type'] = data['type'];
}
if (data['status'] !== undefined) {
    localVarQueryParameter['status'] = data['status'];
}
if (data['orderby'] !== undefined) {
    localVarQueryParameter['orderby'] = data['orderby'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface InvitationApi_getInvitationDetails$Params {
    invitation: string,
    namespace: string,
}

export const InvitationApi_getInvitationDetails$Path = '/invitations/{invitation}/{namespace}';

/**
 * Gets invitation details of an accepted invitation
 * @param {string} invitation the id of invitation about to be accepted
 * @param {string} namespace the namespace that is trying to access the invitation
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const InvitationApi_getInvitationDetailsParamCreator = async (data: InvitationApi_getInvitationDetails$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'invitation' is not null or undefined
if (data['invitation'] === null || data['invitation'] === undefined) {
    throw new RequiredError('invitation','Required parameter invitation was null or undefined when calling getInvitationDetails.');
}
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getInvitationDetails.');
}
const localVarPath = `/invitations/{invitation}/{namespace}`
    .replace(`{${"invitation"}}`, encodeURIComponent(String(data['invitation'])))
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface InvitationApi_sendArrayInvitations$Params {
    namespace: string,
    array: string,
    emailInvite: InvitationArrayShareEmail,
}

export const InvitationApi_sendArrayInvitations$Path = '/invitations/{namespace}/{array}/share';

/**
 * Sends email to multiple recipients with sharing information regarding an array
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {InvitationArrayShareEmail} emailInvite list of email recipients
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const InvitationApi_sendArrayInvitationsParamCreator = async (data: InvitationApi_sendArrayInvitations$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling sendArrayInvitations.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling sendArrayInvitations.');
}
// verify required parameter 'emailInvite' is not null or undefined
if (data['emailInvite'] === null || data['emailInvite'] === undefined) {
    throw new RequiredError('emailInvite','Required parameter emailInvite was null or undefined when calling sendArrayInvitations.');
}
const localVarPath = `/invitations/{namespace}/{array}/share`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['emailInvite'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['emailInvite'] !== undefined ? data['emailInvite'] : {}) : (data['emailInvite'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface InvitationApi_sendOrganizationInvitations$Params {
    organization: string,
    emailInvite: InvitationOrganizationJoinEmail,
}

export const InvitationApi_sendOrganizationInvitations$Path = '/invitations/{organization}/join';

/**
 * Sends email to multiple recipients with joining information regarding an organization
 * @param {string} organization name or uuid of organization
 * @param {InvitationOrganizationJoinEmail} emailInvite list of email recipients
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const InvitationApi_sendOrganizationInvitationsParamCreator = async (data: InvitationApi_sendOrganizationInvitations$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling sendOrganizationInvitations.');
}
// verify required parameter 'emailInvite' is not null or undefined
if (data['emailInvite'] === null || data['emailInvite'] === undefined) {
    throw new RequiredError('emailInvite','Required parameter emailInvite was null or undefined when calling sendOrganizationInvitations.');
}
const localVarPath = `/invitations/{organization}/join`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['emailInvite'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['emailInvite'] !== undefined ? data['emailInvite'] : {}) : (data['emailInvite'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface InvitationApi_shareGroupByInvite$Params {
    namespace: string,
    group: string,
    emailInvite: InvitationGroupShareEmail,
}

export const InvitationApi_shareGroupByInvite$Path = '/invitations/group/{namespace}/{group}/share';

/**
 * Sends email to multiple recipients with sharing information regarding a group
 * @param {string} namespace namespace group is in (an organization name or user\&#39;s username)
 * @param {string} group name/uri of group that is url-encoded
 * @param {InvitationGroupShareEmail} emailInvite list of email/namespace recipients
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const InvitationApi_shareGroupByInviteParamCreator = async (data: InvitationApi_shareGroupByInvite$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling shareGroupByInvite.');
}
// verify required parameter 'group' is not null or undefined
if (data['group'] === null || data['group'] === undefined) {
    throw new RequiredError('group','Required parameter group was null or undefined when calling shareGroupByInvite.');
}
// verify required parameter 'emailInvite' is not null or undefined
if (data['emailInvite'] === null || data['emailInvite'] === undefined) {
    throw new RequiredError('emailInvite','Required parameter emailInvite was null or undefined when calling shareGroupByInvite.');
}
const localVarPath = `/invitations/group/{namespace}/{group}/share`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"group"}}`, encodeURIComponent(String(data['group'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['emailInvite'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['emailInvite'] !== undefined ? data['emailInvite'] : {}) : (data['emailInvite'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type InvitationApi_acceptInvitation$Response = void;

/**
  * Accepts invitation
  * @param {string} invitation the id of invitation about to be accepted
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function InvitationApi_acceptInvitation(data: InvitationApi_acceptInvitation$Params, options?: any): Promise<AxiosResponse<InvitationApi_acceptInvitation$Response>> {
    const localVarAxiosArgs = await InvitationApi_acceptInvitationParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type InvitationApi_cancelJoinOrganization$Response = void;

/**
  * Cancels join organization invitation
  * @param {string} invitation the id of invitation about to be cancelled
  * @param {string} organization name or uuid of organization
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function InvitationApi_cancelJoinOrganization(data: InvitationApi_cancelJoinOrganization$Params, options?: any): Promise<AxiosResponse<InvitationApi_cancelJoinOrganization$Response>> {
    const localVarAxiosArgs = await InvitationApi_cancelJoinOrganizationParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type InvitationApi_cancelShareArray$Response = void;

/**
  * Cancels array sharing invitation
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} invitation the id of invitation about to be cancelled
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function InvitationApi_cancelShareArray(data: InvitationApi_cancelShareArray$Params, options?: any): Promise<AxiosResponse<InvitationApi_cancelShareArray$Response>> {
    const localVarAxiosArgs = await InvitationApi_cancelShareArrayParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type InvitationApi_cancelShareGroupByInvite$Response = void;

/**
  * Cancels group sharing invitation
  * @param {string} namespace namespace group is in (an organization name or user\&#39;s username)
  * @param {string} invitation the ID of invitation about to be cancelled
  * @param {string} groupName name/uuid of group that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function InvitationApi_cancelShareGroupByInvite(data: InvitationApi_cancelShareGroupByInvite$Params, options?: any): Promise<AxiosResponse<InvitationApi_cancelShareGroupByInvite$Response>> {
    const localVarAxiosArgs = await InvitationApi_cancelShareGroupByInviteParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type InvitationApi_fetchInvitations$Response = InvitationData;

/**
  * Fetch a list of invitations
  * @param {string} type invitation type, \&quot;ARRAY_SHARE\&quot;, \&quot;JOIN_ORGANIZATION\&quot;
  * @param {string} [organization] name or id of organization to filter
  * @param {string} [array] name/uri of array that is url-encoded to filter
  * @param {string} [group] name or ID of group to filter
  * @param {number} [start] start time for tasks to filter by
  * @param {number} [end] end time for tasks to filter by
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {string} [status] Filter to only return \&quot;PENDING\&quot;, \&quot;ACCEPTED\&quot;
  * @param {string} [orderby] sort by which field valid values include timestamp, array_name, organization_name
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function InvitationApi_fetchInvitations(data: InvitationApi_fetchInvitations$Params, options?: any): Promise<AxiosResponse<InvitationApi_fetchInvitations$Response>> {
    const localVarAxiosArgs = await InvitationApi_fetchInvitationsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type InvitationApi_getInvitationDetails$Response = InvitationDetails;

/**
  * Gets invitation details of an accepted invitation
  * @param {string} invitation the id of invitation about to be accepted
  * @param {string} namespace the namespace that is trying to access the invitation
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function InvitationApi_getInvitationDetails(data: InvitationApi_getInvitationDetails$Params, options?: any): Promise<AxiosResponse<InvitationApi_getInvitationDetails$Response>> {
    const localVarAxiosArgs = await InvitationApi_getInvitationDetailsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type InvitationApi_sendArrayInvitations$Response = void;

/**
  * Sends email to multiple recipients with sharing information regarding an array
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {InvitationArrayShareEmail} emailInvite list of email recipients
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function InvitationApi_sendArrayInvitations(data: InvitationApi_sendArrayInvitations$Params, options?: any): Promise<AxiosResponse<InvitationApi_sendArrayInvitations$Response>> {
    const localVarAxiosArgs = await InvitationApi_sendArrayInvitationsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type InvitationApi_sendOrganizationInvitations$Response = void;

/**
  * Sends email to multiple recipients with joining information regarding an organization
  * @param {string} organization name or uuid of organization
  * @param {InvitationOrganizationJoinEmail} emailInvite list of email recipients
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function InvitationApi_sendOrganizationInvitations(data: InvitationApi_sendOrganizationInvitations$Params, options?: any): Promise<AxiosResponse<InvitationApi_sendOrganizationInvitations$Response>> {
    const localVarAxiosArgs = await InvitationApi_sendOrganizationInvitationsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type InvitationApi_shareGroupByInvite$Response = void;

/**
  * Sends email to multiple recipients with sharing information regarding a group
  * @param {string} namespace namespace group is in (an organization name or user\&#39;s username)
  * @param {string} group name/uri of group that is url-encoded
  * @param {InvitationGroupShareEmail} emailInvite list of email/namespace recipients
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function InvitationApi_shareGroupByInvite(data: InvitationApi_shareGroupByInvite$Params, options?: any): Promise<AxiosResponse<InvitationApi_shareGroupByInvite$Response>> {
    const localVarAxiosArgs = await InvitationApi_shareGroupByInviteParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface InvoiceApi_getCurrentInvoice$Params {
    namespace: string,
}

export const InvoiceApi_getCurrentInvoice$Path = '/invoices/current/{namespace}';

/**
 * 
 * @param {string} namespace namespace
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const InvoiceApi_getCurrentInvoiceParamCreator = async (data: InvoiceApi_getCurrentInvoice$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getCurrentInvoice.');
}
const localVarPath = `/invoices/current/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface InvoiceApi_getPastInvoices$Params {
    namespace: string,
    start?: number,
    end?: number,
}

export const InvoiceApi_getPastInvoices$Path = '/invoices/past/{namespace}';

/**
 * 
 * @param {string} namespace namespace
 * @param {number} [start] Start time of window of fetch logs, unix epoch in seconds (default: a month ago)
 * @param {number} [end] End time of window of fetch logs, unix epoch in seconds (default: current utc timestamp)
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const InvoiceApi_getPastInvoicesParamCreator = async (data: InvoiceApi_getPastInvoices$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getPastInvoices.');
}
const localVarPath = `/invoices/past/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['start'] !== undefined) {
    localVarQueryParameter['start'] = data['start'];
}
if (data['end'] !== undefined) {
    localVarQueryParameter['end'] = data['end'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface InvoiceApi_payInvoice$Params {
    namespace: string,
    invoice: string,
}

export const InvoiceApi_payInvoice$Path = '/invoices/payment/{namespace}/{invoice}';

/**
 * Manual payment of an open invoice
 * @param {string} namespace namespace
 * @param {string} invoice invoice id to pay
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const InvoiceApi_payInvoiceParamCreator = async (data: InvoiceApi_payInvoice$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling payInvoice.');
}
// verify required parameter 'invoice' is not null or undefined
if (data['invoice'] === null || data['invoice'] === undefined) {
    throw new RequiredError('invoice','Required parameter invoice was null or undefined when calling payInvoice.');
}
const localVarPath = `/invoices/payment/{namespace}/{invoice}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"invoice"}}`, encodeURIComponent(String(data['invoice'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type InvoiceApi_getCurrentInvoice$Response = Invoice;

/**
  * 
  * @param {string} namespace namespace
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function InvoiceApi_getCurrentInvoice(data: InvoiceApi_getCurrentInvoice$Params, options?: any): Promise<AxiosResponse<InvoiceApi_getCurrentInvoice$Response>> {
    const localVarAxiosArgs = await InvoiceApi_getCurrentInvoiceParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type InvoiceApi_getPastInvoices$Response = Array<Invoice>;

/**
  * 
  * @param {string} namespace namespace
  * @param {number} [start] Start time of window of fetch logs, unix epoch in seconds (default: a month ago)
  * @param {number} [end] End time of window of fetch logs, unix epoch in seconds (default: current utc timestamp)
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function InvoiceApi_getPastInvoices(data: InvoiceApi_getPastInvoices$Params, options?: any): Promise<AxiosResponse<InvoiceApi_getPastInvoices$Response>> {
    const localVarAxiosArgs = await InvoiceApi_getPastInvoicesParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type InvoiceApi_payInvoice$Response = void;

/**
  * Manual payment of an open invoice
  * @param {string} namespace namespace
  * @param {string} invoice invoice id to pay
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function InvoiceApi_payInvoice(data: InvoiceApi_payInvoice$Params, options?: any): Promise<AxiosResponse<InvoiceApi_payInvoice$Response>> {
    const localVarAxiosArgs = await InvoiceApi_payInvoiceParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface MlModelApi_getMLModelPreview$Params {
    namespace: string,
    array: string,
    endTimestamp?: number,
}

export const MlModelApi_getMLModelPreview$Path = '/ml_models/{namespace}/{array}/preview';

/**
 * retrieve the preview of the model
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {number} [endTimestamp] UTC timestamp in milliseconds, will make the preview use open_at functionality to open ml model at an older timestamp
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const MlModelApi_getMLModelPreviewParamCreator = async (data: MlModelApi_getMLModelPreview$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getMLModelPreview.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling getMLModelPreview.');
}
const localVarPath = `/ml_models/{namespace}/{array}/preview`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['endTimestamp'] !== undefined) {
    localVarQueryParameter['end_timestamp'] = data['endTimestamp'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type MlModelApi_getMLModelPreview$Response = Preview;

/**
  * retrieve the preview of the model
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {number} [endTimestamp] UTC timestamp in milliseconds, will make the preview use open_at functionality to open ml model at an older timestamp
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function MlModelApi_getMLModelPreview(data: MlModelApi_getMLModelPreview$Params, options?: any): Promise<AxiosResponse<MlModelApi_getMLModelPreview$Response>> {
    const localVarAxiosArgs = await MlModelApi_getMLModelPreviewParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface NotebookApi_getNotebookServerStatus$Params {
    namespace: string,
}

export const NotebookApi_getNotebookServerStatus$Path = '/notebooks/server/{namespace}/status';

/**
 * Get status of the notebook server
 * @param {string} namespace namespace notebook is in (an organization name or user\&#39;s username)
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const NotebookApi_getNotebookServerStatusParamCreator = async (data: NotebookApi_getNotebookServerStatus$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getNotebookServerStatus.');
}
const localVarPath = `/notebooks/server/{namespace}/status`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface NotebookApi_handleUploadNotebook$Params {
    namespace: string,
    fileSize: number,
    inputFile: any,
    credentialsName?: string,
    outputUri?: string,
    name?: string,
}

export const NotebookApi_handleUploadNotebook$Path = '/notebooks/{namespace}/upload';

/**
 * Upload a notebook at the specified location
 * @param {string} namespace The namespace of the notebook
 * @param {number} fileSize size of the file to be uploaded
 * @param {any} inputFile the notebook to upload
 * @param {string} [credentialsName] Optional registered access credentials to use for creation
 * @param {string} [outputUri] output location of the TileDB File
 * @param {string} [name] name to set for registered file
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const NotebookApi_handleUploadNotebookParamCreator = async (data: NotebookApi_handleUploadNotebook$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling handleUploadNotebook.');
}
// verify required parameter 'fileSize' is not null or undefined
if (data['fileSize'] === null || data['fileSize'] === undefined) {
    throw new RequiredError('fileSize','Required parameter fileSize was null or undefined when calling handleUploadNotebook.');
}
// verify required parameter 'inputFile' is not null or undefined
if (data['inputFile'] === null || data['inputFile'] === undefined) {
    throw new RequiredError('inputFile','Required parameter inputFile was null or undefined when calling handleUploadNotebook.');
}
const localVarPath = `/notebooks/{namespace}/upload`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
const localVarFormParams = new FormData();
if (data['credentialsName'] !== undefined) {
    localVarQueryParameter['credentials_name'] = data['credentialsName'];
}
if (data['fileSize'] !== undefined) {
    localVarQueryParameter['file_size'] = data['fileSize'];
}
if (data['outputUri'] !== undefined) {
    localVarQueryParameter['output_uri'] = data['outputUri'];
}
if (data['name'] !== undefined) {
    localVarQueryParameter['name'] = data['name'];
}

    if (data['inputFile'] !== undefined) { 
        localVarFormParams.append('input_file', data['inputFile'] as any);
    }


    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    localVarRequestOptions.data = localVarFormParams;
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface NotebookApi_shutdownNotebookServer$Params {
    namespace: string,
    region?: string,
}

export const NotebookApi_shutdownNotebookServer$Path = '/notebooks/server/{namespace}';

/**
 * Shut down a notebook server
 * @param {string} namespace namespace notebook is in (an organization name or user\&#39;s username)
 * @param {string} [region] The AWS region where notebook POD is running
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const NotebookApi_shutdownNotebookServerParamCreator = async (data: NotebookApi_shutdownNotebookServer$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling shutdownNotebookServer.');
}
const localVarPath = `/notebooks/server/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['region'] !== undefined) {
    localVarQueryParameter['region'] = data['region'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type NotebookApi_getNotebookServerStatus$Response = Array<NotebookStatus>;

/**
  * Get status of the notebook server
  * @param {string} namespace namespace notebook is in (an organization name or user\&#39;s username)
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function NotebookApi_getNotebookServerStatus(data: NotebookApi_getNotebookServerStatus$Params, options?: any): Promise<AxiosResponse<NotebookApi_getNotebookServerStatus$Response>> {
    const localVarAxiosArgs = await NotebookApi_getNotebookServerStatusParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type NotebookApi_handleUploadNotebook$Response = NotebookUploaded;

/**
  * Upload a notebook at the specified location
  * @param {string} namespace The namespace of the notebook
  * @param {number} fileSize size of the file to be uploaded
  * @param {any} inputFile the notebook to upload
  * @param {string} [credentialsName] Optional registered access credentials to use for creation
  * @param {string} [outputUri] output location of the TileDB File
  * @param {string} [name] name to set for registered file
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function NotebookApi_handleUploadNotebook(data: NotebookApi_handleUploadNotebook$Params, options?: any): Promise<AxiosResponse<NotebookApi_handleUploadNotebook$Response>> {
    const localVarAxiosArgs = await NotebookApi_handleUploadNotebookParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type NotebookApi_shutdownNotebookServer$Response = void;

/**
  * Shut down a notebook server
  * @param {string} namespace namespace notebook is in (an organization name or user\&#39;s username)
  * @param {string} [region] The AWS region where notebook POD is running
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function NotebookApi_shutdownNotebookServer(data: NotebookApi_shutdownNotebookServer$Params, options?: any): Promise<AxiosResponse<NotebookApi_shutdownNotebookServer$Response>> {
    const localVarAxiosArgs = await NotebookApi_shutdownNotebookServerParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface NotebooksApi_notebooksNamespaceArrayDownloadGet$Params {
    namespace: string,
    array: string,
    endTimestamp?: number,
}

export const NotebooksApi_notebooksNamespaceArrayDownloadGet$Path = '/notebooks/{namespace}/{array}/download';

/**
 * retrieve the raw exec value and language
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {number} [endTimestamp] UTC timestamp in milliseconds, will make the preview use open_at functionality to open notebook at an older timestamp
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const NotebooksApi_notebooksNamespaceArrayDownloadGetParamCreator = async (data: NotebooksApi_notebooksNamespaceArrayDownloadGet$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling notebooksNamespaceArrayDownloadGet.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling notebooksNamespaceArrayDownloadGet.');
}
const localVarPath = `/notebooks/{namespace}/{array}/download`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['endTimestamp'] !== undefined) {
    localVarQueryParameter['end_timestamp'] = data['endTimestamp'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface NotebooksApi_notebooksNamespaceArrayEndTimestampsGet$Params {
    namespace: string,
    array: string,
    page?: number,
    perPage?: number,
}

export const NotebooksApi_notebooksNamespaceArrayEndTimestampsGet$Path = '/notebooks/{namespace}/{array}/end_timestamps';

/**
 * retrieve a list of timestamps from the array fragment info listing in milliseconds
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const NotebooksApi_notebooksNamespaceArrayEndTimestampsGetParamCreator = async (data: NotebooksApi_notebooksNamespaceArrayEndTimestampsGet$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling notebooksNamespaceArrayEndTimestampsGet.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling notebooksNamespaceArrayEndTimestampsGet.');
}
const localVarPath = `/notebooks/{namespace}/{array}/end_timestamps`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface NotebooksApi_notebooksNamespaceArrayPreviewGet$Params {
    namespace: string,
    array: string,
    endTimestamp?: number,
}

export const NotebooksApi_notebooksNamespaceArrayPreviewGet$Path = '/notebooks/{namespace}/{array}/preview';

/**
 * retrieve the raw exec value and language
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {number} [endTimestamp] UTC timestamp in milliseconds, will make the preview use open_at functionality to open notebook at an older timestamp
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const NotebooksApi_notebooksNamespaceArrayPreviewGetParamCreator = async (data: NotebooksApi_notebooksNamespaceArrayPreviewGet$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling notebooksNamespaceArrayPreviewGet.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling notebooksNamespaceArrayPreviewGet.');
}
const localVarPath = `/notebooks/{namespace}/{array}/preview`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['endTimestamp'] !== undefined) {
    localVarQueryParameter['end_timestamp'] = data['endTimestamp'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface NotebooksApi_notebooksNamespaceArrayPrunePost$Params {
    namespace: string,
    array: string,
    keepVersions?: number,
}

export const NotebooksApi_notebooksNamespaceArrayPrunePost$Path = '/notebooks/{namespace}/{array}/prune';

/**
 * prune fragments of the notebook
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {number} [keepVersions] The number of most recents fragment to preserve
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const NotebooksApi_notebooksNamespaceArrayPrunePostParamCreator = async (data: NotebooksApi_notebooksNamespaceArrayPrunePost$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling notebooksNamespaceArrayPrunePost.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling notebooksNamespaceArrayPrunePost.');
}
const localVarPath = `/notebooks/{namespace}/{array}/prune`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['keepVersions'] !== undefined) {
    localVarQueryParameter['keep_versions'] = data['keepVersions'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type NotebooksApi_notebooksNamespaceArrayDownloadGet$Response = Preview;

/**
  * retrieve the raw exec value and language
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {number} [endTimestamp] UTC timestamp in milliseconds, will make the preview use open_at functionality to open notebook at an older timestamp
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function NotebooksApi_notebooksNamespaceArrayDownloadGet(data: NotebooksApi_notebooksNamespaceArrayDownloadGet$Params, options?: any): Promise<AxiosResponse<NotebooksApi_notebooksNamespaceArrayDownloadGet$Response>> {
    const localVarAxiosArgs = await NotebooksApi_notebooksNamespaceArrayDownloadGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type NotebooksApi_notebooksNamespaceArrayEndTimestampsGet$Response = ArrayEndTimetsampsData;

/**
  * retrieve a list of timestamps from the array fragment info listing in milliseconds
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function NotebooksApi_notebooksNamespaceArrayEndTimestampsGet(data: NotebooksApi_notebooksNamespaceArrayEndTimestampsGet$Params, options?: any): Promise<AxiosResponse<NotebooksApi_notebooksNamespaceArrayEndTimestampsGet$Response>> {
    const localVarAxiosArgs = await NotebooksApi_notebooksNamespaceArrayEndTimestampsGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type NotebooksApi_notebooksNamespaceArrayPreviewGet$Response = Preview;

/**
  * retrieve the raw exec value and language
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {number} [endTimestamp] UTC timestamp in milliseconds, will make the preview use open_at functionality to open notebook at an older timestamp
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function NotebooksApi_notebooksNamespaceArrayPreviewGet(data: NotebooksApi_notebooksNamespaceArrayPreviewGet$Params, options?: any): Promise<AxiosResponse<NotebooksApi_notebooksNamespaceArrayPreviewGet$Response>> {
    const localVarAxiosArgs = await NotebooksApi_notebooksNamespaceArrayPreviewGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type NotebooksApi_notebooksNamespaceArrayPrunePost$Response = void;

/**
  * prune fragments of the notebook
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {number} [keepVersions] The number of most recents fragment to preserve
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function NotebooksApi_notebooksNamespaceArrayPrunePost(data: NotebooksApi_notebooksNamespaceArrayPrunePost$Params, options?: any): Promise<AxiosResponse<NotebooksApi_notebooksNamespaceArrayPrunePost$Response>> {
    const localVarAxiosArgs = await NotebooksApi_notebooksNamespaceArrayPrunePostParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface NotificationsApi_changeNotificationStatus$Params {
    namespace: string,
    statusChange: NotificationStatusChange,
}

export const NotificationsApi_changeNotificationStatus$Path = '/notifications/{namespace}/status';

/**
 * Change status of the given notifications (to read/unread)
 * @param {string} namespace the namespace we want notifications from
 * @param {NotificationStatusChange} statusChange The request body to change the notification status
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const NotificationsApi_changeNotificationStatusParamCreator = async (data: NotificationsApi_changeNotificationStatus$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling changeNotificationStatus.');
}
// verify required parameter 'statusChange' is not null or undefined
if (data['statusChange'] === null || data['statusChange'] === undefined) {
    throw new RequiredError('statusChange','Required parameter statusChange was null or undefined when calling changeNotificationStatus.');
}
const localVarPath = `/notifications/{namespace}/status`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['statusChange'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['statusChange'] !== undefined ? data['statusChange'] : {}) : (data['statusChange'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface NotificationsApi_deleteNotifications$Params {
    namespace: string,
    page?: number,
    perPage?: number,
    status?: string,
    notifications?: Array<string>,
}

export const NotificationsApi_deleteNotifications$Path = '/notifications/{namespace}';

/**
 * Delete the given notifications of a namespace
 * @param {string} namespace the namespace we want notifications from
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {string} [status] the notifications status (read/unread)
 * @param {Array<string>} [notifications] 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const NotificationsApi_deleteNotificationsParamCreator = async (data: NotificationsApi_deleteNotifications$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling deleteNotifications.');
}
const localVarPath = `/notifications/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['status'] !== undefined) {
    localVarQueryParameter['status'] = data['status'];
}


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['notifications'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['notifications'] !== undefined ? data['notifications'] : {}) : (data['notifications'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface NotificationsApi_getNotificationsCount$Params {
    namespace: string,
    status?: string,
}

export const NotificationsApi_getNotificationsCount$Path = '/notifications/{namespace}/count';

/**
 * Count of notifications of a given namespace
 * @param {string} namespace the namespace we want notifications from
 * @param {string} [status] the status of notifications to be counted (read/unread)
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const NotificationsApi_getNotificationsCountParamCreator = async (data: NotificationsApi_getNotificationsCount$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getNotificationsCount.');
}
const localVarPath = `/notifications/{namespace}/count`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['status'] !== undefined) {
    localVarQueryParameter['status'] = data['status'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface NotificationsApi_listNotifications$Params {
    namespace: string,
    page?: number,
    perPage?: number,
    status?: string,
}

export const NotificationsApi_listNotifications$Path = '/notifications/{namespace}';

/**
 * List notifications of a given namespace
 * @param {string} namespace the namespace we want notifications from
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {string} [status] the notifications status (read/unread)
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const NotificationsApi_listNotificationsParamCreator = async (data: NotificationsApi_listNotifications$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling listNotifications.');
}
const localVarPath = `/notifications/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['status'] !== undefined) {
    localVarQueryParameter['status'] = data['status'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface NotificationsApi_markAllNotificationsAsRead$Params {
    namespace: string,
}

export const NotificationsApi_markAllNotificationsAsRead$Path = '/notifications/{namespace}/read_all';

/**
 * Mark all notifications as read
 * @param {string} namespace the namespace that the notifications belong to
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const NotificationsApi_markAllNotificationsAsReadParamCreator = async (data: NotificationsApi_markAllNotificationsAsRead$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling markAllNotificationsAsRead.');
}
const localVarPath = `/notifications/{namespace}/read_all`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type NotificationsApi_changeNotificationStatus$Response = void;

/**
  * Change status of the given notifications (to read/unread)
  * @param {string} namespace the namespace we want notifications from
  * @param {NotificationStatusChange} statusChange The request body to change the notification status
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function NotificationsApi_changeNotificationStatus(data: NotificationsApi_changeNotificationStatus$Params, options?: any): Promise<AxiosResponse<NotificationsApi_changeNotificationStatus$Response>> {
    const localVarAxiosArgs = await NotificationsApi_changeNotificationStatusParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type NotificationsApi_deleteNotifications$Response = void;

/**
  * Delete the given notifications of a namespace
  * @param {string} namespace the namespace we want notifications from
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {string} [status] the notifications status (read/unread)
  * @param {Array<string>} [notifications] 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function NotificationsApi_deleteNotifications(data: NotificationsApi_deleteNotifications$Params, options?: any): Promise<AxiosResponse<NotificationsApi_deleteNotifications$Response>> {
    const localVarAxiosArgs = await NotificationsApi_deleteNotificationsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type NotificationsApi_getNotificationsCount$Response = number;

/**
  * Count of notifications of a given namespace
  * @param {string} namespace the namespace we want notifications from
  * @param {string} [status] the status of notifications to be counted (read/unread)
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function NotificationsApi_getNotificationsCount(data: NotificationsApi_getNotificationsCount$Params, options?: any): Promise<AxiosResponse<NotificationsApi_getNotificationsCount$Response>> {
    const localVarAxiosArgs = await NotificationsApi_getNotificationsCountParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type NotificationsApi_listNotifications$Response = NotificationListResponse;

/**
  * List notifications of a given namespace
  * @param {string} namespace the namespace we want notifications from
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {string} [status] the notifications status (read/unread)
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function NotificationsApi_listNotifications(data: NotificationsApi_listNotifications$Params, options?: any): Promise<AxiosResponse<NotificationsApi_listNotifications$Response>> {
    const localVarAxiosArgs = await NotificationsApi_listNotificationsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type NotificationsApi_markAllNotificationsAsRead$Response = void;

/**
  * Mark all notifications as read
  * @param {string} namespace the namespace that the notifications belong to
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function NotificationsApi_markAllNotificationsAsRead(data: NotificationsApi_markAllNotificationsAsRead$Params, options?: any): Promise<AxiosResponse<NotificationsApi_markAllNotificationsAsRead$Response>> {
    const localVarAxiosArgs = await NotificationsApi_markAllNotificationsAsReadParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface OrganizationApi_addCredential$Params {
    namespace: string,
    accessCredential: AccessCredentialUpdate,
    page?: number,
    perPage?: number,
}

export const OrganizationApi_addCredential$Path = '/credentials/{namespace}';

/**
 * Create a new credential for the namespace
 * @param {string} namespace namespace
 * @param {AccessCredentialUpdate} accessCredential The new credentials to be created.
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_addCredentialParamCreator = async (data: OrganizationApi_addCredential$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling addCredential.');
}
// verify required parameter 'accessCredential' is not null or undefined
if (data['accessCredential'] === null || data['accessCredential'] === undefined) {
    throw new RequiredError('accessCredential','Required parameter accessCredential was null or undefined when calling addCredential.');
}
const localVarPath = `/credentials/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['accessCredential'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['accessCredential'] !== undefined ? data['accessCredential'] : {}) : (data['accessCredential'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_addUserToOrganization$Params {
    organization: string,
    username: string,
    user: CreateOrganizationUserParams,
}

export const OrganizationApi_addUserToOrganization$Path = '/organizations/{organization}/users/{username}';

/**
 * add a user to an organization
 * @param {string} organization organization name
 * @param {string} username username to manipulate
 * @param {CreateOrganizationUserParams} user user details to add
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_addUserToOrganizationParamCreator = async (data: OrganizationApi_addUserToOrganization$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling addUserToOrganization.');
}
// verify required parameter 'username' is not null or undefined
if (data['username'] === null || data['username'] === undefined) {
    throw new RequiredError('username','Required parameter username was null or undefined when calling addUserToOrganization.');
}
// verify required parameter 'user' is not null or undefined
if (data['user'] === null || data['user'] === undefined) {
    throw new RequiredError('user','Required parameter user was null or undefined when calling addUserToOrganization.');
}
const localVarPath = `/organizations/{organization}/users/{username}`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])))
    .replace(`{${"username"}}`, encodeURIComponent(String(data['username'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['user'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['user'] !== undefined ? data['user'] : {}) : (data['user'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_checkSSODomain$Params {
    organization: string,
    uuid: string,
}

export const OrganizationApi_checkSSODomain$Path = '/organizations/{organization}/sso_domains/{uuid}/run_check';

/**
 * Immediately verify ownership of the specified SSO domain ownership claim. 
 * @param {string} organization organization name
 * @param {string} uuid configuration ID
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_checkSSODomainParamCreator = async (data: OrganizationApi_checkSSODomain$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling checkSSODomain.');
}
// verify required parameter 'uuid' is not null or undefined
if (data['uuid'] === null || data['uuid'] === undefined) {
    throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling checkSSODomain.');
}
const localVarPath = `/organizations/{organization}/sso_domains/{uuid}/run_check`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])))
    .replace(`{${"uuid"}}`, encodeURIComponent(String(data['uuid'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_createOrganization$Params {
    organization: string,
    organizationDetails: OrganizationCreateParams,
}

export const OrganizationApi_createOrganization$Path = '/organizations/{organization}';

/**
 * Create an organization
 * @param {string} organization organization name
 * @param {OrganizationCreateParams} organizationDetails organization to create
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_createOrganizationParamCreator = async (data: OrganizationApi_createOrganization$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling createOrganization.');
}
// verify required parameter 'organizationDetails' is not null or undefined
if (data['organizationDetails'] === null || data['organizationDetails'] === undefined) {
    throw new RequiredError('organizationDetails','Required parameter organizationDetails was null or undefined when calling createOrganization.');
}
const localVarPath = `/organizations/{organization}`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['organizationDetails'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['organizationDetails'] !== undefined ? data['organizationDetails'] : {}) : (data['organizationDetails'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_createPricing$Params {
    namespace: string,
    array: string,
    pricingInfo?: PricingInfo,
}

export const OrganizationApi_createPricing$Path = '/payments/pricing/{namespace}/{array}';

/**
 * Create pricing info for a specified URI in a namespace
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {PricingInfo} [pricingInfo] Array pricing details
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_createPricingParamCreator = async (data: OrganizationApi_createPricing$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling createPricing.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling createPricing.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['pricingInfo'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['pricingInfo'] !== undefined ? data['pricingInfo'] : {}) : (data['pricingInfo'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_createSSODomain$Params {
    organization: string,
    config: SSODomainConfig,
}

export const OrganizationApi_createSSODomain$Path = '/organizations/{organization}/sso_domain';

/**
 * Create a new SSO connection that connects this organization to this domain. 
 * @param {string} organization organization name
 * @param {SSODomainConfig} config The SSO connection to create.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_createSSODomainParamCreator = async (data: OrganizationApi_createSSODomain$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling createSSODomain.');
}
// verify required parameter 'config' is not null or undefined
if (data['config'] === null || data['config'] === undefined) {
    throw new RequiredError('config','Required parameter config was null or undefined when calling createSSODomain.');
}
const localVarPath = `/organizations/{organization}/sso_domain`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['config'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['config'] !== undefined ? data['config'] : {}) : (data['config'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_deleteCredential$Params {
    namespace: string,
    name: string,
}

export const OrganizationApi_deleteCredential$Path = '/credentials/{namespace}/{name}';

/**
 * Delete the named access credential. Any arrays still set to use this credential will use the namespace\'s default and may become unreachable.
 * @param {string} namespace namespace
 * @param {string} name A URL-safe version of the credential\&#39;s user-provided name
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_deleteCredentialParamCreator = async (data: OrganizationApi_deleteCredential$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling deleteCredential.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling deleteCredential.');
}
const localVarPath = `/credentials/{namespace}/{name}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_deleteOnboardingItems$Params {
    namespace: string,
    organization?: string,
}

export const OrganizationApi_deleteOnboardingItems$Path = '/onboarding/{namespace}';

/**
 * Deletes completed Onboarding items
 * @param {string} namespace an organization or user\&#39;s uuid
 * @param {string} [organization] Optional organization UUID. This is used for organization onboarding items. Organization onboarding items are unique per-user per-organization. Meaning that the user has different organization onboarding progress for each organization they own. 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_deleteOnboardingItemsParamCreator = async (data: OrganizationApi_deleteOnboardingItems$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling deleteOnboardingItems.');
}
const localVarPath = `/onboarding/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['organization'] !== undefined) {
    localVarQueryParameter['organization'] = data['organization'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_deleteOrganization$Params {
    organization: string,
}

export const OrganizationApi_deleteOrganization$Path = '/organizations/{organization}';

/**
 * Delete organization
 * @param {string} organization organization name
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_deleteOrganizationParamCreator = async (data: OrganizationApi_deleteOrganization$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling deleteOrganization.');
}
const localVarPath = `/organizations/{organization}`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_deletePricing$Params {
    namespace: string,
    array: string,
}

export const OrganizationApi_deletePricing$Path = '/payments/pricing/{namespace}/{array}';

/**
 * Delete pricing info for a specified URI in a namespace
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_deletePricingParamCreator = async (data: OrganizationApi_deletePricing$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling deletePricing.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling deletePricing.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_deleteSSODomain$Params {
    organization: string,
    uuid: string,
}

export const OrganizationApi_deleteSSODomain$Path = '/organizations/{organization}/sso_domains/{uuid}';

/**
 * Deletes the configuration for the given SSO connection.
 * @param {string} organization organization name
 * @param {string} uuid configuration ID
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_deleteSSODomainParamCreator = async (data: OrganizationApi_deleteSSODomain$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling deleteSSODomain.');
}
// verify required parameter 'uuid' is not null or undefined
if (data['uuid'] === null || data['uuid'] === undefined) {
    throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling deleteSSODomain.');
}
const localVarPath = `/organizations/{organization}/sso_domains/{uuid}`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])))
    .replace(`{${"uuid"}}`, encodeURIComponent(String(data['uuid'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_deleteUserFromOrganization$Params {
    organization: string,
    username: string,
}

export const OrganizationApi_deleteUserFromOrganization$Path = '/organizations/{organization}/users/{username}';

/**
 * delete a user from an organization
 * @param {string} organization organization name
 * @param {string} username username to manipulate
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_deleteUserFromOrganizationParamCreator = async (data: OrganizationApi_deleteUserFromOrganization$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling deleteUserFromOrganization.');
}
// verify required parameter 'username' is not null or undefined
if (data['username'] === null || data['username'] === undefined) {
    throw new RequiredError('username','Required parameter username was null or undefined when calling deleteUserFromOrganization.');
}
const localVarPath = `/organizations/{organization}/users/{username}`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])))
    .replace(`{${"username"}}`, encodeURIComponent(String(data['username'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export type OrganizationApi_fetchAllOrganizations$Params = void;

export const OrganizationApi_fetchAllOrganizations$Path = '/organizations';

/**
 * Fetches all organizations that the user is member of
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_fetchAllOrganizationsParamCreator = async (data: OrganizationApi_fetchAllOrganizations$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/organizations`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_getBillingOverview$Params {
    namespace?: string,
}

export const OrganizationApi_getBillingOverview$Path = '/payments/billing-overview';

/**
 * Get billing overview
 * @param {string} [namespace] namespace to filter, defaults to requesting user
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_getBillingOverviewParamCreator = async (data: OrganizationApi_getBillingOverview$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/payments/billing-overview`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_getChargeOperationsAllowed$Params {
    namespace: string,
}

export const OrganizationApi_getChargeOperationsAllowed$Path = '/charges/allowed/{namespace}';

/**
 * Get whether operations which incur charges are allowed
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_getChargeOperationsAllowedParamCreator = async (data: OrganizationApi_getChargeOperationsAllowed$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getChargeOperationsAllowed.');
}
const localVarPath = `/charges/allowed/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_getCredential$Params {
    namespace: string,
    name: string,
}

export const OrganizationApi_getCredential$Path = '/credentials/{namespace}/{name}';

/**
 * Retrieve an access credential by name
 * @param {string} namespace namespace
 * @param {string} name A URL-safe version of the credential\&#39;s user-provided name
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_getCredentialParamCreator = async (data: OrganizationApi_getCredential$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getCredential.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling getCredential.');
}
const localVarPath = `/credentials/{namespace}/{name}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_getNamespaceAutocomplete$Params {
    input: string,
}

export const OrganizationApi_getNamespaceAutocomplete$Path = '/organizations/namespace/autocomplete';

/**
 * Fetch list of usernames and  organization names matching the user\'s partial input
 * @param {string} input partial string entered by the user to engage autocomplete
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_getNamespaceAutocompleteParamCreator = async (data: OrganizationApi_getNamespaceAutocomplete$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'input' is not null or undefined
if (data['input'] === null || data['input'] === undefined) {
    throw new RequiredError('input','Required parameter input was null or undefined when calling getNamespaceAutocomplete.');
}
const localVarPath = `/organizations/namespace/autocomplete`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['input'] !== undefined) {
    localVarQueryParameter['input'] = data['input'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_getOnboardingItems$Params {
    namespace: string,
    organization?: string,
}

export const OrganizationApi_getOnboardingItems$Path = '/onboarding/{namespace}';

/**
 * Get onboarding items
 * @param {string} namespace an organization or user\&#39;s uuid
 * @param {string} [organization] Optional organization UUID. This is used for organization onboarding items. Organization onboarding items are unique per-user per-organization. Meaning that the user has different organization onboarding progress for each organization they own. 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_getOnboardingItemsParamCreator = async (data: OrganizationApi_getOnboardingItems$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getOnboardingItems.');
}
const localVarPath = `/onboarding/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['organization'] !== undefined) {
    localVarQueryParameter['organization'] = data['organization'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_getOnboardingState$Params {
    namespace: string,
    namespaceType: string,
}

export const OrganizationApi_getOnboardingState$Path = '/onboarding/{namespace}/state';

/**
 * Get database state for specific onboarding steps that are not described or saved as onboarding items i.e. The existence of registered assets or the existence of members in an organization etc 
 * @param {string} namespace an organization or user\&#39;s uuid
 * @param {string} namespaceType the namespace type to check (each type makes different requests to REST and returns a different response)
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_getOnboardingStateParamCreator = async (data: OrganizationApi_getOnboardingState$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getOnboardingState.');
}
// verify required parameter 'namespaceType' is not null or undefined
if (data['namespaceType'] === null || data['namespaceType'] === undefined) {
    throw new RequiredError('namespaceType','Required parameter namespaceType was null or undefined when calling getOnboardingState.');
}
const localVarPath = `/onboarding/{namespace}/state`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['namespaceType'] !== undefined) {
    localVarQueryParameter['namespace_type'] = data['namespaceType'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_getOrganization$Params {
    organization: string,
}

export const OrganizationApi_getOrganization$Path = '/organizations/{organization}';

/**
 * Fetch an organization
 * @param {string} organization organization name
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_getOrganizationParamCreator = async (data: OrganizationApi_getOrganization$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling getOrganization.');
}
const localVarPath = `/organizations/{organization}`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_getOrganizationUser$Params {
    organization: string,
    username: string,
}

export const OrganizationApi_getOrganizationUser$Path = '/organizations/{organization}/users/{username}';

/**
 * get a user from an organization
 * @param {string} organization organization name
 * @param {string} username username to manipulate
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_getOrganizationUserParamCreator = async (data: OrganizationApi_getOrganizationUser$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling getOrganizationUser.');
}
// verify required parameter 'username' is not null or undefined
if (data['username'] === null || data['username'] === undefined) {
    throw new RequiredError('username','Required parameter username was null or undefined when calling getOrganizationUser.');
}
const localVarPath = `/organizations/{organization}/users/{username}`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])))
    .replace(`{${"username"}}`, encodeURIComponent(String(data['username'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_getOrganizationsAutocomplete$Params {
    input: string,
}

export const OrganizationApi_getOrganizationsAutocomplete$Path = '/organizations/autocomplete';

/**
 * Fetch list of organization names matching the user\'s partial input
 * @param {string} input partial string entered by the user to engage autocomplete
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_getOrganizationsAutocompleteParamCreator = async (data: OrganizationApi_getOrganizationsAutocomplete$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'input' is not null or undefined
if (data['input'] === null || data['input'] === undefined) {
    throw new RequiredError('input','Required parameter input was null or undefined when calling getOrganizationsAutocomplete.');
}
const localVarPath = `/organizations/autocomplete`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['input'] !== undefined) {
    localVarQueryParameter['input'] = data['input'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_getPaymentDetails$Params {
    namespace: string,
}

export const OrganizationApi_getPaymentDetails$Path = '/payments/{namespace}';

/**
 * Get payment details
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_getPaymentDetailsParamCreator = async (data: OrganizationApi_getPaymentDetails$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getPaymentDetails.');
}
const localVarPath = `/payments/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_getSSODomain$Params {
    organization: string,
    uuid: string,
}

export const OrganizationApi_getSSODomain$Path = '/organizations/{organization}/sso_domains/{uuid}';

/**
 * Gets details about the given SSO domain connection.
 * @param {string} organization organization name
 * @param {string} uuid configuration ID
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_getSSODomainParamCreator = async (data: OrganizationApi_getSSODomain$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling getSSODomain.');
}
// verify required parameter 'uuid' is not null or undefined
if (data['uuid'] === null || data['uuid'] === undefined) {
    throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling getSSODomain.');
}
const localVarPath = `/organizations/{organization}/sso_domains/{uuid}`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])))
    .replace(`{${"uuid"}}`, encodeURIComponent(String(data['uuid'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_getSSODomains$Params {
    organization: string,
}

export const OrganizationApi_getSSODomains$Path = '/organizations/{organization}/sso_domains';

/**
 * Lists all the SSO connections associated with the given organization. 
 * @param {string} organization organization name
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_getSSODomainsParamCreator = async (data: OrganizationApi_getSSODomains$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling getSSODomains.');
}
const localVarPath = `/organizations/{organization}/sso_domains`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_listCredentials$Params {
    namespace: string,
    page?: number,
    perPage?: number,
    provider?: string,
}

export const OrganizationApi_listCredentials$Path = '/credentials/{namespace}';

/**
 * List the credentials available in the namespace
 * @param {string} namespace namespace
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {string} [provider] Show only the credentials from this provider
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_listCredentialsParamCreator = async (data: OrganizationApi_listCredentials$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling listCredentials.');
}
const localVarPath = `/credentials/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['provider'] !== undefined) {
    localVarQueryParameter['provider'] = data['provider'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_removePaymentDetails$Params {
    namespace: string,
    stripeDetails: StripeDetails,
}

export const OrganizationApi_removePaymentDetails$Path = '/payments/{namespace}';

/**
 * Remove payment details
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {StripeDetails} stripeDetails Stripe one use token from element card
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_removePaymentDetailsParamCreator = async (data: OrganizationApi_removePaymentDetails$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling removePaymentDetails.');
}
// verify required parameter 'stripeDetails' is not null or undefined
if (data['stripeDetails'] === null || data['stripeDetails'] === undefined) {
    throw new RequiredError('stripeDetails','Required parameter stripeDetails was null or undefined when calling removePaymentDetails.');
}
const localVarPath = `/payments/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['stripeDetails'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['stripeDetails'] !== undefined ? data['stripeDetails'] : {}) : (data['stripeDetails'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_setOnboardingItems$Params {
    namespace: string,
    items: OnboardingItems,
    organization?: string,
}

export const OrganizationApi_setOnboardingItems$Path = '/onboarding/{namespace}';

/**
 * Set completed Onboarding items
 * @param {string} namespace an organization or user\&#39;s uuid
 * @param {OnboardingItems} items UI informs Rest server for Onboarding item
 * @param {string} [organization] Optional organization UUID. This is used for organization onboarding items. Organization onboarding items are unique per-user per-organization. Meaning that the user has different organization onboarding progress for each organization they own. 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_setOnboardingItemsParamCreator = async (data: OrganizationApi_setOnboardingItems$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling setOnboardingItems.');
}
// verify required parameter 'items' is not null or undefined
if (data['items'] === null || data['items'] === undefined) {
    throw new RequiredError('items','Required parameter items was null or undefined when calling setOnboardingItems.');
}
const localVarPath = `/onboarding/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['organization'] !== undefined) {
    localVarQueryParameter['organization'] = data['organization'];
}


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['items'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['items'] !== undefined ? data['items'] : {}) : (data['items'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_setPaymentDetails$Params {
    namespace: string,
    stripeDetails: StripeDetails,
    radarSession?: string,
}

export const OrganizationApi_setPaymentDetails$Path = '/payments/{namespace}';

/**
 * Set payment details
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {StripeDetails} stripeDetails Stripe one use token from element card
 * @param {string} [radarSession] Provides fraud protection
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_setPaymentDetailsParamCreator = async (data: OrganizationApi_setPaymentDetails$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling setPaymentDetails.');
}
// verify required parameter 'stripeDetails' is not null or undefined
if (data['stripeDetails'] === null || data['stripeDetails'] === undefined) {
    throw new RequiredError('stripeDetails','Required parameter stripeDetails was null or undefined when calling setPaymentDetails.');
}
const localVarPath = `/payments/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['radarSession'] !== undefined) {
    localVarQueryParameter['radar_session'] = data['radarSession'];
}


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['stripeDetails'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['stripeDetails'] !== undefined ? data['stripeDetails'] : {}) : (data['stripeDetails'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_subscribeToArray$Params {
    namespace: string,
    array: string,
}

export const OrganizationApi_subscribeToArray$Path = '/payments/pricing/{namespace}/{array}/subscribe';

/**
 * Subscribe to an array set up for monetization
 * @param {string} namespace Namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_subscribeToArrayParamCreator = async (data: OrganizationApi_subscribeToArray$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling subscribeToArray.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling subscribeToArray.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}/subscribe`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_unsubscribeFromArray$Params {
    namespace: string,
    array: string,
}

export const OrganizationApi_unsubscribeFromArray$Path = '/payments/pricing/{namespace}/{array}/subscribe';

/**
 * Unsubscribe to an array set up for monetization
 * @param {string} namespace Namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_unsubscribeFromArrayParamCreator = async (data: OrganizationApi_unsubscribeFromArray$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling unsubscribeFromArray.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling unsubscribeFromArray.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}/subscribe`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_updateCredential$Params {
    namespace: string,
    name: string,
    accessCredential: AccessCredentialUpdate,
}

export const OrganizationApi_updateCredential$Path = '/credentials/{namespace}/{name}';

/**
 * Update the named access credential. This will also update the information used to access arrays set to use this credential.
 * @param {string} namespace namespace
 * @param {string} name A URL-safe version of the credential\&#39;s user-provided name
 * @param {AccessCredentialUpdate} accessCredential Changes to make to this credential
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_updateCredentialParamCreator = async (data: OrganizationApi_updateCredential$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling updateCredential.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling updateCredential.');
}
// verify required parameter 'accessCredential' is not null or undefined
if (data['accessCredential'] === null || data['accessCredential'] === undefined) {
    throw new RequiredError('accessCredential','Required parameter accessCredential was null or undefined when calling updateCredential.');
}
const localVarPath = `/credentials/{namespace}/{name}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['accessCredential'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['accessCredential'] !== undefined ? data['accessCredential'] : {}) : (data['accessCredential'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_updateOrganization$Params {
    organization: string,
    organizationDetails: OrganizationUpdateParams,
}

export const OrganizationApi_updateOrganization$Path = '/organizations/{organization}';

/**
 * Update organization details
 * @param {string} organization organization name
 * @param {OrganizationUpdateParams} organizationDetails organization details to update
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_updateOrganizationParamCreator = async (data: OrganizationApi_updateOrganization$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling updateOrganization.');
}
// verify required parameter 'organizationDetails' is not null or undefined
if (data['organizationDetails'] === null || data['organizationDetails'] === undefined) {
    throw new RequiredError('organizationDetails','Required parameter organizationDetails was null or undefined when calling updateOrganization.');
}
const localVarPath = `/organizations/{organization}`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['organizationDetails'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['organizationDetails'] !== undefined ? data['organizationDetails'] : {}) : (data['organizationDetails'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_updatePricing$Params {
    namespace: string,
    array: string,
    pricingInfo: PricingInfo,
}

export const OrganizationApi_updatePricing$Path = '/payments/pricing/{namespace}/{array}';

/**
 * Update pricing info for a specified URI in a namespace
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {PricingInfo} pricingInfo Pricing metadata to update
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_updatePricingParamCreator = async (data: OrganizationApi_updatePricing$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling updatePricing.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling updatePricing.');
}
// verify required parameter 'pricingInfo' is not null or undefined
if (data['pricingInfo'] === null || data['pricingInfo'] === undefined) {
    throw new RequiredError('pricingInfo','Required parameter pricingInfo was null or undefined when calling updatePricing.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['pricingInfo'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['pricingInfo'] !== undefined ? data['pricingInfo'] : {}) : (data['pricingInfo'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_updateSSODomain$Params {
    organization: string,
    uuid: string,
    config: SSODomainConfig,
}

export const OrganizationApi_updateSSODomain$Path = '/organizations/{organization}/sso_domains/{uuid}';

/**
 * Updates the configuration for the given SSO connection.
 * @param {string} organization organization name
 * @param {string} uuid configuration ID
 * @param {SSODomainConfig} config The SSO connection to create.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_updateSSODomainParamCreator = async (data: OrganizationApi_updateSSODomain$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling updateSSODomain.');
}
// verify required parameter 'uuid' is not null or undefined
if (data['uuid'] === null || data['uuid'] === undefined) {
    throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling updateSSODomain.');
}
// verify required parameter 'config' is not null or undefined
if (data['config'] === null || data['config'] === undefined) {
    throw new RequiredError('config','Required parameter config was null or undefined when calling updateSSODomain.');
}
const localVarPath = `/organizations/{organization}/sso_domains/{uuid}`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])))
    .replace(`{${"uuid"}}`, encodeURIComponent(String(data['uuid'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['config'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['config'] !== undefined ? data['config'] : {}) : (data['config'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface OrganizationApi_updateUserInOrganization$Params {
    organization: string,
    username: string,
    user: UpdateOrganizationUserParams,
}

export const OrganizationApi_updateUserInOrganization$Path = '/organizations/{organization}/users/{username}';

/**
 * update a user in an organization
 * @param {string} organization organization name
 * @param {string} username username to manipulate
 * @param {UpdateOrganizationUserParams} user user details to update
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OrganizationApi_updateUserInOrganizationParamCreator = async (data: OrganizationApi_updateUserInOrganization$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'organization' is not null or undefined
if (data['organization'] === null || data['organization'] === undefined) {
    throw new RequiredError('organization','Required parameter organization was null or undefined when calling updateUserInOrganization.');
}
// verify required parameter 'username' is not null or undefined
if (data['username'] === null || data['username'] === undefined) {
    throw new RequiredError('username','Required parameter username was null or undefined when calling updateUserInOrganization.');
}
// verify required parameter 'user' is not null or undefined
if (data['user'] === null || data['user'] === undefined) {
    throw new RequiredError('user','Required parameter user was null or undefined when calling updateUserInOrganization.');
}
const localVarPath = `/organizations/{organization}/users/{username}`
    .replace(`{${"organization"}}`, encodeURIComponent(String(data['organization'])))
    .replace(`{${"username"}}`, encodeURIComponent(String(data['username'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['user'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['user'] !== undefined ? data['user'] : {}) : (data['user'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type OrganizationApi_addCredential$Response = void;

/**
  * Create a new credential for the namespace
  * @param {string} namespace namespace
  * @param {AccessCredentialUpdate} accessCredential The new credentials to be created.
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_addCredential(data: OrganizationApi_addCredential$Params, options?: any): Promise<AxiosResponse<OrganizationApi_addCredential$Response>> {
    const localVarAxiosArgs = await OrganizationApi_addCredentialParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_addUserToOrganization$Response = void;

/**
  * add a user to an organization
  * @param {string} organization organization name
  * @param {string} username username to manipulate
  * @param {CreateOrganizationUserParams} user user details to add
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_addUserToOrganization(data: OrganizationApi_addUserToOrganization$Params, options?: any): Promise<AxiosResponse<OrganizationApi_addUserToOrganization$Response>> {
    const localVarAxiosArgs = await OrganizationApi_addUserToOrganizationParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_checkSSODomain$Response = DomainCheckResult;

/**
  * Immediately verify ownership of the specified SSO domain ownership claim. 
  * @param {string} organization organization name
  * @param {string} uuid configuration ID
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_checkSSODomain(data: OrganizationApi_checkSSODomain$Params, options?: any): Promise<AxiosResponse<OrganizationApi_checkSSODomain$Response>> {
    const localVarAxiosArgs = await OrganizationApi_checkSSODomainParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_createOrganization$Response = Organization;

/**
  * Create an organization
  * @param {string} organization organization name
  * @param {OrganizationCreateParams} organizationDetails organization to create
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_createOrganization(data: OrganizationApi_createOrganization$Params, options?: any): Promise<AxiosResponse<OrganizationApi_createOrganization$Response>> {
    const localVarAxiosArgs = await OrganizationApi_createOrganizationParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_createPricing$Response = void;

/**
  * Create pricing info for a specified URI in a namespace
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {PricingInfo} [pricingInfo] Array pricing details
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_createPricing(data: OrganizationApi_createPricing$Params, options?: any): Promise<AxiosResponse<OrganizationApi_createPricing$Response>> {
    const localVarAxiosArgs = await OrganizationApi_createPricingParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_createSSODomain$Response = SSODomainConfig;

/**
  * Create a new SSO connection that connects this organization to this domain. 
  * @param {string} organization organization name
  * @param {SSODomainConfig} config The SSO connection to create.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_createSSODomain(data: OrganizationApi_createSSODomain$Params, options?: any): Promise<AxiosResponse<OrganizationApi_createSSODomain$Response>> {
    const localVarAxiosArgs = await OrganizationApi_createSSODomainParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_deleteCredential$Response = void;

/**
  * Delete the named access credential. Any arrays still set to use this credential will use the namespace\'s default and may become unreachable.
  * @param {string} namespace namespace
  * @param {string} name A URL-safe version of the credential\&#39;s user-provided name
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_deleteCredential(data: OrganizationApi_deleteCredential$Params, options?: any): Promise<AxiosResponse<OrganizationApi_deleteCredential$Response>> {
    const localVarAxiosArgs = await OrganizationApi_deleteCredentialParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_deleteOnboardingItems$Response = void;

/**
  * Deletes completed Onboarding items
  * @param {string} namespace an organization or user\&#39;s uuid
  * @param {string} [organization] Optional organization UUID. This is used for organization onboarding items. Organization onboarding items are unique per-user per-organization. Meaning that the user has different organization onboarding progress for each organization they own. 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_deleteOnboardingItems(data: OrganizationApi_deleteOnboardingItems$Params, options?: any): Promise<AxiosResponse<OrganizationApi_deleteOnboardingItems$Response>> {
    const localVarAxiosArgs = await OrganizationApi_deleteOnboardingItemsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_deleteOrganization$Response = void;

/**
  * Delete organization
  * @param {string} organization organization name
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_deleteOrganization(data: OrganizationApi_deleteOrganization$Params, options?: any): Promise<AxiosResponse<OrganizationApi_deleteOrganization$Response>> {
    const localVarAxiosArgs = await OrganizationApi_deleteOrganizationParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_deletePricing$Response = void;

/**
  * Delete pricing info for a specified URI in a namespace
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_deletePricing(data: OrganizationApi_deletePricing$Params, options?: any): Promise<AxiosResponse<OrganizationApi_deletePricing$Response>> {
    const localVarAxiosArgs = await OrganizationApi_deletePricingParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_deleteSSODomain$Response = void;

/**
  * Deletes the configuration for the given SSO connection.
  * @param {string} organization organization name
  * @param {string} uuid configuration ID
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_deleteSSODomain(data: OrganizationApi_deleteSSODomain$Params, options?: any): Promise<AxiosResponse<OrganizationApi_deleteSSODomain$Response>> {
    const localVarAxiosArgs = await OrganizationApi_deleteSSODomainParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_deleteUserFromOrganization$Response = void;

/**
  * delete a user from an organization
  * @param {string} organization organization name
  * @param {string} username username to manipulate
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_deleteUserFromOrganization(data: OrganizationApi_deleteUserFromOrganization$Params, options?: any): Promise<AxiosResponse<OrganizationApi_deleteUserFromOrganization$Response>> {
    const localVarAxiosArgs = await OrganizationApi_deleteUserFromOrganizationParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_fetchAllOrganizations$Response = Array<Organization>;

/**
  * Fetches all organizations that the user is member of
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_fetchAllOrganizations(data: OrganizationApi_fetchAllOrganizations$Params, options?: any): Promise<AxiosResponse<OrganizationApi_fetchAllOrganizations$Response>> {
    const localVarAxiosArgs = await OrganizationApi_fetchAllOrganizationsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_getBillingOverview$Response = BillingOverview;

/**
  * Get billing overview
  * @param {string} [namespace] namespace to filter, defaults to requesting user
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_getBillingOverview(data: OrganizationApi_getBillingOverview$Params, options?: any): Promise<AxiosResponse<OrganizationApi_getBillingOverview$Response>> {
    const localVarAxiosArgs = await OrganizationApi_getBillingOverviewParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_getChargeOperationsAllowed$Response = void;

/**
  * Get whether operations which incur charges are allowed
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_getChargeOperationsAllowed(data: OrganizationApi_getChargeOperationsAllowed$Params, options?: any): Promise<AxiosResponse<OrganizationApi_getChargeOperationsAllowed$Response>> {
    const localVarAxiosArgs = await OrganizationApi_getChargeOperationsAllowedParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_getCredential$Response = AccessCredential;

/**
  * Retrieve an access credential by name
  * @param {string} namespace namespace
  * @param {string} name A URL-safe version of the credential\&#39;s user-provided name
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_getCredential(data: OrganizationApi_getCredential$Params, options?: any): Promise<AxiosResponse<OrganizationApi_getCredential$Response>> {
    const localVarAxiosArgs = await OrganizationApi_getCredentialParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_getNamespaceAutocomplete$Response = Array<string>;

/**
  * Fetch list of usernames and  organization names matching the user\'s partial input
  * @param {string} input partial string entered by the user to engage autocomplete
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_getNamespaceAutocomplete(data: OrganizationApi_getNamespaceAutocomplete$Params, options?: any): Promise<AxiosResponse<OrganizationApi_getNamespaceAutocomplete$Response>> {
    const localVarAxiosArgs = await OrganizationApi_getNamespaceAutocompleteParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_getOnboardingItems$Response = OnboardingItems;

/**
  * Get onboarding items
  * @param {string} namespace an organization or user\&#39;s uuid
  * @param {string} [organization] Optional organization UUID. This is used for organization onboarding items. Organization onboarding items are unique per-user per-organization. Meaning that the user has different organization onboarding progress for each organization they own. 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_getOnboardingItems(data: OrganizationApi_getOnboardingItems$Params, options?: any): Promise<AxiosResponse<OrganizationApi_getOnboardingItems$Response>> {
    const localVarAxiosArgs = await OrganizationApi_getOnboardingItemsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_getOnboardingState$Response = OnboardingState;

/**
  * Get database state for specific onboarding steps that are not described or saved as onboarding items i.e. The existence of registered assets or the existence of members in an organization etc 
  * @param {string} namespace an organization or user\&#39;s uuid
  * @param {string} namespaceType the namespace type to check (each type makes different requests to REST and returns a different response)
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_getOnboardingState(data: OrganizationApi_getOnboardingState$Params, options?: any): Promise<AxiosResponse<OrganizationApi_getOnboardingState$Response>> {
    const localVarAxiosArgs = await OrganizationApi_getOnboardingStateParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_getOrganization$Response = Organization;

/**
  * Fetch an organization
  * @param {string} organization organization name
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_getOrganization(data: OrganizationApi_getOrganization$Params, options?: any): Promise<AxiosResponse<OrganizationApi_getOrganization$Response>> {
    const localVarAxiosArgs = await OrganizationApi_getOrganizationParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_getOrganizationUser$Response = OrganizationUser;

/**
  * get a user from an organization
  * @param {string} organization organization name
  * @param {string} username username to manipulate
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_getOrganizationUser(data: OrganizationApi_getOrganizationUser$Params, options?: any): Promise<AxiosResponse<OrganizationApi_getOrganizationUser$Response>> {
    const localVarAxiosArgs = await OrganizationApi_getOrganizationUserParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_getOrganizationsAutocomplete$Response = Array<string>;

/**
  * Fetch list of organization names matching the user\'s partial input
  * @param {string} input partial string entered by the user to engage autocomplete
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_getOrganizationsAutocomplete(data: OrganizationApi_getOrganizationsAutocomplete$Params, options?: any): Promise<AxiosResponse<OrganizationApi_getOrganizationsAutocomplete$Response>> {
    const localVarAxiosArgs = await OrganizationApi_getOrganizationsAutocompleteParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_getPaymentDetails$Response = PaymentMethod;

/**
  * Get payment details
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_getPaymentDetails(data: OrganizationApi_getPaymentDetails$Params, options?: any): Promise<AxiosResponse<OrganizationApi_getPaymentDetails$Response>> {
    const localVarAxiosArgs = await OrganizationApi_getPaymentDetailsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_getSSODomain$Response = SSODomainConfig;

/**
  * Gets details about the given SSO domain connection.
  * @param {string} organization organization name
  * @param {string} uuid configuration ID
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_getSSODomain(data: OrganizationApi_getSSODomain$Params, options?: any): Promise<AxiosResponse<OrganizationApi_getSSODomain$Response>> {
    const localVarAxiosArgs = await OrganizationApi_getSSODomainParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_getSSODomains$Response = SSODomainConfigResponse;

/**
  * Lists all the SSO connections associated with the given organization. 
  * @param {string} organization organization name
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_getSSODomains(data: OrganizationApi_getSSODomains$Params, options?: any): Promise<AxiosResponse<OrganizationApi_getSSODomains$Response>> {
    const localVarAxiosArgs = await OrganizationApi_getSSODomainsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_listCredentials$Response = AccessCredentialsData;

/**
  * List the credentials available in the namespace
  * @param {string} namespace namespace
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {string} [provider] Show only the credentials from this provider
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_listCredentials(data: OrganizationApi_listCredentials$Params, options?: any): Promise<AxiosResponse<OrganizationApi_listCredentials$Response>> {
    const localVarAxiosArgs = await OrganizationApi_listCredentialsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_removePaymentDetails$Response = void;

/**
  * Remove payment details
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {StripeDetails} stripeDetails Stripe one use token from element card
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_removePaymentDetails(data: OrganizationApi_removePaymentDetails$Params, options?: any): Promise<AxiosResponse<OrganizationApi_removePaymentDetails$Response>> {
    const localVarAxiosArgs = await OrganizationApi_removePaymentDetailsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_setOnboardingItems$Response = void;

/**
  * Set completed Onboarding items
  * @param {string} namespace an organization or user\&#39;s uuid
  * @param {OnboardingItems} items UI informs Rest server for Onboarding item
  * @param {string} [organization] Optional organization UUID. This is used for organization onboarding items. Organization onboarding items are unique per-user per-organization. Meaning that the user has different organization onboarding progress for each organization they own. 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_setOnboardingItems(data: OrganizationApi_setOnboardingItems$Params, options?: any): Promise<AxiosResponse<OrganizationApi_setOnboardingItems$Response>> {
    const localVarAxiosArgs = await OrganizationApi_setOnboardingItemsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_setPaymentDetails$Response = void;

/**
  * Set payment details
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {StripeDetails} stripeDetails Stripe one use token from element card
  * @param {string} [radarSession] Provides fraud protection
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_setPaymentDetails(data: OrganizationApi_setPaymentDetails$Params, options?: any): Promise<AxiosResponse<OrganizationApi_setPaymentDetails$Response>> {
    const localVarAxiosArgs = await OrganizationApi_setPaymentDetailsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_subscribeToArray$Response = void;

/**
  * Subscribe to an array set up for monetization
  * @param {string} namespace Namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_subscribeToArray(data: OrganizationApi_subscribeToArray$Params, options?: any): Promise<AxiosResponse<OrganizationApi_subscribeToArray$Response>> {
    const localVarAxiosArgs = await OrganizationApi_subscribeToArrayParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_unsubscribeFromArray$Response = void;

/**
  * Unsubscribe to an array set up for monetization
  * @param {string} namespace Namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_unsubscribeFromArray(data: OrganizationApi_unsubscribeFromArray$Params, options?: any): Promise<AxiosResponse<OrganizationApi_unsubscribeFromArray$Response>> {
    const localVarAxiosArgs = await OrganizationApi_unsubscribeFromArrayParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_updateCredential$Response = void;

/**
  * Update the named access credential. This will also update the information used to access arrays set to use this credential.
  * @param {string} namespace namespace
  * @param {string} name A URL-safe version of the credential\&#39;s user-provided name
  * @param {AccessCredentialUpdate} accessCredential Changes to make to this credential
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_updateCredential(data: OrganizationApi_updateCredential$Params, options?: any): Promise<AxiosResponse<OrganizationApi_updateCredential$Response>> {
    const localVarAxiosArgs = await OrganizationApi_updateCredentialParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_updateOrganization$Response = void;

/**
  * Update organization details
  * @param {string} organization organization name
  * @param {OrganizationUpdateParams} organizationDetails organization details to update
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_updateOrganization(data: OrganizationApi_updateOrganization$Params, options?: any): Promise<AxiosResponse<OrganizationApi_updateOrganization$Response>> {
    const localVarAxiosArgs = await OrganizationApi_updateOrganizationParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_updatePricing$Response = void;

/**
  * Update pricing info for a specified URI in a namespace
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {PricingInfo} pricingInfo Pricing metadata to update
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_updatePricing(data: OrganizationApi_updatePricing$Params, options?: any): Promise<AxiosResponse<OrganizationApi_updatePricing$Response>> {
    const localVarAxiosArgs = await OrganizationApi_updatePricingParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_updateSSODomain$Response = SSODomainConfig;

/**
  * Updates the configuration for the given SSO connection.
  * @param {string} organization organization name
  * @param {string} uuid configuration ID
  * @param {SSODomainConfig} config The SSO connection to create.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_updateSSODomain(data: OrganizationApi_updateSSODomain$Params, options?: any): Promise<AxiosResponse<OrganizationApi_updateSSODomain$Response>> {
    const localVarAxiosArgs = await OrganizationApi_updateSSODomainParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type OrganizationApi_updateUserInOrganization$Response = void;

/**
  * update a user in an organization
  * @param {string} organization organization name
  * @param {string} username username to manipulate
  * @param {UpdateOrganizationUserParams} user user details to update
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OrganizationApi_updateUserInOrganization(data: OrganizationApi_updateUserInOrganization$Params, options?: any): Promise<AxiosResponse<OrganizationApi_updateUserInOrganization$Response>> {
    const localVarAxiosArgs = await OrganizationApi_updateUserInOrganizationParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface OverviewApi_getOverview$Params {
    namespace?: string,
    createdBy?: string,
    start?: number,
    end?: number,
}

export const OverviewApi_getOverview$Path = '/overview';

/**
 * 
 * @param {string} [namespace] namespace to filter, defaults to requesting user
 * @param {string} [createdBy] username to filter (for tasks), defaults to requesting user
 * @param {number} [start] start time for tasks to filter by
 * @param {number} [end] end time for tasks to filter by
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const OverviewApi_getOverviewParamCreator = async (data: OverviewApi_getOverview$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/overview`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}
if (data['createdBy'] !== undefined) {
    localVarQueryParameter['created_by'] = data['createdBy'];
}
if (data['start'] !== undefined) {
    localVarQueryParameter['start'] = data['start'];
}
if (data['end'] !== undefined) {
    localVarQueryParameter['end'] = data['end'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type OverviewApi_getOverview$Response = OverviewData;

/**
  * 
  * @param {string} [namespace] namespace to filter, defaults to requesting user
  * @param {string} [createdBy] username to filter (for tasks), defaults to requesting user
  * @param {number} [start] start time for tasks to filter by
  * @param {number} [end] end time for tasks to filter by
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function OverviewApi_getOverview(data: OverviewApi_getOverview$Params, options?: any): Promise<AxiosResponse<OverviewApi_getOverview$Response>> {
    const localVarAxiosArgs = await OverviewApi_getOverviewParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface QueryApi_getNotebookFile$Params {
    namespace: string,
    array: string,
}

export const QueryApi_getNotebookFile$Path = '/notebooks/{namespace}/{array}';

/**
 * send a query retrieve the notebooks content
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const QueryApi_getNotebookFileParamCreator = async (data: QueryApi_getNotebookFile$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getNotebookFile.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling getNotebookFile.');
}
const localVarPath = `/notebooks/{namespace}/{array}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface QueryApi_getUDFFile$Params {
    namespace: string,
    array: string,
}

export const QueryApi_getUDFFile$Path = '/udfs/{namespace}/{array}';

/**
 * send a query retrieve the registered udfs content
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const QueryApi_getUDFFileParamCreator = async (data: QueryApi_getUDFFile$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getUDFFile.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling getUDFFile.');
}
const localVarPath = `/udfs/{namespace}/{array}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type QueryApi_getNotebookFile$Response = any;

/**
  * send a query retrieve the notebooks content
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function QueryApi_getNotebookFile(data: QueryApi_getNotebookFile$Params, options?: any): Promise<AxiosResponse<QueryApi_getNotebookFile$Response>> {
    const localVarAxiosArgs = await QueryApi_getNotebookFileParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type QueryApi_getUDFFile$Response = any;

/**
  * send a query retrieve the registered udfs content
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function QueryApi_getUDFFile(data: QueryApi_getUDFFile$Params, options?: any): Promise<AxiosResponse<QueryApi_getUDFFile$Response>> {
    const localVarAxiosArgs = await QueryApi_getUDFFileParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface RegisteredTaskGraphsApi_deleteRegisteredTaskGraph$Params {
    namespace: string,
    name: string,
}

export const RegisteredTaskGraphsApi_deleteRegisteredTaskGraph$Path = '/taskgraphs/{namespace}/registered/{name}';

/**
 * Delete the given registered task graph. 
 * @param {string} namespace The namespace that owns this registered UDF.
 * @param {string} name The name of the registered task graph.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const RegisteredTaskGraphsApi_deleteRegisteredTaskGraphParamCreator = async (data: RegisteredTaskGraphsApi_deleteRegisteredTaskGraph$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling deleteRegisteredTaskGraph.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling deleteRegisteredTaskGraph.');
}
const localVarPath = `/taskgraphs/{namespace}/registered/{name}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface RegisteredTaskGraphsApi_getRegisteredTaskGraph$Params {
    namespace: string,
    name: string,
}

export const RegisteredTaskGraphsApi_getRegisteredTaskGraph$Path = '/taskgraphs/{namespace}/registered/{name}';

/**
 * Fetch the contents of this registered task graph. 
 * @param {string} namespace The namespace that owns this registered UDF.
 * @param {string} name The name of the registered task graph.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const RegisteredTaskGraphsApi_getRegisteredTaskGraphParamCreator = async (data: RegisteredTaskGraphsApi_getRegisteredTaskGraph$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getRegisteredTaskGraph.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling getRegisteredTaskGraph.');
}
const localVarPath = `/taskgraphs/{namespace}/registered/{name}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface RegisteredTaskGraphsApi_getRegisteredTaskGraphSharingPolicies$Params {
    namespace: string,
    name: string,
}

export const RegisteredTaskGraphsApi_getRegisteredTaskGraphSharingPolicies$Path = '/taskgraphs/{namespace}/registered/{name}/share';

/**
 * Get sharing policies for the task graph.
 * @param {string} namespace The namespace that owns the registered task graph.
 * @param {string} name The name of the task graph.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const RegisteredTaskGraphsApi_getRegisteredTaskGraphSharingPoliciesParamCreator = async (data: RegisteredTaskGraphsApi_getRegisteredTaskGraphSharingPolicies$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getRegisteredTaskGraphSharingPolicies.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling getRegisteredTaskGraphSharingPolicies.');
}
const localVarPath = `/taskgraphs/{namespace}/registered/{name}/share`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface RegisteredTaskGraphsApi_registerRegisteredTaskGraph$Params {
    namespace: string,
    name: string,
    graph?: RegisteredTaskGraph,
}

export const RegisteredTaskGraphsApi_registerRegisteredTaskGraph$Path = '/taskgraphs/{namespace}/registered/{name}';

/**
 * Register a task graph in the given namespace, with the given name. 
 * @param {string} namespace The namespace that owns this registered UDF.
 * @param {string} name The name of the registered task graph.
 * @param {RegisteredTaskGraph} [graph] Task graph to register.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const RegisteredTaskGraphsApi_registerRegisteredTaskGraphParamCreator = async (data: RegisteredTaskGraphsApi_registerRegisteredTaskGraph$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling registerRegisteredTaskGraph.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling registerRegisteredTaskGraph.');
}
const localVarPath = `/taskgraphs/{namespace}/registered/{name}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['graph'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['graph'] !== undefined ? data['graph'] : {}) : (data['graph'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface RegisteredTaskGraphsApi_resubmitTaskGraphExecution$Params {
    namespace: string,
    id: string,
}

export const RegisteredTaskGraphsApi_resubmitTaskGraphExecution$Path = '/taskgraphs/{namespace}/executions/{id}/resubmit';

/**
 * Retry a task graph in the given namespace using the associated execution id.
 * @param {string} namespace The namespace that owns this task graph execution.
 * @param {string} id The UUID of the task graph entry.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const RegisteredTaskGraphsApi_resubmitTaskGraphExecutionParamCreator = async (data: RegisteredTaskGraphsApi_resubmitTaskGraphExecution$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling resubmitTaskGraphExecution.');
}
// verify required parameter 'id' is not null or undefined
if (data['id'] === null || data['id'] === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling resubmitTaskGraphExecution.');
}
const localVarPath = `/taskgraphs/{namespace}/executions/{id}/resubmit`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"id"}}`, encodeURIComponent(String(data['id'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface RegisteredTaskGraphsApi_shareRegisteredTaskGraph$Params {
    namespace: string,
    name: string,
    taskGraphSharing: TaskGraphSharing,
}

export const RegisteredTaskGraphsApi_shareRegisteredTaskGraph$Path = '/taskgraphs/{namespace}/registered/{name}/share';

/**
 * Share a task graph.
 * @param {string} namespace The namespace that owns the registered task graph.
 * @param {string} name The name of the task graph.
 * @param {TaskGraphSharing} taskGraphSharing Namespace and list of permissions to share with. An empty list of permissions will remove the namespace; if permissions already exist they will be deleted then new ones added. In the event of a failure, the new policies will be rolled back to prevent partial policies, and it\&#39;s likely the UDF will not be shared with the namespace at all. 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const RegisteredTaskGraphsApi_shareRegisteredTaskGraphParamCreator = async (data: RegisteredTaskGraphsApi_shareRegisteredTaskGraph$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling shareRegisteredTaskGraph.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling shareRegisteredTaskGraph.');
}
// verify required parameter 'taskGraphSharing' is not null or undefined
if (data['taskGraphSharing'] === null || data['taskGraphSharing'] === undefined) {
    throw new RequiredError('taskGraphSharing','Required parameter taskGraphSharing was null or undefined when calling shareRegisteredTaskGraph.');
}
const localVarPath = `/taskgraphs/{namespace}/registered/{name}/share`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['taskGraphSharing'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['taskGraphSharing'] !== undefined ? data['taskGraphSharing'] : {}) : (data['taskGraphSharing'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface RegisteredTaskGraphsApi_stopTaskGraph$Params {
    namespace: string,
    id: string,
}

export const RegisteredTaskGraphsApi_stopTaskGraph$Path = '/taskgraphs/{namespace}/executions/{id}/stop';

/**
 * Stop a task graph execution in the given namespace using the associated associated execution id.
 * @param {string} namespace The namespace that owns this task graph execution.
 * @param {string} id The UUID of the task graph entry.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const RegisteredTaskGraphsApi_stopTaskGraphParamCreator = async (data: RegisteredTaskGraphsApi_stopTaskGraph$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling stopTaskGraph.');
}
// verify required parameter 'id' is not null or undefined
if (data['id'] === null || data['id'] === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling stopTaskGraph.');
}
const localVarPath = `/taskgraphs/{namespace}/executions/{id}/stop`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"id"}}`, encodeURIComponent(String(data['id'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface RegisteredTaskGraphsApi_updateRegisteredTaskGraph$Params {
    namespace: string,
    name: string,
    graph?: RegisteredTaskGraph,
}

export const RegisteredTaskGraphsApi_updateRegisteredTaskGraph$Path = '/taskgraphs/{namespace}/registered/{name}';

/**
 * Update the contents of an existing registered task graph. 
 * @param {string} namespace The namespace that owns this registered UDF.
 * @param {string} name The name of the registered task graph.
 * @param {RegisteredTaskGraph} [graph] The new contents of the task graph.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const RegisteredTaskGraphsApi_updateRegisteredTaskGraphParamCreator = async (data: RegisteredTaskGraphsApi_updateRegisteredTaskGraph$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling updateRegisteredTaskGraph.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling updateRegisteredTaskGraph.');
}
const localVarPath = `/taskgraphs/{namespace}/registered/{name}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['graph'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['graph'] !== undefined ? data['graph'] : {}) : (data['graph'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type RegisteredTaskGraphsApi_deleteRegisteredTaskGraph$Response = void;

/**
  * Delete the given registered task graph. 
  * @param {string} namespace The namespace that owns this registered UDF.
  * @param {string} name The name of the registered task graph.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function RegisteredTaskGraphsApi_deleteRegisteredTaskGraph(data: RegisteredTaskGraphsApi_deleteRegisteredTaskGraph$Params, options?: any): Promise<AxiosResponse<RegisteredTaskGraphsApi_deleteRegisteredTaskGraph$Response>> {
    const localVarAxiosArgs = await RegisteredTaskGraphsApi_deleteRegisteredTaskGraphParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type RegisteredTaskGraphsApi_getRegisteredTaskGraph$Response = RegisteredTaskGraph;

/**
  * Fetch the contents of this registered task graph. 
  * @param {string} namespace The namespace that owns this registered UDF.
  * @param {string} name The name of the registered task graph.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function RegisteredTaskGraphsApi_getRegisteredTaskGraph(data: RegisteredTaskGraphsApi_getRegisteredTaskGraph$Params, options?: any): Promise<AxiosResponse<RegisteredTaskGraphsApi_getRegisteredTaskGraph$Response>> {
    const localVarAxiosArgs = await RegisteredTaskGraphsApi_getRegisteredTaskGraphParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type RegisteredTaskGraphsApi_getRegisteredTaskGraphSharingPolicies$Response = Array<TaskGraphSharing>;

/**
  * Get sharing policies for the task graph.
  * @param {string} namespace The namespace that owns the registered task graph.
  * @param {string} name The name of the task graph.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function RegisteredTaskGraphsApi_getRegisteredTaskGraphSharingPolicies(data: RegisteredTaskGraphsApi_getRegisteredTaskGraphSharingPolicies$Params, options?: any): Promise<AxiosResponse<RegisteredTaskGraphsApi_getRegisteredTaskGraphSharingPolicies$Response>> {
    const localVarAxiosArgs = await RegisteredTaskGraphsApi_getRegisteredTaskGraphSharingPoliciesParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type RegisteredTaskGraphsApi_registerRegisteredTaskGraph$Response = void;

/**
  * Register a task graph in the given namespace, with the given name. 
  * @param {string} namespace The namespace that owns this registered UDF.
  * @param {string} name The name of the registered task graph.
  * @param {RegisteredTaskGraph} [graph] Task graph to register.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function RegisteredTaskGraphsApi_registerRegisteredTaskGraph(data: RegisteredTaskGraphsApi_registerRegisteredTaskGraph$Params, options?: any): Promise<AxiosResponse<RegisteredTaskGraphsApi_registerRegisteredTaskGraph$Response>> {
    const localVarAxiosArgs = await RegisteredTaskGraphsApi_registerRegisteredTaskGraphParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type RegisteredTaskGraphsApi_resubmitTaskGraphExecution$Response = TaskGraphLog;

/**
  * Retry a task graph in the given namespace using the associated execution id.
  * @param {string} namespace The namespace that owns this task graph execution.
  * @param {string} id The UUID of the task graph entry.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function RegisteredTaskGraphsApi_resubmitTaskGraphExecution(data: RegisteredTaskGraphsApi_resubmitTaskGraphExecution$Params, options?: any): Promise<AxiosResponse<RegisteredTaskGraphsApi_resubmitTaskGraphExecution$Response>> {
    const localVarAxiosArgs = await RegisteredTaskGraphsApi_resubmitTaskGraphExecutionParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type RegisteredTaskGraphsApi_shareRegisteredTaskGraph$Response = void;

/**
  * Share a task graph.
  * @param {string} namespace The namespace that owns the registered task graph.
  * @param {string} name The name of the task graph.
  * @param {TaskGraphSharing} taskGraphSharing Namespace and list of permissions to share with. An empty list of permissions will remove the namespace; if permissions already exist they will be deleted then new ones added. In the event of a failure, the new policies will be rolled back to prevent partial policies, and it\&#39;s likely the UDF will not be shared with the namespace at all. 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function RegisteredTaskGraphsApi_shareRegisteredTaskGraph(data: RegisteredTaskGraphsApi_shareRegisteredTaskGraph$Params, options?: any): Promise<AxiosResponse<RegisteredTaskGraphsApi_shareRegisteredTaskGraph$Response>> {
    const localVarAxiosArgs = await RegisteredTaskGraphsApi_shareRegisteredTaskGraphParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type RegisteredTaskGraphsApi_stopTaskGraph$Response = TaskGraphLog;

/**
  * Stop a task graph execution in the given namespace using the associated associated execution id.
  * @param {string} namespace The namespace that owns this task graph execution.
  * @param {string} id The UUID of the task graph entry.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function RegisteredTaskGraphsApi_stopTaskGraph(data: RegisteredTaskGraphsApi_stopTaskGraph$Params, options?: any): Promise<AxiosResponse<RegisteredTaskGraphsApi_stopTaskGraph$Response>> {
    const localVarAxiosArgs = await RegisteredTaskGraphsApi_stopTaskGraphParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type RegisteredTaskGraphsApi_updateRegisteredTaskGraph$Response = void;

/**
  * Update the contents of an existing registered task graph. 
  * @param {string} namespace The namespace that owns this registered UDF.
  * @param {string} name The name of the registered task graph.
  * @param {RegisteredTaskGraph} [graph] The new contents of the task graph.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function RegisteredTaskGraphsApi_updateRegisteredTaskGraph(data: RegisteredTaskGraphsApi_updateRegisteredTaskGraph$Params, options?: any): Promise<AxiosResponse<RegisteredTaskGraphsApi_updateRegisteredTaskGraph$Response>> {
    const localVarAxiosArgs = await RegisteredTaskGraphsApi_updateRegisteredTaskGraphParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface TaskGraphLogsApi_createTaskGraphLog$Params {
    namespace: string,
    log: TaskGraphLog,
}

export const TaskGraphLogsApi_createTaskGraphLog$Path = '/taskgraphs/{namespace}/log';

/**
 * Create a task graph log.
 * @param {string} namespace The namespace that will own this task graph log.
 * @param {TaskGraphLog} log 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TaskGraphLogsApi_createTaskGraphLogParamCreator = async (data: TaskGraphLogsApi_createTaskGraphLog$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling createTaskGraphLog.');
}
// verify required parameter 'log' is not null or undefined
if (data['log'] === null || data['log'] === undefined) {
    throw new RequiredError('log','Required parameter log was null or undefined when calling createTaskGraphLog.');
}
const localVarPath = `/taskgraphs/{namespace}/log`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['log'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['log'] !== undefined ? data['log'] : {}) : (data['log'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface TaskGraphLogsApi_getTaskGraphLog$Params {
    namespace: string,
    id: string,
}

export const TaskGraphLogsApi_getTaskGraphLog$Path = '/taskgraphs/{namespace}/logs/{id}';

/**
 * Fetch information about a single task graph execution. 
 * @param {string} namespace The namespace that owns this task graph log.
 * @param {string} id The UUID of the task graph log entry.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TaskGraphLogsApi_getTaskGraphLogParamCreator = async (data: TaskGraphLogsApi_getTaskGraphLog$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getTaskGraphLog.');
}
// verify required parameter 'id' is not null or undefined
if (data['id'] === null || data['id'] === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling getTaskGraphLog.');
}
const localVarPath = `/taskgraphs/{namespace}/logs/{id}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"id"}}`, encodeURIComponent(String(data['id'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface TaskGraphLogsApi_getTaskgraphLogFilters$Params {
    namespace?: string,
}

export const TaskGraphLogsApi_getTaskgraphLogFilters$Path = '/taskgraphs/logs/filters';

/**
 * Object containing data related to taskgraph logs to be used in the tasgraph log listing route as filters 
 * @param {string} [namespace] the namespace that the taskgraphs belong to
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TaskGraphLogsApi_getTaskgraphLogFiltersParamCreator = async (data: TaskGraphLogsApi_getTaskgraphLogFilters$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/taskgraphs/logs/filters`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface TaskGraphLogsApi_listTaskGraphLogs$Params {
    namespace?: string,
    createdBy?: string,
    status?: string,
    search?: string,
    startTime?: string,
    endTime?: string,
    page?: number,
    perPage?: number,
}

export const TaskGraphLogsApi_listTaskGraphLogs$Path = '/taskgraphs/logs';

/**
 * Fetch the task graph logs of a namespace the user has access to. The returned entries will include only summary data, and will not include information about the individual tasks that were executed. (This information is available when requesting an individual task graph log.) Entries in the response are ordered from newest to oldest. Pagination parameters work as in other API methods; see PaginationMetadata. 
 * @param {string} [namespace] Include logs for this namespace.
 * @param {string} [createdBy] Include logs from only this user.
 * @param {string} [status] Filter to only return these statuses
 * @param {string} [search] search string that will look at name.
 * @param {string} [startTime] Include logs created after this time.
 * @param {string} [endTime] Include logs created before this time.
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TaskGraphLogsApi_listTaskGraphLogsParamCreator = async (data: TaskGraphLogsApi_listTaskGraphLogs$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/taskgraphs/logs`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}
if (data['createdBy'] !== undefined) {
    localVarQueryParameter['created_by'] = data['createdBy'];
}
if (data['status'] !== undefined) {
    localVarQueryParameter['status'] = data['status'];
}
if (data['search'] !== undefined) {
    localVarQueryParameter['search'] = data['search'];
}
if (data['startTime'] !== undefined) {
    localVarQueryParameter['start_time'] = (data['startTime'] as any instanceof Date) ?
        (data['startTime'] as any).toISOString() :
        data['startTime'];
}
if (data['endTime'] !== undefined) {
    localVarQueryParameter['end_time'] = (data['endTime'] as any instanceof Date) ?
        (data['endTime'] as any).toISOString() :
        data['endTime'];
}
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface TaskGraphLogsApi_resubmitTaskGraphExecution$Params {
    namespace: string,
    id: string,
}

export const TaskGraphLogsApi_resubmitTaskGraphExecution$Path = '/taskgraphs/{namespace}/executions/{id}/resubmit';

/**
 * Retry a task graph in the given namespace using the associated execution id.
 * @param {string} namespace The namespace that owns this task graph execution.
 * @param {string} id The UUID of the task graph entry.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TaskGraphLogsApi_resubmitTaskGraphExecutionParamCreator = async (data: TaskGraphLogsApi_resubmitTaskGraphExecution$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling resubmitTaskGraphExecution.');
}
// verify required parameter 'id' is not null or undefined
if (data['id'] === null || data['id'] === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling resubmitTaskGraphExecution.');
}
const localVarPath = `/taskgraphs/{namespace}/executions/{id}/resubmit`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"id"}}`, encodeURIComponent(String(data['id'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface TaskGraphLogsApi_retryTaskGraphExecution$Params {
    namespace: string,
    id: string,
}

export const TaskGraphLogsApi_retryTaskGraphExecution$Path = '/taskgraphs/{namespace}/executions/{id}/retry';

/**
 * Retries a task graph failed tasks of in the given namespace using the associated execution id.
 * @param {string} namespace The namespace that owns this task graph execution.
 * @param {string} id The UUID of the task graph execution (TaskGraphLog).
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TaskGraphLogsApi_retryTaskGraphExecutionParamCreator = async (data: TaskGraphLogsApi_retryTaskGraphExecution$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling retryTaskGraphExecution.');
}
// verify required parameter 'id' is not null or undefined
if (data['id'] === null || data['id'] === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling retryTaskGraphExecution.');
}
const localVarPath = `/taskgraphs/{namespace}/executions/{id}/retry`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"id"}}`, encodeURIComponent(String(data['id'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface TaskGraphLogsApi_stopTaskGraph$Params {
    namespace: string,
    id: string,
}

export const TaskGraphLogsApi_stopTaskGraph$Path = '/taskgraphs/{namespace}/executions/{id}/stop';

/**
 * Stop a task graph execution in the given namespace using the associated associated execution id.
 * @param {string} namespace The namespace that owns this task graph execution.
 * @param {string} id The UUID of the task graph entry.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TaskGraphLogsApi_stopTaskGraphParamCreator = async (data: TaskGraphLogsApi_stopTaskGraph$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling stopTaskGraph.');
}
// verify required parameter 'id' is not null or undefined
if (data['id'] === null || data['id'] === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling stopTaskGraph.');
}
const localVarPath = `/taskgraphs/{namespace}/executions/{id}/stop`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"id"}}`, encodeURIComponent(String(data['id'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface TaskGraphLogsApi_submitTaskGraph$Params {
    namespace: string,
    id: string,
}

export const TaskGraphLogsApi_submitTaskGraph$Path = '/taskgraphs/{namespace}/graphs/{id}/submit';

/**
 * Submit a single task graph for execution. 
 * @param {string} namespace The namespace that owns this task graph.
 * @param {string} id The UUID of the task graph entry.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TaskGraphLogsApi_submitTaskGraphParamCreator = async (data: TaskGraphLogsApi_submitTaskGraph$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling submitTaskGraph.');
}
// verify required parameter 'id' is not null or undefined
if (data['id'] === null || data['id'] === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling submitTaskGraph.');
}
const localVarPath = `/taskgraphs/{namespace}/graphs/{id}/submit`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"id"}}`, encodeURIComponent(String(data['id'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface TaskGraphLogsApi_updateTaskGraphLog$Params {
    namespace: string,
    id: string,
    log: TaskGraphLog,
}

export const TaskGraphLogsApi_updateTaskGraphLog$Path = '/taskgraphs/{namespace}/logs/{id}';

/**
 * Update information about a single task graph execution. 
 * @param {string} namespace The namespace that owns this task graph log.
 * @param {string} id The UUID of the task graph log entry.
 * @param {TaskGraphLog} log Updates to make to the task graph log. The only manual update that a client should need to make to a task graph log is to update its completion status to &#x60;succeeded&#x60;, &#x60;failed&#x60;, or &#x60;cancelled&#x60;. 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TaskGraphLogsApi_updateTaskGraphLogParamCreator = async (data: TaskGraphLogsApi_updateTaskGraphLog$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling updateTaskGraphLog.');
}
// verify required parameter 'id' is not null or undefined
if (data['id'] === null || data['id'] === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling updateTaskGraphLog.');
}
// verify required parameter 'log' is not null or undefined
if (data['log'] === null || data['log'] === undefined) {
    throw new RequiredError('log','Required parameter log was null or undefined when calling updateTaskGraphLog.');
}
const localVarPath = `/taskgraphs/{namespace}/logs/{id}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"id"}}`, encodeURIComponent(String(data['id'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['log'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['log'] !== undefined ? data['log'] : {}) : (data['log'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type TaskGraphLogsApi_createTaskGraphLog$Response = TaskGraphLog;

/**
  * Create a task graph log.
  * @param {string} namespace The namespace that will own this task graph log.
  * @param {TaskGraphLog} log 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TaskGraphLogsApi_createTaskGraphLog(data: TaskGraphLogsApi_createTaskGraphLog$Params, options?: any): Promise<AxiosResponse<TaskGraphLogsApi_createTaskGraphLog$Response>> {
    const localVarAxiosArgs = await TaskGraphLogsApi_createTaskGraphLogParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type TaskGraphLogsApi_getTaskGraphLog$Response = TaskGraphLog;

/**
  * Fetch information about a single task graph execution. 
  * @param {string} namespace The namespace that owns this task graph log.
  * @param {string} id The UUID of the task graph log entry.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TaskGraphLogsApi_getTaskGraphLog(data: TaskGraphLogsApi_getTaskGraphLog$Params, options?: any): Promise<AxiosResponse<TaskGraphLogsApi_getTaskGraphLog$Response>> {
    const localVarAxiosArgs = await TaskGraphLogsApi_getTaskGraphLogParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type TaskGraphLogsApi_getTaskgraphLogFilters$Response = TaskgraphLogsFiltersResponse;

/**
  * Object containing data related to taskgraph logs to be used in the tasgraph log listing route as filters 
  * @param {string} [namespace] the namespace that the taskgraphs belong to
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TaskGraphLogsApi_getTaskgraphLogFilters(data: TaskGraphLogsApi_getTaskgraphLogFilters$Params, options?: any): Promise<AxiosResponse<TaskGraphLogsApi_getTaskgraphLogFilters$Response>> {
    const localVarAxiosArgs = await TaskGraphLogsApi_getTaskgraphLogFiltersParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type TaskGraphLogsApi_listTaskGraphLogs$Response = TaskGraphLogsData;

/**
  * Fetch the task graph logs of a namespace the user has access to. The returned entries will include only summary data, and will not include information about the individual tasks that were executed. (This information is available when requesting an individual task graph log.) Entries in the response are ordered from newest to oldest. Pagination parameters work as in other API methods; see PaginationMetadata. 
  * @param {string} [namespace] Include logs for this namespace.
  * @param {string} [createdBy] Include logs from only this user.
  * @param {string} [status] Filter to only return these statuses
  * @param {string} [search] search string that will look at name.
  * @param {string} [startTime] Include logs created after this time.
  * @param {string} [endTime] Include logs created before this time.
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TaskGraphLogsApi_listTaskGraphLogs(data: TaskGraphLogsApi_listTaskGraphLogs$Params, options?: any): Promise<AxiosResponse<TaskGraphLogsApi_listTaskGraphLogs$Response>> {
    const localVarAxiosArgs = await TaskGraphLogsApi_listTaskGraphLogsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type TaskGraphLogsApi_resubmitTaskGraphExecution$Response = TaskGraphLog;

/**
  * Retry a task graph in the given namespace using the associated execution id.
  * @param {string} namespace The namespace that owns this task graph execution.
  * @param {string} id The UUID of the task graph entry.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TaskGraphLogsApi_resubmitTaskGraphExecution(data: TaskGraphLogsApi_resubmitTaskGraphExecution$Params, options?: any): Promise<AxiosResponse<TaskGraphLogsApi_resubmitTaskGraphExecution$Response>> {
    const localVarAxiosArgs = await TaskGraphLogsApi_resubmitTaskGraphExecutionParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type TaskGraphLogsApi_retryTaskGraphExecution$Response = TaskGraphLog;

/**
  * Retries a task graph failed tasks of in the given namespace using the associated execution id.
  * @param {string} namespace The namespace that owns this task graph execution.
  * @param {string} id The UUID of the task graph execution (TaskGraphLog).
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TaskGraphLogsApi_retryTaskGraphExecution(data: TaskGraphLogsApi_retryTaskGraphExecution$Params, options?: any): Promise<AxiosResponse<TaskGraphLogsApi_retryTaskGraphExecution$Response>> {
    const localVarAxiosArgs = await TaskGraphLogsApi_retryTaskGraphExecutionParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type TaskGraphLogsApi_stopTaskGraph$Response = TaskGraphLog;

/**
  * Stop a task graph execution in the given namespace using the associated associated execution id.
  * @param {string} namespace The namespace that owns this task graph execution.
  * @param {string} id The UUID of the task graph entry.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TaskGraphLogsApi_stopTaskGraph(data: TaskGraphLogsApi_stopTaskGraph$Params, options?: any): Promise<AxiosResponse<TaskGraphLogsApi_stopTaskGraph$Response>> {
    const localVarAxiosArgs = await TaskGraphLogsApi_stopTaskGraphParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type TaskGraphLogsApi_submitTaskGraph$Response = TaskGraphLog;

/**
  * Submit a single task graph for execution. 
  * @param {string} namespace The namespace that owns this task graph.
  * @param {string} id The UUID of the task graph entry.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TaskGraphLogsApi_submitTaskGraph(data: TaskGraphLogsApi_submitTaskGraph$Params, options?: any): Promise<AxiosResponse<TaskGraphLogsApi_submitTaskGraph$Response>> {
    const localVarAxiosArgs = await TaskGraphLogsApi_submitTaskGraphParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type TaskGraphLogsApi_updateTaskGraphLog$Response = void;

/**
  * Update information about a single task graph execution. 
  * @param {string} namespace The namespace that owns this task graph log.
  * @param {string} id The UUID of the task graph log entry.
  * @param {TaskGraphLog} log Updates to make to the task graph log. The only manual update that a client should need to make to a task graph log is to update its completion status to &#x60;succeeded&#x60;, &#x60;failed&#x60;, or &#x60;cancelled&#x60;. 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TaskGraphLogsApi_updateTaskGraphLog(data: TaskGraphLogsApi_updateTaskGraphLog$Params, options?: any): Promise<AxiosResponse<TaskGraphLogsApi_updateTaskGraphLog$Response>> {
    const localVarAxiosArgs = await TaskGraphLogsApi_updateTaskGraphLogParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface TaskGraphsApi_createTaskGraph$Params {
    namespace: string,
    graph: TaskGraph,
}

export const TaskGraphsApi_createTaskGraph$Path = '/taskgraphs/{namespace}/graphs';

/**
 * Create a single task graph for execution. 
 * @param {string} namespace Include graphs for this namespace.
 * @param {TaskGraph} graph Create the task graph.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TaskGraphsApi_createTaskGraphParamCreator = async (data: TaskGraphsApi_createTaskGraph$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling createTaskGraph.');
}
// verify required parameter 'graph' is not null or undefined
if (data['graph'] === null || data['graph'] === undefined) {
    throw new RequiredError('graph','Required parameter graph was null or undefined when calling createTaskGraph.');
}
const localVarPath = `/taskgraphs/{namespace}/graphs`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['graph'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['graph'] !== undefined ? data['graph'] : {}) : (data['graph'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface TaskGraphsApi_getTaskGraph$Params {
    namespace: string,
    id: string,
}

export const TaskGraphsApi_getTaskGraph$Path = '/taskgraphs/{namespace}/graphs/{id}';

/**
 * Fetch information about a single task graph. 
 * @param {string} namespace The namespace that owns this task graph.
 * @param {string} id The UUID of the task graph entry.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TaskGraphsApi_getTaskGraphParamCreator = async (data: TaskGraphsApi_getTaskGraph$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getTaskGraph.');
}
// verify required parameter 'id' is not null or undefined
if (data['id'] === null || data['id'] === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling getTaskGraph.');
}
const localVarPath = `/taskgraphs/{namespace}/graphs/{id}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"id"}}`, encodeURIComponent(String(data['id'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface TaskGraphsApi_listTaskGraphs$Params {
    namespace: string,
}

export const TaskGraphsApi_listTaskGraphs$Path = '/taskgraphs/{namespace}/graphs';

/**
 * Fetch the task graphs of a namespace the user has access to. The returned entries will include only summary data, and will not include information about the individual tasks that were executed. (This information is available when requesting an individual task graph log.) Entries in the response are ordered from newest to oldest. Pagination parameters work as in other API methods; see PaginationMetadata. 
 * @param {string} namespace Namespace for graphs
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TaskGraphsApi_listTaskGraphsParamCreator = async (data: TaskGraphsApi_listTaskGraphs$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling listTaskGraphs.');
}
const localVarPath = `/taskgraphs/{namespace}/graphs`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface TaskGraphsApi_submitTaskGraph$Params {
    namespace: string,
    id: string,
}

export const TaskGraphsApi_submitTaskGraph$Path = '/taskgraphs/{namespace}/graphs/{id}/submit';

/**
 * Submit a single task graph for execution. 
 * @param {string} namespace The namespace that owns this task graph.
 * @param {string} id The UUID of the task graph entry.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TaskGraphsApi_submitTaskGraphParamCreator = async (data: TaskGraphsApi_submitTaskGraph$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling submitTaskGraph.');
}
// verify required parameter 'id' is not null or undefined
if (data['id'] === null || data['id'] === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling submitTaskGraph.');
}
const localVarPath = `/taskgraphs/{namespace}/graphs/{id}/submit`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"id"}}`, encodeURIComponent(String(data['id'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface TaskGraphsApi_updateTaskGraph$Params {
    namespace: string,
    id: string,
    graph: TaskGraph,
}

export const TaskGraphsApi_updateTaskGraph$Path = '/taskgraphs/{namespace}/graphs/{id}';

/**
 * Update information about a single task graph execution. 
 * @param {string} namespace The namespace that owns this task graph.
 * @param {string} id The UUID of the task graph entry.
 * @param {TaskGraph} graph Updates to make to the task graph.
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TaskGraphsApi_updateTaskGraphParamCreator = async (data: TaskGraphsApi_updateTaskGraph$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling updateTaskGraph.');
}
// verify required parameter 'id' is not null or undefined
if (data['id'] === null || data['id'] === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling updateTaskGraph.');
}
// verify required parameter 'graph' is not null or undefined
if (data['graph'] === null || data['graph'] === undefined) {
    throw new RequiredError('graph','Required parameter graph was null or undefined when calling updateTaskGraph.');
}
const localVarPath = `/taskgraphs/{namespace}/graphs/{id}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"id"}}`, encodeURIComponent(String(data['id'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['graph'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['graph'] !== undefined ? data['graph'] : {}) : (data['graph'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type TaskGraphsApi_createTaskGraph$Response = TaskGraph;

/**
  * Create a single task graph for execution. 
  * @param {string} namespace Include graphs for this namespace.
  * @param {TaskGraph} graph Create the task graph.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TaskGraphsApi_createTaskGraph(data: TaskGraphsApi_createTaskGraph$Params, options?: any): Promise<AxiosResponse<TaskGraphsApi_createTaskGraph$Response>> {
    const localVarAxiosArgs = await TaskGraphsApi_createTaskGraphParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type TaskGraphsApi_getTaskGraph$Response = TaskGraph;

/**
  * Fetch information about a single task graph. 
  * @param {string} namespace The namespace that owns this task graph.
  * @param {string} id The UUID of the task graph entry.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TaskGraphsApi_getTaskGraph(data: TaskGraphsApi_getTaskGraph$Params, options?: any): Promise<AxiosResponse<TaskGraphsApi_getTaskGraph$Response>> {
    const localVarAxiosArgs = await TaskGraphsApi_getTaskGraphParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type TaskGraphsApi_listTaskGraphs$Response = TaskGraphs;

/**
  * Fetch the task graphs of a namespace the user has access to. The returned entries will include only summary data, and will not include information about the individual tasks that were executed. (This information is available when requesting an individual task graph log.) Entries in the response are ordered from newest to oldest. Pagination parameters work as in other API methods; see PaginationMetadata. 
  * @param {string} namespace Namespace for graphs
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TaskGraphsApi_listTaskGraphs(data: TaskGraphsApi_listTaskGraphs$Params, options?: any): Promise<AxiosResponse<TaskGraphsApi_listTaskGraphs$Response>> {
    const localVarAxiosArgs = await TaskGraphsApi_listTaskGraphsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type TaskGraphsApi_submitTaskGraph$Response = TaskGraphLog;

/**
  * Submit a single task graph for execution. 
  * @param {string} namespace The namespace that owns this task graph.
  * @param {string} id The UUID of the task graph entry.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TaskGraphsApi_submitTaskGraph(data: TaskGraphsApi_submitTaskGraph$Params, options?: any): Promise<AxiosResponse<TaskGraphsApi_submitTaskGraph$Response>> {
    const localVarAxiosArgs = await TaskGraphsApi_submitTaskGraphParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type TaskGraphsApi_updateTaskGraph$Response = void;

/**
  * Update information about a single task graph execution. 
  * @param {string} namespace The namespace that owns this task graph.
  * @param {string} id The UUID of the task graph entry.
  * @param {TaskGraph} graph Updates to make to the task graph.
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TaskGraphsApi_updateTaskGraph(data: TaskGraphsApi_updateTaskGraph$Params, options?: any): Promise<AxiosResponse<TaskGraphsApi_updateTaskGraph$Response>> {
    const localVarAxiosArgs = await TaskGraphsApi_updateTaskGraphParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface TasksApi_taskIdResultGet$Params {
    id: string,
    acceptEncoding?: string,
}

export const TasksApi_taskIdResultGet$Path = '/task/{id}/result';

/**
 * Retrieve results of an array task
 * @param {string} id task ID to retrieve stored results
 * @param {string} [acceptEncoding] Encoding to use
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const TasksApi_taskIdResultGetParamCreator = async (data: TasksApi_taskIdResultGet$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'id' is not null or undefined
if (data['id'] === null || data['id'] === undefined) {
    throw new RequiredError('id','Required parameter id was null or undefined when calling taskIdResultGet.');
}
const localVarPath = `/task/{id}/result`
    .replace(`{${"id"}}`, encodeURIComponent(String(data['id'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['acceptEncoding'] !== undefined && data['acceptEncoding'] !== null) {
    localVarHeaderParameter['Accept-Encoding'] = String(data['acceptEncoding']);
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type TasksApi_taskIdResultGet$Response = string;

/**
  * Retrieve results of an array task
  * @param {string} id task ID to retrieve stored results
  * @param {string} [acceptEncoding] Encoding to use
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function TasksApi_taskIdResultGet(data: TasksApi_taskIdResultGet$Params, options?: any): Promise<AxiosResponse<TasksApi_taskIdResultGet$Response>> {
    const localVarAxiosArgs = await TasksApi_taskIdResultGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface UdfApi_handleCopyUDF$Params {
    namespace: string,
    name: string,
    uDFCopy: UDFCopy,
    xTILEDBCLOUDACCESSCREDENTIALSNAME?: string,
    endTimestamp?: number,
}

export const UdfApi_handleCopyUDF$Path = '/udfs/{namespace}/{name}/copy';

/**
 * Copy a tiledb udf at the specified location
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} name name of UDFInfo
 * @param {UDFCopy} uDFCopy Input/Output information to copy a UDF
 * @param {string} [xTILEDBCLOUDACCESSCREDENTIALSNAME] Optional registered access credentials to use for creation
 * @param {number} [endTimestamp] Milliseconds since Unix epoch
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UdfApi_handleCopyUDFParamCreator = async (data: UdfApi_handleCopyUDF$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling handleCopyUDF.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling handleCopyUDF.');
}
// verify required parameter 'uDFCopy' is not null or undefined
if (data['uDFCopy'] === null || data['uDFCopy'] === undefined) {
    throw new RequiredError('uDFCopy','Required parameter uDFCopy was null or undefined when calling handleCopyUDF.');
}
const localVarPath = `/udfs/{namespace}/{name}/copy`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['endTimestamp'] !== undefined) {
    localVarQueryParameter['end_timestamp'] = data['endTimestamp'];
}
if (data['xTILEDBCLOUDACCESSCREDENTIALSNAME'] !== undefined && data['xTILEDBCLOUDACCESSCREDENTIALSNAME'] !== null) {
    localVarHeaderParameter['X-TILEDB-CLOUD-ACCESS-CREDENTIALS-NAME'] = String(data['xTILEDBCLOUDACCESSCREDENTIALSNAME']);
}


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['uDFCopy'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['uDFCopy'] !== undefined ? data['uDFCopy'] : {}) : (data['uDFCopy'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UdfApi_udfsNamespaceArrayEndTimestampsGet$Params {
    namespace: string,
    array: string,
    page?: number,
    perPage?: number,
}

export const UdfApi_udfsNamespaceArrayEndTimestampsGet$Path = '/udfs/{namespace}/{array}/end_timestamps';

/**
 * retrieve a list of timestamps from the array fragment info listing in milliseconds
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UdfApi_udfsNamespaceArrayEndTimestampsGetParamCreator = async (data: UdfApi_udfsNamespaceArrayEndTimestampsGet$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling udfsNamespaceArrayEndTimestampsGet.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling udfsNamespaceArrayEndTimestampsGet.');
}
const localVarPath = `/udfs/{namespace}/{array}/end_timestamps`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UdfApi_udfsNamespaceArrayPreviewGet$Params {
    namespace: string,
    array: string,
    endTimestamp?: number,
}

export const UdfApi_udfsNamespaceArrayPreviewGet$Path = '/udfs/{namespace}/{array}/preview';

/**
 * retrieve the raw exec value and language
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {number} [endTimestamp] UTC timestamp in milliseconds, will make the preview use open_at functionality to open udf at an older timestamp
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UdfApi_udfsNamespaceArrayPreviewGetParamCreator = async (data: UdfApi_udfsNamespaceArrayPreviewGet$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling udfsNamespaceArrayPreviewGet.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling udfsNamespaceArrayPreviewGet.');
}
const localVarPath = `/udfs/{namespace}/{array}/preview`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['endTimestamp'] !== undefined) {
    localVarQueryParameter['end_timestamp'] = data['endTimestamp'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type UdfApi_handleCopyUDF$Response = UDFCopied;

/**
  * Copy a tiledb udf at the specified location
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} name name of UDFInfo
  * @param {UDFCopy} uDFCopy Input/Output information to copy a UDF
  * @param {string} [xTILEDBCLOUDACCESSCREDENTIALSNAME] Optional registered access credentials to use for creation
  * @param {number} [endTimestamp] Milliseconds since Unix epoch
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UdfApi_handleCopyUDF(data: UdfApi_handleCopyUDF$Params, options?: any): Promise<AxiosResponse<UdfApi_handleCopyUDF$Response>> {
    const localVarAxiosArgs = await UdfApi_handleCopyUDFParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UdfApi_udfsNamespaceArrayEndTimestampsGet$Response = ArrayEndTimetsampsData;

/**
  * retrieve a list of timestamps from the array fragment info listing in milliseconds
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UdfApi_udfsNamespaceArrayEndTimestampsGet(data: UdfApi_udfsNamespaceArrayEndTimestampsGet$Params, options?: any): Promise<AxiosResponse<UdfApi_udfsNamespaceArrayEndTimestampsGet$Response>> {
    const localVarAxiosArgs = await UdfApi_udfsNamespaceArrayEndTimestampsGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UdfApi_udfsNamespaceArrayPreviewGet$Response = Preview;

/**
  * retrieve the raw exec value and language
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {number} [endTimestamp] UTC timestamp in milliseconds, will make the preview use open_at functionality to open udf at an older timestamp
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UdfApi_udfsNamespaceArrayPreviewGet(data: UdfApi_udfsNamespaceArrayPreviewGet$Params, options?: any): Promise<AxiosResponse<UdfApi_udfsNamespaceArrayPreviewGet$Response>> {
    const localVarAxiosArgs = await UdfApi_udfsNamespaceArrayPreviewGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};




export interface UserApi_addCredential$Params {
    namespace: string,
    accessCredential: AccessCredentialUpdate,
    page?: number,
    perPage?: number,
}

export const UserApi_addCredential$Path = '/credentials/{namespace}';

/**
 * Create a new credential for the namespace
 * @param {string} namespace namespace
 * @param {AccessCredentialUpdate} accessCredential The new credentials to be created.
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_addCredentialParamCreator = async (data: UserApi_addCredential$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling addCredential.');
}
// verify required parameter 'accessCredential' is not null or undefined
if (data['accessCredential'] === null || data['accessCredential'] === undefined) {
    throw new RequiredError('accessCredential','Required parameter accessCredential was null or undefined when calling addCredential.');
}
const localVarPath = `/credentials/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['accessCredential'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['accessCredential'] !== undefined ? data['accessCredential'] : {}) : (data['accessCredential'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_changePassword$Params {
    changePasswordParams?: UserChangePassword,
}

export const UserApi_changePassword$Path = '/user/change_password';

/**
 * Change user password
 * @param {UserChangePassword} [changePasswordParams] User\&#39;s new password
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_changePasswordParamCreator = async (data: UserApi_changePassword$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/user/change_password`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['changePasswordParams'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['changePasswordParams'] !== undefined ? data['changePasswordParams'] : {}) : (data['changePasswordParams'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export type UserApi_checkLoginCookie$Params = void;

export const UserApi_checkLoginCookie$Path = '/user/check_login';

/**
 * Get login cookie status
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_checkLoginCookieParamCreator = async (data: UserApi_checkLoginCookie$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/user/check_login`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_confirmEmail$Params {
    user: ConfirmEmail,
}

export const UserApi_confirmEmail$Path = '/user/confirm_email';

/**
 * confirm user email
 * @param {ConfirmEmail} user token to use for user requesting email confirmation
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_confirmEmailParamCreator = async (data: UserApi_confirmEmail$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'user' is not null or undefined
if (data['user'] === null || data['user'] === undefined) {
    throw new RequiredError('user','Required parameter user was null or undefined when calling confirmEmail.');
}
const localVarPath = `/user/confirm_email`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['user'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['user'] !== undefined ? data['user'] : {}) : (data['user'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_createPricing$Params {
    namespace: string,
    array: string,
    pricingInfo?: PricingInfo,
}

export const UserApi_createPricing$Path = '/payments/pricing/{namespace}/{array}';

/**
 * Create pricing info for a specified URI in a namespace
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {PricingInfo} [pricingInfo] Array pricing details
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_createPricingParamCreator = async (data: UserApi_createPricing$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling createPricing.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling createPricing.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['pricingInfo'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['pricingInfo'] !== undefined ? data['pricingInfo'] : {}) : (data['pricingInfo'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_deleteCredential$Params {
    namespace: string,
    name: string,
}

export const UserApi_deleteCredential$Path = '/credentials/{namespace}/{name}';

/**
 * Delete the named access credential. Any arrays still set to use this credential will use the namespace\'s default and may become unreachable.
 * @param {string} namespace namespace
 * @param {string} name A URL-safe version of the credential\&#39;s user-provided name
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_deleteCredentialParamCreator = async (data: UserApi_deleteCredential$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling deleteCredential.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling deleteCredential.');
}
const localVarPath = `/credentials/{namespace}/{name}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_deleteOnboardingItems$Params {
    namespace: string,
    organization?: string,
}

export const UserApi_deleteOnboardingItems$Path = '/onboarding/{namespace}';

/**
 * Deletes completed Onboarding items
 * @param {string} namespace an organization or user\&#39;s uuid
 * @param {string} [organization] Optional organization UUID. This is used for organization onboarding items. Organization onboarding items are unique per-user per-organization. Meaning that the user has different organization onboarding progress for each organization they own. 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_deleteOnboardingItemsParamCreator = async (data: UserApi_deleteOnboardingItems$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling deleteOnboardingItems.');
}
const localVarPath = `/onboarding/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['organization'] !== undefined) {
    localVarQueryParameter['organization'] = data['organization'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_deletePricing$Params {
    namespace: string,
    array: string,
}

export const UserApi_deletePricing$Path = '/payments/pricing/{namespace}/{array}';

/**
 * Delete pricing info for a specified URI in a namespace
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_deletePricingParamCreator = async (data: UserApi_deletePricing$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling deletePricing.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling deletePricing.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export type UserApi_deleteUser$Params = void;

export const UserApi_deleteUser$Path = '/user';

/**
 * delete a user
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_deleteUserParamCreator = async (data: UserApi_deleteUser$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/user`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_getBillingOverview$Params {
    namespace?: string,
}

export const UserApi_getBillingOverview$Path = '/payments/billing-overview';

/**
 * Get billing overview
 * @param {string} [namespace] namespace to filter, defaults to requesting user
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_getBillingOverviewParamCreator = async (data: UserApi_getBillingOverview$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/payments/billing-overview`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['namespace'] !== undefined) {
    localVarQueryParameter['namespace'] = data['namespace'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_getChargeOperationsAllowed$Params {
    namespace: string,
}

export const UserApi_getChargeOperationsAllowed$Path = '/charges/allowed/{namespace}';

/**
 * Get whether operations which incur charges are allowed
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_getChargeOperationsAllowedParamCreator = async (data: UserApi_getChargeOperationsAllowed$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getChargeOperationsAllowed.');
}
const localVarPath = `/charges/allowed/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_getCredential$Params {
    namespace: string,
    name: string,
}

export const UserApi_getCredential$Path = '/credentials/{namespace}/{name}';

/**
 * Retrieve an access credential by name
 * @param {string} namespace namespace
 * @param {string} name A URL-safe version of the credential\&#39;s user-provided name
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_getCredentialParamCreator = async (data: UserApi_getCredential$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getCredential.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling getCredential.');
}
const localVarPath = `/credentials/{namespace}/{name}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export type UserApi_getHasSeenV2Status$Params = void;

export const UserApi_getHasSeenV2Status$Path = '/user/has_seen_v2';

/**
 * Get status about if the user has seen UI v2
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_getHasSeenV2StatusParamCreator = async (data: UserApi_getHasSeenV2Status$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/user/has_seen_v2`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_getOnboardingItems$Params {
    namespace: string,
    organization?: string,
}

export const UserApi_getOnboardingItems$Path = '/onboarding/{namespace}';

/**
 * Get onboarding items
 * @param {string} namespace an organization or user\&#39;s uuid
 * @param {string} [organization] Optional organization UUID. This is used for organization onboarding items. Organization onboarding items are unique per-user per-organization. Meaning that the user has different organization onboarding progress for each organization they own. 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_getOnboardingItemsParamCreator = async (data: UserApi_getOnboardingItems$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getOnboardingItems.');
}
const localVarPath = `/onboarding/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['organization'] !== undefined) {
    localVarQueryParameter['organization'] = data['organization'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_getOnboardingState$Params {
    namespace: string,
    namespaceType: string,
}

export const UserApi_getOnboardingState$Path = '/onboarding/{namespace}/state';

/**
 * Get database state for specific onboarding steps that are not described or saved as onboarding items i.e. The existence of registered assets or the existence of members in an organization etc 
 * @param {string} namespace an organization or user\&#39;s uuid
 * @param {string} namespaceType the namespace type to check (each type makes different requests to REST and returns a different response)
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_getOnboardingStateParamCreator = async (data: UserApi_getOnboardingState$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getOnboardingState.');
}
// verify required parameter 'namespaceType' is not null or undefined
if (data['namespaceType'] === null || data['namespaceType'] === undefined) {
    throw new RequiredError('namespaceType','Required parameter namespaceType was null or undefined when calling getOnboardingState.');
}
const localVarPath = `/onboarding/{namespace}/state`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['namespaceType'] !== undefined) {
    localVarQueryParameter['namespace_type'] = data['namespaceType'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_getPaymentDetails$Params {
    namespace: string,
}

export const UserApi_getPaymentDetails$Path = '/payments/{namespace}';

/**
 * Get payment details
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_getPaymentDetailsParamCreator = async (data: UserApi_getPaymentDetails$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling getPaymentDetails.');
}
const localVarPath = `/payments/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_getPerDomainEndpoint$Params {
    email?: string,
}

export const UserApi_getPerDomainEndpoint$Path = '/user/perdomain_login_endpoint';

/**
 * Given an email address, returns the OpenID Connect endpoint where the user should be sent for login. 
 * @param {string} [email] The email address of the user who wants to use per-domain SSO. 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_getPerDomainEndpointParamCreator = async (data: UserApi_getPerDomainEndpoint$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/user/perdomain_login_endpoint`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['email'] !== undefined) {
    localVarQueryParameter['email'] = data['email'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export type UserApi_getSession$Params = void;

export const UserApi_getSession$Path = '/session/scoped';

/**
 * Get scoped session token
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_getSessionParamCreator = async (data: UserApi_getSession$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/session/scoped`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export type UserApi_getTokenScopes$Params = void;

export const UserApi_getTokenScopes$Path = '/tokens/scopes';

/**
 * retrieves available token scopes for a user
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_getTokenScopesParamCreator = async (data: UserApi_getTokenScopes$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/tokens/scopes`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export type UserApi_getUser$Params = void;

export const UserApi_getUser$Path = '/user';

/**
 * Fetch a user
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_getUserParamCreator = async (data: UserApi_getUser$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/user`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_getUserPasswordEntropy$Params {
    password: string,
}

export const UserApi_getUserPasswordEntropy$Path = '/user/password_entropy';

/**
 * Calculate entropy of a user password
 * @param {string} password password string entered by the user
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_getUserPasswordEntropyParamCreator = async (data: UserApi_getUserPasswordEntropy$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'password' is not null or undefined
if (data['password'] === null || data['password'] === undefined) {
    throw new RequiredError('password','Required parameter password was null or undefined when calling getUserPasswordEntropy.');
}
const localVarPath = `/user/password_entropy`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['password'] !== undefined) {
    localVarQueryParameter['password'] = data['password'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_getUsersAutocomplete$Params {
    input: string,
}

export const UserApi_getUsersAutocomplete$Path = '/user/autocomplete';

/**
 * Fetch list of usernames matching the user\'s partial input
 * @param {string} input partial string entered by the user to engage autocomplete
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_getUsersAutocompleteParamCreator = async (data: UserApi_getUsersAutocomplete$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'input' is not null or undefined
if (data['input'] === null || data['input'] === undefined) {
    throw new RequiredError('input','Required parameter input was null or undefined when calling getUsersAutocomplete.');
}
const localVarPath = `/user/autocomplete`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['input'] !== undefined) {
    localVarQueryParameter['input'] = data['input'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_initiateResetUserPassword$Params {
    user: InlineObject,
}

export const UserApi_initiateResetUserPassword$Path = '/user/initiate_password_reset';

/**
 * send email to reset a user password
 * @param {InlineObject} user 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_initiateResetUserPasswordParamCreator = async (data: UserApi_initiateResetUserPassword$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'user' is not null or undefined
if (data['user'] === null || data['user'] === undefined) {
    throw new RequiredError('user','Required parameter user was null or undefined when calling initiateResetUserPassword.');
}
const localVarPath = `/user/initiate_password_reset`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['user'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['user'] !== undefined ? data['user'] : {}) : (data['user'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_listCredentials$Params {
    namespace: string,
    page?: number,
    perPage?: number,
    provider?: string,
}

export const UserApi_listCredentials$Path = '/credentials/{namespace}';

/**
 * List the credentials available in the namespace
 * @param {string} namespace namespace
 * @param {number} [page] pagination offset
 * @param {number} [perPage] pagination limit
 * @param {string} [provider] Show only the credentials from this provider
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_listCredentialsParamCreator = async (data: UserApi_listCredentials$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling listCredentials.');
}
const localVarPath = `/credentials/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['page'] !== undefined) {
    localVarQueryParameter['page'] = data['page'];
}
if (data['perPage'] !== undefined) {
    localVarQueryParameter['per_page'] = data['perPage'];
}
if (data['provider'] !== undefined) {
    localVarQueryParameter['provider'] = data['provider'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_login$Params {
    loginDetails: Login,
}

export const UserApi_login$Path = '/user/login';

/**
 * Login and create a session
 * @param {Login} loginDetails login details
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_loginParamCreator = async (data: UserApi_login$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'loginDetails' is not null or undefined
if (data['loginDetails'] === null || data['loginDetails'] === undefined) {
    throw new RequiredError('loginDetails','Required parameter loginDetails was null or undefined when calling login.');
}
const localVarPath = `/user/login`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['loginDetails'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['loginDetails'] !== undefined ? data['loginDetails'] : {}) : (data['loginDetails'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export type UserApi_logout$Params = void;

export const UserApi_logout$Path = '/user/logout';

/**
 * Logout and remove login cookie
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_logoutParamCreator = async (data: UserApi_logout$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/user/logout`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_oAuth2Consent$Params {
    consentChallenge: string,
    consentDetails: OAuth2ConsentDetails,
}

export const UserApi_oAuth2Consent$Path = '/oauth2/consent';

/**
 * Consent oauth2 scopes
 * @param {string} consentChallenge consent challenge for oauth2
 * @param {OAuth2ConsentDetails} consentDetails user\&#39;s consent details
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_oAuth2ConsentParamCreator = async (data: UserApi_oAuth2Consent$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'consentChallenge' is not null or undefined
if (data['consentChallenge'] === null || data['consentChallenge'] === undefined) {
    throw new RequiredError('consentChallenge','Required parameter consentChallenge was null or undefined when calling oAuth2Consent.');
}
// verify required parameter 'consentDetails' is not null or undefined
if (data['consentDetails'] === null || data['consentDetails'] === undefined) {
    throw new RequiredError('consentDetails','Required parameter consentDetails was null or undefined when calling oAuth2Consent.');
}
const localVarPath = `/oauth2/consent`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['consentChallenge'] !== undefined) {
    localVarQueryParameter['consent_challenge'] = data['consentChallenge'];
}


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['consentDetails'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['consentDetails'] !== undefined ? data['consentDetails'] : {}) : (data['consentDetails'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_oAuth2ConsentCheck$Params {
    consentChallenge: string,
}

export const UserApi_oAuth2ConsentCheck$Path = '/oauth2/consent/check';

/**
 * Check with oauth server what consent request to show
 * @param {string} consentChallenge consent challenge for oauth2
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_oAuth2ConsentCheckParamCreator = async (data: UserApi_oAuth2ConsentCheck$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'consentChallenge' is not null or undefined
if (data['consentChallenge'] === null || data['consentChallenge'] === undefined) {
    throw new RequiredError('consentChallenge','Required parameter consentChallenge was null or undefined when calling oAuth2ConsentCheck.');
}
const localVarPath = `/oauth2/consent/check`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['consentChallenge'] !== undefined) {
    localVarQueryParameter['consent_challenge'] = data['consentChallenge'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_oauth2Login$Params {
    loginChallenge: string,
    loginDetails?: Login,
}

export const UserApi_oauth2Login$Path = '/oauth2/login';

/**
 * Login to oauth2
 * @param {string} loginChallenge login challenge for oauth2
 * @param {Login} [loginDetails] login details
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_oauth2LoginParamCreator = async (data: UserApi_oauth2Login$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'loginChallenge' is not null or undefined
if (data['loginChallenge'] === null || data['loginChallenge'] === undefined) {
    throw new RequiredError('loginChallenge','Required parameter loginChallenge was null or undefined when calling oauth2Login.');
}
const localVarPath = `/oauth2/login`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['loginChallenge'] !== undefined) {
    localVarQueryParameter['login_challenge'] = data['loginChallenge'];
}


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['loginDetails'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['loginDetails'] !== undefined ? data['loginDetails'] : {}) : (data['loginDetails'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_oauth2LoginCheck$Params {
    loginChallenge: string,
}

export const UserApi_oauth2LoginCheck$Path = '/oauth2/login/check';

/**
 * Check with oauth server if login is required
 * @param {string} loginChallenge login challenge for oauth2
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_oauth2LoginCheckParamCreator = async (data: UserApi_oauth2LoginCheck$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'loginChallenge' is not null or undefined
if (data['loginChallenge'] === null || data['loginChallenge'] === undefined) {
    throw new RequiredError('loginChallenge','Required parameter loginChallenge was null or undefined when calling oauth2LoginCheck.');
}
const localVarPath = `/oauth2/login/check`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['loginChallenge'] !== undefined) {
    localVarQueryParameter['login_challenge'] = data['loginChallenge'];
}


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export type UserApi_patchHasSeenV2Status$Params = void;

export const UserApi_patchHasSeenV2Status$Path = '/user/has_seen_v2';

/**
 * User has seen UI v2
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_patchHasSeenV2StatusParamCreator = async (data: UserApi_patchHasSeenV2Status$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/user/has_seen_v2`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_removePaymentDetails$Params {
    namespace: string,
    stripeDetails: StripeDetails,
}

export const UserApi_removePaymentDetails$Path = '/payments/{namespace}';

/**
 * Remove payment details
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {StripeDetails} stripeDetails Stripe one use token from element card
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_removePaymentDetailsParamCreator = async (data: UserApi_removePaymentDetails$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling removePaymentDetails.');
}
// verify required parameter 'stripeDetails' is not null or undefined
if (data['stripeDetails'] === null || data['stripeDetails'] === undefined) {
    throw new RequiredError('stripeDetails','Required parameter stripeDetails was null or undefined when calling removePaymentDetails.');
}
const localVarPath = `/payments/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['stripeDetails'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['stripeDetails'] !== undefined ? data['stripeDetails'] : {}) : (data['stripeDetails'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_requestCredits$Params {
    requestCreditsParams?: UserRequestCredits,
}

export const UserApi_requestCredits$Path = '/user/request_credits';

/**
 * Request credits from sales team
 * @param {UserRequestCredits} [requestCreditsParams] User\&#39;s request for free credits
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_requestCreditsParamCreator = async (data: UserApi_requestCredits$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/user/request_credits`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['requestCreditsParams'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['requestCreditsParams'] !== undefined ? data['requestCreditsParams'] : {}) : (data['requestCreditsParams'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_requestToken$Params {
    tokenRequest?: TokenRequest,
}

export const UserApi_requestToken$Path = '/token';

/**
 * Request an authorization token, optionally taken a TokenRequest object to set parameters on the token
 * @param {TokenRequest} [tokenRequest] token request object
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_requestTokenParamCreator = async (data: UserApi_requestToken$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/token`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['tokenRequest'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['tokenRequest'] !== undefined ? data['tokenRequest'] : {}) : (data['tokenRequest'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_resendConfirmEmail$Params {
    user: InlineObject1,
}

export const UserApi_resendConfirmEmail$Path = '/user/resend_confirm_email';

/**
 * resend email to confirm user email
 * @param {InlineObject1} user 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_resendConfirmEmailParamCreator = async (data: UserApi_resendConfirmEmail$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'user' is not null or undefined
if (data['user'] === null || data['user'] === undefined) {
    throw new RequiredError('user','Required parameter user was null or undefined when calling resendConfirmEmail.');
}
const localVarPath = `/user/resend_confirm_email`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['user'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['user'] !== undefined ? data['user'] : {}) : (data['user'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_resetUserPassword$Params {
    user: PasswordReset,
}

export const UserApi_resetUserPassword$Path = '/user/password_reset';

/**
 * reset user password
 * @param {PasswordReset} user password to update for user requesting update
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_resetUserPasswordParamCreator = async (data: UserApi_resetUserPassword$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'user' is not null or undefined
if (data['user'] === null || data['user'] === undefined) {
    throw new RequiredError('user','Required parameter user was null or undefined when calling resetUserPassword.');
}
const localVarPath = `/user/password_reset`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['user'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['user'] !== undefined ? data['user'] : {}) : (data['user'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_revokeToken$Params {
    token: string,
}

export const UserApi_revokeToken$Path = '/tokens/{token}';

/**
 * revoke an authorization token
 * @param {string} token token name or token itself
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_revokeTokenParamCreator = async (data: UserApi_revokeToken$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'token' is not null or undefined
if (data['token'] === null || data['token'] === undefined) {
    throw new RequiredError('token','Required parameter token was null or undefined when calling revokeToken.');
}
const localVarPath = `/tokens/{token}`
    .replace(`{${"token"}}`, encodeURIComponent(String(data['token'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_setOnboardingItems$Params {
    namespace: string,
    items: OnboardingItems,
    organization?: string,
}

export const UserApi_setOnboardingItems$Path = '/onboarding/{namespace}';

/**
 * Set completed Onboarding items
 * @param {string} namespace an organization or user\&#39;s uuid
 * @param {OnboardingItems} items UI informs Rest server for Onboarding item
 * @param {string} [organization] Optional organization UUID. This is used for organization onboarding items. Organization onboarding items are unique per-user per-organization. Meaning that the user has different organization onboarding progress for each organization they own. 
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_setOnboardingItemsParamCreator = async (data: UserApi_setOnboardingItems$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling setOnboardingItems.');
}
// verify required parameter 'items' is not null or undefined
if (data['items'] === null || data['items'] === undefined) {
    throw new RequiredError('items','Required parameter items was null or undefined when calling setOnboardingItems.');
}
const localVarPath = `/onboarding/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['organization'] !== undefined) {
    localVarQueryParameter['organization'] = data['organization'];
}


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['items'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['items'] !== undefined ? data['items'] : {}) : (data['items'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_setPaymentDetails$Params {
    namespace: string,
    stripeDetails: StripeDetails,
    radarSession?: string,
}

export const UserApi_setPaymentDetails$Path = '/payments/{namespace}';

/**
 * Set payment details
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {StripeDetails} stripeDetails Stripe one use token from element card
 * @param {string} [radarSession] Provides fraud protection
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_setPaymentDetailsParamCreator = async (data: UserApi_setPaymentDetails$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling setPaymentDetails.');
}
// verify required parameter 'stripeDetails' is not null or undefined
if (data['stripeDetails'] === null || data['stripeDetails'] === undefined) {
    throw new RequiredError('stripeDetails','Required parameter stripeDetails was null or undefined when calling setPaymentDetails.');
}
const localVarPath = `/payments/{namespace}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;
if (data['radarSession'] !== undefined) {
    localVarQueryParameter['radar_session'] = data['radarSession'];
}


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['stripeDetails'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['stripeDetails'] !== undefined ? data['stripeDetails'] : {}) : (data['stripeDetails'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_signInPerDomain$Params {
    perDomainDetails: PerDomainSignIn,
}

export const UserApi_signInPerDomain$Path = '/user/sso/perdomain';

/**
 * Sign in with company account and create a session
 * @param {PerDomainSignIn} perDomainDetails perdomain details
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_signInPerDomainParamCreator = async (data: UserApi_signInPerDomain$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'perDomainDetails' is not null or undefined
if (data['perDomainDetails'] === null || data['perDomainDetails'] === undefined) {
    throw new RequiredError('perDomainDetails','Required parameter perDomainDetails was null or undefined when calling signInPerDomain.');
}
const localVarPath = `/user/sso/perdomain`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['perDomainDetails'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['perDomainDetails'] !== undefined ? data['perDomainDetails'] : {}) : (data['perDomainDetails'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_signInWithGithub$Params {
    githubDetails: GithubSignIn,
}

export const UserApi_signInWithGithub$Path = '/user/sso/github';

/**
 * Sign in with github account and create a session
 * @param {GithubSignIn} githubDetails github details
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_signInWithGithubParamCreator = async (data: UserApi_signInWithGithub$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'githubDetails' is not null or undefined
if (data['githubDetails'] === null || data['githubDetails'] === undefined) {
    throw new RequiredError('githubDetails','Required parameter githubDetails was null or undefined when calling signInWithGithub.');
}
const localVarPath = `/user/sso/github`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['githubDetails'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['githubDetails'] !== undefined ? data['githubDetails'] : {}) : (data['githubDetails'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_signInWithGoogle$Params {
    googleDetails: GoogleSignIn,
}

export const UserApi_signInWithGoogle$Path = '/user/sso/google';

/**
 * Sign in with github account and create a session
 * @param {GoogleSignIn} googleDetails google details
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_signInWithGoogleParamCreator = async (data: UserApi_signInWithGoogle$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'googleDetails' is not null or undefined
if (data['googleDetails'] === null || data['googleDetails'] === undefined) {
    throw new RequiredError('googleDetails','Required parameter googleDetails was null or undefined when calling signInWithGoogle.');
}
const localVarPath = `/user/sso/google`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['googleDetails'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['googleDetails'] !== undefined ? data['googleDetails'] : {}) : (data['googleDetails'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_signInWithOkta$Params {
    oktaDetails: OktaSignIn,
}

export const UserApi_signInWithOkta$Path = '/user/sso/okta';

/**
 * Sign in with okta account and create a session
 * @param {OktaSignIn} oktaDetails github details
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_signInWithOktaParamCreator = async (data: UserApi_signInWithOkta$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'oktaDetails' is not null or undefined
if (data['oktaDetails'] === null || data['oktaDetails'] === undefined) {
    throw new RequiredError('oktaDetails','Required parameter oktaDetails was null or undefined when calling signInWithOkta.');
}
const localVarPath = `/user/sso/okta`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['oktaDetails'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['oktaDetails'] !== undefined ? data['oktaDetails'] : {}) : (data['oktaDetails'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_signInWithStripe$Params {
    stripeDetails: StripeSignIn,
}

export const UserApi_signInWithStripe$Path = '/user/sso/stripe';

/**
 * Sign in with stripe account and create a session
 * @param {StripeSignIn} stripeDetails stripe details
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_signInWithStripeParamCreator = async (data: UserApi_signInWithStripe$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'stripeDetails' is not null or undefined
if (data['stripeDetails'] === null || data['stripeDetails'] === undefined) {
    throw new RequiredError('stripeDetails','Required parameter stripeDetails was null or undefined when calling signInWithStripe.');
}
const localVarPath = `/user/sso/stripe`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['stripeDetails'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['stripeDetails'] !== undefined ? data['stripeDetails'] : {}) : (data['stripeDetails'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_subscribeToArray$Params {
    namespace: string,
    array: string,
}

export const UserApi_subscribeToArray$Path = '/payments/pricing/{namespace}/{array}/subscribe';

/**
 * Subscribe to an array set up for monetization
 * @param {string} namespace Namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_subscribeToArrayParamCreator = async (data: UserApi_subscribeToArray$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling subscribeToArray.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling subscribeToArray.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}/subscribe`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export type UserApi_tokensGet$Params = void;

export const UserApi_tokensGet$Path = '/tokens';

/**
 * Fetch a list of user tokens
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_tokensGetParamCreator = async (data: UserApi_tokensGet$Params, options: any = {}): Promise<RequestArgs> => {
const localVarPath = `/tokens`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_unsubscribeFromArray$Params {
    namespace: string,
    array: string,
}

export const UserApi_unsubscribeFromArray$Path = '/payments/pricing/{namespace}/{array}/subscribe';

/**
 * Unsubscribe to an array set up for monetization
 * @param {string} namespace Namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_unsubscribeFromArrayParamCreator = async (data: UserApi_unsubscribeFromArray$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling unsubscribeFromArray.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling unsubscribeFromArray.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}/subscribe`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_updateCredential$Params {
    namespace: string,
    name: string,
    accessCredential: AccessCredentialUpdate,
}

export const UserApi_updateCredential$Path = '/credentials/{namespace}/{name}';

/**
 * Update the named access credential. This will also update the information used to access arrays set to use this credential.
 * @param {string} namespace namespace
 * @param {string} name A URL-safe version of the credential\&#39;s user-provided name
 * @param {AccessCredentialUpdate} accessCredential Changes to make to this credential
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_updateCredentialParamCreator = async (data: UserApi_updateCredential$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling updateCredential.');
}
// verify required parameter 'name' is not null or undefined
if (data['name'] === null || data['name'] === undefined) {
    throw new RequiredError('name','Required parameter name was null or undefined when calling updateCredential.');
}
// verify required parameter 'accessCredential' is not null or undefined
if (data['accessCredential'] === null || data['accessCredential'] === undefined) {
    throw new RequiredError('accessCredential','Required parameter accessCredential was null or undefined when calling updateCredential.');
}
const localVarPath = `/credentials/{namespace}/{name}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"name"}}`, encodeURIComponent(String(data['name'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['accessCredential'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['accessCredential'] !== undefined ? data['accessCredential'] : {}) : (data['accessCredential'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_updatePricing$Params {
    namespace: string,
    array: string,
    pricingInfo: PricingInfo,
}

export const UserApi_updatePricing$Path = '/payments/pricing/{namespace}/{array}';

/**
 * Update pricing info for a specified URI in a namespace
 * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
 * @param {string} array name/uri of array that is url-encoded
 * @param {PricingInfo} pricingInfo Pricing metadata to update
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_updatePricingParamCreator = async (data: UserApi_updatePricing$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'namespace' is not null or undefined
if (data['namespace'] === null || data['namespace'] === undefined) {
    throw new RequiredError('namespace','Required parameter namespace was null or undefined when calling updatePricing.');
}
// verify required parameter 'array' is not null or undefined
if (data['array'] === null || data['array'] === undefined) {
    throw new RequiredError('array','Required parameter array was null or undefined when calling updatePricing.');
}
// verify required parameter 'pricingInfo' is not null or undefined
if (data['pricingInfo'] === null || data['pricingInfo'] === undefined) {
    throw new RequiredError('pricingInfo','Required parameter pricingInfo was null or undefined when calling updatePricing.');
}
const localVarPath = `/payments/pricing/{namespace}/{array}`
    .replace(`{${"namespace"}}`, encodeURIComponent(String(data['namespace'])))
    .replace(`{${"array"}}`, encodeURIComponent(String(data['array'])));
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['pricingInfo'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['pricingInfo'] !== undefined ? data['pricingInfo'] : {}) : (data['pricingInfo'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_userRegistration$Params {
    signupParams: UserSignupParams,
}

export const UserApi_userRegistration$Path = '/user/signup';

/**
 * Register a new user
 * @param {UserSignupParams} signupParams user being registered
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_userRegistrationParamCreator = async (data: UserApi_userRegistration$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'signupParams' is not null or undefined
if (data['signupParams'] === null || data['signupParams'] === undefined) {
    throw new RequiredError('signupParams','Required parameter signupParams was null or undefined when calling userRegistration.');
}
const localVarPath = `/user/signup`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['signupParams'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['signupParams'] !== undefined ? data['signupParams'] : {}) : (data['signupParams'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};

export interface UserApi_userUpdate$Params {
    user: UserUpdateParams,
}

export const UserApi_userUpdate$Path = '/user';

/**
 * Update user details
 * @param {UserUpdateParams} user user details to update
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
*/
const UserApi_userUpdateParamCreator = async (data: UserApi_userUpdate$Params, options: any = {}): Promise<RequestArgs> => {
// verify required parameter 'user' is not null or undefined
if (data['user'] === null || data['user'] === undefined) {
    throw new RequiredError('user','Required parameter user was null or undefined when calling userUpdate.');
}
const localVarPath = `/user`;
const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
let baseOptions: any;

const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
const localVarHeaderParameter = {} as any;
const localVarQueryParameter = {} as any;


    localVarHeaderParameter['Content-Type'] = 'application/json';
    localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // @ts-ignore
    delete localVarUrlObj.search;
    let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
    localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
    const needsSerialization = (localVarRequestOptions.headers['Content-Type'] !== 'application/octet-stream' && (typeof data['user'] !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json');
    localVarRequestOptions.data =  needsSerialization ? JSON.stringify(data['user'] !== undefined ? data['user'] : {}) : (data['user'] || "");
    return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
    };
};


export type UserApi_addCredential$Response = void;

/**
  * Create a new credential for the namespace
  * @param {string} namespace namespace
  * @param {AccessCredentialUpdate} accessCredential The new credentials to be created.
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_addCredential(data: UserApi_addCredential$Params, options?: any): Promise<AxiosResponse<UserApi_addCredential$Response>> {
    const localVarAxiosArgs = await UserApi_addCredentialParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_changePassword$Response = void;

/**
  * Change user password
  * @param {UserChangePassword} [changePasswordParams] User\&#39;s new password
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_changePassword(data: UserApi_changePassword$Params, options?: any): Promise<AxiosResponse<UserApi_changePassword$Response>> {
    const localVarAxiosArgs = await UserApi_changePasswordParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_checkLoginCookie$Response = InlineResponse200;

/**
  * Get login cookie status
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_checkLoginCookie(data: UserApi_checkLoginCookie$Params, options?: any): Promise<AxiosResponse<UserApi_checkLoginCookie$Response>> {
    const localVarAxiosArgs = await UserApi_checkLoginCookieParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_confirmEmail$Response = void;

/**
  * confirm user email
  * @param {ConfirmEmail} user token to use for user requesting email confirmation
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_confirmEmail(data: UserApi_confirmEmail$Params, options?: any): Promise<AxiosResponse<UserApi_confirmEmail$Response>> {
    const localVarAxiosArgs = await UserApi_confirmEmailParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_createPricing$Response = void;

/**
  * Create pricing info for a specified URI in a namespace
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {PricingInfo} [pricingInfo] Array pricing details
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_createPricing(data: UserApi_createPricing$Params, options?: any): Promise<AxiosResponse<UserApi_createPricing$Response>> {
    const localVarAxiosArgs = await UserApi_createPricingParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_deleteCredential$Response = void;

/**
  * Delete the named access credential. Any arrays still set to use this credential will use the namespace\'s default and may become unreachable.
  * @param {string} namespace namespace
  * @param {string} name A URL-safe version of the credential\&#39;s user-provided name
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_deleteCredential(data: UserApi_deleteCredential$Params, options?: any): Promise<AxiosResponse<UserApi_deleteCredential$Response>> {
    const localVarAxiosArgs = await UserApi_deleteCredentialParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_deleteOnboardingItems$Response = void;

/**
  * Deletes completed Onboarding items
  * @param {string} namespace an organization or user\&#39;s uuid
  * @param {string} [organization] Optional organization UUID. This is used for organization onboarding items. Organization onboarding items are unique per-user per-organization. Meaning that the user has different organization onboarding progress for each organization they own. 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_deleteOnboardingItems(data: UserApi_deleteOnboardingItems$Params, options?: any): Promise<AxiosResponse<UserApi_deleteOnboardingItems$Response>> {
    const localVarAxiosArgs = await UserApi_deleteOnboardingItemsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_deletePricing$Response = void;

/**
  * Delete pricing info for a specified URI in a namespace
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_deletePricing(data: UserApi_deletePricing$Params, options?: any): Promise<AxiosResponse<UserApi_deletePricing$Response>> {
    const localVarAxiosArgs = await UserApi_deletePricingParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_deleteUser$Response = void;

/**
  * delete a user
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_deleteUser(data: UserApi_deleteUser$Params, options?: any): Promise<AxiosResponse<UserApi_deleteUser$Response>> {
    const localVarAxiosArgs = await UserApi_deleteUserParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_getBillingOverview$Response = BillingOverview;

/**
  * Get billing overview
  * @param {string} [namespace] namespace to filter, defaults to requesting user
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_getBillingOverview(data: UserApi_getBillingOverview$Params, options?: any): Promise<AxiosResponse<UserApi_getBillingOverview$Response>> {
    const localVarAxiosArgs = await UserApi_getBillingOverviewParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_getChargeOperationsAllowed$Response = void;

/**
  * Get whether operations which incur charges are allowed
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_getChargeOperationsAllowed(data: UserApi_getChargeOperationsAllowed$Params, options?: any): Promise<AxiosResponse<UserApi_getChargeOperationsAllowed$Response>> {
    const localVarAxiosArgs = await UserApi_getChargeOperationsAllowedParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_getCredential$Response = AccessCredential;

/**
  * Retrieve an access credential by name
  * @param {string} namespace namespace
  * @param {string} name A URL-safe version of the credential\&#39;s user-provided name
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_getCredential(data: UserApi_getCredential$Params, options?: any): Promise<AxiosResponse<UserApi_getCredential$Response>> {
    const localVarAxiosArgs = await UserApi_getCredentialParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_getHasSeenV2Status$Response = HasSeenV2StatusGetResponse;

/**
  * Get status about if the user has seen UI v2
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_getHasSeenV2Status(data: UserApi_getHasSeenV2Status$Params, options?: any): Promise<AxiosResponse<UserApi_getHasSeenV2Status$Response>> {
    const localVarAxiosArgs = await UserApi_getHasSeenV2StatusParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_getOnboardingItems$Response = OnboardingItems;

/**
  * Get onboarding items
  * @param {string} namespace an organization or user\&#39;s uuid
  * @param {string} [organization] Optional organization UUID. This is used for organization onboarding items. Organization onboarding items are unique per-user per-organization. Meaning that the user has different organization onboarding progress for each organization they own. 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_getOnboardingItems(data: UserApi_getOnboardingItems$Params, options?: any): Promise<AxiosResponse<UserApi_getOnboardingItems$Response>> {
    const localVarAxiosArgs = await UserApi_getOnboardingItemsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_getOnboardingState$Response = OnboardingState;

/**
  * Get database state for specific onboarding steps that are not described or saved as onboarding items i.e. The existence of registered assets or the existence of members in an organization etc 
  * @param {string} namespace an organization or user\&#39;s uuid
  * @param {string} namespaceType the namespace type to check (each type makes different requests to REST and returns a different response)
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_getOnboardingState(data: UserApi_getOnboardingState$Params, options?: any): Promise<AxiosResponse<UserApi_getOnboardingState$Response>> {
    const localVarAxiosArgs = await UserApi_getOnboardingStateParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_getPaymentDetails$Response = PaymentMethod;

/**
  * Get payment details
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_getPaymentDetails(data: UserApi_getPaymentDetails$Params, options?: any): Promise<AxiosResponse<UserApi_getPaymentDetails$Response>> {
    const localVarAxiosArgs = await UserApi_getPaymentDetailsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_getPerDomainEndpoint$Response = PerDomainOIDCEndpoint;

/**
  * Given an email address, returns the OpenID Connect endpoint where the user should be sent for login. 
  * @param {string} [email] The email address of the user who wants to use per-domain SSO. 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_getPerDomainEndpoint(data: UserApi_getPerDomainEndpoint$Params, options?: any): Promise<AxiosResponse<UserApi_getPerDomainEndpoint$Response>> {
    const localVarAxiosArgs = await UserApi_getPerDomainEndpointParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_getSession$Response = Token;

/**
  * Get scoped session token
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_getSession(data: UserApi_getSession$Params, options?: any): Promise<AxiosResponse<UserApi_getSession$Response>> {
    const localVarAxiosArgs = await UserApi_getSessionParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_getTokenScopes$Response = Array<TokenScope>;

/**
  * retrieves available token scopes for a user
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_getTokenScopes(data: UserApi_getTokenScopes$Params, options?: any): Promise<AxiosResponse<UserApi_getTokenScopes$Response>> {
    const localVarAxiosArgs = await UserApi_getTokenScopesParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_getUser$Response = User;

/**
  * Fetch a user
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_getUser(data: UserApi_getUser$Params, options?: any): Promise<AxiosResponse<UserApi_getUser$Response>> {
    const localVarAxiosArgs = await UserApi_getUserParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_getUserPasswordEntropy$Response = UserPasswordEntropy;

/**
  * Calculate entropy of a user password
  * @param {string} password password string entered by the user
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_getUserPasswordEntropy(data: UserApi_getUserPasswordEntropy$Params, options?: any): Promise<AxiosResponse<UserApi_getUserPasswordEntropy$Response>> {
    const localVarAxiosArgs = await UserApi_getUserPasswordEntropyParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_getUsersAutocomplete$Response = Array<string>;

/**
  * Fetch list of usernames matching the user\'s partial input
  * @param {string} input partial string entered by the user to engage autocomplete
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_getUsersAutocomplete(data: UserApi_getUsersAutocomplete$Params, options?: any): Promise<AxiosResponse<UserApi_getUsersAutocomplete$Response>> {
    const localVarAxiosArgs = await UserApi_getUsersAutocompleteParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_initiateResetUserPassword$Response = void;

/**
  * send email to reset a user password
  * @param {InlineObject} user 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_initiateResetUserPassword(data: UserApi_initiateResetUserPassword$Params, options?: any): Promise<AxiosResponse<UserApi_initiateResetUserPassword$Response>> {
    const localVarAxiosArgs = await UserApi_initiateResetUserPasswordParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_listCredentials$Response = AccessCredentialsData;

/**
  * List the credentials available in the namespace
  * @param {string} namespace namespace
  * @param {number} [page] pagination offset
  * @param {number} [perPage] pagination limit
  * @param {string} [provider] Show only the credentials from this provider
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_listCredentials(data: UserApi_listCredentials$Params, options?: any): Promise<AxiosResponse<UserApi_listCredentials$Response>> {
    const localVarAxiosArgs = await UserApi_listCredentialsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_login$Response = void;

/**
  * Login and create a session
  * @param {Login} loginDetails login details
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_login(data: UserApi_login$Params, options?: any): Promise<AxiosResponse<UserApi_login$Response>> {
    const localVarAxiosArgs = await UserApi_loginParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_logout$Response = void;

/**
  * Logout and remove login cookie
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_logout(data: UserApi_logout$Params, options?: any): Promise<AxiosResponse<UserApi_logout$Response>> {
    const localVarAxiosArgs = await UserApi_logoutParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_oAuth2Consent$Response = OAuth2Redirect;

/**
  * Consent oauth2 scopes
  * @param {string} consentChallenge consent challenge for oauth2
  * @param {OAuth2ConsentDetails} consentDetails user\&#39;s consent details
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_oAuth2Consent(data: UserApi_oAuth2Consent$Params, options?: any): Promise<AxiosResponse<UserApi_oAuth2Consent$Response>> {
    const localVarAxiosArgs = await UserApi_oAuth2ConsentParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_oAuth2ConsentCheck$Response = OAuth2ConsentDetails;

/**
  * Check with oauth server what consent request to show
  * @param {string} consentChallenge consent challenge for oauth2
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_oAuth2ConsentCheck(data: UserApi_oAuth2ConsentCheck$Params, options?: any): Promise<AxiosResponse<UserApi_oAuth2ConsentCheck$Response>> {
    const localVarAxiosArgs = await UserApi_oAuth2ConsentCheckParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_oauth2Login$Response = OAuth2Redirect;

/**
  * Login to oauth2
  * @param {string} loginChallenge login challenge for oauth2
  * @param {Login} [loginDetails] login details
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_oauth2Login(data: UserApi_oauth2Login$Params, options?: any): Promise<AxiosResponse<UserApi_oauth2Login$Response>> {
    const localVarAxiosArgs = await UserApi_oauth2LoginParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_oauth2LoginCheck$Response = OAuth2Redirect;

/**
  * Check with oauth server if login is required
  * @param {string} loginChallenge login challenge for oauth2
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_oauth2LoginCheck(data: UserApi_oauth2LoginCheck$Params, options?: any): Promise<AxiosResponse<UserApi_oauth2LoginCheck$Response>> {
    const localVarAxiosArgs = await UserApi_oauth2LoginCheckParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_patchHasSeenV2Status$Response = void;

/**
  * User has seen UI v2
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_patchHasSeenV2Status(data: UserApi_patchHasSeenV2Status$Params, options?: any): Promise<AxiosResponse<UserApi_patchHasSeenV2Status$Response>> {
    const localVarAxiosArgs = await UserApi_patchHasSeenV2StatusParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_removePaymentDetails$Response = void;

/**
  * Remove payment details
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {StripeDetails} stripeDetails Stripe one use token from element card
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_removePaymentDetails(data: UserApi_removePaymentDetails$Params, options?: any): Promise<AxiosResponse<UserApi_removePaymentDetails$Response>> {
    const localVarAxiosArgs = await UserApi_removePaymentDetailsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_requestCredits$Response = void;

/**
  * Request credits from sales team
  * @param {UserRequestCredits} [requestCreditsParams] User\&#39;s request for free credits
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_requestCredits(data: UserApi_requestCredits$Params, options?: any): Promise<AxiosResponse<UserApi_requestCredits$Response>> {
    const localVarAxiosArgs = await UserApi_requestCreditsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_requestToken$Response = Token;

/**
  * Request an authorization token, optionally taken a TokenRequest object to set parameters on the token
  * @param {TokenRequest} [tokenRequest] token request object
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_requestToken(data: UserApi_requestToken$Params, options?: any): Promise<AxiosResponse<UserApi_requestToken$Response>> {
    const localVarAxiosArgs = await UserApi_requestTokenParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_resendConfirmEmail$Response = void;

/**
  * resend email to confirm user email
  * @param {InlineObject1} user 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_resendConfirmEmail(data: UserApi_resendConfirmEmail$Params, options?: any): Promise<AxiosResponse<UserApi_resendConfirmEmail$Response>> {
    const localVarAxiosArgs = await UserApi_resendConfirmEmailParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_resetUserPassword$Response = void;

/**
  * reset user password
  * @param {PasswordReset} user password to update for user requesting update
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_resetUserPassword(data: UserApi_resetUserPassword$Params, options?: any): Promise<AxiosResponse<UserApi_resetUserPassword$Response>> {
    const localVarAxiosArgs = await UserApi_resetUserPasswordParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_revokeToken$Response = void;

/**
  * revoke an authorization token
  * @param {string} token token name or token itself
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_revokeToken(data: UserApi_revokeToken$Params, options?: any): Promise<AxiosResponse<UserApi_revokeToken$Response>> {
    const localVarAxiosArgs = await UserApi_revokeTokenParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_setOnboardingItems$Response = void;

/**
  * Set completed Onboarding items
  * @param {string} namespace an organization or user\&#39;s uuid
  * @param {OnboardingItems} items UI informs Rest server for Onboarding item
  * @param {string} [organization] Optional organization UUID. This is used for organization onboarding items. Organization onboarding items are unique per-user per-organization. Meaning that the user has different organization onboarding progress for each organization they own. 
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_setOnboardingItems(data: UserApi_setOnboardingItems$Params, options?: any): Promise<AxiosResponse<UserApi_setOnboardingItems$Response>> {
    const localVarAxiosArgs = await UserApi_setOnboardingItemsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_setPaymentDetails$Response = void;

/**
  * Set payment details
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {StripeDetails} stripeDetails Stripe one use token from element card
  * @param {string} [radarSession] Provides fraud protection
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_setPaymentDetails(data: UserApi_setPaymentDetails$Params, options?: any): Promise<AxiosResponse<UserApi_setPaymentDetails$Response>> {
    const localVarAxiosArgs = await UserApi_setPaymentDetailsParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_signInPerDomain$Response = void;

/**
  * Sign in with company account and create a session
  * @param {PerDomainSignIn} perDomainDetails perdomain details
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_signInPerDomain(data: UserApi_signInPerDomain$Params, options?: any): Promise<AxiosResponse<UserApi_signInPerDomain$Response>> {
    const localVarAxiosArgs = await UserApi_signInPerDomainParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_signInWithGithub$Response = void;

/**
  * Sign in with github account and create a session
  * @param {GithubSignIn} githubDetails github details
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_signInWithGithub(data: UserApi_signInWithGithub$Params, options?: any): Promise<AxiosResponse<UserApi_signInWithGithub$Response>> {
    const localVarAxiosArgs = await UserApi_signInWithGithubParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_signInWithGoogle$Response = void;

/**
  * Sign in with github account and create a session
  * @param {GoogleSignIn} googleDetails google details
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_signInWithGoogle(data: UserApi_signInWithGoogle$Params, options?: any): Promise<AxiosResponse<UserApi_signInWithGoogle$Response>> {
    const localVarAxiosArgs = await UserApi_signInWithGoogleParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_signInWithOkta$Response = void;

/**
  * Sign in with okta account and create a session
  * @param {OktaSignIn} oktaDetails github details
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_signInWithOkta(data: UserApi_signInWithOkta$Params, options?: any): Promise<AxiosResponse<UserApi_signInWithOkta$Response>> {
    const localVarAxiosArgs = await UserApi_signInWithOktaParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_signInWithStripe$Response = void;

/**
  * Sign in with stripe account and create a session
  * @param {StripeSignIn} stripeDetails stripe details
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_signInWithStripe(data: UserApi_signInWithStripe$Params, options?: any): Promise<AxiosResponse<UserApi_signInWithStripe$Response>> {
    const localVarAxiosArgs = await UserApi_signInWithStripeParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_subscribeToArray$Response = void;

/**
  * Subscribe to an array set up for monetization
  * @param {string} namespace Namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_subscribeToArray(data: UserApi_subscribeToArray$Params, options?: any): Promise<AxiosResponse<UserApi_subscribeToArray$Response>> {
    const localVarAxiosArgs = await UserApi_subscribeToArrayParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_tokensGet$Response = Array<Token>;

/**
  * Fetch a list of user tokens
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_tokensGet(data: UserApi_tokensGet$Params, options?: any): Promise<AxiosResponse<UserApi_tokensGet$Response>> {
    const localVarAxiosArgs = await UserApi_tokensGetParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_unsubscribeFromArray$Response = void;

/**
  * Unsubscribe to an array set up for monetization
  * @param {string} namespace Namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_unsubscribeFromArray(data: UserApi_unsubscribeFromArray$Params, options?: any): Promise<AxiosResponse<UserApi_unsubscribeFromArray$Response>> {
    const localVarAxiosArgs = await UserApi_unsubscribeFromArrayParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_updateCredential$Response = void;

/**
  * Update the named access credential. This will also update the information used to access arrays set to use this credential.
  * @param {string} namespace namespace
  * @param {string} name A URL-safe version of the credential\&#39;s user-provided name
  * @param {AccessCredentialUpdate} accessCredential Changes to make to this credential
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_updateCredential(data: UserApi_updateCredential$Params, options?: any): Promise<AxiosResponse<UserApi_updateCredential$Response>> {
    const localVarAxiosArgs = await UserApi_updateCredentialParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_updatePricing$Response = void;

/**
  * Update pricing info for a specified URI in a namespace
  * @param {string} namespace namespace array is in (an organization name or user\&#39;s username)
  * @param {string} array name/uri of array that is url-encoded
  * @param {PricingInfo} pricingInfo Pricing metadata to update
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_updatePricing(data: UserApi_updatePricing$Params, options?: any): Promise<AxiosResponse<UserApi_updatePricing$Response>> {
    const localVarAxiosArgs = await UserApi_updatePricingParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_userRegistration$Response = void;

/**
  * Register a new user
  * @param {UserSignupParams} signupParams user being registered
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_userRegistration(data: UserApi_userRegistration$Params, options?: any): Promise<AxiosResponse<UserApi_userRegistration$Response>> {
    const localVarAxiosArgs = await UserApi_userRegistrationParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};

export type UserApi_userUpdate$Response = void;

/**
  * Update user details
  * @param {UserUpdateParams} user user details to update
  * @param {*} [options] Override http request option.
  * @throws {RequiredError}
  */
export async function UserApi_userUpdate(data: UserApi_userUpdate$Params, options?: any): Promise<AxiosResponse<UserApi_userUpdate$Response>> {
    const localVarAxiosArgs = await UserApi_userUpdateParamCreator(data, options);
    const axiosRequestArgs = {...localVarAxiosArgs.options, url: localVarAxiosArgs.url};
    return axios.request(axiosRequestArgs);
};


