import {
  UserApi_getHasSeenV2Status,
  UserApi_getHasSeenV2Status$Params,
  UserApi_getHasSeenV2Status$Path,
  UserApi_getHasSeenV2Status$Response,
} from 'api-client';
import { AxiosResponse } from 'axios';
import { UseRequestType, useQueryRequest } from 'hooks/useRequest';
import { useQuery } from '@tanstack/react-query';

const useGetHasSeenV2: UseRequestType<
  UserApi_getHasSeenV2Status$Params,
  UserApi_getHasSeenV2Status$Response
> = (queryData, config, queryConfig) => {
  const { isLoading, data, error, refetch } = useQuery<
    AxiosResponse<UserApi_getHasSeenV2Status$Response>,
    UserApi_getHasSeenV2Status$Params
  >(
    [UserApi_getHasSeenV2Status$Path],
    useQueryRequest(UserApi_getHasSeenV2Status, config),
    queryConfig
  );

  return [refetch, data, error, isLoading];
};

export default useGetHasSeenV2;
