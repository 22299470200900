import useGetHasSeenV2 from 'hooks/api/User/useGetHasSeenV2';
import useUpdateHasSeenV2 from 'hooks/api/User/useUpdateHasSeenV2';
import React, { useEffect, useState } from 'react';
import useModal from 'store/ModalStore';

const V2NewLookIndicator: React.FC = () => {
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [, response, , loading] = useGetHasSeenV2(undefined, {
    noSnackbar: true,
  });
  const [updateHasSeenV2] = useUpdateHasSeenV2();
  const { showNewLookModal, flushModals } = useModal((state) => ({
    showNewLookModal: state.showNewLookModal,
    flushModals: state.flushModals,
  }));

  useEffect(() => {
    if (!loading && !!response) {
      setShouldShowModal(response?.data.has_seen_v2 === false);
    }
  }, [loading, response]);

  useEffect(() => {
    if (shouldShowModal) {
      showNewLookModal({
        onSuccess: async () => {
          await updateHasSeenV2([undefined]);
          flushModals();
        },
      });
    }
  }, [shouldShowModal, showNewLookModal, updateHasSeenV2, flushModals]);

  return null;
};

export default V2NewLookIndicator;
