import Typography from 'components/Typography';
import React from 'react';
import Button, { ButtonProps } from 'components/v2/Button';
import { motion } from 'framer-motion';
import styles from './Placeholder.module.scss';
import classNames from 'classnames';

export type PlaceholderGraphic =
  | 'asset-sharing'
  | 'no-api-tokens'
  | 'no-recently-created'
  | 'no-asset-activity'
  | 'no-servers'
  | 'no-notifications'
  | 'no-description'
  | 'no-assets'
  | 'no-search-results'
  | 'no-task-graphs'
  | 'no-tasks'
  | 'no-active-apps'
  | 'no-metadata'
  | 'no-marketplace-results'
  | 'no-payment-method'
  | 'no-invoices'
  | 'no-cloud-credentials'
  | 'no-asset-preview'
  | 'unauthorized-access'
  | 'unexpected-error'
  | 'no-contents-groups'
  | 'add-member';

interface Props {
  graphic: PlaceholderGraphic;
  title?: string;
  description?: string;
  actions?: ButtonProps[];
  customActionComponent?: React.ReactNode;
  dataTestId?: string;
}

const Placeholder: React.FC<Props> = (props) => {
  const {
    graphic,
    title,
    description,
    actions = [],
    dataTestId,
    customActionComponent,
  } = props;

  const style = {
    top: -16,
    opacity: 0,
  };
  const styleTop = {
    top: 16,
    opacity: 0,
  };
  const styleAnimate = {
    opacity: 1,
    top: 0,
  };
  const transition = { duration: 0.4 };

  return (
    <div className={styles.wrapper} data-testid={dataTestId}>
      <div className={styles.content}>
        {graphic && (
          <motion.div
            style={style}
            transition={transition}
            animate={styleAnimate}
            exit={style}
            className={styles['graphic-wrapper']}
          >
            <div
              data-testid="placeholder-background"
              className={classNames(
                styles['background-wrapper'],
                styles[`${graphic}--background`]
              )}
            />
            <div
              data-testid="placeholder-img"
              className={classNames(
                styles['graphic-img'],
                styles[`${graphic}--img`]
              )}
            />
          </motion.div>
        )}
        <motion.div
          style={styleTop}
          transition={transition}
          animate={styleAnimate}
          exit={styleTop}
          className={styles.main}
        >
          <div>
            <Typography
              as="h5"
              fontSize="heading-sm"
              fontWeight="700"
              className="mb-2"
              align="center"
            >
              {title}
            </Typography>
            <Typography align="center" as="p" fontSize="body-md">
              {description}
            </Typography>
          </div>
          {customActionComponent && (
            <div className={styles.buttons}>{customActionComponent}</div>
          )}
          {!!actions && actions.length > 0 && (
            <div className={styles.buttons}>
              {actions.map((btnProp, i) => (
                <Button key={`${i}-${btnProp.title}`} {...btnProp} />
              ))}
            </div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default Placeholder;
