import React, { useContext, useEffect, useState, useRef } from 'react';
import useGetUser from 'hooks/api/User/useGetUser';
import { EnabledFeature, InlineResponse200, User } from 'api-client';
import isAxiosError from 'utils/helpers/isAxiosError';
import { RefetchType } from 'hooks/useRequest';
import { AxiosResponse } from 'axios';
import eventBus, { EventTypes } from 'utils/helpers/eventBus';
import useMount from 'hooks/useMount';
import Loader from 'components/Loader';
import { authLinks } from 'utils/links';
import history from 'utils/helpers/history';
import queryClient from 'utils/helpers/queryClient';
import useCheckLoginCookie from 'hooks/api/User/useCheckLoginCookie';
import JupyterStore from 'store/JupyterStore';

/**
 * Initial store
 */
export const store: any = {};

const loadedUserChannel = 'LOADED_USER';
// Channel to broadcast and sync user data across all tabs
const tabChannel = new BroadcastChannel(loadedUserChannel);

/**
 * Create user context
 */
const UserContext = React.createContext(store);

interface UserProviderProps extends React.PropsWithChildren {
  defaultUser?: User | false;
}

export const UserProvider: React.FC<UserProviderProps> = ({
  children,
  defaultUser,
}) => {
  const [user, setUser] = useState<User | undefined>(defaultUser as User);
  const setSelectedRegion = JupyterStore((state) => state.setSelectedRegion);
  const selectedRegion = JupyterStore((state) => state.selectedRegion);

  /**
   * Make a request to check the existence of a login cookie
   */
  const [
    checkLogin,
    checkLoginCookieResponse,
    checkLoginCookieError,
    checkLoginCookieLoading,
  ] = useCheckLoginCookie(
    undefined,
    {
      noSnackbar: true,
    },
    {
      enabled: defaultUser === undefined,
    }
  );
  /**
   * GET user object only if:
   * - there is no default user passed
   * - check_login response has finished and the value is true
   * - or check_login response has finished with an error
   */
  const shouldFetchUser =
    defaultUser === undefined &&
    (!!checkLoginCookieResponse?.data?.status || !!checkLoginCookieError);

  /**
   * userRef is used in the logout event listener
   */
  const userRef = useRef(user);
  const [manuallyFetchUser, userResponse, userError, userInfoLoading] =
    useGetUser(
      undefined,
      { noSnackbar: true },
      {
        enabled: shouldFetchUser,
      }
    );

  useEffect(() => {
    /**
     * This fixes an important bug which causes the user to get stuck in login page
     * Since the useGetUser hook is conditonally enabled,
     * the automatic request may stuck, and when switching from false to true it may not fire the request
     * This must be a react-query bug (the `enabled` property has serious issues anyway).
     *
     * The code below will enforce the http request if shouldFetchUser is true
     * while there is no response cached
     *
     * How to reproduce this (important):
     * - Run the whole stack locally
     * - Log in
     * - Open the getUser handler in mvp go and hardcode a 500 error response and re-run mvp go server
     * - Refresh the page, you will have valid login cookie but you will get an error on getUser which will log you out
     * - Revert the change in mvp go and re-run it
     * - Log in
     * - You are stuck
     *
     * We should refactor the whole auth state logic across the app since this approach has a lot of problems
     * and this is a cheap fix
     */
    if (shouldFetchUser && !userResponse) {
      manuallyFetchUser();
    }
  }, [shouldFetchUser, userResponse, manuallyFetchUser]);

  /**
   * Keep userRef in sync with user
   */
  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    if (user?.default_region && !selectedRegion) {
      setSelectedRegion(user.default_region);
    }
  }, [user, setSelectedRegion, selectedRegion]);

  /**
   * Indicates user login status
   */
  const isLoggedIn = !!user;

  /**
   * Sync user data from other tabs
   */
  tabChannel.onmessage = (ev: MessageEvent): void => {
    setUser(ev.data);
  };

  useEffect(() => {
    const userData = userResponse?.data;

    if (userData) {
      tabChannel.postMessage(userData);

      /**
       * Update user object every time fetchUser is called
       */
      setUser(userData);
    }
  }, [userResponse?.data]);

  useEffect(() => {
    /**
     * We care mostly for 500s, since 401 errors are handled
     * from the useRequest hook.
     */
    if (isAxiosError(userError) && userError.response?.status !== 401) {
      if (isLoggedIn) {
        history.push(authLinks.logout());
      }
    }
  }, [userError, isLoggedIn]);

  function hasEnabledFeature(feature: EnabledFeature): boolean | undefined {
    return user?.enabled_features?.includes(feature);
  }

  /**
   * Publish values to be used inside our components
   */
  const providerValue = {
    user,
    isLoggedIn,
    hasEnabledFeature,
    actions: {
      checkLogin,
    },
  };

  useMount(() => {
    eventBus.on(EventTypes.LOGOUT, () => {
      /**
       * If there are user data, clear queryClient and user
       */
      if (!!userRef.current) {
        queryClient.clear();
        setUser(undefined);
      }
    });
  });

  if (
    (checkLoginCookieLoading || userInfoLoading || userResponse?.data) &&
    !user
  ) {
    /**
     * Render skeleton if user data still loading
     * or if user data are fetched but not set to local state yet
     */
    return <Loader />;
  }

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};

export const withUserProvider = (
  ChildComponent: any,
  defaultUser?: User
): any => {
  const WithUserProvider = (props: any): any => {
    return (
      <UserProvider defaultUser={defaultUser}>
        <ChildComponent {...props} />
      </UserProvider>
    );
  };
  return WithUserProvider;
};

interface UserContextType {
  user: User;
  isLoggedIn: boolean;
  hasEnabledFeature: (feature: EnabledFeature) => boolean;
  actions: {
    checkLogin: RefetchType<AxiosResponse<InlineResponse200, any>>;
  };
}

/**
 * Export user pre-defined context
 */
export function useUserContext(): UserContextType {
  return useContext(UserContext);
}

export default UserContext;
